import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Segment, Container, Header, Card, List, Button} from "semantic-ui-react";
import {Link} from "react-router-dom";

class NoRecruitingSeasonComponent extends Component {
    render() {
        return (
            <Fragment>
                <Container as={Segment} basic text style={{marginBottom: 50}}>
                    <Header textAlign={'center'} size={'huge'}>
                        <Header.Subheader style={{}}>
                            모집 기간이 아닙니다.
                        </Header.Subheader>
                        현재 모집중인 시즌이 없습니다
                    </Header>
                </Container>
                <Container text style={{marginBottom: 50}}>
                    <Card fluid raised>
                        <Card.Content>
                            <List bulleted>
                                <List.Item>
                                    매년 봄(3~6월), 가을(9~12월)에 운영됩니다.
                                </List.Item>
                                <List.Item>
                                    봄시즌은 2월, 가을 시즌은 8월에 모집합니다.
                                </List.Item>
                                <List.Item>
                                    궁금하신 사항이 있으신가요? <a href={'http://pf.kakao.com/_ptxdSxd/chat'}
                                                       target={'_blank'}>
                                    카카오톡
                                </a>으로 문의하기
                                </List.Item>
                            </List>
                        </Card.Content>
                        <Card.Content textAlign={'center'}>

                            <Button fluid color={'blue'} as={Link} target={'_blank'}
                                    to={{pathname: 'https://goo.gl/forms/zVIO6GaImLUtj5bw2'}}>
                                모집 알림 신청하기
                            </Button>
                        </Card.Content>
                    </Card>
                </Container>
            </Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NoRecruitingSeasonComponent);
