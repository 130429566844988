import React, {Component} from 'react';
import {Segment, Form, Table, Container, Button, Card, Header, Divider} from 'semantic-ui-react'
import {db} from "../../config/firebase";

class AdminTeamComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchedMember: [],
            searchMode: false,
            searchName: '',
        }
    }

    _searchMembers = () => {
        const {searchName} = this.state;
        db.collection('members')
            .where('name', '==', searchName)
            .get()
            .then(snapshot => {
                let searchedMember = [];
                snapshot.forEach(doc => {
                    let member = doc.data();
                    member.id = doc.id;
                    searchedMember.push(member);
                })
                this.setState({
                    searchedMember
                })
            })
    }


    render() {
        const {teamIndex} = this.props;
        const {members, title} = this.props.team;
        return (
            <Card fluid>
                <Card.Content>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>번호</Table.HeaderCell>
                                <Table.HeaderCell>이름</Table.HeaderCell>
                                <Table.HeaderCell>이메일</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                members.map((member, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{i + 1}</Table.Cell>
                                            <Table.Cell>{member.name}</Table.Cell>
                                            <Table.Cell>{member.email}</Table.Cell>
                                            <Table.Cell>
                                                <Button basic onClick={() => {
                                                    this.props.removeMember(teamIndex, i)
                                                }}>
                                                    [삭제하기]
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Card.Content>
                <Card.Content>
                    <Form>
                        <Form.Input
                            value={this.state.searchName}
                            onChange={(e, d) => {
                                this.setState({
                                    searchName: d.value
                                })
                            }}
                        />
                        <Button onClick={() => {
                            this.setState({
                                searchMode: true,
                            })
                            this._searchMembers()
                        }}>
                            검색
                        </Button>
                        <Button onClick={() => {
                            this.setState({
                                searchMode: false,
                            })
                        }}>
                            닫기
                        </Button>
                    </Form>

                </Card.Content>
                {
                    this.state.searchMode && (
                        <Card.Content>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>번호</Table.HeaderCell>
                                        <Table.HeaderCell>이름</Table.HeaderCell>
                                        <Table.HeaderCell>이메일</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        this.state.searchedMember.map((member, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell>{i + 1}</Table.Cell>
                                                    <Table.Cell>{member.name}</Table.Cell>
                                                    <Table.Cell>{member.email}</Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }
                                </Table.Body>
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <Button onClick={() => {
                                                this.props.addMember(teamIndex)
                                            }}>
                                                추가하기
                                            </Button>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell colSpan='5'>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Card.Content>
                    )
                }


            </Card>
        );
    }
}


export default AdminTeamComponent;
