import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Responsive,
    Container,
    Segment,
    Grid,
    Item,
    Card,
    Image,
    Label,
    Header,
    Button,
    List,
    Modal,
    Icon
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {Parallax} from 'react-scroll-parallax';

import {BDCOLOR, BDCOLOR_GREY, BDCOLOR_SUB2} from "../../constants/BDBrand";
import {goalExample} from "../../data/bd_goals";
import {essayExample} from "../../data/bd_essay";
import {teams, wholeMeeting} from "../../data/bd_team";
import Analytics from "../../middleware/Analytics";

const propTypes = {};
const defaultProps = {};

const styles = {
    main1: {
        height: '100vh',
        backgroundColor: BDCOLOR,
    },
    main2: {
        height: '100vh',
        backgroundColor: BDCOLOR_GREY,
    },
    main3: {
        height: '100vh',
        backgroundColor: 'white',
    },
    mainHeaderWhite: {
        fontSize: '3em',
        lineHeight: '1.3em',
        color: 'white',
    },
    mainHeader: {
        fontSize: '3em',
        lineHeight: '1.3em',
    },
    subHeader: {
        fontSize: '2em',
        lineHeight: '1.3em',
        color: BDCOLOR
    },
    mainDescription: {
        fontSize: '17px',
        margin: '5px'
    }

};

class Home extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        modalOpen: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Analytics.pageViewAll('/')
    }

    handleClose = () => this.setState({modalOpen: false})

    render() {
        return (
            <div>
                <Segment vertical basic style={styles.main1}>
                    <Grid as={Container} style={styles.main1} verticalAlign={'middle'} columns={2} stackable>

                        <Grid.Column width={6}>
                            <div style={styles.mainHeaderWhite}> Being&Doing</div>
                        </Grid.Column>
                        <Grid.Column width={10} style={{color: 'white'}}>
                            <Parallax
                                offsetYMax={'-20%'}
                                offsetYMin={'20%'}
                                slowerScrollRate
                            >
                                <div style={styles.mainDescription}>
                                    <p>내가 원하는 것을 성취하면서 인생을 살기위해 노력합니다.</p>
                                    <p>우리는 꿈을 찾고, 꿈을 달성하기 위한 4개월의 목표를 설정하고,</p>
                                    <p>성취해 나가는 과정을 통해 성장하고, 인생의 의미를 찾을 수 있다고 믿습니다.</p>
                                </div>
                            </Parallax>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment vertical basic style={styles.main2}>

                    <Grid as={Container} style={styles.main2} verticalAlign={'middle'} columns={2} stackable>
                        <Grid.Column width={6}>
                            <Parallax
                                offsetYMax={'350%'}
                                offsetYMin={'-10%'}
                                slowerScrollRate
                            >
                                <div style={styles.mainHeader}>
                                    What We Do
                                </div>
                            </Parallax>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Parallax
                                offsetYMax={'-20%'}
                                offsetYMin={'20%'}
                                slowerScrollRate
                            >
                                <div style={styles.subHeader}>
                                    Being
                                </div>
                                <div style={styles.mainDescription}>
                                    <p>나를 인식하고, 미래를 구체화 할 수 있도록 생각을 글로 나타냅니다.</p>
                                </div>
                                {
                                    essayExample.map((essay, i) => {
                                        return (
                                            <Segment basic key={i}>
                                                <Image src={essay.thumbnail} size='mini'
                                                       floated='left'/> {essay.title}
                                            </Segment>
                                        )
                                    })
                                }
                            </Parallax>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment vertical basic style={styles.main2}>
                    <Grid as={Container} style={styles.main2} verticalAlign={'middle'} columns={2} stackable>
                        <Grid.Column width={6}>
                            <Parallax
                                offsetYMax={'350%'}
                                offsetYMin={'-350%'}
                                slowerScrollRate
                            >
                                <div style={styles.mainHeader}>
                                </div>
                            </Parallax>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Parallax
                                offsetYMax={'-20%'}
                                offsetYMin={'20%'}
                                slowerScrollRate
                            >
                                <div style={styles.subHeader}>
                                    Doing
                                </div>
                                <div style={styles.mainDescription}>
                                    <p>4개월 동안 반드시 달성할 목표를 세우고</p>
                                    <p>매일의 노력을 쌓아서 목표를 달성합니다</p>
                                    <Card.Group itemsPerRow={6}>
                                        {
                                            goalExample.map((goal, i) => {
                                                return (
                                                    <Card key={i}>
                                                        <Image src={goal.image}/>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Card.Group>
                                </div>
                            </Parallax>

                        </Grid.Column>
                    </Grid>

                </Segment>
                <Segment vertical basic style={styles.main2}>
                    <Grid as={Container}
                          style={styles.main2
                          }
                          verticalAlign={'middle'}
                          columns={2}
                          stackable
                    >
                        <Grid.Column width={6}>
                            <Parallax
                                offsetYMax={'350%'}
                                offsetYMin={'-350%'}
                                slowerScrollRate
                            >
                                <div style={styles.mainHeader}>
                                    Team Meeting
                                </div>
                            </Parallax>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Parallax
                                offsetYMax={'-20%'}
                                offsetYMin={'20%'}
                                slowerScrollRate
                            >
                                <div style={styles.mainDescription}>
                                    <p>4개월 동안 2주마다 한번씩 총 8번 </p>
                                    <p>팀모임에서 팀 동료들을 만납니다.</p>
                                    <p>주말 하루 성장을 위한 시간으로 시작합니다</p>
                                </div>
                                <Card.Group itemsPerRow={3}>

                                    {
                                        teams.map((team, i) => {
                                            return (
                                                <Card key={i}>
                                                    <Image src={team}/>
                                                </Card>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Parallax>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment vertical basic style={styles.main2}>
                    <Grid as={Container}
                          style={styles.main2
                          }
                          verticalAlign={'middle'}
                          columns={2}
                          stackable
                    >
                        <Grid.Column width={6}>
                            <Parallax
                                offsetYMax={'350%'}
                                offsetYMin={'-350%'}
                                slowerScrollRate
                            >
                                <div style={styles.mainHeader}>
                                    전체 모임
                                </div>
                            </Parallax>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Parallax
                                offsetYMax={'-20%'}
                                offsetYMin={'20%'}
                                slowerScrollRate
                            >
                                <div style={styles.subHeader}>
                                    BD Talk
                                </div>
                                <div style={styles.mainDescription}>
                                    <p>Crew의 좋은 아이디어와 경험을 듣고
                                        나를 더 발전 시킬 수 있습니다.</p>
                                </div>
                                <br/>
                                <div style={styles.subHeader}>
                                    Closing
                                </div>
                                <div style={styles.mainDescription}>
                                    <p>시즌이 끝날 때, 모든 Crew가 모여 축하하고 친해질 수 있는 기회를 갖습니다.</p>
                                </div>
                                <Card.Group itemsPerRow={3}>

                                    {
                                        wholeMeeting.map((a, i) => {
                                            return (
                                                <Card key={i}>
                                                    <Image src={a}/>
                                                </Card>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Parallax>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment vertical basic style={styles.main2}>
                    <Grid as={Container}
                          style={styles.main2
                          }
                          verticalAlign={'middle'}
                          stackable
                    >
                        <Grid.Column style={{textAlign: 'center'}}>
                            <div style={styles.subHeader}>
                                내가 원하는 나의 미래를 만들기 위해 함께할 동료를 모집합니다. <br/>
                            </div>
                            <br/>
                            <Button as={Link} to={'/recruit'} size={'big'} color={'blue'}>
                                지원하러 가기
                            </Button>
                            <Button as={Link} to={'/program'} size={'big'} color={'teal'}>
                                프로그램 보기
                            </Button>
                        </Grid.Column>
                    </Grid>

                </Segment>


                <Modal open={this.state.modalOpen}>
                    <Modal.Content>
                        <Container textAlign={'center'} closeIcon={true}>
                            <Header content='Recruiting Now'/>
                            <List>
                                <List.Item>2018년 Spring Season BD와 함께할 Crew를 모집합니다.</List.Item>
                                <List.Item>지원기간: 2/22(목) ~ 2/23(수)23:59</List.Item>
                                <List.Item>OT: 3/10(토) 10:00 </List.Item>
                            </List>
                            <Button as={Link} to={'/program'} content={'자세히 보기'} color={'blue'}/>
                            <Button color={'black'} content={'닫기'} onClick={this.handleClose}/>
                        </Container>
                    </Modal.Content>
                </Modal>

            </div>
        );
    }
};

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
