import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container, Segment, Grid} from 'semantic-ui-react';

const propTypes = {};
const defaultProps = {};


class backgroundImage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Segment
                vertical
                basic
                /*style={/!*{
                    marginTop: '50px',
                    paddingTop: '350px',
                    width: '100%',
                    minHeight: '450px',
                    backgroundImage: this.props.imageUrl,
                    backgroundSize: '100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    margin: '0px',
                    textAlign: 'center',
                    // backgroundAttachment: 'fixed'
                }*!/
                }*/
            >
                <Grid
                    style={{
                        height: '50vh',
                        backgroundImage: this.props.imageUrl,
                        backgroundSize: 'cover' ,
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        mozBackgroundSize: 'cover',
                        oBackgroundSize: 'cover',

                    }}
                    verticalAlign='bottom'
                    textAlign='center'

                >
                    <Grid.Column>
                        <h2 style={{
                            color: this.props.fontColor
                        }}>
                            {this.props.children}
                        </h2>
                    </Grid.Column>
                </Grid>

            </Segment>
        );
    }
};

backgroundImage.propTypes = propTypes;
backgroundImage.defaultProps = defaultProps;

export default backgroundImage;
