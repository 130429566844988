import React, {Component, Fragment} from 'react';
import {db, firebase} from "../../config/firebase";
import {
    Loader,
    Segment,
    Container,
    Header,
    Card,
    Grid,
    Message,
    Divider,
    Statistic,
    Step,
    Button,
    Icon,
    Table
} from 'semantic-ui-react'
import {categoryInfo, stepInfo} from "./bdPaperInfo";
import {withLineEnter} from "../../middleware/stringHandler";
import {BDCOLOR, BDCOLOR_GREY, BDCOLOR_SUB, BDCOLOR_SUB2} from "../../constants/BDBrand";
import moment from 'moment';


class MemberBdPaperDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bdPaper: {},
            stepNumber: 0,
            isLoaded: false,
        }
    }


    componentDidMount() {
        this._loadBdPaper()

    }

    componentWillUnmount() {
    }

    _loadBdPaper = () => {
        let {bdPaperId} = this.props.match.params;
        let userEmail = firebase.auth().currentUser.email;

        db.collection('bdPapers')
            .doc(bdPaperId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    window.alert('존재하지 않는 BD Paper 입니다.')
                    this.props.history.goBack();
                    return;
                }
                let bdPaper = doc.data();
                bdPaper.id = doc.id;

                if (!((bdPaper.email === userEmail) || (bdPaper.email === 'yhyun0513@gmail.com'))) {
                    window.alert('본인의 BD Paper에만 접근할 수 있습니다.')
                    this.props.history.goBack();
                    return;
                }
                this._checkEditable(bdPaper)
            })
            .catch(e => {
                console.log(e);
            })
    }

    _checkEditable = async (bdPaper) => {
        let seasonId = bdPaper.season;
        bdPaper.editable = await db.collection('seasons')
            .doc(seasonId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    return false;
                }
                let season = doc.data();
                return season.isOngoing;
            })
            .catch(e => {
                console.log(e);
                return false;
            });

        console.log({
            season: bdPaper.season,
            editable: bdPaper.editable
        })
        this.setState({
            bdPaper,
            isLoaded: true,
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div>
                    <Loader active>BD Paper를 불러오고 있습니다.</Loader>
                </div>
            );
        }
        return (
            <Fragment>
                <Container style={{
                    margin: 20,
                    marginTop: 10,
                    padding: 40,
                    border: '0.1px solid',
                    borderColor: 'grey',
                    flex: 1,
                    backgroundColor: 'white'
                }}>
                    <Header size="huge" textAlign="center">
                        BD Paper
                        <Header.Subheader>
                            최초 작성일: {moment(this.state.bdPaper.createdAt.seconds * 1000).format('YYYY. MM. DD (ddd)')}
                        </Header.Subheader>
                    </Header>
                </Container>
                <Container style={{
                    margin: 20,
                    marginTop: 10,
                    padding: 40,
                    border: '0.1px solid',
                    borderColor: 'grey',
                    flex: 1,
                    backgroundColor: 'white'
                }}>
                    {this.renderTab(1, this.state.bdPaper)}
                </Container>
                <Container style={{
                    margin: 20,
                    marginTop: 10,
                    padding: 40,
                    border: '0.1px solid',
                    borderColor: 'grey',
                    flex: 1,
                    backgroundColor: 'white'
                }}>
                    {this.renderTab(3, this.state.bdPaper)}
                </Container>
                <Container style={{
                    margin: 20,
                    marginTop: 10,
                    padding: 40,
                    border: '0.1px solid',
                    borderColor: 'grey',
                    flex: 1,
                    backgroundColor: 'white'
                }}>
                    {this.renderTab(5, this.state.bdPaper)}
                </Container>
                <Container style={{
                    margin: 20,
                    marginTop: 10,
                    padding: 40,
                    border: '0.1px solid',
                    borderColor: 'grey',
                    flex: 1,
                    backgroundColor: 'white'
                }}>
                    {this.renderTab(6, this.state.bdPaper)}
                </Container>
            </Fragment>
        );
    }

    renderTab = (pageNumber, bdPaper) => {
        switch (pageNumber) {
            case 0:
                return (
                    <Segment basic>
                        <Header size="huge" textAlign="center">
                            BD Paper
                        </Header>
                        <Grid as={Container} text>
                            {stepInfo.map((step, i) => {
                                if (i === 0) return null;
                                return (
                                    <Grid.Row key={i}>
                                        <Grid.Column width={2}>
                                            <Statistic size="tiny">
                                                <Statistic.Value>{i}</Statistic.Value>
                                                <Statistic.Label>단계</Statistic.Label>
                                            </Statistic>
                                        </Grid.Column>
                                        <Grid.Column width={14}>
                                            <Header size="small">{step.title}</Header>
                                            {step.description}
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    </Segment>
                );
            case 1:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>1</Statistic.Value>
                                <Statistic.Label>To-Be도출</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">내가 되고 싶은 나의 모습은 어떤가요? 생각하고 상상해 봅시다</Header>
                        <Grid>
                            {bdPaper.tobe.map((tobe, i) => {
                                const {title} = categoryInfo(tobe.categoryName);

                                return (
                                    <Grid.Column width={8} key={i}>
                                        <Header size="medium" color="blue">
                                            {title}
                                        </Header>
                                        <Divider/>
                                        <Header size="tiny" color="grey">
                                            {categoryInfo(tobe.categoryName).question1}
                                        </Header>
                                        <Message>{withLineEnter(tobe.answer1)}</Message>
                                        <Divider hidden/>
                                        <Header size="tiny" color="grey">
                                            {categoryInfo(tobe.categoryName).question2}
                                        </Header>
                                        <Message>{withLineEnter(tobe.answer2)}</Message>
                                        <Divider hidden/>
                                        <Header size="tiny" color="grey">
                                            {categoryInfo(tobe.categoryName).question3}
                                        </Header>
                                        <Message>{withLineEnter(tobe.answer3)}</Message>
                                    </Grid.Column>
                                );
                            })}
                        </Grid>
                        <Divider hidden section/>
                        <Divider horizontal>
                            <Header>정리</Header>
                        </Divider>
                        <Divider hidden section/>
                        <Grid>
                            <Grid.Row columns={4}>
                                {bdPaper.tobe.map((tobe, i) => {
                                    return (
                                        <Grid.Column textAlign="center" key={i}>
                                            <Header color="blue">{categoryInfo(tobe.categoryName).title}</Header>
                                            <p style={{fontWeight: '900'}}>{withLineEnter(tobe.answer3)} </p>
                                        </Grid.Column>
                                    );
                                })}
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column textAlign="center">
                                    <Header color="blue">미래의 나를 한마디로</Header>
                                    <p>나는 (4)무엇을 위해, (1)어떤 사람이 되어서, (2)어떤 일을 하면서, (3)어떻게 사는 인생을 살고 있을 거에요.</p>
                                    <Message warning>{withLineEnter(bdPaper.oneSentence)}</Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                );
            case 2:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>2</Statistic.Value>
                                <Statistic.Label>핵심 역량 도출</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">내가 원하는 내가 되기 위해 중요한/필요한 역량은 무엇인가요? 나를 구성하는 주요 영역별로 핵심역량을 작성해
                            봅시다.</Header>

                        <Message warning>
                            <Message.Header>내가 원하는 나의 모습 </Message.Header>
                            <Message.Content> {withLineEnter(bdPaper.oneSentence)}</Message.Content>
                        </Message>
                        <Grid padded divided="vertically">
                            <Grid.Row>
                                <Grid.Column width={2}>영역</Grid.Column>
                                <Grid.Column width={14}>역량</Grid.Column>
                            </Grid.Row>
                            {bdPaper.goalPackages.map((area, i) => {
                                return (
                                    <Grid.Row key={i}>
                                        <Grid.Column width={2}
                                                     style={{
                                                         backgroundColor: BDCOLOR_SUB,
                                                         color: 'white',
                                                         display: 'flex',
                                                         flexDirection: 'column',
                                                         justifyContent: 'center'
                                                     }}
                                                     textAlign={'center'}>
                                            {area.areaName}
                                        </Grid.Column>
                                        <Grid.Column width={14}>
                                            {area.capability.map((capa, i) => {
                                                return (
                                                    <Card key={i}>
                                                        <Card.Content>{capa.name}</Card.Content>
                                                    </Card>
                                                );
                                            })}
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    </Segment>
                );
            case 3:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>3</Statistic.Value>
                                <Statistic.Label>역량별 As-Is분석</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">내가 원하는 내가 되기 위해 중요한/필요한 역량은 무엇인가요? 나를 구성하는 주요 영역별로 핵심역량을 작성해
                            봅시다.</Header>
                        <Message warning>
                            <Message.Header>내가 원하는 나의 모습 </Message.Header>
                            <Message.Content> {withLineEnter(bdPaper.oneSentence)}</Message.Content>
                        </Message>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={2}>영역</Grid.Column>
                                <Grid.Column width={14}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={4}>역량</Grid.Column>
                                            <Grid.Column width={6}>AS-IS</Grid.Column>
                                            <Grid.Column width={6}>TO-BE</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            {bdPaper.goalPackages.map((area, i) => {
                                return (
                                    <Grid.Row key={i}>
                                        <Grid.Column width={2} style={{
                                            backgroundColor: BDCOLOR_SUB,
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            {area.areaName}
                                        </Grid.Column>
                                        <Grid.Column width={14}>
                                            <Grid>
                                                {area.capability.map((capa, i) => {
                                                    return (
                                                        <Grid.Row key={i}>
                                                            <Grid.Column width={4}>
                                                                <Card fluid>
                                                                    <Card.Content>{capa.name}</Card.Content>
                                                                </Card>
                                                            </Grid.Column>
                                                            <Grid.Column width={6}>
                                                                <Card fluid>
                                                                    <Card.Content>{withLineEnter(capa.asIs)}</Card.Content>
                                                                </Card>
                                                            </Grid.Column>
                                                            <Grid.Column width={6}>
                                                                <Card fluid>
                                                                    <Card.Content>{withLineEnter(capa.toBe)}</Card.Content>
                                                                </Card>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    </Segment>
                );
            case 4:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>4</Statistic.Value>
                                <Statistic.Label>TASK 도출</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header size="medium" textAlign="center">
                            핵심 역량을 달성하기 위해 당장 실행해야 할 세부 과제는 무엇인가요 As-Is에서 To-Be로 가기 위해 필요한 모든 TASK를 작성해보세요
                        </Header>
                        <Message warning>
                            <Message.Header>내가 원하는 나의 모습 </Message.Header>
                            <Message.Content> {withLineEnter(bdPaper.oneSentence)}</Message.Content>
                        </Message>
                        {bdPaper.goalPackages.map((area, i) => {
                            return (
                                <Fragment key={i}>
                                    <Divider hidden section/>
                                    <Header style={{color: BDCOLOR_SUB}}>
                                        {area.areaName}
                                    </Header>
                                    <Divider/>
                                    {area.capability.map((capa, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <Header>
                                                    {capa.name}
                                                    <Header.Subheader>
                                                        <span
                                                            style={{color: BDCOLOR_SUB}}>AS-IS</span> : {capa.asIs}
                                                        <br/>
                                                        <span style={{color: BDCOLOR_SUB}}>TO-BE</span> : {capa.toBe}
                                                    </Header.Subheader>
                                                </Header>
                                                <Card.Group>
                                                    {capa.task.map((task, i) => {
                                                        return (
                                                            <Card key={i}>
                                                                <Card.Content>
                                                                    <p>{task.name}</p>
                                                                </Card.Content>
                                                            </Card>
                                                        );
                                                    })}
                                                </Card.Group>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            );
                        })}
                    </Segment>
                );
            case 5:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>5</Statistic.Value>
                                <Statistic.Label>우선순위 화</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center" size="medium">
                            TASK중 이번 4개월 간 수행 할 최우선 과제는 무엇인가요 TASK의 “중요도”와 “실행 가능성” 기준으로 고민 해 봅시다
                        </Header>
                        <Message positive>
                            <Message.Header>목표 선정하기!</Message.Header>
                            <Message.Content>TASK들 중에서 이번 시즌 목표를 정해봅시다! 목표는 적을 수록 좋습니다. 중요한 소수의 목표를 집중해서 달성하는 것이
                                중요합니다.</Message.Content>
                        </Message>
                        {bdPaper.goalPackages.map((area, i) => {
                            return (
                                <div key={i} style={{marginTop: 50}}>
                                    <Header style={{color: BDCOLOR_SUB}} size={'huge'}>
                                        {i + 1}. {area.areaName}
                                    </Header>
                                    {area.capability.map((capa, j) => {
                                        return (
                                            <div key={j} style={{marginBottom: 70}}>
                                                <Header color={'blue'}>
                                                    {i + 1}.{j + 1}. {capa.name}
                                                </Header>
                                                <Card.Group itemsPerRow={2}>
                                                    <Card>
                                                        <Card.Content>
                                                            <Card.Header>
                                                                AS-IS
                                                            </Card.Header>
                                                            <Card.Description>
                                                                {withLineEnter(capa.asIs)}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                    <Card>
                                                        <Card.Content>
                                                            <Card.Header>
                                                                TO-BE
                                                            </Card.Header>
                                                            <Card.Description>
                                                                {withLineEnter(capa.toBe)}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                </Card.Group>
                                                <Card.Group itemsPerRow={3}>
                                                    {
                                                        capa.task.map((task, k) => {
                                                            return (
                                                                <Card key={k}>
                                                                    <Card.Content
                                                                        style={{fontWeight: '900'}}>
                                                                        <Header size={'small'} color={'grey'}>
                                                                            TASK {k + 1}
                                                                        </Header>
                                                                        {task.name}
                                                                    </Card.Content>
                                                                    <Card.Content>
                                                                        {task.selected && (
                                                                            <Fragment>
                                                                                <Icon name="check" color="green"/>
                                                                                <span
                                                                                    style={{color: 'green'}}> 이번기수 목표</span>
                                                                            </Fragment>
                                                                        )}
                                                                    </Card.Content>
                                                                </Card>
                                                            );
                                                        })
                                                    }
                                                </Card.Group>
                                            </div>
                                        )
                                    })}
                                </div>
                            );
                        })}
                    </Segment>
                );
            case 6:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>6</Statistic.Value>
                                <Statistic.Label>목표 구체화</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">이번 기수 목표를 구체화 시켜 봅시다.</Header>
                        {bdPaper.goalPackages.map((area, i) => {
                            return area.capability.map((capa, i) => {
                                return capa.task.map((task, i) => {
                                    if (!task.selected) return null;
                                    return (
                                        <Fragment key={i}>
                                            <Header size={'huge'}>
                                                {task.name}
                                            </Header>
                                            <Card.Group itemsPerRow={3}>
                                                <Card>
                                                    <Card.Content>
                                                        <Header>
                                                            {capa.name}
                                                        </Header>
                                                    </Card.Content>
                                                </Card>
                                                <Card>
                                                    <Card.Content>
                                                        <Header>
                                                            AS-IS
                                                        </Header>
                                                        {capa.asIs}
                                                    </Card.Content>
                                                </Card>
                                                <Card>
                                                    <Card.Content>
                                                        <Header>
                                                            TO-BE
                                                        </Header>
                                                        {capa.toBe}
                                                    </Card.Content>
                                                </Card>
                                            </Card.Group>
                                            {task.isSpecific ? (
                                                <Table>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell width={1}>주</Table.HeaderCell>
                                                            <Table.HeaderCell width={7}>상세 계획</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>25%</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>50%</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>75%</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>100%</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {task.plan_detail.map((plan, i) => {
                                                            return (
                                                                <Table.Row key={i}>
                                                                    <Table.Cell width={1}>{plan.week}</Table.Cell>
                                                                    <Table.Cell
                                                                        width={7}>{plan.description}</Table.Cell>
                                                                    <Table.Cell width={2}>{plan.three}</Table.Cell>
                                                                    <Table.Cell width={2}>{plan.six}</Table.Cell>
                                                                    <Table.Cell width={2}>{plan.nine}</Table.Cell>
                                                                    <Table.Cell width={2}>{plan.twelve}</Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            ) : (
                                                <Table>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell widt={4}>점수</Table.HeaderCell>
                                                            <Table.HeaderCell widt={12}>주별 달성 기준</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    {task.plan_short.map((plan, i) => {
                                                        return (
                                                            <Table.Body key={i}>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>25%</Table.Cell>
                                                                    <Table.Cell width={12}>{plan.three}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>50%</Table.Cell>
                                                                    <Table.Cell width={12}>{plan.six}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>75%</Table.Cell>
                                                                    <Table.Cell width={12}>{plan.nine}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>100%</Table.Cell>
                                                                    <Table.Cell width={12}>{plan.twelve}</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        );
                                                    })}
                                                </Table>
                                            )}
                                            <Divider section/>
                                        </Fragment>
                                    );
                                });
                            });
                        })}
                    </Segment>
                );
            default:
                return null;
        }
    };
}


export default MemberBdPaperDetailContainer;
