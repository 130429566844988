import React, {Component} from 'react';
import {db} from "../../config/firebase";
import {getBDMemberInfo} from "../../middleware/bdMemberInfo";
import {Container, Segment, Card,} from "semantic-ui-react";
import {Link} from "react-router-dom";

class MemberSeasonsMainContainers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teams: []
        }
    }


    componentDidMount() {
        this._getMemberInfo()
    }

    _getMemberInfo = async () => {
        const memberInfo = await getBDMemberInfo();
        this._loadTeamData(memberInfo.id);
    };

    _loadTeamData = (memberId) => {
        console.log(memberId);
        db.collection('teams').where(`membersIds.${memberId}`, ">", 0)
            .get()
            .then(snapshot => {
                const teams = [];
                snapshot.forEach(doc => {
                    let team = doc.data();
                    team.id = doc.id;
                    teams.push(team);
                });
                this.setState({
                    teams: teams
                });
                console.log(teams);
            })
            .catch(e => {
                console.log(e);
            })
    };

    render() {
        return (
            <div>
                {JSON.stringify(this.state.teams)}
                <Segment vertical basic as={Container}>
                    <Card.Group itemsPerRow={3}>
                        {
                            this.state.teams.map((team, i) => {
                                return (
                                    <Card key={i} as={Link} to={{pathname: `/app/team/${team.id}`}}>
                                        <Card.Content>
                                            {team.seasonId}
                                        </Card.Content>
                                        <Card.Content>
                                            {
                                                team.members.map((member, j) => {
                                                    return (
                                                        <p key={j}>
                                                            {member.name}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </Segment>
            </div>
        );
    }
}


export default MemberSeasonsMainContainers;
