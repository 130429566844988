import React, {Component} from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import {Card, Image,} from 'semantic-ui-react';

class TalkCardComponent extends Component {
    render() {
        const {talk} = this.props;
        return (
            <Card raised as={Link} to={{pathname: `/talk/${talk.id}`}}>
                <Image src={talk.image}/>
                <Card.Content>
                    <Card.Header size={'small'}>
                        {talk.title}
                    </Card.Header>
                    <Card.Meta>
                        {moment(talk.date.seconds * 1000).format('YYYY. MM. DD')}
                    </Card.Meta>
                    <Card.Description>
                        [{talk.seasonTitle} 시즌] {talk.speakerName}
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }
}


export default TalkCardComponent;
