import {createLogic} from 'redux-logic';
import {essayActionCreators, essayActions} from "./essayStore";
import Network from "../../middleware/Network";
import _ from 'lodash';


const fetchAllMyEssays = createLogic({
  type: essayActions.FETCH_ALL_MY_ESSAYS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const {member} = getState().auth;
    if (!member.id) return done();
    const memberId = member.id;
    const essays = await Network.getAllMyEssays(memberId);
    if (!essays.err)
      dispatch(essayActionCreators.fetchAllMyEssaysSuccess(_.orderBy(essays,['createdAt.seconds'],['desc'])));

    if (action.cb)
      action.cb();
    done();
  }
});



export default [
  fetchAllMyEssays,
]