import React, {Component} from "react";
import PropTypes from "prop-types";
import {AboutUsBackground} from "../../components/index";
import {Link} from "react-router-dom";
import {
    Card,
    Segment,
    Container,
    Image,
    Header,
    Item,
    Button,
    Icon,
    Label,
    List,
    Divider,
    Grid
} from "semantic-ui-react";
import {talkList} from "../../data/Sphere";
import ReactGA from "react-ga";
import {BDCOLOR} from "../../constants/BDBrand";

ReactGA.initialize("UA-114613423-1");

const propTypes = {};
const defaultProps = {};

class TalkMain extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview("/sphere/talk");
    }

    render() {
        // const a = this.props.match.params.id.toLowerCase();
        // const sphere = sphereDetail.find(function (obj) { return obj.id === a; });

        return <div>
            <AboutUsBackground imageUrl={"url(/images/sphere/08Detail.jpg)"} fontColor="white">
                BD Crew의 경험 아이디어 새로운 통찰력을 <br/> 접할 수 있는 BD만의 TED
            </AboutUsBackground>
            <Segment vertical basic style={styles.Segment}>
                <Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Header content={"2018 Spring"}/>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <Card fluid raised>
                                    <Card.Content>
                                        <Item.Group fluid raised unstackable style={{backgroundColor: "white"}}>
                                            <Item>
                                                <Item.Image src={"/images/sphere/talk/bdtalk_deepdive.png"}/>
                                                <Item.Content>
                                                    <Item.Header style={{color: BDCOLOR}}>
                                                        BD Talk 2018 Fall 공지
                                                    </Item.Header>
                                                    <Item.Description>
                                                        11월 3일 토요일 오후 4시 ~
                                                    </Item.Description>
                                                    <Item.Description>@헤이그라운드</Item.Description>
                                                    <List bulleted>
                                                        <List.Item>
                                                            BD Talk는 발표자가 BD Crew들에게 공유하고
                                                            싶은 나의 가치관, 성공기, 실패기 등 '내가
                                                            되고 싶은 내가 되기 위한 나의 시도'에 대해서
                                                            이야기 할 수 있는 BD표 TED입니다.
                                                        </List.Item>
                                                        <List.Item>
                                                            Crew들과 좋은 생각들과 아이디어를 나누면서
                                                            새로운 시각과 생각을 통해 영감을 불러 일으킬
                                                            수 있습니다 :)
                                                        </List.Item>
                                                    </List>
                                                    <Item.Extra>
                                                        <Button floated="right" basic color='blue' as={Link}
                                                                to={{pathname: '/sphere/talk_notice/2018f'}}>
                                                            자세히 보기
                                                        </Button>
                                                    </Item.Extra>
                                                </Item.Content>
                                            </Item>
                                        </Item.Group>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
            {talkList.map((season, i) => {
                return <Segment vertical basic style={styles.Segment} key={i}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Header content={season.season}/>
                                </Grid.Column>
                                <Grid.Column width={13}>
                                    <Card.Group itemsPerRow={3} stackable>
                                        {season.movies.map((talk, i) => {
                                            return <Card as={Link} to={"/sphere/talk/" + talk.id} key={i}
                                                         style={{borderRadius: "0px", border: "none"}} raised>
                                                <Image src={talk.image} style={{borderRadius: "0px", border: "none"}}/>
                                                <Card.Content>
                                                    <Card.Header>{talk.title}</Card.Header>
                                                    <Card.Meta>{talk.speakerName}</Card.Meta>
                                                    <Card.Description>
                                                        {talk.subtitle}
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>;
                                        })}
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>;
            })}
        </div>;
    }
}

const styles = {
    Segment: {
        margin: "5em 0em"
    }
};

TalkMain.propTypes = propTypes;
TalkMain.defaultProps = defaultProps;

export default TalkMain;
