import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../../components/index';
import {Segment, Container, Card, Image, Divider, List, Grid, Header, Item, Label, Icon} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {goalExample} from "../../data/bd_goals";
import {essayExample} from "../../data/bd_essay";
import * as BDBrand from "../../constants/BDBrand";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');

const propTypes = {};
const defaultProps = {};

const styles = {
    Segment: {
        margin: "5em 0em"
    },
    saying: {
        color: BDBrand.BDCOLOR,
        fontSize: BDBrand.SAYING_SIZE,
        fontStyle: 'italic',
        lineHeight: '1.5em',
    },
    mainContents:{
        lineHeight: '1.7em',
    }
}

class Eod extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/program/eod');
    }

    render() {


        return (
            <div>
                Eod
                <AboutUsBackground imageUrl={'url(/images/background/bd_eod.jpg)'} fontColor='black'>
                    [EOD] 매일 실천하면 습관이 됩니다
                </AboutUsBackground>
                <Segment vertical basic style={styles.Segment} textAlign={'center'}>

                    <Container>
                        <span style={styles.saying}>
                            처음에는 내가 습관을 만들지만 <br/>
                            장기적으로는 습관이 나를 만든다

                        </span>
                        <br/>
                        <span>
                            - 존 드라이든 -
                        </span>
                    </Container>

                </Segment>

                {/* Why */}
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='목표달성에 가장 효과적인 방법은 무엇인가요?'/>
                                </Grid.Column>
                                <Grid.Column width={11} style={styles.mainContents}>

                                    목표를 달성하는 가장 좋은 방법은 습관입니다. <br/>
                                    습관이 되면 나의 의지력을 사용하지 않아도 자연스레 실천하게 됩니다. <br/>
                                    높은 목표는 큰 덩어리로 느껴져서 내일로 미루기 쉽습니다. <br/>
                                    실천 가능한 수준으로 목표를 나누고 지속적으로 실천해야 원하는 목표에 도달할 수 있습니다. <br/>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                {/* How */}

                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='습관을 만드는 가장 좋은 방법!'/>
                                </Grid.Column>
                                <Grid.Column width={11} style={styles.mainContents}>

                                    습관을 만드는 가장 좋은 방법은 매일 하는 것입니다. <br/>
                                    하루에 실천 가능한 수준으로 목표를 나누고, 지속적으로 노력해야 합니다. <br/>
                                    매일의 노력을 기록하면서 객관적으로 바라보고 지속하는 힘을 얻습니다. <br/>
                                    함께 노력하는 동료는 자극제의 역할을 합니다. <br/>


                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                {/* How to */}
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='BD에서는 어떻게 하나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11} style={styles.mainContents}>

                                    매일 행동할 구체적인 양을 정하고, 기록하고 공유합니다. <br/>
                                    Being&Doing은 EOD App을 통해 매일매일 노력을 체크할 수 있도록 돕습니다. <br/>
                                    <br/><br/>
                                    <Header content={'EOD'}/>

                                    <List ordered>
                                        <List.Item> 매일 투자할 수 있는 목표를 설정합니다. </List.Item>
                                        <List.Item> 실천 여부를 기록합니다. </List.Item>
                                        <List.Item> 정해진 시간에 합니다. </List.Item>
                                        <List.Item> 다른 사람을 보면서 자극 받습니다. </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable container>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='EOD App'/>
                                </Grid.Column>
                                <Grid.Column width={11} style={styles.mainContents} textAlign={'center'}>

                                    <Card.Group itemPerRow={2} stackable doubling>
                                        <Card textAlign={'center'}>
                                            <Image src={"/images/program/eod/eodWriting.jpg"}/>
                                        </Card>
                                        <Card>
                                            <Image src={"/images/program/eod/eodStat.jpg"}/>
                                        </Card>
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>
                <Segment vertical basic style={styles.Segment}>
                    <Container textAlign={'center'} text>
                        <Link to={'/program/goal'} style={{marginLeft: 20}}>
                            <Icon name={'left chevron'} size={'big'}/>
                            Goal에 대해서 알아볼까요
                        </Link>
                        <Link to={'/people'} style={{marginLeft: 20}}>
                            BD Crew들을 보러갈까요
                            <Icon name={'right chevron'} size={'big'}/>
                        </Link>

                    </Container>
                </Segment>
                <Segment basic vertical>

                </Segment>
            </div>
        );
    }
};

Eod.propTypes = propTypes;
Eod.defaultProps = defaultProps;

export default Eod;
