import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Segment, Container, Grid, List, Button, Image, Header} from 'semantic-ui-react';
import {db} from "../../config/firebase";
import {Link} from 'react-router-dom';
import {BDCOLOR_SUB} from "../../constants/BDBrand";
import RecruitingNowHorizontalBannerComponent from "../../components/home/RecruitingNowHorizontalBannerComponent";

const propTypes = {};
const defaultProps = {};

class PartialFooter extends Component {


    render() {
        return (
            <div style={{marginTop: 30}}>
                <Segment inverted vertical basic style={{padding: '5em 0em'}}>
                    <Container>
                        <Grid divided inverted stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header inverted as='h4' content='Contact'/>
                                    <List link inverted verticalAlign={'middle'}>
                                        <List.Item as='a'
                                                   href="mailto:beingndoing.apply@gmail.com">

                                        </List.Item>
                                        <List.Item as='a'
                                                   href="mailto:beingndoing.apply@gmail.com">
                                            <Image avatar src={'/images/social/gmail.png'}/>
                                            <List.Content>
                                                <List.Header style={{color: 'white', fontSize:12 , fontWeight:'200'}}>
                                                    이메일로 문의하기
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item as='a' href='https://pf.kakao.com/_ptxdSxd/chat' target={'_blank'}>
                                            <Image avatar src={'/images/social/kakao.png'}/>
                                            <List.Content>
                                                <List.Header style={{color: '#E3C748', fontSize:12 , fontWeight:'200'}}>
                                                    카카오 플러스 친구로 문의하기
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item as='a' href='https://www.facebook.com/beingndoing' target={'_blank'}>
                                            <Image avatar src={'/images/social/facebook.png'}/>
                                            <List.Content>
                                                <List.Header style={{color: '#405A93', fontSize:12 , fontWeight:'200'}}>
                                                    페이스북 페이지 보기
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item as='a' href='https://blog.naver.com/beingndoing/' target={'_blank'}>
                                            <Image avatar src={'/images/social/naver.jpg'}/>
                                            <List.Content>
                                                <List.Header style={{color: '#6BB749', fontSize:12 , fontWeight:'200'}}>
                                                    네이버 블로그 더 많은 글 보기
                                                </List.Header>

                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Header inverted as='h4' content='Site Map'/>
                                    <List link inverted>
                                        <List.Item as={Link} to={'/about'}>알아보기</List.Item>
                                        <List.Item as={Link} to={'/blog'}>소식</List.Item>
                                        <List.Item as={Link} to={'/talk'}>BD Talk</List.Item>
                                        <List.Item as={Link} to={'/faqs'}>자주 묻는 질문</List.Item>
                                    </List>
                                </Grid.Column>
                                {/*<Grid.Column width={7}>
                                <Header as='h4' inverted>Footer Header</Header>
                                <p>Extra space for a call to action inside the footer that could help re-engage users.</p>
                            </Grid.Column>*/}
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>
            </div>
        );
    }
};

PartialFooter.propTypes = propTypes;
PartialFooter.defaultProps = defaultProps;

export default PartialFooter;
