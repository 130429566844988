import React, {Component, Fragment} from 'react';
import {db, firebase} from "../../config/firebase";
import {
    Loader,
    Segment,
    Container,
    Header,
    Card,
    Grid,
    Message,
    Divider,
    Statistic,
    Step,
    Button,
    Icon,
    Table,
    Form,
    TextArea,
    Input,
    Checkbox,
} from 'semantic-ui-react'
import {bdPaperCapability, categoryInfo, stepInfo} from "./bdPaperInfo";
import {withLineEnter} from "../../middleware/stringHandler";
import {BDCOLOR_SUB} from "../../constants/BDBrand";

class MemberBdPaperWriteContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bdPaper: {},
            stepNumber: 0,
            isLoaded: false,
            uploading: false,
        }
    }


    componentDidMount() {
        this._loadBdPaper()

    }

    componentWillUnmount() {
    }

    _loadBdPaper = () => {
        let {bdPaperId} = this.props.match.params;
        let userEmail = firebase.auth().currentUser.email;

        db.collection('bdPapers')
            .doc(bdPaperId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    window.alert('존재하지 않는 BD Paper 입니다.')
                    this.props.history.goBack();
                    return;
                }
                let bdPaper = doc.data();
                bdPaper.id = doc.id;

                if (!((bdPaper.email === userEmail) || (bdPaper.email === 'yhyun0513@gmail.com'))) {
                    window.alert('본인의 BD Paper에만 접근할 수 있습니다.')
                    this.props.history.goBack();
                    return;
                }
                this._checkEditable(bdPaper)
            })
            .catch(e => {
                console.log(e);
            })
    };
    _saveBDPaper = () => {
        this.setState({uploading: true});
        let {bdPaperId} = this.props.match.params;
        let {bdPaper} = this.state;
        bdPaper.modifiedAt = new Date();
        db.collection('bdPapers')
            .doc(bdPaperId)
            .update(bdPaper)
            .then(() => {
                this.setState({uploading: false});
            })
            .catch(e => {
                console.log(e);
                this.setState({uploading: false});
                window.alert('저장 실패. 재시도해주세요');
            })
    };

    _checkEditable = async (bdPaper) => {
        let seasonId = bdPaper.season;
        bdPaper.editable = await db.collection('seasons')
            .doc(seasonId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    return false;
                }
                let season = doc.data();
                return season.isOngoing;
            })
            .catch(e => {
                console.log(e);
                return false;
            });

        if (!bdPaper.editable) {
            window.alert('시즌이 종료된 이후에는 BD Paper를 수정할 수 없습니다.')
            this.props.history.goBack();
            return;
        }

        console.log({
            season: bdPaper.season,
            editable: bdPaper.editable
        })
        this.setState({
            bdPaper,
            isLoaded: true,
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div>
                    <Loader active>BD Paper를 불러오고 있습니다.</Loader>
                </div>
            );
        }
        return (
            <div style={{
                // margin: 20,
                // marginTop: 60,
                // padding: 40,
                // border: '0.1px solid',
                // borderColor: 'grey',
                // flex: 1,
                // minHeight: '90vh',
                // backgroundColor: 'white'
            }}>
                <Container style={{
                    margin: 20,
                    marginTop: 60,
                    padding: 40,
                    border: '0.1px solid',
                    borderColor: 'grey',
                    flex: 1,
                    minHeight: '90vh',
                    backgroundColor: 'white'
                }}>
                    <Button.Group fluid tabIndex={3}>
                        {stepInfo.map((step, i) => {
                            return (
                                <Button
                                    tabIndex={i}
                                    key={i}
                                    active={this.state.stepNumber === i}
                                    onClick={(e, data) => {
                                        console.log(data);
                                        this.setState({stepNumber: data.tabIndex});
                                    }}
                                >
                                    {step.title}
                                </Button>
                            );
                        })}
                    </Button.Group>
                    <Divider hidden/>
                    <div style={{textAlign: 'right'}}>
                        <Button size="large" onClick={this._saveBDPaper} loading={this.state.uploading}
                                disabled={this.state.uploading} color="orange">
                            저장하기
                        </Button>
                    </div>
                    {this.renderTab(this.state.stepNumber, this.state.bdPaper)}
                </Container>
            </div>
        );
    }

    renderTab = (pageNumber, bdPaper) => {
        switch (pageNumber) {
            case 0:
                return (
                    <Segment basic>
                        <Header size="huge" textAlign="center">
                            BD Paper
                        </Header>

                        <Grid as={Container} text>
                            {stepInfo.map((step, i) => {
                                if (i === 0) return null;
                                return (
                                    <Grid.Row key={i}>
                                        <Grid.Column width={2}>
                                            <Statistic size="tiny">
                                                <Statistic.Value>{i}</Statistic.Value>
                                                <Statistic.Label>단계</Statistic.Label>
                                            </Statistic>
                                        </Grid.Column>
                                        <Grid.Column width={14}>
                                            <Header size="small">{step.title}</Header>
                                            {step.description}
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    </Segment>
                );
            case 1:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>1</Statistic.Value>
                                <Statistic.Label>To-Be도출</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">내가 되고 싶은 나의 모습은 어떤가요? 생각하고 상상해 봅시다</Header>
                        <Grid>
                            {bdPaper.tobe.map((tobe, i) => {
                                const {title} = categoryInfo(tobe.categoryName);

                                return (
                                    <Grid.Column width={8} key={i}>
                                        <Header style={{color: BDCOLOR_SUB}}>
                                            {title}
                                        </Header>
                                        <Divider/>
                                        <Header size="tiny" color="grey">
                                            {categoryInfo(tobe.categoryName).question1}
                                        </Header>
                                        <Form>
                      <TextArea autoHeight
                                value={bdPaper.tobe[i].answer1}
                                onChange={(e, {value}) => {
                                    let bdPaper = this.state.bdPaper;
                                    bdPaper.tobe[i].answer1 = value;
                                    this.setState({bdPaper});
                                }}/>
                                        </Form>
                                        <Divider hidden/>
                                        <Header size="tiny" color="grey">
                                            {categoryInfo(tobe.categoryName).question2}
                                        </Header>
                                        <Form>
                      <TextArea autoHeight
                                value={bdPaper.tobe[i].answer2}
                                onChange={(e, {value}) => {
                                    let bdPaper = this.state.bdPaper;
                                    bdPaper.tobe[i].answer2 = value;
                                    this.setState({bdPaper});
                                }}/>
                                        </Form>
                                        <Divider hidden/>
                                        <Header size="tiny" color="grey">
                                            {categoryInfo(tobe.categoryName).question3}
                                        </Header>
                                        <Form>
                      <TextArea
                          autoHeight
                          value={bdPaper.tobe[i].answer3}
                          onChange={(e, {value}) => {
                              let bdPaper = this.state.bdPaper;
                              bdPaper.tobe[i].answer3 = value;
                              this.setState({bdPaper});
                          }}
                      />
                                        </Form>
                                    </Grid.Column>
                                );
                            })}
                        </Grid>
                        <Divider hidden section/>
                        <Divider horizontal>
                            <Header>정리</Header>
                        </Divider>
                        <Divider hidden section/>
                        <Grid>
                            <Grid.Row columns={4}>
                                {bdPaper.tobe.map((tobe, i) => {
                                    return (
                                        <Grid.Column textAlign="center" key={i}>
                                            <Header
                                                style={{color: BDCOLOR_SUB}}>{categoryInfo(tobe.categoryName).title}</Header>
                                            <p style={{fontWeight: '900'}}>{withLineEnter(tobe.answer3)} </p>
                                        </Grid.Column>
                                    );
                                })}
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column textAlign="center">
                                    <Header style={{color: BDCOLOR_SUB}}>미래의 나를 한마디로</Header>
                                    <p>나는 (4)무엇을 위해, (1)어떤 사람이 되어서, (2)어떤 일을 하면서, (3)어떻게 사는 인생을 살고 있을 거에요.</p>
                                    <Form>
                    <TextArea
                        autoHeight
                        value={bdPaper.oneSentence}
                        onChange={(e, {value}) => {
                            let bdPaper = this.state.bdPaper;
                            bdPaper.oneSentence = value;
                            this.setState({bdPaper});
                        }}
                    />
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                );
            case 2:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>2</Statistic.Value>
                                <Statistic.Label>핵심 역량 도출</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">내가 원하는 내가 되기 위해 중요한/필요한 역량은 무엇인가요? 나를 구성하는 주요 영역별로 핵심역량을 작성해
                            봅시다.</Header>

                        <Message warning>
                            <Message.Header>내가 원하는 나의 모습 </Message.Header>
                            <Message.Content> {withLineEnter(bdPaper.oneSentence)}</Message.Content>
                        </Message>
                        <Grid padded divided="vertically">
                            <Grid.Row>
                                <Grid.Column width={2}>영역</Grid.Column>
                                <Grid.Column width={14}>역량</Grid.Column>
                            </Grid.Row>
                            {bdPaper.goalPackages.map((area, i) => {
                                return (
                                    <Grid.Row key={i}>
                                        <Grid.Column width={2} style={{
                                            backgroundColor: BDCOLOR_SUB,
                                            color: 'white',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                            {area.areaName}
                                        </Grid.Column>
                                        <Grid.Column width={14}>
                                            {area.capability.map((capa, j) => {
                                                return (
                                                    <Card key={j}>
                                                        <Card.Content>
                                                            <Form>
                                                                <Input
                                                                    type="text"
                                                                    fluid
                                                                    value={capa.name}
                                                                    onChange={(e, {value}) => {
                                                                        let bdPaper = this.state.bdPaper;
                                                                        bdPaper.goalPackages[i].capability[j].name = value;
                                                                        this.setState({bdPaper});
                                                                    }}
                                                                />
                                                            </Form>
                                                        </Card.Content>
                                                        <Card.Content textAlign="center">
                                                            <Button
                                                                size={'small'}
                                                                compact
                                                                circular
                                                                icon="minus"
                                                                onClick={() => {
                                                                    if (capa.name !== '') {
                                                                        let confirm = window.confirm('정말 삭제하시겠습니까?');
                                                                        if (!confirm) {
                                                                            return;
                                                                        }
                                                                    }
                                                                    let bdPaper = this.state.bdPaper;
                                                                    bdPaper.goalPackages[i].capability.splice(j, 1);
                                                                    this.setState({bdPaper});
                                                                }}
                                                            />
                                                        </Card.Content>
                                                    </Card>
                                                );
                                            })}
                                            <Card>
                                                <Button
                                                    size={'small'}
                                                    compact
                                                    circular
                                                    icon="plus"
                                                    style={{borderRadius: 100}}
                                                    onClick={() => {
                                                        let bdPaper = this.state.bdPaper;
                                                        let newCapability = bdPaperCapability();
                                                        bdPaper.goalPackages[i].capability.push(newCapability);
                                                        console.log({bdPaper})
                                                        this.setState({bdPaper});
                                                    }}
                                                />
                                            </Card>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    </Segment>
                );
            case 3:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>3</Statistic.Value>
                                <Statistic.Label>역량별 As-Is분석</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">내가 원하는 내가 되기 위해 중요한/필요한 역량은 무엇인가요? 나를 구성하는 주요 영역별로 핵심역량을 작성해
                            봅시다.</Header>
                        <Message warning>
                            <Message.Header>내가 원하는 나의 모습 </Message.Header>
                            <Message.Content> {withLineEnter(bdPaper.oneSentence)}</Message.Content>
                        </Message>
                        <Grid padded divided="vertically">
                            <Grid.Row>
                                <Grid.Column width={2}>영역</Grid.Column>
                                <Grid.Column width={14}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={4}>역량</Grid.Column>
                                            <Grid.Column width={6}>AS-IS</Grid.Column>
                                            <Grid.Column width={6}>TO-BE</Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            {bdPaper.goalPackages.map((area, i) => {
                                return (
                                    <Grid.Row key={i}>
                                        <Grid.Column width={2} style={{
                                            backgroundColor: BDCOLOR_SUB,
                                            color: 'white',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                            {area.areaName}
                                        </Grid.Column>
                                        <Grid.Column width={14}>
                                            <Grid>
                                                {
                                                    area.capability.map((capa, j) => {
                                                        return (
                                                            <Grid.Row key={j}>
                                                                <Grid.Column width={4}>
                                                                    <div style={{
                                                                        border: '0.5px solid grey',
                                                                        borderRadius: 3,
                                                                        color: 'black',
                                                                        padding: 10,
                                                                    }}>
                                                                        {capa.name}
                                                                    </div>
                                                                </Grid.Column>
                                                                <Grid.Column width={6}>

                                                                    <Form>
                                                                        <TextArea
                                                                            placeholder={'현재 수준'}
                                                                            autoHeight
                                                                            value={capa.asIs}
                                                                            onChange={(e, {value}) => {
                                                                                let bdPaper = this.state.bdPaper;
                                                                                bdPaper.goalPackages[i].capability[j].asIs = value;
                                                                                this.setState({bdPaper});
                                                                            }}
                                                                        />
                                                                    </Form>
                                                                </Grid.Column>
                                                                <Grid.Column width={6}>
                                                                    <Form>
                                                                        <TextArea
                                                                            placeholder={'원하는 수준'}
                                                                            autoHeight
                                                                            value={capa.toBe}
                                                                            onChange={(e, {value}) => {
                                                                                let bdPaper = this.state.bdPaper;
                                                                                bdPaper.goalPackages[i].capability[j].toBe = value;
                                                                                this.setState({bdPaper});
                                                                            }}
                                                                        />
                                                                    </Form>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        );
                                                    })}
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    </Segment>
                );
            case 4:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>4</Statistic.Value>
                                <Statistic.Label>TASK 도출</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header size="medium" textAlign="center">
                            핵심 역량을 달성하기 위해 당장 실행해야 할 세부 과제는 무엇인가요 As-Is에서 To-Be로 가기 위해 필요한 모든 TASK를 작성해보세요
                        </Header>
                        <Message warning>
                            <Message.Header>내가 원하는 나의 모습 </Message.Header>
                            <Message.Content> {withLineEnter(bdPaper.oneSentence)}</Message.Content>
                        </Message>
                        <Divider hidden/>
                        <Divider hidden/>
                        <Divider hidden/>
                        {bdPaper.goalPackages.map((area, i) => {
                            return (
                                <div key={i} style={{marginBottom: 100}}>
                                    <Header style={{color: BDCOLOR_SUB}} size={'huge'}>
                                        {i + 1}. {area.areaName}
                                    </Header>
                                    {area.capability.map((capa, j) => {
                                        return (
                                            <div key={j} style={{marginBottom: 70}}>
                                                <Header color={'blue'}>
                                                    {i + 1}.{j + 1}. {capa.name}
                                                </Header>
                                                <Card.Group itemsPerRow={2}>
                                                    <Card>
                                                        <Card.Content>
                                                            <Card.Header size={'small'}
                                                                         color={'grey'}>
                                                                AS-IS
                                                            </Card.Header>
                                                            <Card.Description>
                                                                {withLineEnter(capa.asIs)}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                    <Card>
                                                        <Card.Content>
                                                            <Card.Header size={'small'}
                                                                         color={'grey'}>
                                                                TO-BE
                                                            </Card.Header>
                                                            <Card.Description>
                                                                {withLineEnter(capa.toBe)}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                </Card.Group>
                                                <Card.Group itemsPerRow={3}>
                                                    {capa.task && capa.task.map((task, k) => {
                                                        return (
                                                            <Card key={k}>
                                                                <Card.Content>
                                                                    <Header size={'small'} color={'grey'}>
                                                                        TASK {k + 1}
                                                                    </Header>
                                                                    <Form>
                                                                        <Input
                                                                            fluid
                                                                            value={task.name}
                                                                            onChange={(e, {value}) => {
                                                                                let bdPaper = this.state.bdPaper;
                                                                                bdPaper.goalPackages[i].capability[j].task[k].name = value;
                                                                                this.setState({bdPaper});
                                                                            }}
                                                                        />
                                                                    </Form>
                                                                </Card.Content>
                                                                <Card.Content textAlign="center">
                                                                    <Button
                                                                        size={'small'}
                                                                        compact
                                                                        circular
                                                                        icon="minus"
                                                                        onClick={() => {
                                                                            const a = window.confirm('TASK를 삭제하시겠습니까?');
                                                                            if (!a) return;
                                                                            let bdPaper = this.state.bdPaper;
                                                                            bdPaper.goalPackages[i].capability[j].task.splice(k, 1);
                                                                            this.setState({bdPaper});
                                                                        }}
                                                                    />
                                                                </Card.Content>
                                                            </Card>
                                                        );
                                                    })}
                                                </Card.Group>
                                                <Card style={{}}>
                                                    <Card.Content>
                                                        <Button
                                                            primary
                                                            basic
                                                            size={'small'}
                                                            fluid
                                                            compact
                                                            icon="plus"
                                                            onClick={() => {
                                                                let bdPaper = this.state.bdPaper;
                                                                bdPaper.goalPackages[i].capability[j].task.push({name: ''});
                                                                this.setState({bdPaper});
                                                            }}
                                                        >
                                                            TASK 추가하기
                                                        </Button>
                                                    </Card.Content>
                                                </Card>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </Segment>
                );
            case 5:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>5</Statistic.Value>
                                <Statistic.Label>우선순위 화</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center" size="medium">
                            TASK중 이번 4개월 간 수행 할 최우선 과제는 무엇인가요 TASK의 “중요도”와 “실행 가능성” 기준으로 고민 해 봅시다
                        </Header>
                        <Message positive>
                            <Message.Header>목표 선정하기!</Message.Header>
                            <Message.Content>TASK들 중에서 이번 시즌 목표를 정해봅시다! 목표는 적을 수록 좋습니다. 중요한 소수의 목표를 집중해서 달성하는 것이
                                중요합니다.</Message.Content>
                        </Message>
                        {bdPaper.goalPackages.map((area, i) => {
                            return (
                                <div key={i} style={{marginTop: 50}}>
                                    <Header style={{color: BDCOLOR_SUB}} size={'huge'}>
                                        {i + 1}. {area.areaName}
                                    </Header>

                                    {area.capability.map((capa, j) => {
                                        return (
                                            <div key={j} style={{marginBottom: 70}}>
                                                <Header color={'blue'}>
                                                    {i + 1}.{j + 1}. {capa.name}
                                                </Header>
                                                <Card.Group itemsPerRow={2}>
                                                    <Card>
                                                        <Card.Content>
                                                            <Card.Header>
                                                                AS-IS
                                                            </Card.Header>
                                                            <Card.Description>
                                                                {withLineEnter(capa.asIs)}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                    <Card>
                                                        <Card.Content>
                                                            <Card.Header>
                                                                TO-BE
                                                            </Card.Header>
                                                            <Card.Description>
                                                                {withLineEnter(capa.toBe)}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                </Card.Group>
                                                <Card.Group itemsPerRow={3}>
                                                    {
                                                        capa.task && capa.task.map((task, k) => {
                                                            return (
                                                                <Card key={k}>
                                                                    <Card.Content
                                                                        style={{fontWeight: '900'}}>
                                                                        <Header size={'small'} color={'grey'}>
                                                                            TASK {k + 1}
                                                                        </Header>
                                                                        {task.name}
                                                                    </Card.Content>
                                                                    <Card.Content>
                                                                        <Checkbox
                                                                            label="이번 시즌 목표로 하기"
                                                                            checked={task.selected}
                                                                            toggle
                                                                            onChange={(event, {checked}) => {
                                                                                console.log(checked);
                                                                                let bdPaper = this.state.bdPaper;
                                                                                bdPaper.goalPackages[i].capability[j].task[k].selected = checked;
                                                                                if (!bdPaper.goalPackages[i].capability[j].task[k].plan_detail || bdPaper.goalPackages[i].capability[j].task[k].plan_detail.length < 1) {
                                                                                    bdPaper.goalPackages[i].capability[j].task[k].plan_detail = [
                                                                                        {
                                                                                            week: '1주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '2주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '3주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '4주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '5주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '6주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '7주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '8주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '9주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '10주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '11주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '12주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '13주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '14주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '15주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        },
                                                                                        {
                                                                                            week: '16주',
                                                                                            description: '',
                                                                                            three: '',
                                                                                            six: '',
                                                                                            nine: '',
                                                                                            twelve: ''
                                                                                        }
                                                                                    ];
                                                                                }
                                                                                if (!bdPaper.goalPackages[i].capability[j].task[k].plan_short || bdPaper.goalPackages[i].capability[j].task[k].plan_short.length < 1) {
                                                                                    bdPaper.goalPackages[i].capability[j].task[k].plan_short = [{
                                                                                        three: '',
                                                                                        six: '',
                                                                                        nine: '',
                                                                                        twelve: ''
                                                                                    }];
                                                                                }
                                                                                this.setState({bdPaper}, () => {
                                                                                    console.log(this.state.bdPaper);
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Card.Content>
                                                                </Card>
                                                            );
                                                        })}
                                                </Card.Group>
                                            </div>
                                        )
                                    })}
                                </div>
                            );
                        })}
                    </Segment>
                );
            case 6:
                return (
                    <Segment basic>
                        <center>
                            <Statistic>
                                <Statistic.Value>6</Statistic.Value>
                                <Statistic.Label>목표 구체화</Statistic.Label>
                            </Statistic>
                        </center>
                        <Header textAlign="center">이번 기수 목표를 구체화 시켜 봅시다.</Header>
                        {bdPaper.goalPackages.map((area, i) => {
                            return area.capability.map((capa, j) => {
                                return capa.task.map((task, k) => {
                                    if (!task.selected) return null;
                                    return (
                                        <Fragment key={i * 100 + j * 10 + k}>
                                            <Header size={'huge'}>
                                                {task.name}
                                            </Header>
                                            <Card.Group itemsPerRow={3}>
                                                <Card>
                                                    <Card.Content>
                                                        <Header>
                                                            {capa.name}
                                                        </Header>
                                                    </Card.Content>
                                                </Card>
                                                <Card>
                                                    <Card.Content>
                                                        <Header>
                                                            AS-IS
                                                        </Header>
                                                        {capa.asIs}
                                                    </Card.Content>
                                                </Card>
                                                <Card>
                                                    <Card.Content>
                                                        <Header>
                                                            TO-BE
                                                        </Header>
                                                        {capa.toBe}
                                                    </Card.Content>
                                                </Card>
                                            </Card.Group>
                                            <Segment basic>
                                                <Checkbox
                                                    label="주차별 상세 목표 세우기"
                                                    checked={task.isSpecific}
                                                    toggle
                                                    onChange={(event, {checked}) => {
                                                        console.log(checked);
                                                        let bdPaper = this.state.bdPaper;
                                                        bdPaper.goalPackages[i].capability[j].task[k].isSpecific = checked;
                                                        this.setState({bdPaper}, () => {
                                                            console.log(this.state.bdPaper);
                                                        });
                                                    }}
                                                />
                                            </Segment>
                                            {task.isSpecific ? (
                                                <Table>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell width={1}>주</Table.HeaderCell>
                                                            <Table.HeaderCell width={7}>상세 계획</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>25%</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>50%</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>75%</Table.HeaderCell>
                                                            <Table.HeaderCell width={2}>100%</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {task.plan_detail.map((plan, l) => {
                                                            return (
                                                                <Table.Row key={l}>
                                                                    <Table.Cell width={1}>{plan.week}</Table.Cell>
                                                                    <Table.Cell width={7}>
                                                                        <Form>
                                      <TextArea
                                          autoHeight
                                          value={plan.description}
                                          onChange={(e, {value}) => {
                                              let bdPaper = this.state.bdPaper;
                                              bdPaper.goalPackages[i].capability[j].task[k].plan_detail[l].description = value;
                                              this.setState({bdPaper}, () => {
                                                  console.log(this.state.bdPaper);
                                              });
                                          }}
                                      />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={2}>
                                                                        <Form>
                                      <TextArea
                                          autoHeight
                                          value={plan.three}
                                          onChange={(e, {value}) => {
                                              let bdPaper = this.state.bdPaper;
                                              bdPaper.goalPackages[i].capability[j].task[k].plan_detail[l].three = value;
                                              this.setState({bdPaper}, () => {
                                                  console.log(this.state.bdPaper);
                                              });
                                          }}
                                      />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={2}>
                                                                        <Form>
                                      <TextArea
                                          autoHeight
                                          value={plan.six}
                                          onChange={(e, {value}) => {
                                              let bdPaper = this.state.bdPaper;
                                              bdPaper.goalPackages[i].capability[j].task[k].plan_detail[l].six = value;
                                              this.setState({bdPaper}, () => {
                                                  console.log(this.state.bdPaper);
                                              });
                                          }}
                                      />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={2}>
                                                                        <Form>
                                      <TextArea
                                          autoHeight
                                          value={plan.nine}
                                          onChange={(e, {value}) => {
                                              let bdPaper = this.state.bdPaper;
                                              bdPaper.goalPackages[i].capability[j].task[k].plan_detail[l].nine = value;
                                              this.setState({bdPaper}, () => {
                                                  console.log(this.state.bdPaper);
                                              });
                                          }}
                                      />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={2}>
                                                                        <Form>
                                      <TextArea
                                          autoHeight
                                          value={plan.twelve}
                                          onChange={(e, {value}) => {
                                              let bdPaper = this.state.bdPaper;
                                              bdPaper.goalPackages[i].capability[j].task[k].plan_detail[l].twelve = value;
                                              this.setState({bdPaper}, () => {
                                                  console.log(this.state.bdPaper);
                                              });
                                          }}
                                      />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            ) : (
                                                <Table>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell widt={4}>점수</Table.HeaderCell>
                                                            <Table.HeaderCell widt={12}>주별 달성 기준</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    {task.plan_short.map((plan, l) => {
                                                        return (
                                                            <Table.Body key={l}>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>25%</Table.Cell>
                                                                    <Table.Cell width={12}>
                                                                        <Form>
                                                                          <TextArea
                                                                              autoHeight
                                                                              value={plan.three}
                                                                              onChange={(e, {value}) => {
                                                                                  let bdPaper = this.state.bdPaper;
                                                                                  bdPaper.goalPackages[i].capability[j].task[k].plan_short[l].three = value;
                                                                                  this.setState({bdPaper}, () => {
                                                                                      console.log(this.state.bdPaper);
                                                                                  });
                                                                              }}/>
                                                                        </Form>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>50%</Table.Cell>
                                                                    <Table.Cell width={12}>
                                                                        <Form>
                                                                          <TextArea
                                                                              autoHeight
                                                                              value={plan.six}
                                                                              onChange={(e, {value}) => {
                                                                                  let bdPaper = this.state.bdPaper;
                                                                                  bdPaper.goalPackages[i].capability[j].task[k].plan_short[l].six = value;
                                                                                  this.setState({bdPaper}, () => {
                                                                                      console.log(this.state.bdPaper);
                                                                                  });
                                                                              }}
                                                                          />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>75%</Table.Cell>
                                                                    <Table.Cell width={12}>
                                                                        <Form>
                                                                          <TextArea
                                                                              autoHeight
                                                                              value={plan.nine}
                                                                              onChange={(e, {value}) => {
                                                                                  let bdPaper = this.state.bdPaper;
                                                                                  bdPaper.goalPackages[i].capability[j].task[k].plan_short[l].nine = value;
                                                                                  this.setState({bdPaper}, () => {
                                                                                      console.log(this.state.bdPaper);
                                                                                  });
                                                                              }}
                                                                          />
                                                                        </Form>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}>100%</Table.Cell>
                                                                    <Table.Cell width={12}>
                                                                        <Form>
                                                                          <TextArea
                                                                              autoHeight
                                                                              value={plan.twelve}
                                                                              onChange={(e, {value}) => {
                                                                                  let bdPaper = this.state.bdPaper;
                                                                                  bdPaper.goalPackages[i].capability[j].task[k].plan_short[l].twelve = value;
                                                                                  this.setState({bdPaper}, () => {
                                                                                      console.log(this.state.bdPaper);
                                                                                  });
                                                                              }}/>
                                                                        </Form>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        );
                                                    })}
                                                </Table>
                                            )}
                                            <Divider section/>
                                        </Fragment>
                                    );
                                });
                            });
                        })}
                    </Segment>
                );
            default:
                return null;
        }
    };
}


export default MemberBdPaperWriteContainer;
