import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container, Segment, Grid} from 'semantic-ui-react'

const propTypes = {};
const defaultProps = {};

var style = {
    height: '70vh',
    marginTop: '50px',
    width: '100%',
    backgroundImage: 'url(/images/background/bdClosing2.jpg)',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: '0px',

}

class HomeBackground extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Segment
                basic
                vertical
            >
                <Grid
                    fluid
                    style={style}
                    verticalAlign='bottom'
                    textAlign='center'
                >
                    <Grid.Column>
                        <h1 style={{color: '#009ada'}}>Being&Doing</h1>
                        <h2></h2>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }
};

HomeBackground.propTypes = propTypes;
HomeBackground.defaultProps = defaultProps;

export default HomeBackground;
