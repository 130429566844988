export const moveItemInArrayFromIndexToIndex = (array, fromIndex, toIndex) => {
    const newArray = [...array];

    if (fromIndex === toIndex) return newArray;

    const target = newArray[fromIndex];
    const inc = toIndex < fromIndex ? -1 : 1;

    for (let i = fromIndex; i !== toIndex; i += inc) {
        newArray[i] = newArray[i + inc];
    }

    newArray[toIndex] = target;

    return newArray;
};