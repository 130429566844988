export const authActions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',

  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',

  GET_MEMBER_INFO: 'GET_MEMBER_INFO',
  GET_MEMBER_INFO_SUCCESS: 'GET_MEMBER_INFO_SUCCESS',


  SIGN_OUT: 'SIGN_OUT',
};

export const authActionCreators = {
  signIn: (email, password, cb) => ({type: authActions.SIGN_IN, email, password, cb}),
  signInSuccess: (user, cb) => ({type: authActions.SIGN_IN_SUCCESS, user, cb}),

  signOut : (cb)=>({type:authActions.SIGN_OUT,cb}),

  getUserInfo: (cb) => ({type: authActions.GET_USER_INFO, cb}),
  getUserInfoSuccess: (user, cb) => ({type: authActions.GET_USER_INFO_SUCCESS, user, cb}),

  getMemberInfo: (cb) => ({type: authActions.GET_MEMBER_INFO, cb}),
  getMemberInfoSuccess: (member, cb) => ({type: authActions.GET_MEMBER_INFO_SUCCESS, cb, member}),

};

const initialState = {
  user: {},
  member: {},
  isLoggedIn: false,
  isMember: false,
};


export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
      };

    case authActions.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
      };

    case authActions.GET_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        member: action.member,
        isMember: true,
      };

    case authActions.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};