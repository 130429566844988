import React, {Component} from 'react';
import {BDCOLOR, BDCOLOR_SUB} from "../../constants/BDBrand";
import {Link} from "react-router-dom";
import {Segment, Header, Button} from 'semantic-ui-react'
import {db} from "../../config/firebase";
import moment from "moment/moment";

class RecruitingNowHorizontalBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonsNowRecruiting: [],
            isLoaded: false,
        }
    }


    componentDidMount() {
        this.loadSeasons()
    }


    loadSeasons = async () => {
        const seasonsRef = db.collection('seasons').where('isRecruiting', '==', true);
        seasonsRef
            .get()
            .then(snapshot => {
                const seasonsNowRecruiting = [];

                snapshot.forEach(doc => {
                    // console.log(doc.id, '=>', doc.data())
                    const season = doc.data();
                    season.id = doc.id;
                    seasonsNowRecruiting.push(season)

                });

                this.setState({
                    seasonsNowRecruiting: seasonsNowRecruiting,
                    isLoaded: true,
                })
            })
            .catch(e => {
                console.log(e)
            });
    }

    render() {
        if (!this.state.isLoaded) return null;

        if (this.state.seasonsNowRecruiting.length < 1) return null;

        const season = this.state.seasonsNowRecruiting[0];


        const {
            isRecruiting,
            earlyBird1st,
            earlyBird2nd,
            registerStartDate,
            registerEndDate,
        } = season;

        if (this.state.seasonsNowRecruiting.length > 0) {

            if (moment().isSameOrBefore(moment(earlyBird1st.endDate.seconds * 1000), 'day')) {
                return (
                    <Segment vertical basic
                             style={{
                                 // backgroundColor: 'rgba(54,120,188, 0.5)',
                                 paddingTop: 30,
                                 paddingBottom: 30,
                                 textAlign: 'center',
                                 marginTop: 100,
                                 minHeight: 250,
                                 display: 'flex',
                                 alignItems: 'center',
                             }}>
                        <div style={{margin: 'auto',}}>
                            <Header size={'large'} style={{color: BDCOLOR_SUB}}>
                                {season.title} 시즌 신규 멤버를 모집합니다.
                                <Header.Subheader>
                                    지원기간: {moment(season.registerStartDate.seconds * 1000).format('MM/DD(ddd)')} - {moment(season.registerEndDate.seconds * 1000).format('MM/DD(ddd)')}
                                </Header.Subheader>
                            </Header>
                            <p style={{fontSize: 'small', color: BDCOLOR}}>
                                1차 얼리버드 진행중 <br/>
                                (~{moment(season.earlyBird1st.endDate.seconds * 1000).locale('ko').format('MM/DD dddd')}까지)
                            </p>
                            <Button fluid as={Link} to={'/apply'}>
                                15% 할인! 얼리버드로 지원하기
                            </Button>
                        </div>
                    </Segment>
                )
            } else if (moment().isSameOrBefore(moment(earlyBird2nd.endDate.seconds * 1000), 'day')) {
                return (
                    <Segment vertical basic
                             style={{
                                 // backgroundColor: 'rgba(54,120,188, 0.5)',
                                 paddingTop: 30,
                                 paddingBottom: 30,
                                 textAlign: 'center',
                                 marginTop: 100,
                                 minHeight: 250,
                                 display: 'flex',
                                 alignItems: 'center',
                             }}>
                        <div style={{margin: 'auto',}}>
                            <Header size={'large'} style={{color: BDCOLOR_SUB}}>
                                {season.title} 시즌 신규 멤버를 모집합니다.
                                <Header.Subheader>
                                    지원기간: {moment(season.registerStartDate.seconds * 1000).format('MM/DD(ddd)')} - {moment(season.registerEndDate.seconds * 1000).format('MM/DD(ddd)')}
                                </Header.Subheader>
                            </Header>
                            <p style={{fontSize: 'small', color: BDCOLOR}}>
                                얼리버드 진행중 <br/>
                                (~{moment(season.earlyBird2nd.endDate.seconds * 1000).locale('ko').format('MM/DD dddd')}까지)
                            </p>
                            <Button fluid as={Link} to={'/apply'}>
                                10% 할인! 얼리버드로 지원하기
                            </Button>
                        </div>
                    </Segment>
                )
            } else {
                return (
                    <Segment vertical basic
                             style={{
                                 // backgroundColor: 'rgba(54,120,188, 0.5)',
                                 paddingTop: 30,
                                 paddingBottom: 30,
                                 textAlign: 'center',
                                 marginTop: 100,
                                 minHeight: 250,
                                 display: 'flex',
                                 alignItems: 'center',
                             }}>
                        <div style={{margin: 'auto',}}>
                            <Header size={'large'} style={{color: BDCOLOR_SUB}}>
                                {season.title} 시즌 신규 멤버를 모집합니다.
                                <Header.Subheader>
                                    지원기간: {moment(season.registerStartDate.seconds * 1000).format('MM/DD(ddd)')} - {moment(season.registerEndDate.seconds * 1000).format('MM/DD(ddd)')}
                                </Header.Subheader>
                            </Header>
                            <Button fluid as={Link} to={'/apply'}>
                                더 알아보기
                            </Button>
                        </div>
                    </Segment>
                )
            }

        } else {
            return null
        }

    }
}


export default RecruitingNowHorizontalBannerComponent;
