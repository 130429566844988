import React, {Component} from 'react';
import {connect} from 'react-redux';
import {db} from "../../config/firebase";
import {BDCOLOR_SUB} from "../../constants/BDBrand";
import {Container, Header, Card} from 'semantic-ui-react';
import {withLineEnter} from "../../middleware/stringHandler";
import _ from 'lodash';

class ReviewComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allReviews: []
        }
    }

    componentDidMount() {
        this.loadAllReviews();
    };

    loadAllReviews = async () => {
        const allReviewsRef = db.collection('reviews').doc('main').collection('review');

        allReviewsRef
            .where('number', '>', 0)
            .orderBy('number')
            .get()
            .then(snapshot => {
                let allReviews = []
                snapshot.forEach(doc => {
                    const review = doc.data();

                    review.id = doc.id;

                    allReviews.push(review);

                })

                allReviews = _.shuffle(allReviews)

                this.setState({
                    allReviews
                })
            })

    }

    render() {
        return (
            <div style={{
                backgroundColor: BDCOLOR_SUB,
                minHeight: '100px',
                marginTop: 100,
                paddingTop: 20,
                paddingBottom: 20,
            }}>
                <div style={{padding: 30, textAlign: 'center'}}>
                    <Header inverted>
                        BD Crew가 말하는 BD
                    </Header>
                </div>
                <Container style={{
                    display: 'flex',
                    // justifyContent: 'space-evenly',
                    flexWrap: 'nowrap',
                    overflowX: 'auto',
                    scrollbar: 'none',
                    WebkitScrollbarDisplay: 'none',
                    overflowScrolling: 'touch',
                    WebkitOverflowScrolling: "touch",
                }}>
                    {
                        this.state.allReviews.map((review, i) => {
                            return (
                                <div style={{
                                    width: 220,
                                    minHeight: 450,
                                    backgroundColor: 'white',
                                    // alignItems: 'center',
                                    // justifyContent: 'center',
                                    flexDirection: 'column',
                                    margin: 10,
                                    display: 'flex',
                                    flex: '0 0 auto'
                                }} key={i}>
                                    <div style={{
                                        backgroundImage: `url(${review.image})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        width: 220,
                                        height: 200,
                                    }}>

                                    </div>
                                    <div style={{
                                        flex: 1,
                                        padding: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div style={{}}>
                                            <Header textAlign={'center'}>
                                                {review.title}
                                                <Header.Subheader>
                                                    {review.subTitle}
                                                </Header.Subheader>
                                            </Header>
                                        </div>
                                        <div style={{
                                            flex: '1',
                                            marginTop: 15,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}>
                                            <p style={{textAlign: 'center'}}>
                                                {withLineEnter(review.content)}
                                            </p>

                                        </div>
                                        <div style={{marginTop: 15, textAlign: 'center'}}>
                                            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                                {review.userName}
                                            </div>
                                            <div style={{
                                                textAlign: 'center',
                                                color: BDCOLOR_SUB,
                                                fontSize: 12,
                                                lineHeight: '120%'
                                            }}>
                                                {withLineEnter(review.userDescription)}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReviewComponent);
