import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Container, Segment, Divider, Card, Icon, Button, Header, Form, Image, Table, Modal} from 'semantic-ui-react'
import {imageUploadToFirebase} from "../../middleware/firebaseImageHandler";
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

const membersRef = db.collection('members');

const initialMemberData = {
    name: '',
    email: '',
    phone: '',

    image: 'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/profileImage%2F201902%2Fsquare-image.png?alt=media&token=cb814c7f-cad9-405b-8cfd-70f92458ef2e',
    imageFirebase: {
        bucket: "profileImage/201902",
        fileName: "square-image",
        imageRef: 'profileImage/201902/square-image.png',
        imageRef200: 'profileImage/201902/thumb_200_square-image.png',
        imageRef400: 'profileImage/201902/thumb_400_square-image.png',
        imageRef800: 'profileImage/201902/thumb_800_square-image.png'
    },

    numberOfChair: 0,
    numberOfMember: 0,
    numberOfStaff: 0,
    career: [],
    description: '',
    bdIs: '',
    jobTitle: '',
    birthDay: new Date()
};

class AdminMemberManagementContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

            allMembers: [],
            openModifyModal: false,
            openAddModal: false,

            modifyMemberData: initialMemberData,
            newMemberData: initialMemberData,
        }
    }


    componentDidMount() {
        this._loadAllMembers()
    }

    _refresh = () => {
        this._loadAllMembers()
    }

    _loadAllMembers = () => {
        membersRef
            .orderBy('name')
            .get()
            .then(snapshot => {
                const allMembers = [];
                snapshot.forEach(doc => {
                    const member = doc.data();
                    member.id = doc.id;
                    allMembers.push(member);
                })
                this.setState({
                    allMembers
                })
            })
            .catch(e => {
                console.log(e);
            })
    }


    _completeAddUser = () => {

        const a = window.confirm('업로드 하시겠습니까?')
        if (!a) {
            return;
        }

        const {newMemberData} = this.state;
        newMemberData.createdAt = new Date();
        newMemberData.modifiedAt = new Date();

        membersRef
            .add(newMemberData)
            .then(docRef => {
                console.log(docRef.id);
                this._refresh()
                this.setState({
                    openAddModal: false,
                    newMemberData: {
                        name: '',
                        email: '',
                        phone: '',

                        image: 'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/profileImage%2F201902%2Fsquare-image.png?alt=media&token=cb814c7f-cad9-405b-8cfd-70f92458ef2e',
                        imageFirebase: {
                            bucket: "profileImage/201902",
                            fileName: "square-image",
                            imageRef: 'profileImage/201902/square-image.png',
                            imageRef200: 'profileImage/201902/thumb_200_square-image.png',
                            imageRef400: 'profileImage/201902/thumb_400_square-image.png',
                            imageRef800: 'profileImage/201902/thumb_800_square-image.png'
                        },

                        numberOfChair: 0,
                        numberOfMember: 0,
                        numberOfStaff: 0,
                        career: [],
                        description: '',
                        bdIs: '',
                        jobTitle: '',
                        birthDay: new Date()
                    },
                })
            })
            .catch(e => {
                console.log(e);
            })
    };
    _cancelAddAddUser = () => {
        const a = window.confirm('취소하시겠습니까?')
        if (!a) {
            return;
        }

        this.setState({
            newMemberData: {
                name: '',
                email: '',
                phone: '',

                image: 'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/profileImage%2F201902%2Fsquare-image.png?alt=media&token=cb814c7f-cad9-405b-8cfd-70f92458ef2e',
                imageFirebase: {
                    bucket: "profileImage/201902",
                    fileName: "square-image",
                    imageRef: 'profileImage/201902/square-image.png',
                    imageRef200: 'profileImage/201902/thumb_200_square-image.png',
                    imageRef400: 'profileImage/201902/thumb_400_square-image.png',
                    imageRef800: 'profileImage/201902/thumb_800_square-image.png'
                },

                numberOfChair: 0,
                numberOfMember: 0,
                numberOfStaff: 0,
                career: [],
                description: '',
                bdIs: '',
                jobTitle: '',
                birthDay: new Date()
            },
            openAddModal: false,
        })
    };
    _handleChangeNewMemberData = (e, d) => {
        const newMemberData = this.state.newMemberData
        newMemberData[d.name] = d.value;

        this.setState({
            newMemberData
        })
    }
    onNewMemberImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'profileImage'

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);

            const newMemberData = this.state.newMemberData;

            newMemberData.image = image;
            newMemberData.imageFirebase = imageFirebase;

            this.setState({
                newMemberData
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

        }
    }

    _completeModify = () => {

        const a = window.confirm('수정 하시겠습니까?')
        if (!a) {
            return;
        }

        const {modifyMemberData} = this.state;
        modifyMemberData.modifiedAt = new Date();

        membersRef
            .doc(modifyMemberData.id)
            .set(modifyMemberData)
            .then(() => {
                this._refresh();
                this.setState({
                    modifyMemberData: initialMemberData,
                    openModifyModal: false
                })
            })
            .catch(e => {
                console.log(e);
            })

    };
    _cancelModify = () => {
        this.setState({
            modifyMemberData: initialMemberData,
            openModifyModal: false,
        })
    };
    _handleChangeModifyMemberData = (e, d) => {
        const modifyMemberData = this.state.modifyMemberData
        modifyMemberData[d.name] = d.value;

        this.setState({
            modifyMemberData
        })
    }
    _onModifyMemberImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'profileImage'

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);

            const modifyMemberData = this.state.modifyMemberData;

            modifyMemberData.image = image;
            modifyMemberData.imageFirebase = imageFirebase;

            this.setState({
                modifyMemberData
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

        }
    }

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>

                <Segment vertical as={Container}>
                    <Button icon labelPosition='left' primary
                            onClick={() => {
                                this.setState({
                                    openAddModal: true
                                })
                            }}>
                        <Icon name='user'/> 멤버 추가하기
                    </Button>
                </Segment>
                <Segment vertical as={Container}>
                    <Table size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    번호
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    사진
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    이름
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    이메일
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    전화번호
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    기타
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.allMembers.map((member, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>
                                                {i + 1}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Image avatar src={member.image}/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {member.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {member.email}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {member.phone}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button onClick={() => {
                                                    this.setState({
                                                        openModifyModal: true,
                                                        modifyMemberData: member
                                                    })
                                                }}>
                                                    수정하기
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell/>
                                <Table.HeaderCell colSpan='5'>
                                    <Button icon labelPosition='left' primary size='small'
                                            onClick={() => {
                                                this.setState({
                                                    openAddModal: true
                                                })
                                            }}>
                                        <Icon name='user'/> 멤버 추가하기
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>

                </Segment>
                <Modal open={this.state.openAddModal}>
                    <Modal.Content>
                        <h3>신규 유저 생성</h3>
                    </Modal.Content>
                    <Modal.Content>
                        {
                            this.state.newMemberData.image && (
                                <Image src={this.state.newMemberData.image}
                                       size={'small'}
                                       onClick={() => {
                                           const a = window.confirm('삭제하시겠습니까?')
                                           if (!a) {
                                               return;
                                           }
                                           this.setState(state => {
                                               const newMemberData = state.newMemberData;
                                               newMemberData.image = '';
                                               newMemberData.imageFirebase = {};
                                               return {
                                                   newMemberData
                                               }
                                           })

                                       }}
                                />
                            )
                        }
                        <Form>
                            <Form.Input
                                label={'name'}
                                name='name'
                                value={this.state.newMemberData.name}
                                onChange={this._handleChangeNewMemberData}
                            />
                            <Form.Input
                                label={'email'}
                                name='email'
                                value={this.state.newMemberData.email}
                                onChange={this._handleChangeNewMemberData}
                            />
                            <Form.Input
                                label='phone'
                                name='phone'
                                value={this.state.newMemberData.phone}
                                onChange={this._handleChangeNewMemberData}
                            />
                            <Form.TextArea
                                value={this.state.newMemberData.bdIs}
                                rows={8}
                                name={'bdIs'}
                                label={'bdIs'}
                                placeholder={'bdIs'}
                                onChange={this._handleChangeNewMemberData}/>
                            <Form.TextArea
                                value={this.state.newMemberData.description}
                                rows={8}
                                name={'description'}
                                label={'description'}
                                placeholder={'설명'}
                                onChange={this._handleChangeNewMemberData}/>
                            <Form.Field>
                                <label>프로필 사진 추가</label>
                                <input type="file" onChange={e => {
                                    this.onNewMemberImageUpload(e)
                                }}
                                       accept=".jpg, .jpeg, .png, .heic"/>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={() => {
                            this._completeAddUser()
                        }}>
                            확인
                        </Button>
                        <Button negative onClick={() => {
                            this._cancelAddAddUser()
                        }}>
                            취소
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.openModifyModal}>
                    <Modal.Content>
                        <h3>수정</h3>
                    </Modal.Content>
                    <Modal.Content>
                        {
                            this.state.modifyMemberData.image && (
                                <Image src={this.state.modifyMemberData.image}
                                       size={'small'}
                                       onClick={() => {
                                           const a = window.confirm('삭제하시겠습니까?')
                                           if (!a) {
                                               return;
                                           }
                                           this.setState(state => {
                                               const modifyMemberData = state.modifyMemberData;
                                               modifyMemberData.image = '';
                                               modifyMemberData.imageFirebase = {};
                                               return {
                                                   modifyMemberData
                                               }
                                           })

                                       }}
                                />
                            )
                        }
                        <Form>
                            <Form.Input
                                label={'name'}
                                name='name'
                                value={this.state.modifyMemberData.name}
                                onChange={this._handleChangeModifyMemberData}
                            />
                            <Form.Input
                                label={'email'}
                                name='email'
                                value={this.state.modifyMemberData.email}
                                onChange={this._handleChangeModifyMemberData}
                            />
                            <Form.Input
                                label='phone'
                                name='phone'
                                value={this.state.modifyMemberData.phone}
                                onChange={this._handleChangeModifyMemberData}
                            />
                            <Form.TextArea
                                value={this.state.modifyMemberData.bdIs}
                                rows={8}
                                name={'bdIs'}
                                label={'bdIs'}
                                placeholder={'설명'}
                                onChange={this._handleChangeModifyMemberData}/>
                            <Form.TextArea
                                value={this.state.modifyMemberData.description}
                                rows={8}
                                name={'description'}
                                label={'description'}
                                placeholder={'설명'}
                                onChange={this._handleChangeModifyMemberData}/>
                            <Form.Field>
                                <label>프로필 사진 추가</label>
                                <input type="file" onChange={e => {
                                    this._onModifyMemberImageUpload(e)
                                }}
                                       accept=".jpg, .jpeg, .png, .heic"/>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={() => {
                            this._completeModify()
                        }}>
                            확인
                        </Button>
                        <Button negative onClick={() => {
                            this._cancelModify()
                        }}>
                            취소
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        );
    }
}


export default AdminMemberManagementContainer;
