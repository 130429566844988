import {createLogic} from 'redux-logic';
import {goalActionCreators, goalActions} from "./goalStore";
import Network from "../../middleware/Network";


const fetchAllGoals = createLogic({
  type: goalActions.FETCH_ALL_GOALS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {member} = getState().auth;
    if (!member.id) return done();
    const memberId = member.id;
    const goals = await Network.getAllMyGoalsByMemberId(memberId);

    if (!goals.err)
      dispatch(goalActionCreators.fetchAllGoalsSuccess(goals));

    if (action.cb) action.cb();

    done();
  }
});


export default [
  fetchAllGoals,
]