import React, {Component, Fragment} from 'react';
import {db, firebase} from "../../config/firebase";
import {Segment, Container, Card, Divider, Button, Header, Message} from 'semantic-ui-react'
import moment from 'moment'
import {Link} from 'react-router-dom';
import {rawBDPaper} from "./bdPaperInfo";
import {getBDMemberInfo} from "../../middleware/bdMemberInfo";


class MemberBdPaperMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bdPapers: [],
            ongoingSeasonDidNotWriteBdPaper: [],

            fetchingMakingBdPaper: false,
        }
    }

    componentDidMount() {
        this._loadMyBDPaperList();
        window.scrollTo(0, 0);
    }

    _loadMyBDPaperList = () => {
        const email = firebase.auth().currentUser.email;
        db.collection('bdPapers')
            // .where('isDeleted', '!==', false)
            .where('email', '==', email)
            .orderBy('season')
            .orderBy('modifiedAt')
            .get()
            .then(snapshot => {
                let bdPapers = [];
                snapshot.forEach(async doc => {
                    let bdPaper = doc.data();
                    bdPaper.id = doc.id;
                    bdPapers.push(bdPaper);
                });

                console.log({bdPapers})
                this.setState({
                    bdPapers
                });
                this._loadOngoingSeasonForCheckNewPaper(bdPapers)
            })
    };
    _loadOngoingSeasonForCheckNewPaper = (bdPapers) => {
        db.collection('seasons')
            .where('isOngoing', '==', true)
            .get()
            .then(snapshot => {
                let ongoingSeasonDidNotWriteBdPaper = [];
                snapshot.forEach(doc => {
                    let season = doc.data();
                    season.id = doc.id;
                    if (bdPapers.findIndex(bdpaper => bdpaper.season === doc.id) < 0) {
                        ongoingSeasonDidNotWriteBdPaper.push(season);
                    }
                });

                this.setState({
                    ongoingSeasonDidNotWriteBdPaper
                })
                console.log(ongoingSeasonDidNotWriteBdPaper)
            })
    }

    _addNewBdPaper = async (seasonId) => {

        this.setState({
            fetchingMakingBdPaper: true,
        });
        let {
            name,
            email,
        } = await getBDMemberInfo();

        const newBDPaper = rawBDPaper(name, email, seasonId);

        db.collection('bdPapers')
            .add(newBDPaper)
            .then(() => {
                this._loadMyBDPaperList();
                this.setState({
                    ongoingSeasonDidNotWriteBdPaper: [],
                    fetchingMakingBdPaper: false
                })
            })
            .catch(e => {
                this.setState({
                    fetchingMakingBdPaper: false
                })
            })
    }

    render() {
        return (
            <Fragment>
                <Segment as={Container} basic vertical>
                    <Header style={{fontWeight: '900'}} size={'huge'}>
                        BD Paper
                    </Header>
                    <Message positive>
                        <Message.Header>Tip!</Message.Header>
                        <Message.List>
                            <Message.Item>BD Paper는 Crew여러분의 목표 및 Task 설계에 도움을 드리기 위한 guide입니다.</Message.Item>
                            <Message.Item>모든 빈칸을 채우시지 않아도 됩니다 </Message.Item>
                            <Message.Item>BD Paper를 따라, 장기적인 관점에서 인생을 생각해봅시다.</Message.Item>
                            <Message.Item>그리고 4개월 동안 내가 할 일을 정해봅시다.</Message.Item>
                        </Message.List>
                        <Button as={Link} to={'/app/bdpaper/sample'} style={{marginTop:10}} primary>
                            작성 샘플 보기
                        </Button>
                    </Message>
                </Segment>
                <Segment as={Container} basic vertical>
                    <Card.Group itemsPerRow={3}>
                        {
                            this.state.bdPapers.map((bdPaper, i) => {
                                return (
                                    <BdPaperCardComponent key={i} bdPaper={bdPaper} {...this.props}/>
                                )
                            })
                        }
                        {
                            this.state.ongoingSeasonDidNotWriteBdPaper.map((season, i) => {
                                return (
                                    <Card raised key={i}>
                                        <Card.Content>
                                            <Card.Header>
                                                {season.id}
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content>
                                            <Button
                                                fluid
                                                basic
                                                primary
                                                disabled={this.state.fetchingMakingBdPaper}
                                                onClick={() => {
                                                    this._addNewBdPaper(season.id)
                                                }}>
                                                작성 시작하기
                                            </Button>
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </Segment>
            </Fragment>
        );
    }
}


class BdPaperCardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bdPaper: props.bdPaper
        }
    }

    componentDidMount() {
        this._checkEditable();
    }

    _checkEditable = async () => {
        let {bdPaper} = this.state;
        let seasonId = bdPaper.season;

        bdPaper.editable = await db.collection('seasons')
            .doc(seasonId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    return false;
                }
                let season = doc.data();
                return season.isOngoing;
            })
            .catch(e => {
                console.log(e);
                return false;
            });

        console.log({
            season: bdPaper.season,
            editable: bdPaper.editable
        })
        this.setState({
            bdPaper
        })
    }

    render() {
        const {bdPaper} = this.state;
        return (
            <Card raised>
                <Card.Content>
                    <Card.Header>
                        {bdPaper.season}
                    </Card.Header>
                    <Card.Description>
                        작성일: {moment(bdPaper.createdAt.seconds * 1000).format('YYYY. MM. DD (ddd)')}
                    </Card.Description>
                    {/*<Card.Meta>
                        {bdPaper.oneSentence}
                    </Card.Meta>*/}
                </Card.Content>
                <Card.Content>
                    <Button as={Link} to={`/app/bdpaper/read/${bdPaper.id}`} basic>
                        보기
                    </Button>
                    {
                        bdPaper.editable ? (
                            <Fragment>
                                <Button as={Link} to={`/app/bdpaper/write/${bdPaper.id}`} basic primary>
                                    작성
                                </Button>
                            </Fragment>
                        ) : null
                    }
                </Card.Content>
            </Card>
        )
    }
}


export default MemberBdPaperMainContainer;
