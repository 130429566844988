import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Card, Header, Image,} from 'semantic-ui-react';
import moment from 'moment'

class BlogCardComponent extends Component {

    render() {
        const {blog} = this.props;
        return (
            <Card as={Link} to={{pathname: `/blog/${blog.id}`}}>
                <Image src={blog.image}/>
                <Card.Content>
                    <Header size={'small'}>
                        {blog.title}
                        <Header.Subheader>
                            {moment(blog.date.seconds * 1000).format('YYYY. MM. DD')}
                        </Header.Subheader>
                    </Header>
                    <Card.Description style={{}}>
                        {blog.subTitle}
                    </Card.Description>

                </Card.Content>
            </Card>
        );
    }
}


export default BlogCardComponent;
