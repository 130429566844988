import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../../components/index';
import {
    Segment,
    Container,
    Card,
    Image,
    Divider,
    List,
    Button,
    Grid,
    Header,
    Item,
    Label,
    Icon
} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import * as BDBrand from "../../constants/BDBrand";
import {interviews} from "../../data/bd_interview";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-114613423-1');


const propTypes = {};
const defaultProps = {};
const styles = {
    Header: {
        backgroundColor: BDBrand.BDCOLOR,
        height: '30vh'

    },
    mainContent: {
        padding: 20,
    },
    Segment: {
        margin: "5em 0em"
    },
    qnaSegment: {
        padding: '3em'
    }
}

class Interview extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.pageview('/people/interview/' + this.props.match.params.id);

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        window.scrollTo(0, 0);
        ReactGA.pageview('/people/interview/' + this.props.match.params.id);
    }


    render() {
        const index = this.props.match.params.id;
        const prevInterview = Number(index) - 1;
        const nextInterview = Number(index) + 1;
        const lengthOfInterviews = interviews.length;
        const selectedInterview = interviews[index];

        return (
            <div>
                <Grid style={styles.Header} textAlign={'center'} verticalAlign={'middle'}>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            {
                                Number(index) == 0 ?
                                    (null) :
                                    (<Link to={'/people/interview/' + prevInterview}>
                                        <Icon name={'left chevron'} size={'massive'} color={'white'}/>
                                    </Link>)
                            }

                        </Grid.Column>
                        <Grid.Column>
                            <h4>BD Crew들에게 물었습니다.</h4>
                            <h1 style={{color: 'white'}}>"BD 어때요?"</h1>
                        </Grid.Column>
                        <Grid.Column>
                            {
                                Number(index) + 1 == lengthOfInterviews ?
                                    (null) :
                                    (<Link to={'/people/interview/' + nextInterview}>
                                        <Icon name={'right chevron'} size={'massive'} color={'white'}/>
                                        <br/>
                                    </Link>)
                            }

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Segment vertical basic style={styles.Segment}>
                    <Container text={true}>
                        <Image src={selectedInterview.image} size={'small'} centered/>
                        <div style={{textAlign:'center', fontSize: '1.6em'}}>
                            {selectedInterview.interviewee}
                        </div>
                        {selectedInterview.contents.map((a, i) => {
                            return (
                                <Segment basic style={styles.qnaSegment}>
                                    <Header content={"Q. " + a.q}/>
                                    <Divider/>
                                    <div style={{fontSize: '1.3em'}} dangerouslySetInnerHTML={{__html: a.a}} >

                                    </div>

                                </Segment>

                            )
                        })}
                    </Container>

                </Segment>
                <Segment vertical basic style={styles.Segment} textAlign={'center'}>
                    {
                        Number(index) == 0 ?
                            (null) :
                            (<Link to={'/people/interview/' + prevInterview} style={{marginRight: 20}}>
                                <Icon name={'left chevron'} size={'big'}/>
                                이전 인터뷰 보기
                            </Link>)
                    }
                    {
                        Number(index) + 1 == lengthOfInterviews ?
                            (null) :
                            (<Link to={'/people/interview/' + nextInterview} style={{marginLeft: 20}}>
                                다음 인터뷰 보기
                                <Icon name={'right chevron'} size={'big'}/>
                            </Link>)
                    }
                </Segment>

            </div>
        );
    }
};

Interview.propTypes = propTypes;
Interview.defaultProps = defaultProps;

export default Interview;
