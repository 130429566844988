import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Card, Image, Header, Loader} from 'semantic-ui-react'
import {firebase} from "../../config/firebase";
import {Link, withRouter} from "react-router-dom";
import TalkCardComponent from "../../components/talk/TalkCardComponent";
import Analytics from "../../middleware/Analytics";

const db = firebase.firestore()

class TalkDetailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            talk: {},
            otherTalks: [],
            isLoaded: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        const talkId = this.props.match.params.talkId;
        Analytics.pageViewAll(`/talk/${talkId}`);
        this.loadTalkData(talkId);
    }



    componentWillUpdate(nextProps, nextState) {
        if (nextProps.location !== this.props.location) {
            this.setState({
                isLoaded: false
            })
            window.scrollTo(0, 0);
            const talkId = nextProps.match.params.talkId;
            this.loadTalkData(talkId);
            Analytics.pageViewAll(`/talk/${talkId}`);
        }
    }



    loadTalkData = async (talkId) => {

        const talkRef = db.collection('talks').doc(talkId);
        talkRef
            .get()
            .then(doc => {
                const talk = doc.data();
                talk.id = doc.id;
                this.setState({
                    talk,
                    isLoaded: true
                })
                if (talk.seasonId) {
                    this.loadOtherTalksAtSameDay(talk);
                }
            })
    };

    loadOtherTalksAtSameDay = async (selectedTalk) => {
        const talkRef = db.collection('talks').where('seasonId', '==', selectedTalk.seasonId)
        talkRef
            .get()
            .then(snapshot => {
                const otherTalks = [];
                snapshot.forEach(doc => {
                    const talk = doc.data();
                    talk.id = doc.id;

                    if (talk.id === selectedTalk.id) {
                    } else {
                        otherTalks.push(talk);
                    }
                })
                this.setState({
                    otherTalks
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <Loader active/>
            )
        }
        return (
            <div>
                <Container as={Segment} vertical textAlign='center'>
                    <iframe width="560" height="315" src={this.state.talk.link} frameBorder="0"
                            allow="autoplay; encrypted-media"></iframe>
                </Container>
                {
                    this.state.otherTalks.length > 0 ? (
                        <Container as={Segment} vertical>
                            <Header>
                                같은 날 발표된 다른 토크
                            </Header>
                            <Card.Group itemsPerRow={3} doubling>
                                {
                                    this.state.otherTalks.map((talk, i) => {
                                        return (
                                            <TalkCardComponent key={i} talk={talk}/>
                                        )
                                    })
                                }
                            </Card.Group>
                        </Container>
                    ) : null
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TalkDetailContainer));
