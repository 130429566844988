import {db} from "../config/firebase";


class Network {
  constructor() {

  }

  onError = (e, request, message) => {
    console.log(e);
    return {
      err: '에러 발생'
    }
  };

  onNext = (data, requestName) => {
    console.log(requestName, data);
    return data;
  };

  getUserInfo = (userId) => {
    return db.collection('users')
      .doc(userId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return this.onNext(Object.assign(doc.data(), {id: doc.id}), 'GET USER INFO');
      })
      .catch(e => {
          return this.onError(e)
        }
      );
  };
  getMemberInfo = (userEmail) => {
    return db
      .collection('members')
      .where('email', '==', userEmail.toLowerCase())
      .get()
      .then(snapshot => {
        const members = [];
        snapshot.forEach(doc => {
          let member = Object.assign(doc.data(), {id: doc.id});
          members.push(member)
        });
        return this.onNext(members);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  getAllMyGoalsByMemberId = (memberId) => {
    return db.collection('goals')
      .where('memberId', '==', memberId)
      .where('isDeleted', '==', false)
      .get()
      .then(snapshot => {
        const goals = [];
        snapshot.forEach(doc => {
          let goal = Object.assign(doc.data(), {id: doc.id});
          goals.push(goal)
        });
        return this.onNext(goals);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  getAllMyEssays = (memberId) => {
    return db.collection('essays')
      .where('memberId', '==', memberId)
      .where('deleted', '==', false)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return this.onNext(essays);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  }

  getAllMyEods = (memberId) => {
    return db.collection('eods')
      .where('memberId', '==', memberId)
      .where('isDeleted', '==', false)
      .get()
      .then(snapshot => {
        const eods = [];
        snapshot.forEach(doc => {
          let eod = Object.assign(doc.data(), {id: doc.id});
          eods.push(eod)
        });
        return this.onNext(eods);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  }
}


export default new Network();