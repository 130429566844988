import React, {Component} from 'react';
import moment from "moment/moment";
import numeral from "numeral";
import {Segment, Loader, Image, Item, Container, Table, Progress, Label, Card, Rating} from 'semantic-ui-react'
import {withLineEnter} from "../../middleware/stringHandler";
import {BDCOLOR, BDCOLOR_GREY} from "../../constants/BDBrand";

class EssayComponent extends Component {

  componentDidMount() {

  }

  render() {
    const {essay} = this.props;
    return (
      <Item style={{backgroundColor: 'white', padding: '1rem', borderRadius: '1rem'}}>
        <Item.Content style={{}}>
          <div style={{color: BDCOLOR, fontWeight: '900', fontSize: '0.8rem', marginBottom: '1rem'}}>
            {essay.isPublic ? '전체 공개' : essay.isTeamPublic ? '팀공개' : '비공개'}
          </div>
          <Item.Header
            content={essay.title}
            style={{
              fontSize: '1rem'
            }}/>
          <Item.Description>
            {essay.body.substring(0, 100)}...
          </Item.Description>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop:'1rem'}}>
            <div style={{fontSize: '0.8rem', color: 'grey'}}>
              {moment(essay.createdAt.seconds * 1000).format('YYYY. MM. DD (ddd)')}
            </div>
            <Rating maxRating={5} defaultRating={essay.selfRate}
                    color={'black'}
                    disabled
                    icon='star'
                    size='small'/>
          </div>
        </Item.Content>
        {
          (essay.images && essay.images.length > 0) && (
            <Item.Image
              size={'small'}
              src={essay.images[0].imageUrl}
              style={{
                paddingLeft: '2rem'
              }}
            />
          )
        }

      </Item>
    );
  }
}


export default EssayComponent;
