import {combineReducers} from "redux"
import {authReducer} from "./auth/authStore";
import {goalReducer} from "./goal/goalStore";
import {essayReducer} from "./essay/essayStore";
import {eodReducer} from "./eod/eodStore";

const reducers = combineReducers({
  auth: authReducer,
  goalReducer: goalReducer,
  essayReducer: essayReducer,
  eodReducer: eodReducer,
});

const rootReducer = (state, action) => {
  console.log(action);
  console.log(state);
  return reducers(state, action)
};

export default rootReducer;