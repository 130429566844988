// const BDCOLOR = '#22395A';
const BDCOLOR = '#1678C2';
const BDCOLOR_SUB = '#22395A';
const BDCOLOR_SUB2 = '#D3F8FF';
const BDCOLOR_GREY = '#E4E4E4';
// const BDCOLOR = '#009ADA';
const SAYING_SIZE = '1.5em';
export {
    BDCOLOR,
    SAYING_SIZE,
    BDCOLOR_SUB,
    BDCOLOR_SUB2,
    BDCOLOR_GREY,
}