import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Segment, Container, Grid, Form, Message, Button} from 'semantic-ui-react';

import {auth} from "../../config/firebase";

class FindPasswordContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      uploading: false,
    }
  }


  _sendFindPassword = async (e) => {
    e.preventDefault();
    if (this.state.email === '') {
      return window.alert('이메일을 입력해 주세요');
    }
    this.setState({uploading: true});
    auth
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        window.alert('이메일이 발송되었습니다');
        this.setState({uploading: false});
      })
      .catch(e => {
        window.alert(e.message);
        this.setState({uploading: false});
      })
  };

  handleChange = (e, {value, name}) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <Grid textAlign="center" style={{height: '90vh'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}} textAlign="left">
          <Form size="large">
            <Segment stacked>

              <Form.Input icon="mail" iconPosition="left" placeholder="E-mail address"
                          type="email" name="email" value={this.state.email}
                          onChange={this.handleChange}/>

              <Button fluid
                      active={this.state.uploading}
                      disabled={this.state.uploading}
                      onClick={this._sendFindPassword} color="blue" basic size="large">
                비밀번호 재설정 이메일 보내기
              </Button>
            </Segment>
          </Form>
          {
            this.state.message ? (
              <Message warning>{this.state.message}</Message>
            ) : null
          }
        </Grid.Column>
      </Grid>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(FindPasswordContainer);