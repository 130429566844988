import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Container} from 'semantic-ui-react';

const propTypes = {

};
const defaultProps = {

};

const style = {
    Segment : {
        padding: '100px'
    },
    header : {
        marginTop:'1em',
        fontWeight: 100,
        fontSize: '1.4em',
    },
    header1 : {
        marginTop:'1em',
        fontWeight: 400,
        fontSize: '1.5em',
    }
}

class HomeBottom extends Component{
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Segment
                vertical
                basic
                style={style.Segment}
            >
                <Container
                    textAlign='center'
                    style={style.header1}
                >
                    당신은, 당신의 분야에서 탁월한 최고가 되고 싶습니까?
                </Container>

            </Segment>
        );
    }
};

HomeBottom.propTypes = propTypes;
HomeBottom.defaultProps = defaultProps;

export default HomeBottom;
