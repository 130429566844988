import * as moment from 'moment';

// MOMENT로 날짜 다 수정해야함



const articleData = [
    {
        image: "/images/articleThumnail/i1.daumcdn-15.jpg",
        title: "샘 월튼, [불황없는 소비를 창조하라]를 읽고",
        summary: "​ 월마트는 세간에서 이미 한물간 기업이라고 평가를 받기도 하지만 그 영향력은 여전히 대단하다. 1962년 아칸소의 작은 도시에서 잡화점으로 시작한 월마트는 창업 41년(2002년)만에 세계에서 매출액이 가장 큰 회사가 된다. 2016년 연간 매출액 4,850억 달러로 여전히 매출 기준 세계 1등이며, 230만 명의 직원이 일하고 있는 세계에서 일자리를 가",
        tag: [
            "인물", "월마트", "창업가"
        ],
        date: moment("2018-02-11").format('YYYY-MM-DD'),
        author: "김왕수",
        authorImage: "/images/crewProfile/17f_wangsoo.jpg",
        url: "https://brunch.co.kr/@project1/20",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-14.jpg",
        title: "여전히 노력은 중요하다",
        summary: "세계 최고. 초일류. 어떤 사람은 세계 최고가 되고, 어떤 사람은 그렇지 못한 걸까요?  정답은 노력에 있습니다. 최근 ‘노오력’이라고 폄하되기도 합니다만 여전히 가장 중요한 가치입니다. 노오력 이라고 폄하되는 데는 이유가 있습니다. 어떠한 노력을 어떻게 하는 것이 중요한데, 그런 정의 없이 무조건 노오력 만 하라고 하면 받아들이기 어려울 수 있으니까요. ",
        tag: [
            "최고",
        ],
        date: moment("2018-01-28").format('YYYY-MM-DD'),
        author: "Project 1",
        authorImage: "/images/logo.png",
        url: "https://brunch.co.kr/@project1/19",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-13.jpg",
        title: "부동산, 파도 대신 바람",
        summary: "주의: 철저히 개인 의견이 반영된 글입니다.  서론 영화 ‘관상’의 마지막 장면에서 관상가(송강호)와 한명회(김의성)가 나눈 대사는 아직도 내 머리 속에 맴돈다.송강호는 김의성에게 이렇게 말한다.  그냥 수양은 왕이 될 사람이었단 말이오. 난 사람의 얼굴을 봤을 뿐 시대의 모습을 보지 못했소. 시시각각 변하는 파도만 본 격이지. 바람을 보아야 하는데. 파도",
        tag: [
            "부동산",
        ],
        date: moment("2018-01-28").format('YYYY-MM-DD'),
        author: "Project 1",
        authorImage: "/images/logo.png",
        url: "https://brunch.co.kr/@project1/18",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-12.png",
        title: "생존을 위한 초기 수용",
        summary: "새로운 사업을 시작하거나 관련된 일을 하는 사람들은 (e.g. 스타트업 종사자, 투자자 etc) 아마 next big thing이 뭐가 될지에 관심이 많을 것이다.  어떤 기술, 분야가 앞으로 유망한 지 판단하고, 그 wave를 잘 활용하여 사업적인 성과로 이어지도록 하는 것이 그들의 사업적 성패와 직결될 것이기 때문이다.  너무 빨리 뛰어들면 시간과 자금",
        tag: [
            "혁신", "기술 수용",
        ],
        date: moment("2018-01-20").format('YYYY-MM-DD'),
        author: "Project 1",
        authorImage: "/images/logo.png",
        url: "https://brunch.co.kr/@project1/17",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-11.jpg",
        title: "시애틀에 대해서",
        summary: "1. 휴먼스케일  City of One Million People 내가 느끼는 ‘살기 좋은 도시의 규모’는 100-300만 명 정도다. 병특으로 대전에서 근무할 때 진짜 대전이 ‘삶의 질’이 높은 걸 느꼈고, 내가 여행 중에 가장 좋아했던 퍼스, 그리고 오랜만에 시애틀도 딱 그 정도 규모다.  중소도시는 어느 정도 연봉이 보장되는 직업들이 그나마 있고, 부",
        tag: [
            "도시", "여행"
        ],
        date: moment("2017-11-27").format('YYYY-MM-DD'),
        author: "최혁준",
        authorImage: "/images/logo.png",
        url: "https://brunch.co.kr/@project1/16",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-10.jpg",
        title: "1,000시간의 독서",
        summary: "2015년 6월부터 2015년 12월까지, 약 6개월 동안 ‘전업 독서가’로서 책을 읽었습니다. 주 5일, 하루에 8시간씩 이게 마치 나의 직업이라 생각하고 책을 읽었습니다. 25주 동안 책을 읽었으니 약 1천 시간 정도 일을 했네요. ‘전업 독서가’인 기간 동안 90권 정도의 책을 완독 했습니다. 그 외에도 관심 있는 여러 책들을 그때그때 부분 부분 찾아",
        tag: [
            "독서가", "독서"
        ],
        date: moment("2017-11-25").format('YYYY-MM-DD'),
        author: "김산",
        authorImage: "/images/crewProfile/17f_san.jpg",
        url: "https://brunch.co.kr/@project1/15",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-9.jpg",
        title: "한국을 떠나 세상으로",
        summary: "저는 회사를 퇴사하고, 현재 세계일주 중에 있습니다. 2017년 1월 말에 일본으로 출국하여, 호주/뉴질랜드, 동남아, 유럽을 거쳐 현재 미국에 있습니다.   언젠가부터 글로벌하게 비즈니스를 하는 기업을 만들고 싶다는 생각을 하게 되었습니다. 한국에서 태어나서 한국에서 자랐다는 생각이 자꾸 들어서, 그러한 생각을 극복하고 싶었습니다. MBA 등 유학도 고민",
        tag: [
            "세계일주",
        ],
        date: moment("2017-11-07").format('YYYY-MM-DD'),
        author: "최혁준",
        authorImage: "/images/logo.png",
        url: "https://brunch.co.kr/@project1/14",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-8.jpg",
        title: "[Ready To Globe] 1.제로 투 원",
        summary: "0. 프롤로그 보러가기   0에서 1이 되는 것은 1에서 10이 되는 것과 완전히 다르다. 그 뒤에는 수많은 삽질의 역사가 숨어 있다. 대부분의 첫 번째 출시 제품은 대부분 구리다. 그런데 소비자들은 절대 구린 제품을 구매하지 않는다. 스타트업이 만들었다고 해서 품질을 타협해주지도 않는다. 그들은 그냥 좋은 제품을 좋은 가격에 구매하면 그만인 것이다. 그 ",
        tag: [
            "창업", "독일"
        ],
        date: moment("2017-10-17").format('YYYY-MM-DD'),
        author: "강현욱",
        authorImage: "/images/crewProfile/17f_kevin.jpg",
        url: "https://brunch.co.kr/@project1/11",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-7.jpg",
        title: "더 나은 마케팅 측정 모형",
        summary: "어떻게 마케팅 Attribution에 게임이론이 적용될 수 있을까? *해당 블로그 포스팅은 Attribution에 대한 이해를 필요로 합니다. 만약 Attribution에 대해서 처음 접하시는 분은 먼저 블로그 포스 ...\n",
        tag: [
            "마케팅", "Marketing"
        ],
        date: moment("2017-09-29").format('YYYY-MM-DD'),
        author: "조경상",
        authorImage: "/images/crewProfile/17f_kevin.jpg",
        url: "https://brunch.co.kr/@project1/11",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-6.jpg",
        title: "위대한 기업을 만드는 기업문화",
        summary: "\"문화는 아침식사로 전략을 먹는다\" 알렉스 퍼거슨은 영국 프리미어리그의 맨체스터 유나이티드 축구팀의 감독을 맡았던 감독이다. 그는 27년의 재임기간 동안 프리미어리그 우승 13회, 챔피언스리그 우승 2회 등 무수히 많은 우승컵을 들어 올린 전설적인 명장이다. 물론 퍼거슨의 팀에 크리스티앙 호날두나 데이비드 베컴과 같은 세계 최고의 선수가 있었던 적도 많았다. 하지만 그가 항상 최고의 선수단으..",
        tag: [],
        date: "2017-09-28",
        author: "백승엽",
        authorImage: "/images/crewProfile/17f_seungyeop.jpg",
        url: "https://brunch.co.kr/@project1/10",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-5.jpg",
        title: "8,760 시간의 기록",
        summary: "1년 동안 24시간 한순간도 빠짐없이 기록한 후 느낀 점 8,760 시간 = 24시간 x 365일 정확히 1년을 시간으로 환산한 것입니다. 저는 시간을 기록합니다. 매일매일, 24시간을. 단 한순간도 빠짐없이 ...",
        tag: [],
        date: "2017-09-24",
        author: "윤영훈",
        authorImage: "/images/crewProfile/17f_younghoon2.jpg",
        url: "https://brunch.co.kr/@project1/9",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-4.jpg",
        title: "내가 생각하는 마케팅",
        summary: "브랜딩과 퍼포먼스 마케팅은 무엇이 다를까? 마케터라고 모두가 전부 같은 활동을 하지 않는다. 광고부터 프로덕트 레벨까지 다양한 마케팅의 분야가 있지만 우리가 흔히 생각하는 마케팅 활동의 대부분은 조금 더 광고나 프로모션에 집중되어 있는 느낌이다. 이런 시점에서 봤을 때, 어떤 사람은 흔히 말하는 ‘브랜딩’을 하기도 하고 어떤 사람은 조금 더 즉시적인 성과를 내는 마케팅을 하기도 한다 (이걸 요즘에는 ...",
        tag: [],
        date: "2017-09-21",
        author: "조경상",
        authorImage: "/images/crewProfile/17f_kevin.jpg",
        url: "https://brunch.co.kr/@project1/8",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-3.jpg",
        title: "우리의 고객은 누구인가?",
        summary: "B2C vs. B2B 올 초부터 딥러닝 기반 B2B 솔루션의 PM 업무를 맡게 되었다. 평소에 맡고 싶었던 PM 업무였던 만큼 열정적으로 업무에 임했다. 제품 기획단계부터 시작해 개발 관리, 출시, ...",
        tag: [],
        date: moment("2017-09-17").format('YYYY-MM-DD'),
        author: "김민석",
        authorImage: "/images/crewProfile/17f_minseok.jpg",
        url: "https://brunch.co.kr/@project1/7",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-2.jpg",
        title: "[Road to Globe] 0. 프롤로그",
        summary: "나는 어떻게 독일로 가게 되었는가 글로벌 기업은 모든 창업팀의 꿈이다. 한편으론 글로벌 기업을 꿈꾸지 않는 창업팀이라면 훌륭한 창업팀이라고 할 수 없다. 기업의 세계에서는 안타깝게도 ...\n",
        tag: [],
        date: moment("2017-09-03").format('YYYY-MM-DD'),
        author: "강현욱",
        authorImage: "/images/crewProfile/17s_01_hw@2x.png",
        url: "https://brunch.co.kr/@project1/5",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn-1.jpg",
        title: "고객을 만나기 전 하는 일들",
        summary: "세일즈에 대한 나의 철학 세일즈 담당에게 고객과의 만남만큼 중요한 일은 없다. 엄밀히 말해서 세일즈맨에게 ‘일터’는 사무실보다는 고객과의 만남 장소에 더 가깝다. 세일즈에서 가장 중요한 일이 고객과의 만남인 만큼, 고객 만남에 앞서서 생각할 것들을 정리해보았다. 일하는 회사의 특성상 내용이 B2B 세일즈에 특화되어 있을 수 있다. 하지만 여전히 어떤 분야의 세일즈에도 적용 가능하다...",
        tag: [],
        date: moment("2017-08-27").format('YYYY-MM-DD'),
        author: "김왕수",
        authorImage: "/images/crewProfile/17f_wangsoo.jpg",
        url: "https://brunch.co.kr/@project1/4",
    },
    {
        image: "/images/articleThumnail/i1.daumcdn.jpg",
        title: "읽는 만큼 성장한다.",
        summary: "지식의 격차, 사고방식의 격차 \"책을 왜 읽어야 하나요?\" 답하기 쉽지 않은 질문이다. 그리고 많은 사람들이 하는 질문이기도 하다. 분명 많은 사람들이 책 읽기는 중요하다고 생각하지만 한...",
        tag: [],
        date: moment("2017-08-20").format('YYYY-MM-DD'),
        author: "조경상",
        authorImage: "/images/crewProfile/17f_kevin.jpg",
        url: "https://brunch.co.kr/@project1/3",
    },
];

export {articleData};