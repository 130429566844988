import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Segment, Loader, Table, Container, Button} from 'semantic-ui-react'

class BoardsForAllMembersMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            boardsForAllMembers: [],
            isLoaded: false,
        }
    }


    componentDidMount() {
        this._loadBoardsForAll()
    }


    _loadBoardsForAll = () => {
        db.collection('boardsForAllMembers')
            .limit(5)
            .get()
            .then(snapshot => {
                const boardsForAllMembers = [];
                snapshot.forEach(doc => {
                    const content = doc.data();
                    content.id = doc.id;
                    boardsForAllMembers.push(content);
                })

                this.setState({
                    boardsForAllMembers,
                    isLoaded: true
                })
            })
            .catch(e => {
                console.log(e);
            })

    };

    render() {
        if (!this.state.isLoaded) {
            return (
                <Loader active/>
            )
        }
        return (
            <Fragment>
                <Segment vertical basic as={Container}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>번호</Table.HeaderCell>
                                <Table.HeaderCell>제목</Table.HeaderCell>
                                <Table.HeaderCell>날짜</Table.HeaderCell>
                                <Table.HeaderCell>작성자</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.boardsForAllMembers.map((item, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{i + 1}</Table.Cell>
                                            <Table.Cell>{item.title}</Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                        <Table.Footer fullwidth>
                            <Table.Row>
                                <Table.HeaderCell colspan={4}>
                                    <Button>
                                        글쓰기
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>

                </Segment>
            </Fragment>
        );
    }
}


export default BoardsForAllMembersMainContainer;
