import * as moment from 'moment';


const essayExample =[
    {
        title: "나를 가슴뛰게 하는 것은 무엇인가?",
        _id: "1",
        thumbnail:'/images/program/eod/01.jpg',
        description: "스켈레톤의 윤성빈 선수는 올림픽은 신이 준 기회가 아니라 노력이라고 했다. 예전에는 금메달리스트를 보면 ‘어린 나이에 성공했네. 저렇게 어린 나이에 성공했는데 나는 뭐했지?’ 라며 뛰어난 재능을 가진 사람을 막연히 부러워했다.",
        tag : [],

        authorImage:'/images/image.png',
        author: "이유아",
        date : moment('2018-02-21').format('YYYY-MM-DD'),
        content: "<h3><strong>신이 준 기회가 아니라 노력입니다.</strong></h3>\n" +
        "\n" +
        "<p>스켈레톤의 윤성빈 선수는 올림픽은 신이 준 기회가 아니라 노력이라고 했다. 예전에는 금메달리스트를 보면 &lsquo;어린 나이에 성공했네. 저렇게 어린 나이에 성공했는데 나는 뭐했지?&rsquo; 라며 뛰어난 재능을 가진 사람을 막연히 부러워했다. 하지만 요즘은 운동에만 집중했던 그의 노력을 먼저 생각한다. 윤성빈 선수는 평창 경기장을 380번을 탔다고 한다. &lsquo;6개월 동안 하루에 두번씩만 타면 되네&rsquo;라고 말하는 사람이 있을 지도 모르겠다. 하지만 스스로에게 물어보자 무언가를 6개월 동안 빠짐없이 두 번씩 한 적이 있었던가? 시상대에서 눈물을 흘리는 선수를 보면 그의 노력의 가치를 알기에 나도 눈시울이 붉어진다.</p>\n" +
        "\n" +
        "<p>&nbsp;</p>\n" +
        "\n" +
        "<h3><strong>&ldquo;노력-자신감-쫄깃한 심장-성취감&rdquo;</strong></h3>\n" +
        "\n" +
        "<p>내 가슴이 뛰었던 순간은 노력-자신감-쫄깃한 심장-성취감으로 연결된다. 학부 때 나는 벼락치기의 달인이었다. 시험기간이 시작되면 밤을 새며 딱 세 번씩 봤다. 처음에는 하나도 모르는 내용도, &lsquo;이만 하면 오케이!&rsquo; 라는 자신감이 드는 순간이 온다. 시험이 시작되면 심장이 쫄깃쫄깃 터질 듯이 긴장된다. 나는 이제 준비가 되었다! 노력을 잘 풀어내기만 하면 된다. 그 정도의 쫄깃한 심장을 느꼈다면 성적은 언제나 옳았다. 회사에서도 마찬가지, 처음 임원진 보고를 담당했을 때의 막막함. 하지만 집중할 분량을 정하고 열심히 노력하다 보면 막막함이 자신감으로 바뀌는 순간이 있다. 물론 발표 전 나의 심장은 요동치지만 기분 좋은 긴장이다. 발표 후의 성취감을 이루 말할 수가 없다. 내가 노력해서 얻은 결과이니까.</p>\n" +
        "\n" +
        "<p>&nbsp;</p>\n" +
        "\n" +
        "<h3><strong>&ldquo;해야 한다&rdquo; vs &ldquo;하고 싶다. 할 것이다&rdquo;</strong></h3>\n" +
        "\n" +
        "<p>기대하지 않았던 운이 찾아오는 경우도 있다. 시험에 찍은 문제가 맞거나, 길을 가다가 돈을 줍거나... 물론 당시는 정말 기분이 좋다. 하지만 기쁨은 오래 지속되지는 않는다. 지금 상황에서 내가 바라는 목표를 정하고, 내 자신에게 부끄럽지 않을 만큼 노력했을 때 가장 즐겁고 기억에 남는다. 인생이라는 항해에서 방향의 키를 내가 잡고 있는 기분이니까. 외부 요인에 의해서 정해진 목표라면 노력 자체가 곤욕이다. 무언가에 이끌려서 허덕거리며 따라가는 느낌. 참 싫다. 하지만 외부에서 주어진 목표라도 잘 생각해보면 내 삶에 도움이 되는 경우가 많다. 그렇게 되면 다시 내 가슴은 뛴다. 열심히 집중해서 노력하고 성취하면 되니까. &ldquo;해야 해서 한다&rdquo;가 &ldquo;하고 싶다. 할 것이다&rdquo;로 바뀌는 순간 내 삶의 주도권은 나에게 온다.</p>\n" +
        "\n" +
        "<p>&nbsp;</p>\n" +
        "\n" +
        "<h3><strong>일의 시작과 끝이 분명한 사람</strong></h3>\n" +
        "\n" +
        "<p>올해는 어떤 가슴 뛰는 목표를 정해 볼까? 일의 시작과 끝이 분명한 사람이 되고 싶다. 회사에서 Due를 지키지 못하는 나를 자주 발견한다. 기한을 맞춰야 한다는 의무감에 항상 부담스럽다. 하지만 비단 회사일 뿐만 아니라 다른 일에도 시작과 끝이 분명하면 인생 전체에 참 도움이 많이 될 것이다. 하나를 깔끔하게 마치고 다른 재미있는 일을 시도할 수 있으니까. 그렇다면 이제 깔끔하게 마무리하는 습관을 만들기 위한 액션플랜을 세우고 노력하는 일만 남았다. 상반기가 끝났을 때 기분 좋은 짜릿함을 맛보게 되겠지.</p>\n"
    },
    {
        title: "평생 지켜가고 싶은 소신은 무엇인가?",
        _id: "2",
        thumbnail:'/images/program/eod/02.jpg',
        description: "상상 속의 나와 현실의 내 모습을 구분하고, 항상 있는 그대로 나를 바라보자. 드러내기 부끄럽거나 구질한 면도 온전한 나의 일부분인 것을 부정하지 말자. 세간에 꼴불견이라고 일컬어지는 행동이 혹여 지금의 내 모습은 아닌지 언제나 되돌아보자. 나도 못 하는 것을 마치 나는 잘 하고 있다는 듯, 감히 타인에게 가르치려 들지 말자.",
        tag : [],

        authorImage:'/images/image.png',
        author: "김덕호",
        date : moment('2018-02-10').format('YYYY-MM-DD'),
        content: "<h3>1. 자기객관화</h3>\n" +
        "\n" +
        "<p>상상 속의 나와 현실의 내 모습을 구분하고, 항상 있는 그대로 나를 바라보자. 드러내기 부끄럽거나 구질한 면도 온전한 나의 일부분인 것을 부정하지 말자. 세간에 꼴불견이라고 일컬어지는 행동이 혹여 지금의 내 모습은 아닌지 언제나 되돌아보자. 나도 못 하는 것을 마치 나는 잘 하고 있다는 듯, 감히 타인에게 가르치려 들지 말자.</p>\n" +
        "\n" +
        "<h3>2. 매일 성장하기</h3>\n" +
        "\n" +
        "<p>오늘은 어제 몰랐던 것을 무엇 하나라도 더 알고 지나가는 날이어야 한다. 비단 지식을 쌓는 일뿐만 아니라, 익숙한 일상에서 새로움을 발견하고 생각이 트이는 성장이 있어야 한다. 오늘의 내가 어제의 나보다는 낫다는 확신이 선 삶을 살아야 한다.</p>\n" +
        "\n" +
        "<h3>3. 오직 나만이 결정하는 삶</h3>\n" +
        "\n" +
        "<p>다양한 의견에 귀 기울이되, 내 삶의 결정은 오직 나만이 내릴 수 있는 인생을 살자. 내게 강요된 선택이란 없으며, 타인에게 의존하는 선택도 없다. 죽이 되든, 밥이 되든 오직 나의 의지로 내가 결정하는 삶을 살자.</p>\n" +
        "\n" +
        "<h3>4. 모든 게 내 탓</h3>\n" +
        "\n" +
        "<p>잘못된 일의 원인을 외부에 돌리는 비겁한 행동은 지양하자. 순간의 자괴감을 모면하기 위해 꺼내든 자기합리화는 깊이 넣어두자. 모든 게 나의 선택이었고, 내 탓으로 인해 생긴 결과라는 것을 받아들이자.</p>\n"
    },
    {
        title: "나를 믿는 것은 어떤 의미인가?",
        _id: "3",
        thumbnail:'/images/program/eod/03.jpg',
        description: "성공하는 사람들의 공통점 중에 하나는 '믿음'이다. 믿음이 있는 사람은 기준이 자기 내부에 있기 때문에 쉽게 흔들리지 않는다. 스스로의 동력으로 움직이기 때문에 남들이 시키는 일& 시키지 않는 일에 연연하지 않고 가치를 창출한다. 믿음은 Why?에 대한 대답을 가지는 것이다. ",
        tag : [],

        authorImage:'/images/image.png',
        author: "김소미",
        date : moment('2017-09-09').format('YYYY-MM-DD'),
        content: "<h3>&#39;믿음&#39; 이란 자기 기준. 중심.&nbsp;</h3>\n" +
        "\n" +
        "<p>성공하는 사람들의 공통점 중에 하나는 &#39;믿음&#39;이다. 믿음이 있는 사람은 기준이 자기 내부에 있기 때문에 쉽게 흔들리지 않는다. 스스로의 동력으로 움직이기 때문에 남들이 시키는 일&amp; 시키지 않는 일에 연연하지 않고 가치를 창출한다. 믿음은 Why?에 대한 대답을 가지는 것이다.&nbsp;</p>\n" +
        "\n" +
        "<h3>1. 왜 믿음을 가져야 하는가?&nbsp;</h3>\n" +
        "\n" +
        "<p>&#39;믿음&#39;은 태도의 영역에 있다.<br />\n" +
        "류승완 감독 曰 &#39;재능이 있고 없고는 중요하지 않은 것 같아요. 재능이 있다고 믿는 게 중요한거죠.&#39;<br />\n" +
        "믿는 것과 믿지 못하는 것은 필연적으로 찾아오는 성공과 실패에 대한 나의 태도를 결정한다.시계를 멀리 던졌을 때, 승패는 언제나 엇갈리게 된다. 기나긴 승부 속에서 승리와 패배를 어떻게 받아 들이냐가 중요한 것이다. 승리의 기쁨에 심하게 도취하지 않고 패배의 수렁에 너무 깊이 빠져들지 않는 방법이 무엇인가를 깨닫는 순간, 진정한 승부 근성을 발휘할 수 있다.<br />\n" +
        "Self doubt vs idea doubt&nbsp;</p>\n" +
        "\n" +
        "<p>인간은(이유는 모르겠지만) 무엇인가 할 일이 필요하다. 무언가 정성을 쏟고 에너지와 시간을 낭비할(?) 대상, 몰입의 대상이 필요한 것이다. 이때 믿음은 몰입의 대상이 된다. 누구든지 인간은 자신만의 결핍과 그러한 결핍을 해소할 수단이 필요하고, 적절하게 해소(배출)되지 않으면 비정상적으로 표출된다.</p>\n" +
        "\n" +
        "<h3>2.무엇을 믿어야 하는가? &nbsp;</h3>\n" +
        "\n" +
        "<p>그것이 어떤 것이라도 좋다. 본인이 옳다고 믿을 수 있는 것. 중요하다고 믿을 수 있는 것. 본인의 기준이 필요하다. 무언가에 대해 부당하다거나 사랑한다고 말할 수 없으면. 평생 주인으로 살 수 없다.&nbsp;</p>\n" +
        "\n" +
        "<h3>3.나는 무엇을 믿는가?&nbsp;</h3>\n" +
        "\n" +
        "<p>&lsquo;운명&rsquo; 나의 노력으로 어찌할 수 없는 부분이 있다는 것을. 인정하는 것을 믿는다. 하지만 정말 진지했는가? 하찮은 작은 일이라고 대충 생각하고 처리하지 않았는가? 정말 스스로에게 이게 나의 끝이야. 라고 말할 수 있는가? 나 자신은 속일 수 없다. 현재의 나에서 최선을 다하자. 결과는 겸허하게 받아들이 되, 떳떳하자.<br />\n" +
        "기회가 왔을 때 받아들일 준비가 되어 있는 사람.&nbsp;</p>\n" +
        "\n" +
        "<p>인간은 일생동안 무언가를 믿기 위해 사는 것일 지도 모르겠다.<br />\n" +
        "그것이 무엇인가는 중요하지 않다. 옳다고 믿을 수 있는 것을 찾아가는 여정이 인생이다.</p>\n"
    },
    {
        title: "내가 요즘 좋아하는 것은 무엇인가",
        _id: "4",
        thumbnail:'/images/program/eod/04.jpg',
        description: "사람 만나면 나오는 FAQ가 있다. \"어떤 OO 좋아하세요?\" OO은 음식, 영화, 음악 등 다양하다. 이 정도의 FAQ라면 그럴싸한 모범답안을 미리 고민해봄직도 한데, 30년이 지나서야 내 답이 성에 안 차기 시작했다. 그 중에서 답이 가장 모호했던 부분이 음악이다. ",
        tag : [],

        authorImage:'/images/image.png',
        author: "김주은",
        date : moment('2017-09-02').format('YYYY-MM-DD'),
        content: "<h3>&quot;어떤 OO 좋아하세요?&quot;</h3>\n" +
        "\n" +
        "<p>사람 만나면 나오는 FAQ가 있다. &quot;어떤 OO 좋아하세요?&quot; OO은 음식, 영화, 음악 등 다양하다. 이 정도의 FAQ라면 그럴싸한 모범답안을 미리 고민해봄직도 한데, 30년이 지나서야 내 답이 성에 안 차기 시작했다. 그 중에서 답이 가장 모호했던 부분이 음악이다. 늘 Jazzy한 것을 좋아한다고 답했다. Jazz가 뭔지 잘 모르기도 하고 좋아하는 사람들은 매니악하게 좋아하는 이 장르를 언급하는 것이 불편하고 지식(보다도 취향의) 미천함이 드러날까봐 불안하기도 했다. 그래서 당당하게 재즈를 좋아한다고 하지 못하고, 재지한 것을 좋아한다고 뭉뚱그렸다.</p>\n" +
        "\n" +
        "<h3>Jazzy에 한걸음 다가가다</h3>\n" +
        "\n" +
        "<p>이번에 재즈에 대해 취향찾기를 시작하면서 Jazzy의 정체를 찾았다. 재즈의 역사를 죽 훑으니 사실 대부분의 재즈는 내게는 과하게 경쾌하다. 내가 좋아하던 재즈는, 재즈클럽에서 여성 보컬이 다소 마이너한 분위기의 스탠다드 넘버를 부르는 한정적인 범위였던 것이다. 내가 재지함을 좋아한다는 것의 큰 비중은 보컬이 차지한다. 보컬을 좋아하는 이유는, 내가 참여할 여지가 있다고 생각하기 때문이다. 나는 운동을 좋아하지만, 단순히 운동을 관람하는 것은 즐기지 않는다. 관람을 해야 한다면, 신들의 경기 같은 NBA보다 여자농구 보는 것을 즐긴다. 왜냐면 내가 플레이를 하는 데 더 직접적인 참조가 되기 때문이다. 하지만 재즈의 역사에서 보컬은 굉장히 일부임을 알게 되었다. 물론 여전히 재즈 트리오인 베이스, 피아노, 재즈 드럼의 연주도 좋고 엇박의 변주도 좋다. 하지만 이런 풍의 음악을 좋아하는 것이지, 이것을 장르화하기에는 무리가 있다는 생각이 든다.</p>\n" +
        "\n" +
        "<h3>Jazzy를 조금 더 이해해 보자</h3>\n" +
        "\n" +
        "<ol>\n" +
        "\t<li>내가 좋게 들었던 장르-쿨재즈, 퓨전재즈, 소울재즈-의 고전 명반을 훑고,</li>\n" +
        "\t<li>내가 좋아하는 음악의 장르가 아닌 특성을 정의하고</li>\n" +
        "\t<li>그 특성을 기반으로 동시대의 좋은 뮤지션과 음악도 찾으려 한다.</li>\n" +
        "\t<li>동시에 내가 참여할 수 있는 여지를 넓혀가려고 한다. 동호회 등 가입까지도 생각 중이다. 아무래도 내가 취향을 개발하는 방법은 공부보다는 참여다.</li>\n" +
        "</ol>\n"
    },
    {
        title: "나의 50대는 어떤 모습일까?",
        _id: "5",
        thumbnail:'/images/program/eod/05.jpg',
        description: "유형으로든 무형으로든 그 때까지 이루어내고 얻어낸 소중한 것들을 아끼면서도, 또한 그것들에 너무 얽매이지 않는 해탈함을 얻어냈기를. 분명 그런 환경에 도달하기까지 숱한 크고 작은 고난과 역경과 치열함이 있겠지만",
        tag : [],

        authorImage:'/images/image.png',
        author: "이태석",
        date : moment('2017-03-11').format('YYYY-MM-DD'),
        content: "<h3>구체적이진 않지만..</h3>\n" +
        "\n" +
        "<p>50대의 내 모습이 어떨지 상상이 잘 되진 않는다.&nbsp;<br />\n" +
        "하지만 높은 확률로 가족이 있을 것 같고 (자식의 유무까지는 아직 모르겠다), 그 곳이 어디가 됐든 조용하고 안정적이며 활기있는 동네에서 살고 있기를 희망한다.<br />\n" +
        "욕심을 부리자면 주요도심에서 그리 멀지 않았으면 한다.</p>\n" +
        "\n" +
        "<p><br />\n" +
        "유형으로든 무형으로든 그 때까지 이루어내고 얻어낸 소중한 것들을 아끼면서도, 또한 그것들에 너무 얽매이지 않는 해탈함을 얻어냈기를.<br />\n" +
        "분명 그런 환경에 도달하기까지 숱한 크고 작은 고난과 역경과 치열함이 있겠지만 그래도 여전히 내가 그 때의 현재를 누리면서도 도전정신을 가지고 있기를 바란다.</p>\n" +
        "\n" +
        "<p>안정성과 안주를 일종의 실패라고, 뒤처지는 것이라고 생각하지는 않지만, 더 나음을 위해, 또한 새로운 것을 위해 나아갈 수 있어야 하겠다.</p>\n" +
        "\n" +
        "<h3>그러기 위해서는..</h3>\n" +
        "\n" +
        "<p>그러기 위해서는, 새로운 것을 받아들일 수 있고 또 공부하고 싶은 열정이 있는 정신이 있어야겠지.<br />\n" +
        "깊어지는 영혼과 더불어 유연한 정신의 소유자가 되어있으면 좋겠다.</p>\n" +
        "\n" +
        "<p>---------------------------------------</p>\n" +
        "\n" +
        "<p>만일</p>\n" +
        "\n" +
        "<p>만일 네가 모든 걸 잃었고 모두가 너를 비난할 때<br />\n" +
        "너 자신이 머리를 똑바로 쳐들 수 있다면.<br />\n" +
        "만일 모든 사람이 너를 의심할 때<br />\n" +
        "너 자신은 스스로를 신뢰할 수 있다면.</p>\n" +
        "\n" +
        "<p>만일 네가 기다릴 수 있고<br />\n" +
        "또한 기다림에 지치지 않을 수 있다면.<br />\n" +
        "거짓이 들리더라도 거짓과 타협하지 않으며<br />\n" +
        "미움을 받더라도 그 미움에 지지 않을 수 있다면.<br />\n" +
        "그러면서도 너무 선한 체하지 않고<br />\n" +
        "너무 지혜로운 말들을 늘어놓지 않을 수 있다면.</p>\n" +
        "\n" +
        "<p><br />\n" +
        "만일 네가 꿈을 갖더라도<br />\n" +
        "그 꿈의 노예가 되지 않을 수 있다면.<br />\n" +
        "또한 네가 어떤 생각을 갖더라도<br />\n" +
        "그 생각이 유일한 목표가 되지 않게 할 수 있다면.</p>\n" +
        "\n" +
        "<p>그리고 만일 인생의 길에서 성공과 실패를 만나더라도<br />\n" +
        "그 두 가지를 똑같은 것으로 받아들일 수 있다면.<br />\n" +
        "네가 말한 진실이 왜곡되어 바보들이 너를 욕하더라도<br />\n" +
        "너 자신은 그것을 참고 들을 수 있다면.<br />\n" +
        "그리고 만일 너의 전생애를 바친 일이 무너지더라도<br />\n" +
        "몸을 굽히고서 그걸 다시 일으켜 세울 수 있다면.</p>\n" +
        "\n" +
        "<p>한번쯤은 네가 쌓아 올린 모든 걸 걸고<br />\n" +
        "내기를 할 수 있다면.<br />\n" +
        "그래서 다 잃더라도 처음부터 다시 시작할 수 있다면.<br />\n" +
        "그러면서도 네가 잃은 것에 대해 침묵할 수 있고<br />\n" +
        "다 잃은 뒤에도 변함없이<br />\n" +
        "네 가슴과 어깨와 머리가 널 위해 일할 수 있다면.<br />\n" +
        "설령 너에게 아무것도 남아 있지 않는다 해도<br />\n" +
        "강한 의지로 그것들을 움직일 수 있다면.</p>\n" +
        "\n" +
        "<p>만일 군중과 이야기하면서도 너 자신의 덕을 지킬 수 있고<br />\n" +
        "왕과 함께 걸으면서도 상식을 잃지 않을 수 있다면.<br />\n" +
        "적이든 친구든 너를 해치지 않게 할 수 있다면.<br />\n" +
        "모두가 너에게 도움을 청하되<br />\n" +
        "그들로 하여금<br />\n" +
        "너에게 너무 의존하지 않게 만들 수 있다면.<br />\n" +
        "그리고 만일 네가 도저히 용서할 수 없는 1분간을&nbsp;<br />\n" +
        "거리를 두고 바라보는 60초로 대신할 수 있다면.<br />\n" +
        "그렇다면 세상은 너의 것이며<br />\n" +
        "너는 비로소<br />\n" +
        "한 사람의 어른이 되는 것이다.</p>\n" +
        "\n" +
        "<p>ㅡ 루디야드 키플링</p>\n"
    },

    {
        title: "목표를 달성하기 위해서는 무엇이 중요한가",
        _id: "6",
        thumbnail:'/images/program/eod/06.jpg',
        description: "의지력을 쓰는 것은 환경 속에서 흘러가는 것 대비 비교할 수 없는 난이도의 문제를 수반한다. 또한 의지력의 총량이 정해져있다는 사실을 염두에 둘 필요가 있다(참고도서 : The one thing).",
        tag : [],

        authorImage:'/images/image.png',
        author: "오아영",
        date : moment('2017-03-09').format('YYYY-MM-DD'),
        content: "<h3>환경과 의지</h3>\n" +
        "\n" +
        "<p>지금의 내가 회사에 아침지각 하지 않는 것을 나의 꼬마적 친구들은 믿기 어려워 한다. 그만큼 시간약속은 내 평생에 있어 큰 퀘스트였다. 초 중 고 시절 늘 아침 지각자였던 본인이 지각으로 교문에서 잡힌 기억이 손꼽히는 것은, 내가 학교에 도착하는 9시 즈음엔 선도부도 학생주임선생님도 모두 철수했기 때문이었다. 수능 공부를 꽤 열심히 해 놓고도 수능 당일엔 1교시 언어영역 듣기평가 막 시작할 적에 고사장에 입성했고(그 시간에 철문 열어준다.) 자율의 전당인 대학에서는 말할 것도 없었다. 뻔뻔해서가 아니라 죄송하고 부끄럽게 생각하면서도 늘 고치지 못했으니 주변사람들 못지않게 스스로도 나름의 고충이 있었다.<br />\n" +
        "분명 그랬던 내가 어찌 지금은 그 고질병을 노력도 없이 극복했다. 인사팀장이라는 직책의 무게와 근태를 중시하는 회사분위기가 맞물려 만들어낸 마법이더라. 되짚어보니 지금의 근무환경에선 처음부터 어느 정도 당연하게 그랬는데, 하루아침에 고친 걸 보면 환경이 무섭긴 무섭구나. 내가 그걸 고치려고 얼마나 애를 썼었는데!!&nbsp;</p>\n" +
        "\n" +
        "<h3>못하는 것을 할 수 있는 것으로 가장 쉽게 바꾸어주는 장치가 바로 환경이다.</h3>\n" +
        "\n" +
        "<p>환경의 도움없이 하는 일은 100% 의지를 끌어다 써야 하는데, 의지는 많은 경우 얼마나 본능에 저항하며 본능을 거스를 수 있느냐에 따라 그 발현정도가 결정된다. 환경은 적응이라는, 모든 생명체 안에 깃든 강한 본능을 끌어낸다. 환경 안에서 하는 것들은 본능의 원조를 받는다.</p>\n" +
        "\n" +
        "<h3>의지는 투쟁이니 쉽지 않고,환경은 순응이니 비교적 쉽다.</h3>\n" +
        "\n" +
        "<p>의지력을 쓰는 것은 환경 속에서 흘러가는 것 대비 비교할 수 없는 난이도의 문제를 수반한다. 또한 의지력의 총량이 정해져있다는 사실을 염두에 둘 필요가 있다(참고도서 : The one thing). 하여 의지력은 돈이나 시간처럼 최대한 아껴서 정말 필요한 곳에 써야하는 무엇이다. 세 번째 정도로 중요한 데 의지력을 마음 놓고 부었다가는 최고로 중요한 데 정작 의지력을 쓸 수 없게 된다.&nbsp;<br />\n" +
        "환경을 조성할 수 있다면 가장 좋고(여기서 환경은 제도, 사람, 장소, 동선, 상황, 프로세스 등 본인 이외 외부세계의 모든 것 이 될 수 있다), 그것이 안될 때는 어쩔 수 없이 의지력을 써야만 한다. 의지력을 끌어다 쓰는 것을 피하기 어렵다면 얼른 그 의지력의 영역을 습관의 영역으로 가져오는 것이 좋다(참고도서: 21일프로젝트). 습관의 영역으로 끌어오면 노력하지 않고 쉽게 계속할 수 있고 의지력을 아끼게 된다. 아낀 그 의지력은 또 다른 중요한 데 쓸 수 있겠다.</p>\n" +
        "\n" +
        "<h3>의지보단 환경이다.</h3>\n" +
        "\n" +
        "<p>환경은 내 의지와 상관없이 특정한 행동을 유발하는 맥락이고 내 의도와 상관없이 들어오는 자극이다. 내 상태와 별개로 존재하기에 내 생각과 행동을 어려움 없이 이끈다. 그러니 환경 만들기에 골몰해보자.<br />\n" +
        "날마다 운동을 해서 건강해지고 싶은 목표가 있다면 &lsquo;매일 운동을 하기 위해 어떤 운동 목표를 세우고 실행할까?&rsquo; 보다 &lsquo;어떻게 하면 날마다 운동을 할 만한 환경을 만들 수 있을까?&rsquo; 가 더 좋은 질문일 것이다.</p>\n" +
        "\n" +
        "<p>+사람은 매우 강력하고 대표적인 환경이다<br />\n" +
        "나를 환경으로 삼은 BD 팀원들에게 최고의 환경이 되겠노라 다짐한다.</p>\n"
    },
    {
        title: "나에게 중요한 가치는 무엇인가",
        _id: "7",
        thumbnail:'/images/program/eod/07.jpg',
        description: "어쩌면 늦을 수 있는 24살이라는 나이에 패션공부를 시작했습니다. 그래서 20살 동급생들에 비해 절대적 시간이 부족함을 느껴, 지난 시간들을 만회하고자 최선을 다해서 살았습니다.",
        tag : [],

        authorImage:'/images/image.png',
        author: "박정수",
        date : moment('2016-08-29').format('YYYY-MM-DD'),
        content: "<h3>[시간]</h3>\n" +
        "\n" +
        "<p>어쩌면 늦을 수 있는 24살이라는 나이에 패션공부를 시작했습니다. 그래서 20살 동급생들에 비해 절대적 시간이 부족함을 느껴, 지난 시간들을 만회하고자 최선을 다해서 살았습니다. 인생을 42.195km 마라톤으로 놓고 본다면 24살이라는 나이는 10km쯤 뛰어온 결코 늦은 나이는 아니지만, 상대적인 관점에서 봤을 때는, 시간이 부족해 항상 마음 한 켠에는 조급함이 있었습니다. 이러한 점을 봤을 때, 시간 관리를 잘하는 것이 목표 도달의 속도를 결정짓지 않나 생각해서 중요하다고 생각합니다.</p>\n" +
        "\n" +
        "<h3>[열정]</h3>\n" +
        "\n" +
        "<p>패션 분야에서 일하고 싶은 열정이 있었기에 어떠한 유혹도 뿌려칠 수 있었으며, 남들이 겪어보지 못했던 힘듬도 견뎌내며 좋은 성적을 낼 수 있었다고 생각합니다. 정말 넘치는 열정이 있다면 자기가 하고자 하는 분야에서 빛을 낼 수 있을 거라고 확신합니다.</p>\n" +
        "\n" +
        "<h3>[목표]</h3>\n" +
        "\n" +
        "<p>23살까지는, 줄 곧 중학교,고등학교,대학교를 거치며 순탄하게 살아온 학생들에 비해 우회하면서 돌아왔지만, 24살부터는 제 인생에서 하고 싶은 일을 찾고 공부하기 시작했기 때문에 결코 늦었다고 생각하지 않습니다. 인생에서 중요한 점 하나라면 &#39;자기가 좋아하는 일을 하는 것&#39;이라고 생각합니다. 저는 그것을 찾았고, 이제는 BD를 통해 재정립한 목표를 도달하기 위해 더 분발하려고 합니다.</p>\n" +
        "\n" +
        "<h3>[배움]</h3>\n" +
        "\n" +
        "<p>100세 시대인 현재, 배움에는 끝이 없다고 생각합니다. 배움을 그만하는 순간 더 이상의 성장이 없다고 생각하는 1인입니다. 항상 새로운 것을 보고 겪고, 자극제로 삼아서 더 배우고 성장하는 것이 개인의 잠재력을 이끌어내고, 궁극적으로 남들이 해내지 못하는 원대한 일을 해낼 수 있다고 생각합니다.&nbsp;</p>\n" +
        "\n" +
        "<h3>[자세]</h3>\n" +
        "\n" +
        "<p>어느 사회, 어느 그룹을 나가서 새로운 사람들을 만나면 항상 저보다 훌륭한 사람은 존재하기 마련입니다. 그들을 보고 열등감을 느끼지 않고, 자신 스스로를 더욱 성장시킬 수 있는 자극제로 생각할 수 있는 긍정적인 자세가 있다면 인생을 더 풍요롭고, 알차게 살 수 있지 않을까 생각해서 마지막 단어로 선정했습니다. &emsp;&emsp;&emsp;</p>\n"
    },
    {
        title: "내가 부족하다고 느끼는 부분은 무엇인가?",
        _id: "8",
        thumbnail:'/images/program/eod/08.jpg',
        description: "집에서 대중교통으로 50분 거리에 있는 '선린인터넷고등학교'라는 매우 특이한 고등학교를 선택해서 갔던 것 부터 나의 평범하지 않은 인생 여정이 시작되었고, ",
        tag : [],

        authorImage:'/images/image.png',
        author: "변수민",
        date : moment('2016-08-26').format('YYYY-MM-DD'),
        content: "<h3>나는 다른 사람들을 이해하는 능력이 떨어진다.&nbsp;</h3>\n" +
        "\n" +
        "<p>최근의 일이 아니라 꽤 오래전 일이긴 하지만, (여러가지 계기로 인해) 내가 다른 사람들을 이해하는 능력이 떨어진다는 것을 깨달았다. 이 문제에 대해서 곰곰히 생각해봤는데, 그 이유를 찾으려면 고등학교 시절로 거슬러 올라가야 할 것 같다. 집에서 대중교통으로 50분 거리에 있는 &#39;선린인터넷고등학교&#39;라는 매우 특이한 고등학교를 선택해서 갔던 것 부터 나의 평범하지 않은 인생 여정이 시작되었고, 꽤 많은 부분에 있어서 남들이 다 해봤을만한 경험을 하지 못하고 살아왔다. 예를 들면, 고등학교때 야간 자율 학습은 정말로 신청자만 받아서 하는 &#39;자율 학습&#39;이었고 (그래서 나는 안 했다), 수능 당일 아침에 아무도 나를 깨우지 않아서 수능을 보지 못했으며, 대학도 지구 반대편으로 갔었다. 일단 외국인이라는 신분 자체가 나를 주류(majority)에서 멀찌감치 떼어놨었고, 대학 시절에 친하게 지내던 친구들도 하나같이 다 특이한 놈들이었다. 그리고 지금도 평범하지 않은 직장에서 평범하지 않은 사람들과 같이 일을 하고 있다.</p>\n" +
        "\n" +
        "<p>심지어는 그 흔한 &#39;알바&#39; 한 번 해본적이 없다. 대학교 1학년때 나의 인생 첫 직업이 학부생 연구 조교(research assistant)였고, 그 이후로 지금까지 내가 가졌던 일자리의 직무 내용에서 &#39;코딩&#39;이 빠진 적은 단 한번도 없었다. 물론 이민법상 학교 밖에서 일을 하는 것이 극도로 제한되어 있었기 때문에[^1] 그렇다는 핑계거리도 있었긴 했지만, 학교 안에 있는 파파존스 피자에서 일을 해볼 수 있었음에도 불구하고 그렇게까지 열심히 살지는 않았었다.[^2]</p>\n" +
        "\n" +
        "<p>&quot;커피숍 알바라도 해볼걸&quot; 하는 후회가 남는다. 스타벅스에서 매장에서 고객들의 주문을 받고 직접 커피를 만드는 일을 해볼 수 있다면 스타벅스 경영 보고서에서는 얻기 어려운 인사이트를 얻을 수도 있기 때문이다. 그렇지만 나의 시간은 점점 비싸지고 있기 때문에 지금이라도 알바를 해봐야겠다는 생각은 점점 약화 되고 있다.</p>\n" +
        "\n" +
        "<h3>경험의 부족에서 오는 결핍은 책으로 채워보자.&nbsp;</h3>\n" +
        "\n" +
        "<p>내가 다른 사람들을 이해하는 능력이 떨어지는 것은 선천적으로 그런 것이 아니라 단지 경험이 부족하기 때문이라고 생각한다. 그럴 것이라 굳게 믿고 책을 한 권 구입했다. 책을 아직 다 읽지는 못했지만, 작가가 어선에서 일 하면서 겪었던 일, 편의점 알바 하면서 겪었던 일 등 내가 미처 경험해보지 못한 세상을 간접적으로나마 느껴볼 수 있어서 좋았다.</p>\n" +
        "\n" +
        "<p>[^1]: CPT라는 프로그램을 통해 정식으로 허가를 받고 전공과 관련된 일을 제한된 기간동안 학교 밖에서 할 수 있는 기회가 있긴 했다. 보통 이것을 통해 인턴십 같은 것을 한다.</p>\n" +
        "\n" +
        "<p>[^2]: 그렇기 때문에 학부생때 가난하게 살았던 것이 아닌가 하는 생각도 든다. 레스토랑에서 서버로 일 하면서 팁만 잘 챙겨 받아도 학부생 연구 조교의 시급 따위는 가볍게 뛰어 넘을 수 있을테니까.<br />\n" +
        "&nbsp;</p>\n"
    },
    {
        title: "나는 왜 이목표를 설정했는가",
        _id: "9",
        thumbnail:'/images/program/eod/09.jpg',
        description: "저는 한국에서 또 하나의 1등 글로벌 기업을 만드는 데 일조하고 싶습니다. 하지만 아직은 결과형 목표의 한계를 가지고 있다고 생각합니다. 금메달 역시 목표가 될 수는 있지만 보다 많은 사람에게 감동과 기쁨을 준다는",
        tag : [],

        authorImage:'/images/image.png',
        author: "김왕수",
        date : moment('2016-08-20').format('YYYY-MM-DD'),
        content: "<h3>목표1. 특정분야에 깊은 지식 갖기 &rarr; 매주 한권 책읽기 중국, 기업, 리더십분야</h3>\n" +
        "\n" +
        "<h3>목표2. 세상의 흐름을 알고 알맞은 의사결정 내리기&rarr; 매일 종이신문 1개 읽기</h3>\n" +
        "\n" +
        "<h3>+ 목표 선정이유</h3>\n" +
        "\n" +
        "<p>0. 저는 한국에서 또 하나의 1등 글로벌 기업을 만드는 데 일조하고 싶습니다. 하지만 아직은 결과형 목표의 한계를 가지고 있다고 생각합니다. 금메달 역시 목표가 될 수는 있지만 보다 많은 사람에게 감동과 기쁨을 준다는 이타적/동사형 목표를 가지면 내 삶의 의미가 깊어지는 동시에 매일에 더 충실할 수 있다고 생각합니다. 그래서 제 인생의 장기 목표를 수정하려고 생각은 하고 있지만 아직 마음에 쏙 드는 문장을 찾지는 못했습니다.&nbsp;</p>\n" +
        "\n" +
        "<p>1. 기존에 있는 글로벌 기업의 리더로 들어가는 것보다는, 지금 없거나, 있더라도 규모가 작은 기업을 키워나가고 싶은 생각입니다. 글로벌 기업으로 발돋움 하려면 개인이 아닌 팀이 되어 위대한 업적을 이룰 수 있어야 한다고 생각합니다. 그러기 위해서는 팀의 힘을 한 곳에 모을 수 있는 리더십이 필요하지만 아직은 리더로서의 역량이 많이 부족하다고 생각합니다. 그래서 이번 4개월간 리더로서의 역량을 키우는 데에 집중할 생각입니다.</p>\n" +
        "\n" +
        "<p>2. 4개월 후 제가 만들어 내고 싶은 근육은 특정분야의 전문지식과 판단력 그리고 나만의 리더십원칙입니다. &nbsp;그래서 매주 한권의 책(중국/기업/리더십 관련)을 읽고, 매일 신문을 읽고 4개월후 나만의 리더십 원칙을 갖추고자 합니다.</p>\n" +
        "\n" +
        "<h3>+ 더 나은 목표 달성을 위해</h3>\n" +
        "\n" +
        "<p>3. BD 10기의 첫 2주를 뒤돌아보면 책읽기와 신문읽기의 점수가 많이 달랐습니다.&nbsp;<br />\n" +
        "신문은 매일 근무시간전(오전 9-10시) 회사에서 1시간씩 읽고 있어 매주 12점을 받았는데, 책읽기의 경우는 그렇지 못했습니다. 출근 지하철에서는 책을 펴고는 있었지만 낮은 집중력, 피곤함 등으로 몇페이지 읽지 못했고, 퇴근길에는 업무후의 스트레스를 푸느라 대개 책을 펴지도 못했습니다. 책 읽기 역시 신문과 마찬가지로 특정시간을 고정해서 &#39;어떠한 일이 있어도 1시간은 읽는 습관&#39; 을 들여야 할 것 같습니다. 이사를 와서 이동시간이 많이 줄어든 만큼 책은 매일 오전 7시45분~8시45분까지 읽겠습니다. 장소는 집앞 스타벅스, 회사내 소규모 회의실입니다. 2주후에는 더 나은 점수를 받도록 꼭 저와의 약속을 지키겠습니다.</p>\n" +
        "\n" +
        "<p>&nbsp;</p>\n"
    },
    {
        title: "나에게 특별한 점이 있다면 무엇일까?",
        _id: "10",
        thumbnail:'/images/program/eod/10.jpg',
        description: "'나는 나를 잘 알고 있을까?' 언젠가, \"조하리의 창\"을 처음 알게 되었을 때, 신선한 충격이었다. 2x2 matrix로 되어있는 그 그림을 보았을때… 조하리의 창이라는 개념이 무엇 때문에 생겨난 것인지를 기억하고 있지는 못하다",
        tag : [],

        authorImage:'/images/image.png',
        author: "윤영훈",
        date : moment('2016-08-22').format('YYYY-MM-DD'),
        content: "<h3>&lsquo;나는 나를 잘 알고 있을까?&rsquo;</h3>\n" +
        "\n" +
        "<p>언젠가, &quot;조하리의 창&quot;을 처음 알게 되었을 때, 신선한 충격이었다. 2x2 matrix로 되어있는 그 그림을 보았을때&hellip; 조하리의 창이라는 개념이 무엇 때문에 생겨난 것인지를 기억하고 있지는 못하다. 조하리의 창 2 x 2 matrix를 보면서, &quot;날 가장 잘 아는 것은 나지&quot;라고 너무나 당연하게 생각하고 있었던 것이 다를 수도 있다는 것, 내가 알지 못하는 나의 모습은 어떤 것들이 있을지, 다른 사람들이 인지하고 있는 나의 모습은 어떤 것일지 궁금했다.&nbsp;</p>\n" +
        "\n" +
        "<p>이번 주제 &quot;나에게 특별한 점이 있다면 그건 무엇일까?&quot;는 일생질문이라는 책에서 보고 에세이 계획에 추가했다. 나를 더 믿기 위해서 나 스스로를 더 이해해야 한다고 생각했기 때문이다.&nbsp;</p>\n" +
        "\n" +
        "<p>&quot;특별한 점&quot;은 너무 범위가 모호하고 넓으니, 범위를 제한 시켜 보자. 시기는 최근 1년, 사람들과의 관계에서 생긴 일들 중, 내 주위에 있었던 다른 많은 사람들과 내가 다른 의견을 가지고 있었던 사건을 기반으로 생각해 보자.</p>\n" +
        "\n" +
        "<h3>&ldquo;주인의식&rdquo;</h3>\n" +
        "\n" +
        "<p>지난 직장에서 나와 함께 일하는 동료들과의 가장 큰 의견차이를 발생 시켰던 부분은, &nbsp; &nbsp;<br />\n" +
        "내가 가진 &quot;내가 내린 이 의사결정에 내 돈을 태울 수 있을 것인가?&quot; 라는 생각이다.&nbsp;</p>\n" +
        "\n" +
        "<p>나는 회식하면서 회사 뒷담화를 하는 것을 싫어한다. 회사 또는 경영자는 회식을 하면서 동료들끼리 친분을 쌓고 일을 더 잘하자! 라는 목적을 가지고 회식비를 지원한다. 회사가 어려울 때 흥청망청 출장을 가는 것도 싫어한다. 너무나 이기적인 행동이다.</p>\n" +
        "\n" +
        "<p>혹자는 이런 생각을 가지고 있는 것이 &quot;주인 의식&quot;이라고 했다. 그것이 나에게 도움이 되는 생각인지, 아닌지는 모르겠다고도 이야기 했다. 내가 속해 있는 모든 조직에 대해서 주인의식을 갖는 것은 당연히 아니다. 나는 주인 의식을 가지기 어려운 조직에 오랫동안 머물지 못한다.&nbsp;</p>\n" +
        "\n" +
        "<p>이런 생각들을 하는 게, 최근 1년 내가 보인 나의 특별한 점인 것 같다.&nbsp;<br />\n" +
        "다음 번엔, 나의 특별한 점을 활용할 수 있는 방법들에 대해서 고민해 보자.</p>\n"
    },


    {
        title: "지금 현재 자신에게 중요한 다섯 단어는?",
        _id: "11",
        thumbnail:'/images/program/eod/11.jpg',
        description: "한국. 성장. 세계. Team. 세계관. 입니다. 저는 '한국이 더 강한 나라가 되는 것'에 기여하는데 제 인생을 사용하고 싶습니다. 직업적 영역인 투자자로서, 돈을 벌면서도, 번 돈을 활용해서도 한국의 발전에 기여하고 싶습니다.",
        tag : [],

        authorImage:'/images/image.png',
        author: "최혁준",
        date : moment('2016-03-02').format('YYYY-MM-DD'),
        content: "<h3>한국. 성장. 세계. Team. 세계관. 입니다.</h3>\n" +
        "\n" +
        "<h3>1. 한국</h3>\n" +
        "\n" +
        "<p>저는 &#39;한국이 더 강한 나라가 되는 것&#39;에 기여하는데 제 인생을 사용하고 싶습니다. 직업적 영역인 투자자로서, 돈을 벌면서도, 번 돈을 활용해서도 한국의 발전에 기여하고 싶습니다.</p>\n" +
        "\n" +
        "<h3>2. 성장</h3>\n" +
        "\n" +
        "<p>요즘 중요시 생각하는 단어는 &#39;성장&#39;입니다. 점점 더 현재의 제 능력으로는 할 수 없는 일들을 추구하다보니, 성장을 해야만 하는 상황에 처하고 있습니다.어떻게 성장할 수 있을까가 고민입니다. 이번 BD 목표에서는 &#39;투자자로서의 성장&#39; 부분을 반영할 생각입니다.</p>\n" +
        "\n" +
        "<h3>3. 세계</h3>\n" +
        "\n" +
        "<p>1년 정도의 해외체류 경험도 있고, 세계 각국을 많이 다닌 편인데도 불구하고, 세계에 대한 이해가 아직 많이 부족해서, 이해도를 많이 높이고 싶습니다. 이번 BD 목표에서도 이 부분을 반영할 생각입니다.</p>\n" +
        "\n" +
        "<h3>4. Team</h3>\n" +
        "\n" +
        "<p>누구와 무엇을 어떻게 라는 단어의 순서처럼, 저한테는 누구와가 크게 중요한 것 같습니다. 인생을 제가 함께 하고 싶은 사람들과 Team을 꾸려서 같이 살아갈 수 있다면 행복할 것 같습니다. Team 을 만드는데 시간을 쓰고 싶습니다.</p>\n" +
        "\n" +
        "<h3>5. 세계관</h3>\n" +
        "\n" +
        "<p>객관적인 정보라는 말에 대한 회의가 드는 요즘입니다. 왜냐하면 아무리 객관적인 정보라고 해도, 받아들이는 사람이 주관적으로 해석하기 때문입니다. 어떤 것을 받아들이는게 가장 영향을 많이 미치는 건, 내용보다도 자신의 정보 필터라는 생각이 드는 요즘입니다. 어떤 color의 색안경을 끼고 있는가 에 따라 결정됩니다.<br />\n" +
        "나는 어떤 안경을 끼고 있는지, 나는 내 안경이 마음에 드는지, 어떠한 color의 안경을 고를지를 생각합니다. 안경을 잘 고르느냐에 따라 인생이 달라질 것 같아요.<br />\n" +
        "&nbsp;</p>\n"
    },









];

export {essayExample}