import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Container} from 'semantic-ui-react'
import {Bar} from 'react-chartjs-2';


const propTypes = {

};
const defaultProps = {

};

class HomeEodTime extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: {
                labels: [
                    "김민석", "김왕수", "윤영훈", "오광훈", "김윤혜",
                    "김민석", "김왕수", "윤영훈", "오광훈", "김윤혜",
                ],
                datasets: [{
                    label: 'EOD Hours',
                    data: [
                        12, 19, 3, 5, 2,
                        3, 4, 4, 5, 1
                    ],
                    backgroundColor: [
                    ],
                    borderColor: [
                        'black',
                        'black',
                        'black',
                        'black',
                        'black',
                        'black',
                        'black',
                        'black',
                        'black',
                        'black',
                    ],
                    borderWidth: 1
                }],
            }
        }
    }
    
    render() {
        return (
            <Segment
                vertical
                basic
                style={{marginTop: '100px'}}
            >
                <Container
                    style={{
                        padding: '50px',
                    }}
                >
                <Bar
                    width='3'
                    height='1'
                    data={this.state.data}/>
                </Container>
            </Segment>
        );
    }
};

HomeEodTime.propTypes = propTypes;
HomeEodTime.defaultProps = defaultProps;

export default HomeEodTime;
