import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Segment, Container, Grid, Header, List} from 'semantic-ui-react';

const propTypes = {

};
const defaultProps = {

};

class AboutUsVisionStatement extends Component{
    constructor(props){
        super(props);
    }
    
    render() {
        return (
            <div>
                <Segment vertical basic>
                    <Container text>
                        <Header as='h2' content="Mission"/>
                        <p>
                            Project 1은 최고를 꿈꾸는(되고자 하는) 사람들이 지속해서 성장하는 것을 돕고 최고가 되게 하는 것을 목적으로 합니다.
                        </p>
                    </Container>
                </Segment>

                <Segment vertical basic>
                    <Container text>
                        <Header as='h2' content="Belief"/>
                        <List bulleted>
                            <List.Item>Project 1은 의도된 학습을 지속하여 나의 comfort zone을 넓혀 나갑니다.</List.Item>
                            <List.Item>서로 다른 분야에서 최고가 되기 위해 노력하는 동료들을 만납니다.</List.Item>
                            <List.Item>다른 분야의 생각을 통해 나 또한 완전히 다른 방식의 접근을 할 수 있습니다.</List.Item>
                        </List>
                    </Container>
                </Segment>

                <Segment vertical basic>

                    <Container text>
                        <Header as='h2' content="Principle"/>
                    </Container>

                    <Grid container columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header content="1"/>
                                <p>
                                    가슴 뛰는 높은 목표를 갖고<br/>
                                    최고가 될 수 있다고 생각합니다.
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <Header content="2"/>
                                <p>
                                    최고가 되는 모습을 자주 상상하고<br/>
                                    최고처럼 시간을 사용합니다.
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <Header content="3"/>
                                <p>
                                    자신이 설정한 목표 달성을 위해<br/>
                                    누구보다 많은 시간과 에너지를 쏟습니다.
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <Header content="4"/>
                                <p>
                                    매일의 노력을 쌓아서<br/>
                                    장기적으로 큰 성과를 만듭니다.
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <Header content="5"/>
                                <p>
                                    모임에서는 각자의 의견을 존중하며,<br/>
                                    서로간에 긍정적인 영향을 줍니다.
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Segment>

            </div>
        );
    }
};

AboutUsVisionStatement.propTypes = propTypes;
AboutUsVisionStatement.defaultProps = defaultProps;

export default AboutUsVisionStatement;
