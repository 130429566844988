import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {essayActionCreators} from "../../redux/essay/essayStore";
import {Segment, Loader, Image, Item, Container, Table, Progress, Card} from 'semantic-ui-react'
import moment from 'moment';
import _ from 'lodash';
import EssayComponent from "../../components/essay/EssayComponent";

class MemberEssaysMainContainer extends Component {

  constructor(props) {
    super(props);

  }


  componentDidMount() {
    this._loadMyAllEssays();
    window.scrollTo(0,0)
  }

  _loadMyAllEssays = async () => {
    this.props.fetchAllMyEssays();
  };

  render() {
    const {essays} = this.props.essayReducer;

    return (
      <div>
        {essays.length}
        <Item.Group
          unstackable
          style={{
            maxWidth: '600px',
            margin: 'auto'
          }}>
          {
            essays.map((essay, i) => {
              return (
                <EssayComponent essay={essay} key={i}/>
              )
            })
          }
        </Item.Group>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
    ...essayActionCreators,
  }
);


export default enhance(MemberEssaysMainContainer);