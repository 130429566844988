import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAVoeDI3Sr6KJuKt4LtcbtuJoC-GLVWdbI",
  authDomain: "beingndoing-web.firebaseapp.com",
  databaseURL: "https://beingndoing-web.firebaseio.com",
  projectId: "beingndoing-web",
  storageBucket: "beingndoing-web.appspot.com",
  messagingSenderId: "824740976577"
};

firebase.initializeApp(firebaseConfig);

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
// firebase.auth().languageCode = 'kr';

const db = firebase.firestore();
const auth = firebase.auth();
export {
  firebaseConfig,
  firebase,
  db,
  auth,
}