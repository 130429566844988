import React from 'react';

export const deleteTag = string => {
    if (!string)
        return '';
    else
        return string
            .replace(/&nbsp;/g, '')
            .replace(/&lsquo;/g, '')
            .replace(/&quot;/g, '')
            .replace(/&rsquo;/g, '')
            .replace(/&hellip; /g, '')
            .replace(/&#39;/g, '')
            .replace(/&gt;/g, '')
            .replace(/&lt;/g, '')
            .replace(/&rarr;/g, '')
            .replace(/&ldquo;/g, '')
            .replace(/&rdquo;/g, '')
            .replace(/&#x0D;/g, '')
            .replace(/<[^>]+>/g, '');
};

export const deleteTabAndSpace = string => {
    if (!string)
        return '';
    else
        return string
            .replace(/\t/g, '')
            .replace(/\s/g, '')
};

export const withLineEnter = string => {
    if (!string) {
        return '';
    } else {
        return string
            .split('\n')
            .map((line, i) => {
                return <span key={i}>{line}<br/></span>
            });
    }
};
