const sphere = [
    {
        title: 'BD Talk',
        date: '18/05/12 Sat',
        status: 'Coming Soon',
        statusLabelColor: 'red',
        category: 'talk',
        tag: '',
        description: 'Crew들의 생생한 경험과 이를 통해 얻은 통찰력을 엿볼 수 있는 자리 매 시즌 중간에 1회 개최합니다',
        image: '/images/sphere/08.jpg'
    },
    {
        title: 'Dream Builder',
        date: '18/03/10 ~ 18/06/24',
        status: '종료',
        statusLabelColor: 'black',
        tag: '',
        description: '내가 지향하는 삶의 방향을 설정하고 궁극의 인생목표를 함께 찾아가는 모임입니다',
        image: '/images/sphere/07.jpg'
    },
    {
        title: '제프 베조스 그가 읽은 책들',
        date: '18/02/12 ~ 18/07/15',
        status: '진행중',
        statusLabelColor: 'blue',
        tag: 'Readers',
        description: '제프베조스가 읽은 책이라고 소개된 12권 중 11권을 읽습니다.',
        image: '/images/sphere/06.png'
    },
    {
        title: '아름답고 정확한 글쓰기',
        date: '18/02/12 ~ 18/03/11',
        status: '종료',
        statusLabelColor: 'black',
        tag: 'Readers',
        description: '품격있는 글, 아름답고 정확한 문장을 쓰기 위한 단기 집중 Reading & Writing',
        image: '/images/sphere/05.png'
    },
    {
        title: '사마천의 사기 읽기',
        date: '17/11/06~18/02/06',
        status: '종료',
        statusLabelColor: 'black',
        tag: 'Readers',
        description: '사마천의 사기 시리즈를 읽습니다',
        image: '/images/sphere/04.png'
    },
    {
        title: '철학의 집짓기',
        date: '17/10/01 ~ 17/12/09',
        status: '종료',
        statusLabelColor: 'black',
        tag: 'Readers',
        description: '철학책을 읽는 과정을 통해 철학의 의미에 대해 생각해 보고 생각하는 힘을 길러봅시다.',
        image: '/images/sphere/03.png'
    },
    {
        title: '료마가 간다 읽기',
        date: '17/08/05 ~ 17/09/30',
        status: '종료',
        statusLabelColor: 'black',
        tag: 'Readers',
        description: '손정의 회장의 인생책을 읽어봅시다',
        image: '/images/sphere/02.png'
    },
    {
        title: 'Statistics',
        date: '17/01/16~17/02/27',
        status: '종료',
        statusLabelColor: 'black',
        tag: 'Readers',
        description: '복잡한 세상을 객관적 시각에서 바라볼 수 있는 통찰력을 기르기 위한 통계책 읽기 모임입니다.',
        image: '/images/sphere/01.jpg'
    },

];



const talkList = [
    {
        season: "2018 Spring",
        description: "",
        movies: [
            {
                id: "18S01",
                title: "퇴사생의 뚜벅뚜벅 와인 여행",
                subtitle: "",
                speakerName: "윤누리",
                image: "/images/sphere/talk/2018S01.jpg",
                link: "https://www.youtube.com/embed/ShAWWMj_3HU?rel=0&amp;showinfo=0"
            },
            {
                id: "18S02",
                title: "사이드프로젝트 101",
                subtitle: "",
                speakerName: "윤성준",
                image: "/images/sphere/talk/2018S02.jpg",
                link: "https://www.youtube.com/embed/ciZDrrNzlVI?rel=0&amp;showinfo=0"
            },
            {
                id: "18S03",
                title: "여행이 가져다 준 변화",
                subtitle: "",
                speakerName: "이교영",
                image: "/images/sphere/talk/2018S03.jpg",
                link: "https://www.youtube.com/embed/EbjlKEbY4og?rel=0&amp;showinfo=0"
            },
            {
                id: "18S04",
                title: "책 읽다가 코딩하는 이유: 책장에 대하여",
                subtitle: "",
                speakerName: "윤영훈",
                image: "/images/sphere/talk/2018S04.jpg",
                link: "https://www.youtube.com/embed/puB3ETPpx50?rel=0&amp;showinfo=0"
            }
        ]
    },

    {
        season: "2017 Fall",
        description: "",
        movies: [
            {
                id: "17F01",
                title: "인생에 빛나는 순간들",
                subtitle: "",
                speakerName: "조영화",
                image: "/images/sphere/talk/2017F01.jpg",
                link: "https://www.youtube.com/embed/WgTbf9Vjic8?rel=0&amp;showinfo=0"
            },
            {
                id: "17F02",
                title: "스케일 키우기",
                subtitle: "",
                speakerName: "이태석",
                image: "/images/sphere/talk/2017F02.jpg",
                link: "https://www.youtube.com/embed/Xdwg5xUG0RU?rel=0&amp;showinfo=0"
            },
            {
                id: "17F03",
                title: "취미생활러로 살아가는 방법",
                subtitle: "",
                speakerName: "이미혜",
                image: "/images/sphere/talk/2017F03.jpg",
                link: "https://www.youtube.com/embed/_JItVRfMb14?rel=0&amp;showinfo=0"
            }
        ]
    },
    {
        season: "2017 Spring",
        description: "",
        movies: [
            {
                id: "17S01",
                title: "당신의 꿈은 무엇입니까",
                subtitle: "",
                speakerName: "이유아",
                image: "/images/sphere/talk/2017S01.jpg",
                link: "https://www.youtube.com/embed/Lg3otvOxLl8?rel=0&amp;showinfo=0"
            },
            {
                id: "17S02",
                title: "원정코딩",
                subtitle: "여행을 좋아하는 개발자의 고상한 취미",
                speakerName: "변수민",
                image: "/images/sphere/talk/2017S03.jpg",
                link: "https://www.youtube.com/embed/NnRXiCVHtqQ?rel=0&amp;showinfo=0"
            },
            {
                id: "17S04",
                title: "Spend your time like money(Part.2)",
                subtitle: "8,650시간의 기록. 24시간 365일 하루도 빠짐없이",
                speakerName: "윤영훈",
                image: "/images/sphere/talk/2017S04.jpg",
                link: "https://www.youtube.com/embed/1hRoN-Fz-FM?rel=0&amp;showinfo=0"
            }
        ]
    },
    {
        season: "2016 Fall",
        description: "",
        movies: [
            {
                id: "16S01",
                title: "내 삶을 행복하게 만드는 나만의 방법",
                subtitle: "나는 나의 장밋빛 미래를 확신합니다",
                speakerName: "김왕수",
                image: "/images/sphere/talk/2016F01.jpg",
                link: "https://www.youtube.com/embed/G002G573sDY?rel=0&amp;showinfo=0"
            },
            {
                id: "16S02",
                title: "책이 사람을 만드나",
                subtitle: "나를 키운건 8할이 책이다",
                speakerName: "허경석",
                image: "/images/sphere/talk/2016F02.jpg",
                link: "https://www.youtube.com/embed/uRt5HinxpW4?rel=0&amp;showinfo=0"
            },
            {
                id: "16S03",
                title: "인생은 어떻게 결정되는가",
                subtitle: "",
                speakerName: "최혁준",
                image: "/images/sphere/talk/2016F03.jpg",
                link: "https://www.youtube.com/embed/iS9_bqYguNk?rel=0&amp;showinfo=0"
            }
        ]
    }
];


export {sphere, talkList}