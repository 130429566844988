import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Segment, Form, Table, Image, Container, Button, Card, Header, Divider} from 'semantic-ui-react'
import moment from "moment/moment";
import {Link} from 'react-router-dom';
import AdminTeamComponent from "../../components/admin-season/AdminTeamComponent";
import _ from 'lodash'
import {imageUploadToFirebase} from "../../middleware/firebaseImageHandler";

class AdminSeasonModifyContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      season: {
        applicationFormUrl: '',
        chairSchedule: {
          chairAnnouncement: '',
          chairClosing: '',
          chairOrientation: '',
        },
        earlyBird1st: {
          applicationFormUrl: '',
          discountRate: 0.15,
          endDate: '',
          startDate: '',
        },
        earlyBird2nd: {
          applicationFormUrl: '',
          discountRate: 0.10,
          endDate: '',
          startDate: '',
        },
        isRecruiting: false,
        isOngoing: false,
        memberAnnouncementDate: '',
        membershipFee: 190000,
        number: 0,
        registerEndDate: '',
        registerStartDate: '',
        schedule: {
          closing: '',
          orientation: '',
          talk: '',
          teamMeetingStart: '',

        },
        seasonEndDate: '',
        seasonStartDate: '',
        id: '',
      },


      isLoaded: false,

      applicationFormUrl: '',
      chairSchedule: {
        chairAnnouncement: '',
        chairClosing: '',
        chairOrientation: '',
      },
      earlyBird1st: {
        applicationFormUrl: '',
        discountRate: 0.15,
        endDate: '',
        startDate: '',
      },
      earlyBird2nd: {
        applicationFormUrl: '',
        discountRate: 0.10,
        endDate: '',
        startDate: '',
      },
      isRecruiting: false,
      isOngoing: false,

      memberAnnouncementDate: '',
      membershipFee: 190000,
      number: 0,
      registerEndDate: '',
      registerStartDate: '',
      schedule: {
        closing: '',
        orientation: '',
        talk: '',
        teamMeetingStart: '',

      },
      seasonEndDate: '',
      seasonStartDate: '',
      title: '',
      id: '',


      image: "",
      imageFirebase: {},


      teams: [],

    }
  }

  componentDidMount() {
    this._loadSeasonData();
    this._loadTeamData();
  }

  handleChange = (e, d) => {
    this.setState({
      [d.name]: d.value ? d.value : d.checked
    })
  };
  _loadSeasonData = () => {
    const {seasonId} = this.props.match.params;
    db.collection('seasons')
      .doc(seasonId)
      .get()
      .then(doc => {
        const season = doc.data();
        season.id = doc.id;

        const {
          applicationFormUrl,
          chairSchedule,
          earlyBird1st,
          earlyBird2nd,
          isRecruiting,
          isOngoing,
          memberAnnouncementDate,
          membershipFee,
          number,
          registerEndDate,
          registerStartDate,
          schedule,
          seasonEndDate,
          seasonStartDate,
          title,

          image,
          imageFirebase
        } = season;
        this.setState({
          season,

          applicationFormUrl,
          chairSchedule: {
            chairAnnouncement: (chairSchedule && chairSchedule.chairAnnouncement) ? moment(chairSchedule.chairAnnouncement.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            chairClosing: (chairSchedule && chairSchedule.chairClosing) ? moment(chairSchedule.chairClosing.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            chairOrientation: (chairSchedule && chairSchedule.chairOrientation) ? moment(chairSchedule.chairOrientation.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          },
          earlyBird1st: {
            applicationFormUrl: (earlyBird1st && earlyBird1st.applicationFormUrl) ? earlyBird1st.applicationFormUrl : '',
            discountRate: (earlyBird1st && earlyBird1st.discountRate) ? earlyBird1st.discountRate : '0',
            endDate: (earlyBird1st && earlyBird1st.endDate) ? moment(earlyBird1st.endDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            startDate: (earlyBird1st && earlyBird1st.startDate) ? moment(earlyBird1st.startDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          },
          earlyBird2nd: {
            applicationFormUrl: (earlyBird2nd && earlyBird2nd.applicationFormUrl) ? earlyBird2nd.applicationFormUrl : '',
            discountRate: (earlyBird2nd && earlyBird2nd.discountRate) ? earlyBird2nd.discountRate : '0',
            endDate: (earlyBird2nd && earlyBird2nd.endDate) ? moment(earlyBird2nd.endDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            startDate: (earlyBird2nd && earlyBird2nd.startDate) ? moment(earlyBird2nd.startDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          },
          isRecruiting,
          isOngoing: isOngoing ? isOngoing : false,
          memberAnnouncementDate: (memberAnnouncementDate) ? moment(memberAnnouncementDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          membershipFee,
          number,

          registerEndDate: (registerEndDate) ? moment(registerEndDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          registerStartDate: (registerStartDate) ? moment(registerStartDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          schedule: {
            closing: (schedule && schedule.closing) ? moment(schedule.closing.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            orientation: (schedule && schedule.orientation) ? moment(schedule.orientation.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            talk: (schedule && schedule.talk) ? moment(schedule.talk.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
            teamMeetingStart: (schedule && schedule.teamMeetingStart) ? moment(schedule.teamMeetingStart.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          },
          seasonEndDate: (seasonEndDate) ? moment(seasonEndDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          seasonStartDate: (seasonStartDate) ? moment(seasonStartDate.seconds * 1000).format('YYYY-MM-DDTHH:mm') : new Date(),
          title,

          image: image ? image : '',
          imageFirebase: imageFirebase ? imageFirebase : {},

          isLoaded: true,
        })
      })
      .catch(e => {
        console.log(e);
      })
  }
  _modifySeason = () => {
    const {seasonId} = this.props.match.params;
    const {
      applicationFormUrl,
      chairSchedule,
      earlyBird1st,
      earlyBird2nd,
      isRecruiting,
      isOngoing,
      memberAnnouncementDate,
      membershipFee,
      number,
      registerEndDate,
      registerStartDate,
      schedule,
      seasonEndDate,
      seasonStartDate,
      title,
      image,
      imageFirebase,
    } = this.state;

    const data = {
      applicationFormUrl: applicationFormUrl ? applicationFormUrl : "",
      chairSchedule: {
        chairAnnouncement: new Date(chairSchedule.chairAnnouncement),
        chairClosing: new Date(chairSchedule.chairClosing),
        chairOrientation: new Date(chairSchedule.chairOrientation),
      },
      earlyBird1st: {
        applicationFormUrl: earlyBird1st.applicationFormUrl ? earlyBird1st.applicationFormUrl : "",
        discountRate: earlyBird1st.discountRate ? parseFloat(earlyBird1st.discountRate.toString()) : 0,
        endDate: new Date(earlyBird1st.endDate),
        startDate: new Date(earlyBird1st.startDate),
      },
      earlyBird2nd: {
        applicationFormUrl: earlyBird2nd.applicationFormUrl ? earlyBird2nd.applicationFormUrl : "",
        discountRate: earlyBird2nd.discountRate ? parseFloat(earlyBird2nd.discountRate.toString()) : 0,
        endDate: new Date(earlyBird2nd.endDate),
        startDate: new Date(earlyBird2nd.startDate),
      },
      isRecruiting: isRecruiting ? isRecruiting : false,
      isOngoing: isOngoing ? isOngoing : false,
      memberAnnouncementDate: new Date(memberAnnouncementDate),
      membershipFee: parseInt(membershipFee.toString(), 10),
      number: parseInt(number.toString(), 10),
      registerEndDate: new Date(registerEndDate),
      registerStartDate: new Date(registerStartDate),
      schedule: {
        closing: new Date(schedule.closing),
        orientation: new Date(schedule.orientation),
        talk: new Date(schedule.talk),
        teamMeetingStart: new Date(schedule.teamMeetingStart),

      },
      seasonEndDate: new Date(seasonEndDate),
      seasonStartDate: new Date(seasonStartDate),
      title: title ? title : '',
      image: image ? image : '',
      imageFirebase: imageFirebase ? image : {},
    }
    db.collection('seasons')
      .doc(seasonId)
      .set(data, {merge: true})
      .then(() => {
        window.alert('수정되었습니다.')
        this._loadSeasonData()
      })
      .catch(e => {
        console.log(e)
        window.alert('수정에 실패하였습니다.')
      })
  }
  _loadTeamData = () => {
    const {seasonId} = this.props.match.params;
    db.collection('teams')
      .where('seasonId', '==', seasonId)
      .orderBy('chair.name')
      .get()
      .then(async snapshot => {
        const teams = [];

        snapshot.forEach(doc => {
          let team = doc.data();
          team.id = doc.id;

          if (team.chairId) {
            let chair = db.collection('members').doc(team.chairId).get().then(doc => {
              if (!doc.exists) {
                return {}
              }
              let chair = doc.data();
              chair.id = doc.id;
              return chair;
            })

            team.chair = chair;
          }
          teams.push(team);
        });


        this.setState({
          teams
        })
      })
      .catch(e => {
        console.log(e);
      })
  }
  _addTeam = () => {
    const {seasonId} = this.props.match.params;
    db.collection('teams')
      .add({
        seasonId,
        members: [],
        chair: {
          name: ''
        },
        title: '',
        createdAt: new Date(),
        modifiedAt: new Date(),
      })
      .then(() => {
        this._loadTeamData();
      })
      .catch(e => {
        console.log(e);
      })
  }
  _removeTeam = (teamId) => {
    const a = window.confirm('삭제하시겠습니까? 되돌릴 수 없습니다.');
    if (!a) {
      return;
    }
    db.collection('teams')
      .doc(teamId)
      .set({
        isDeleted: true,
        seasonId: 0
      }, {
        merge: true
      })
      .then(() => {
        this._loadTeamData();
      })
      .catch(e => {
        console.log(e);
      })
  }
  _onImageUpload = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let bucketName = 'seasonImage'

    reader.onloadend = async () => {
      const {
        mainImage: image,
        mainImageFirebase: imageFirebase
      } = await imageUploadToFirebase(file, bucketName);


      this.setState({
        image,
        imageFirebase
      })
    };
    try {
      reader.readAsDataURL(file);
    } catch (e) {

    }
  }


  render() {
    return (
      <Fragment>

        <Segment vertical basic>
          <Container>
            <Button onClick={this._modifySeason}>
              저장하기
            </Button>
          </Container>
        </Segment>
        <Segment vertical basic>
          <Container>
            <Form>
              <Card.Group itemsPerRow={3} stackable>
                <Card>
                  <Card.Content>
                    <Header content={'시즌 스케줄'} dividing/>
                    <Form.Input label={'seasonStartDate'}
                                name={'seasonStartDate'}
                                type={'datetime-local'}
                                value={this.state.seasonStartDate}
                                onChange={this.handleChange}/>

                    <Form.Input label={'seasonEndDate'}
                                name={'seasonEndDate'}
                                type={'datetime-local'}
                                value={this.state.seasonEndDate}
                                onChange={this.handleChange}/>

                    <Form.Input label={'orientation'}
                                name={'orientation'}
                                type={'datetime-local'}
                                value={this.state.schedule.orientation}
                                onChange={(e, d) => {
                                  let schedule = this.state.schedule;
                                  schedule.orientation = d.value;
                                  this.setState({
                                    schedule
                                  })
                                }}/>

                    <Form.Input label={'teamMeetingStart'}
                                name={'teamMeetingStart'}
                                type={'datetime-local'}
                                value={this.state.schedule.teamMeetingStart}
                                onChange={(e, d) => {
                                  let schedule = this.state.schedule;
                                  schedule.teamMeetingStart = d.value;
                                  this.setState({
                                    schedule
                                  })
                                }}/>

                    <Form.Input label={'talk'}
                                name={'talk'}
                                type={'datetime-local'}
                                value={this.state.schedule.talk}
                                onChange={(e, d) => {
                                  let schedule = this.state.schedule;
                                  schedule.talk = d.value;
                                  this.setState({
                                    schedule
                                  })
                                }}/>

                    <Form.Input label={'closing'}
                                name={'closing'}
                                type={'datetime-local'}
                                value={this.state.schedule.closing}
                                onChange={(e, d) => {
                                  let schedule = this.state.schedule;
                                  schedule.closing = d.value;
                                  this.setState({
                                    schedule
                                  })
                                }}/>
                  </Card.Content>
                </Card>
                <Card>
                  <Card.Content>
                    <Header content={'기본정보'} dividing/>
                    <Form.Input label={'membershipFee'}
                                name={'membershipFee'}
                                value={this.state.membershipFee}
                                onChange={this.handleChange}/>
                    <Form.Input label={'number'}
                                name={'number'}
                                value={this.state.number}
                                onChange={this.handleChange}/>
                    <Form.Input label={'title'}
                                name={'title'}
                                value={this.state.title}
                                onChange={this.handleChange}/>
                    <Form.Radio label={'isRecruiting'}
                                name={'isRecruiting'}
                                checked={this.state.isRecruiting}
                                toggle
                                onChange={this.handleChange}/>
                    <Form.Radio label={'isOngoing'}
                                name={'isOngoing'}
                                checked={this.state.isOngoing}
                                toggle
                                onChange={this.handleChange}/>

                  </Card.Content>
                </Card>

                <Card>
                  {
                    this.state.image && (
                      <Image src={this.state.image} onClick={() => {
                        const a = window.confirm('삭제하시겠습니까?')
                        if (!a) {
                          return;
                        }
                        this.setState({
                          image: '',
                          imageFirebase: {}
                        })
                      }}/>
                    )
                  }

                  <Card.Content>
                    <Form.Field>
                      <label>시즌 사진 추가</label>
                      <input type="file" onChange={e => {
                        this._onImageUpload(e)
                      }}
                             accept=".jpg, .jpeg, .png, .heic"/>
                    </Form.Field>
                  </Card.Content>
                </Card>
              </Card.Group>
              <Card.Group itemsPerRow={3} stackable>
                <Card>
                  <Card.Content>
                    <Header content={'모집 스케줄'} dividing style={{}}/>
                    <Form.Input label={'title'}
                                name={'title'}
                                value={this.state.title}
                                onChange={this.handleChange}/>

                    <Form.Input label={'applicationFormUrl'}
                                name={'applicationFormUrl'}
                                value={this.state.applicationFormUrl}
                                onChange={this.handleChange}/>

                    <Form.Input label={'registerStartDate'}
                                name={'registerStartDate'}
                                type={'datetime-local'}
                                value={this.state.registerStartDate}
                                onChange={this.handleChange}/>

                    <Form.Input label={'registerEndDate'}
                                name={'registerEndDate'}
                                type={'datetime-local'}
                                value={this.state.registerEndDate}
                                onChange={this.handleChange}/>

                    <Form.Input label={'memberAnnouncementDate'}
                                name={'memberAnnouncementDate'}
                                type={'datetime-local'}
                                value={this.state.memberAnnouncementDate}
                                onChange={this.handleChange}/>

                  </Card.Content>
                </Card>
                <Card>
                  <Card.Content>
                    <Header content={'얼리버드 1차 스케줄'} dividing style={{}}/>
                    <Form.Input label={'applicationFormUrl'}
                                name={'applicationFormUrl'}
                                value={this.state.earlyBird1st.applicationFormUrl}
                                onChange={(e, d) => {
                                  let earlyBird1st = this.state.earlyBird1st;
                                  earlyBird1st.applicationFormUrl = d.value;
                                  this.setState({
                                    earlyBird1st
                                  })
                                }}/>
                    <Form.Input label={'discountRate'}
                                name={'discountRate'}
                                value={this.state.earlyBird1st.discountRate}
                                onChange={(e, d) => {
                                  let earlyBird1st = this.state.earlyBird1st;
                                  earlyBird1st.discountRate = d.value;
                                  this.setState({
                                    earlyBird1st
                                  })
                                }}/>
                    <Form.Input label={'startDate'}
                                name={'startDate'}
                                type={'datetime-local'}
                                value={this.state.earlyBird1st.startDate}
                                onChange={(e, d) => {
                                  let earlyBird1st = this.state.earlyBird1st;
                                  earlyBird1st.startDate = d.value;
                                  this.setState({
                                    earlyBird1st
                                  })
                                }}/>
                    <Form.Input label={'endDate'}
                                name={'endDate'}
                                type={'datetime-local'}
                                value={this.state.earlyBird1st.endDate}
                                onChange={(e, d) => {
                                  let earlyBird1st = this.state.earlyBird1st;
                                  earlyBird1st.endDate = d.value;
                                  this.setState({
                                    earlyBird1st
                                  })
                                }}/>
                  </Card.Content>
                </Card>
                <Card>
                  <Card.Content>
                    <Header content={'얼리버드 2차 스케줄'} dividing/>
                    <Form.Input label={'applicationFormUrl'}
                                name={'applicationFormUrl'}
                                value={this.state.earlyBird2nd.applicationFormUrl}
                                onChange={(e, d) => {
                                  let earlyBird2nd = this.state.earlyBird2nd;
                                  earlyBird2nd.applicationFormUrl = d.value;
                                  this.setState({
                                    earlyBird2nd
                                  })
                                }}/>
                    <Form.Input label={'discountRate'}
                                name={'discountRate'}
                                value={this.state.earlyBird2nd.discountRate}
                                onChange={(e, d) => {
                                  let earlyBird2nd = this.state.earlyBird2nd;
                                  earlyBird2nd.discountRate = d.value;
                                  this.setState({
                                    earlyBird2nd
                                  })
                                }}/>
                    <Form.Input label={'startDate'}
                                name={'startDate'}
                                type={'datetime-local'}
                                value={this.state.earlyBird2nd.startDate}
                                onChange={(e, d) => {
                                  let earlyBird2nd = this.state.earlyBird2nd;
                                  earlyBird2nd.startDate = d.value;
                                  this.setState({
                                    earlyBird2nd
                                  })
                                }}/>
                    <Form.Input label={'endDate'}
                                name={'endDate'}
                                type={'datetime-local'}
                                value={this.state.earlyBird2nd.endDate}
                                onChange={(e, d) => {
                                  let earlyBird2nd = this.state.earlyBird2nd;
                                  earlyBird2nd.endDate = d.value;
                                  this.setState({
                                    earlyBird2nd
                                  })
                                }}/>
                  </Card.Content>
                </Card>
              </Card.Group>
              <Card.Group itemsPerRow={3} stackable>
                <Card>
                  <Card.Content>
                    <Header content={'체어 일정'} dividing style={{}}/>


                    <Form.Input label={'chairAnnouncement'}
                                name={'chairAnnouncement'}
                                type={'datetime-local'}
                                value={this.state.chairSchedule.chairAnnouncement}
                                onChange={(e, d) => {
                                  let chairSchedule = this.state.chairSchedule;
                                  chairSchedule.chairAnnouncement = d.value;
                                  this.setState({
                                    chairSchedule
                                  })
                                }}/>

                    <Form.Input label={'chairOrientation'}
                                name={'chairOrientation'}
                                type={'datetime-local'}
                                value={this.state.chairSchedule.chairOrientation}
                                onChange={(e, d) => {
                                  let chairSchedule = this.state.chairSchedule;
                                  chairSchedule.chairOrientation = d.value;
                                  this.setState({
                                    chairSchedule
                                  })
                                }}/>

                    <Form.Input label={'chairClosing'}
                                name={'chairClosing'}
                                type={'datetime-local'}
                                value={this.state.chairSchedule.chairClosing}
                                onChange={(e, d) => {
                                  let chairSchedule = this.state.chairSchedule;
                                  chairSchedule.chairClosing = d.value;
                                  this.setState({
                                    chairSchedule
                                  })
                                }}/>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Form>
          </Container>
        </Segment>
        <Segment vertical basic>
          <Container>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>팀 이름</Table.HeaderCell>
                  <Table.HeaderCell>체어 이름</Table.HeaderCell>
                  <Table.HeaderCell>기타</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.state.teams.map((team, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {team.title}
                        </Table.Cell>
                        <Table.Cell>
                          {team.chair && team.chair.name}
                        </Table.Cell>
                        <Table.Cell>
                          <Button as={Link} to={`/admin/team/modify/${team.id}`}>
                            수정하기
                          </Button>
                          <Button onClick={() => {
                            this._removeTeam(team.id)
                          }}>
                            삭제하기
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.Cell columns={3}>
                    <Button onClick={this._addTeam}>
                      추가하기
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Container>
        </Segment>
      </Fragment>
    );
  }
}


export default AdminSeasonModifyContainer;
