import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../../components/index';
import {Segment, Container, Card, Image, Divider, List, Grid, Header, Item, Label, Icon} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {goalExample} from "../../data/bd_goals";
import {essayExample} from "../../data/bd_essay";
import * as BDBrand from "../../constants/BDBrand";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');

const propTypes = {};
const defaultProps = {};

const styles = {
    Segment: {
        margin: "5em 0em"
    },
    saying: {
        color: BDBrand.BDCOLOR,
        fontSize: BDBrand.SAYING_SIZE,
        fontStyle: 'italic',
        lineHeight: '1.5em',
    },
    mainContents: {
        lineHeight: '2em',
    }
}

class Essay extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/program/essay');
    }

    render() {


        return (
            <div>
                Gaol
                <AboutUsBackground imageUrl={'url(/images/background/bd_essay.jpg)'} fontColor='black'>
                    [ESSAY] 내가 이루고 싶은 삶의 모습을 그려봅니다
                </AboutUsBackground>
                <Segment vertical basic style={styles.Segment} textAlign={'center'}>

                    <Container>
                        <span style={styles.saying}>
                            인생은 속도가 아니라 방향이다

                        </span>
                        <br/>
                        <span>
                            - 임마누엘 페스트라이쉬 -
                        </span>
                    </Container>

                </Segment>

                {/* Why */}
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='에세이를 왜 써야 하나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <Grid.Column width={11}>
                                        <span style={styles.mainContents}>
                                            인생의 방향을 찾는 일은 생각만큼 수월하지 않습니다. <br/>
                                            세상이 정해준 기준에 흔들리고 방황합니다. <br/>
                                            자신을 이해하고 나에게 소중한 가치를 답하는 과정이 필요합니다. <br/>
                                            인생의 주인이 되기 위한 첫 걸음은 나에 대한 이해에서 시작합니다. <br/>
                                        </span>
                                    </Grid.Column>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                {/* How */}

                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='어떤 에세이를 쓰나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <span style={styles.mainContents}>
                                        에세이는 자신의 욕망을 찾고, 진정한 자아를 탐색하는 과정입니다. <br/>
                                        BD는 ‘Being에 기반한 Doing’을 지향합니다. <br/>
                                        주변에서 강요하는 Doing이 아니라, <br/>
                                        자신이 원하는 Doing을 하기 위해서, <br/>
                                        에세이를 통해서 나다운 삶의 모습(Being)을 찾습니다. <br/>
                                    </span>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                {/* How to */}
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='BD에서는 어떻게 하나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <span style={styles.mainContents}>
                                        나를 더 깊이 이해하기 위해서 핵심 가치를 묻는 질문들에 답해 봅니다. <br/>
                                        Being&Doing에서는 자기와의 대화를 도와줄 인생질문 DB를 제공합니다. <br/>
                                        <br/>
                                        [에세이 주제 예시]
                                        <List ordered>
                                            <List.Item> 나에게 중요한 단어 다섯개? </List.Item>
                                            <List.Item> 목표를 달성하기 위해서는 무엇이 중요한가? </List.Item>
                                            <List.Item> 나를 가슴 뛰게 하는 순간은? </List.Item>
                                            <List.Item> 나를 믿는 것은 어떤 의미인가? </List.Item>
                                            <List.Item> 나는 왜 이목표를 설정했는가? </List.Item>
                                        </List>
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='에세이 예시'/>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    {essayExample.map((essay, i) => {
                                        return (
                                            <div>
                                                <Segment basic key={i}>
                                                    <Item.Group>
                                                        <Item as={Link} to={"/program/essay/" + i}>
                                                            <Item.Image size='small' src={essay.thumbnail}/>
                                                            <Item.Content>
                                                                <Item.Header className='under'>

                                                                    {essay.title}
                                                                </Item.Header>
                                                                <Item.Meta>
                                                                    {
                                                                        essay.tag.map((tag, i) => {
                                                                            return (
                                                                                <Label key={i}>
                                                                                    #{tag}
                                                                                </Label>
                                                                            )
                                                                        })
                                                                    }
                                                                </Item.Meta>
                                                                <Item.Description>{essay.description}</Item.Description>
                                                                <Item.Extra>
                                                                    {/*<Image src={essay.authorImage} size='mini'
                                                                           circular/>*/}
                                                                    <span>{essay.author}</span>
                                                                    <span>{essay.date}</span>
                                                                </Item.Extra>
                                                            </Item.Content>
                                                        </Item>

                                                    </Item.Group>
                                                </Segment>
                                                <Divider/>
                                            </div>
                                        )
                                    })}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>
                <Segment vertical basic style={styles.Segment}>
                    <Container textAlign={'center'} text>
                        <Link to={'/program/goal'} style={{marginLeft: 20}}>
                            Goal에 대해서 알아볼까요
                            <Icon name={'right chevron'} size={'big'}/>
                        </Link>

                    </Container>
                </Segment>
                <Segment basic vertical>

                </Segment>
            </div>
        );
    }
};

Essay.propTypes = propTypes;
Essay.defaultProps = defaultProps;

export default Essay;
