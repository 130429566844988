import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Card, Image, Button, Segment, Container, Table, Header,} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

class AdminEssayListMainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            essays: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadBlogList();
    }

    loadBlogList = async () => {
        const essaysRef = db.collection('essays').orderBy('createdAt', 'desc');
        essaysRef
            .get()
            .then(snapshot => {
                const essays = [];
                snapshot.forEach(doc => {
                    const essay = doc.data();
                    essay.id = doc.id;
                    essays.push(essay);
                })
                this.setState({
                    essays
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Container as={Segment} vertical>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>No.</Table.HeaderCell>
                                <Table.HeaderCell>제목</Table.HeaderCell>
                                <Table.HeaderCell>No.</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.essays.map((essay,i)=>{
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{i+1}</Table.Cell>
                                            <Table.Cell>{essay.title}</Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Container>
            </Fragment>
        );
    }
}


export default AdminEssayListMainContainer;
