import React from 'react'
import {List} from 'semantic-ui-react'

const general = [
    {
        title: {
            content: 'BD를 하면 어떤게 좋을까요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>(자기 이해) 자신에 대해서 더 많이 성찰하고 생각해볼 수 있습니다</List.Item>
                        <List.Item>(도전과 용기) 자신이 원하는 것을 시작할 수 있습니다.</List.Item>
                        <List.Item>(목표 달성) 자신이 계획한 목표를 달성할 수 있습니다.</List.Item>
                        <List.Item>(습관 형성) 자신에게 필요한 습관을 형성할 수 있습니다.</List.Item>
                        <List.Item>(동료와 자극) 열심히 사는 사람들과 서로 자극을 주고 받을 수 있습니다.</List.Item>
                        <List.Item>(성장) 어제보다 나은, 성장을 추구할 수 있습니다.</List.Item>
                        <List.Item>(BD Support) Being&Doing은 이를 위한 BD만의 Support System을 갖추고 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: 'BD Crew는 어떤 사람인가요?, 직장인/대학생도 있나요? 연령대는 어떠한가요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>Being&Doing은 나이 제한을 두지 않고 있습니다. 2012년 BD를 처음 시작할 때는 사회 초년생들의 모임으로 시작하였고, Crew들이 성장하고,
                            BD가 확산됨에 따라 현재는 대학생과 직장인의 구분 없이 확장해 나가고 있습니다.</List.Item>
                        <List.Item>Crew들의 연령대 분포는 95년생 ~ 80년생까지 다양하게 구성되어 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '어떤 일을 하는 Crew들의 있나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>Being&Doing은 전공과 직업에 관계 없이 자신의 꿈을 향해서 달려나가는 사람들의 모임입니다.</List.Item>
                        <List.Item>기존 Crew들을 기준으로, 대기업/스타트업/금융권 재직자 및 대학(원)생 뿐만 아니라 창업가, 디자이너, 교사, 컨설턴트, 프로그래머, 마케터,
                            변호사, 의사 등 다양하게 분포되어 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '회비는 얼마인가요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>Being&Doing은 비영리로 운영되지만, 운영경비를 충당하기 위해, 시즌별로 Membership Fee를 받습니다.</List.Item>
                        <List.Item>Basic Membership은 19만원, Talk와 Closing 참가비가 포함된 Club Membership은 22만원 입니다. </List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '모집 일정은 언제인가요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>대학 학기제와 동일하게, 1년에 총 두번, Spring과 Fall 두 번의 시즌으로 진행합니다.</List.Item>
                        <List.Item>Spring 시즌: 3월~6월 <br/>(모집기간) 2월 중</List.Item>
                        <List.Item>Fall 시즌: 9월~12월 <br/>(모집기간) 8월 중</List.Item>
                    </List>

                </div>
            ),
        }
    },

]

const goal = [
    {
        title: {
            content: '어떤 목표를 설정해야 하나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>Being&Doing은 '내가 되고 싶은 내가 되어가는 과정을 함께하는 모임'이기 때문에, 내가 되고 싶은 나!를 위한 목표라면 어떤 목표라도 설정할 수 있습니다. 환영합니다.</List.Item>
                        <List.Item>내 인생에서 가장 중요한 질문에 대한 답을 찾기 위해서 목표를 세우는 것을 권장합니다.</List.Item>
                        <List.Item>동시에, 내 하루하루를 개선시킬 수 있는 꾸준한 운동, 영어 공부, 독서 등과 같은 습관 목표들도 환영합니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '목표 설정 및 달성을 위한 커리큘럼이 따로 정해져 있나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>Being & Doing은 목표 설정부터, 실행, Review 및 개선을 위한 Support System이 있습니다.
                            <List.List bulleted>
                                <List.Item>(목표 설정) 목표 설정을 위한 BD Paper를 작성합니다. 제대로 된 목표 설정이 목표 달성을 좌우하기 때문에, 많은 시간을 들여 목표를 설정합니다.</List.Item>
                                <List.Item>(과정) BD App, EOD등 목표 달성과정을 관리할 수 있는 다양한 Tool이 제공됩니다.</List.Item>
                                <List.Item>(목표 점검) 중간리뷰, 최종리뷰 등을 통해, 장기적인 목표 달성을 위한 방법을 제공합니다.</List.Item>
                            </List.List>
                        </List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '목표는 몇개 설정해야 하나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>목표는 적을 수록, 소수의 목표에 집중할 수록 좋습니다.</List.Item>
                        <List.Item>목표의 개수를 제한하지는 않지만, 3개 이내의 목표를 추천합니다. App에는 총 5개의 목표를 기입할 수 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '저는 뚜렷한 목표가 없는데, BD에 참가해도 될까요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>달성하고 싶은 목표가 있는 것이 좋습니다. 하지만, 무엇인가 이루고 싶은 의지가 강하게 있다면, BD에 참여하시는 것을 추천 드립니다.</List.Item>
                        <List.Item>주위에 목표 달성을 위해 노력하는 사람들이 있으면, 나도 그렇게 될 가능성이 높아집니다. 사람은 주변의 사람의 영향을 강하게 받으니까요.</List.Item>
                    </List>
                </div>
            ),
        }
    },
]

const essay = [
    {
        title: {
            content: '어떤 주제로 에세이를 쓰나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>에세이 주제에 제한은 없습니다.</List.Item>
                        <List.Item>장기적으로 내가 되고 싶은 내가 되기 위해 나에게 중요한 질문을 스스로 질문을 던지는 것을 추천 드립니다.</List.Item>
                        <List.Item>지금까지 나왔던 다른 Crew들의 에세이 주제를 드립니다. 참고하여 에세이 주제를 선정할 수 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '얼마나 자주 써야 하나요? 한 시즌에 몇 편의 에세이를 작성해야 하나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>매 팀모임에 한 개의 에세이를 작성합니다.</List.Item>
                        <List.Item>한 시즌에 총 8개의 에세이를 작성합니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '분량 제한이 있나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>분량에 제한은 없습니다.</List.Item>
                        <List.Item>분량보다는 정말 중요한 '질문'인지 고민하고, 스스로 납득할 수 있는 에세이를 작성합니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '에세이는 어디에 쓰나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>BD App에 작성합니다</List.Item>
                        <List.Item>작성된 에세이를 BD App에 반드시 추가해 주세요. 에세이 작성 여부를 App에 업로드 여부로 판단합니다.</List.Item>
                        <List.Item>형태가 있는 아웃풋을 만드는 것이 가장 중요합니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
]

const teamMeeting = [
    {
        title: {
            content: '언제 어디서 모이나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>주로 주말 토/일 중 하루를 정하여 오전에 진행됩니다. 장소는 팀 별 사정에 따라 조율이 가능하며 지난 기수들 같은 경우에는 강남역, 홍대입구역, 서울대입구역, 광화문 등지에서 모임을 진행하였습니다.</List.Item>
                        <List.Item>해외/지방 거주자나 Offline 모임의 참가가 어려우신 분들을 위해서 online 모임을 도입하여 진행하고 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '얼마나 자주 모이나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>팀모임은 2주에 한 번, 매 모임마다 약 2시간에 걸쳐 진행됩니다.</List.Item>
                        <List.Item>OT와 Closing을 제외하고 16주 동안 총 8번의 모임이 진행됩니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '모여서 무엇을 하나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>(Being) 지난 2주 간 각자 선정하고 준비한 에세이를 공유합니다. 에세이의 목적은 자기 자신에게 중요한 질문들에 대한 답에 대해 스스로 생각해보는 기회를 갖고자 함이며, 팀원들의 다양한 견해와 의견을 받을 수 있습니다.</List.Item>
                        <List.Item>(Doing) 지난 2주간 본인이 설정한 목표에 얼마나 충실했는가를 점수화하고 서로 공유하는 시간을 가집니다. 매주 목표를 팀원들과 공유함으로서, 목표달성률을 더 높일 수 있습니다.</List.Item>
                        <List.Item>(미래) 다음 2주가 수행할 목표와 생각할 에세이 주제에 대해서 이야기 합니다. 단기적 미래에 대한 철저한 계획을 반복하연 반드시 목표에 다을 수 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '몇 명이 한팀이 되나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>기본적으로 4명을 한 팀으로 구성합니다.</List.Item>
                        <List.Item>경우에 따라 5명이 한 팀으로 구성되는 경우도 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '팀원은 어떻게 결정되나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>지원 기간이 종료되면 우선 체어를 선발합니다.</List.Item>
                        <List.Item>선발된 체어가 본인 팀의 멤버를 선발합니다.</List.Item>
                        <List.Item>인원의 제한이 있어, 모든 분들을 선발하지 못하는 점 양해 부탁드립니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '온라인으로 팀모임이 가능한가요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>해외 출장/해외 거주자/유학생 등을 고려하여 4명 이상의 온라인 멤버가 확보될 경우 온라인 비디모임을 운영합니다.</List.Item>
                        <List.Item>90%이상은 오프라인 모임 위주 입니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '사정이 생겨서 팀모임에 결석하게 되었습니다. 어떻게 되나요?',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item>팀모임을 3번 이상 결석하는 경우, BD에서 탈퇴 처리됩니다.</List.Item>
                        <List.Item>무단 결석 및 지각이 잦아, 팀모임에 큰 영향을 주는 경우, 위의 기준과 관계없이 BD에서 탈퇴 처리 될 수 있습니다.</List.Item>
                    </List>
                </div>
            ),
        }
    },

]

const sphere = [
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },
    {
        title: {
            content: '',
        },
        content: {
            content: (
                <div>
                    <List>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                        <List.Item></List.Item>
                    </List>
                </div>
            ),
        }
    },

]

export {
    general,
    essay,
    goal,
    teamMeeting,
    sphere
}