import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {db} from "../../config/firebase";
import {Link} from 'react-router-dom'
import {Container, Segment, Loader, Grid, Divider, Header, Image, List, Card, Button} from "semantic-ui-react";
import {BDCOLOR, BDCOLOR_GREY, BDCOLOR_SUB} from "../../constants/BDBrand";
import moment from 'moment';
import NoRecruitingSeasonComponent from '../../components/apply/NoRecruitingSeasonComponent';
import 'moment/locale/ko'
import 'moment-precise-range-plugin'
import Analytics from "../../middleware/Analytics";

moment.locale('ko')


class ApplyMainContainer extends Component {

  timer;

  constructor(props) {
    super(props);
    this.state = {
      seasonsNowRecruiting: [],
      isLoaded: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    Analytics.pageViewAll('/apply')

    this.loadSeasons();
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }



  loadSeasons = async () => {
    const seasonsRef = db.collection('seasons').where('isRecruiting', '==', true);
    seasonsRef
      .get()
      .then(snapshot => {
        const seasonsNowRecruiting = [];

        snapshot.forEach(doc => {

          const season = doc.data();
          season.id = doc.id
          seasonsNowRecruiting.push(season)
          console.log(season);

        });

        this.setState({
          seasonsNowRecruiting: seasonsNowRecruiting,
          isLoaded: true
        })
      })
      .catch(e => {
        console.log(e)
      });
  }



  render() {

    return (
      <Fragment>
        <div style={{backgroundColor: BDCOLOR_SUB, paddingTop: 30, paddingBottom: 30, marginBottom: 50}}>
          <Container as={Segment}
                     textAlign={'center'}
                     vertical
                     basic style={{paddingTop: 30}}
                     className={'animated fadeIn slow'}>
            <p style={{color: 'white'}}>
              BD
            </p>
            <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                 className={'hanSerifJapanese'}>
              지원하기
            </div>
            <Header inverted>
              <Header.Subheader>
                내가 바라는 나의 미래를 실현하기 위해 노력하는 사람들을 찾고 있습니다
              </Header.Subheader>
            </Header>
          </Container>
          <Container as={Segment}
                     textAlign={'center'}
                     vertical
                     basic style={{paddingTop: 30}}
                     className={'animated fadeIn delay-1s slow'}>
            <div style={{
              backgroundImage: `url(/images/background/closingColor.jpg)`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '100%',
              minHeight: 300,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }}>
            </div>
            <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                2018 가을 클로징 - 소감을 발표하던 중
                            </span>
            </div>
          </Container>
        </div>

        {
          ((this.state.isLoaded) && (this.state.seasonsNowRecruiting.length < 1)) && (
            <NoRecruitingSeasonComponent/>
          )
        }
        {
          this.state.seasonsNowRecruiting.map((season, i) => {

            return (
              <Fragment key={i}>

                <Container as={Segment} basic text style={{marginBottom: 50}}>
                  <Header textAlign={'center'} size={'huge'}>
                    <Header.Subheader style={{}}>
                      {season.number}번째 BD
                    </Header.Subheader>
                    <span style={{color: BDCOLOR_SUB}}>{season.title} BD 멤버를 모집합니다</span>
                  </Header>
                </Container>


                <Container as={Segment} text
                           style={{marginBottom: 50, paddingTop: 20, paddingBottom: 20}}>
                  <Header content={'모집 일정'}
                          style={{marginBottom: 20}}
                          as={'h1'}
                          dividing/>


                  <List as='ol'>
                    <List.Item as='li' value='*' style={{marginBottom: 20}}>
                                            <span
                                              style={{fontWeight: '900'}}>지원 기간</span> : {moment(season.registerStartDate.seconds * 1000).format('MM/DD (ddd)')} - {moment(season.registerEndDate.seconds * 1000).format('MM/DD (ddd)')}
                      <List.Item as='ol'>
                        {
                          moment().isSameOrBefore(moment(season.earlyBird1st.endDate.seconds * 1000), 'day') && (
                            <List.Item as='li' value='-' style={{marginBottom: 10}}>
                              <List.Content>
                                <List.Header>
                                  <span style={{color: BDCOLOR, fontWeight: 'bold'}}> 1차 얼리버드</span>:
                                  회비 15% 할인
                                </List.Header>
                                <List.Description>
                                  {moment(season.earlyBird1st.startDate.seconds * 1000).format('MM/DD (ddd)').toUpperCase()} - {moment(season.earlyBird1st.endDate.seconds * 1000).format('MM/DD (ddd)').toUpperCase()}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          )
                        }
                        {
                          moment().isBetween(moment(season.earlyBird2nd.startDate.seconds * 1000), moment(season.earlyBird2nd.endDate.seconds * 1000), 'days') && (
                            <List.Item as='li' value='-' style={{marginBottom: 10}}>
                              <List.Content>
                                <List.Header>
                                  <span style={{color: BDCOLOR, fontWeight: 'bold'}}> 얼리버드</span>:
                                  회비 10% 할인
                                </List.Header>
                                <List.Description>
                                  {moment(season.earlyBird2nd.startDate.seconds * 1000).format('MM/DD (ddd)').toUpperCase()} - {moment(season.earlyBird2nd.endDate.seconds * 1000).format('MM/DD (ddd)').toUpperCase()}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          )
                        }
                      </List.Item>
                    </List.Item>
                    <List.Item as='li' value='*' style={{marginBottom: 20,}}>
                                        <span style={{fontWeight: '900'}}>
                                            체어 선정
                                        </span>
                      (팀리더)
                      <List.Item as='ol'>
                        <List.Item as='li' value='-' style={{marginBottom: 10}}>
                          <List.Content>
                            <List.Header>
                              체어 발표
                            </List.Header>
                            <List.Description>
                              {moment(season.chairSchedule.chairAnnouncement.seconds * 1000).format('MM. DD (ddd)').toUpperCase()}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item as='li' value='-' style={{marginBottom: 10}}>
                          <List.Content>
                            <List.Header>
                              체어 OT
                            </List.Header>
                            <List.Description>
                              {moment(season.chairSchedule.chairOrientation.seconds * 1000).format('MM. DD (ddd) HH:mm').toUpperCase()}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item as='li' value='-' style={{marginBottom: 10}}>
                          <List.Content>
                            <List.Header>
                              체어 혜택
                            </List.Header>
                            <List.Description>
                              체어로 활동시, 시즌이 종료될 때 회비의 약 30%를 환급해 드립니다.
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      </List.Item>
                    </List.Item>
                    <List.Item as='li' value='*' style={{marginBottom: 20}}>
                      <span style={{fontWeight: '900'}}>팀/멤버 선정</span>
                      <List.Item as='ol'>
                        <List.Item as='li' value='-' style={{marginBottom: 10}}>
                          <List.Content>
                            <List.Header>
                              발표
                              : {moment(season.memberAnnouncementDate.seconds * 1000).format('MM. DD (ddd)').toUpperCase()}
                            </List.Header>
                            <List.Description>
                              (팀의 체어가 전화 또는 문자로 연락을 드립니다)
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      </List.Item>
                    </List.Item>
                  </List>
                </Container>

                <Container as={Segment} text
                           style={{marginBottom: 50, paddingTop: 20, paddingBottom: 20}}>

                  <Header content={`시즌 일정`}
                          style={{marginBottom: 20}}
                          as={'h1'}
                          dividing/>
                  <Image
                    src={'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/homepage%2F%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%8C%E1%85%A5%E1%86%BC.png?alt=media&token=4c59f7b5-55a9-41e1-80db-0c89ed33e45f'}/>

                  <List as='ol'>
                    <List.Item as='li' value='*' style={{marginBottom: 20}}>
                      <span style={{fontWeight: '900'}}>OT</span>
                      : <span style={{color: 'red'}}>이번 시즌에는 전체 OT 대신, 첫번째 팀모임에서 팀별로 OT가 진행됩니다.</span>
                      {/*: {moment(season.schedule.orientation.seconds * 1000).format('MM. DD (ddd) HH:mm').toUpperCase()}*/}
                    </List.Item>
                    <List.Item as='li' value='*' style={{marginBottom: 20}}>
                      <span style={{fontWeight: '900'}}>BD Talk</span>
                      : {moment(season.schedule.talk.seconds * 1000).format('MM. DD (ddd) HH:mm').toUpperCase()}
                    </List.Item>
                    <List.Item as='li' value='*' style={{marginBottom: 20}}>
                      <span style={{fontWeight: '900'}}>CLOSING</span>
                      : {moment(season.schedule.closing.seconds * 1000).format('MM. DD (ddd) HH:mm').toUpperCase()}
                    </List.Item>
                    <List.Item as='li' value='*' style={{marginBottom: 20}}>
                      <span style={{fontWeight: '900'}}>팀 모임</span>
                      <List.Item as='ol'>
                        <List.Item as='li' value='-'>
                          토요일 팀
                          : {moment(season.schedule.teamMeetingStart.seconds * 1000).format('MM. DD (ddd)').toUpperCase()} 시작
                        </List.Item>
                        <List.Item as='li' value='-'>
                          일요일 팀
                          : {moment(season.schedule.teamMeetingStart.seconds * 1000).add(1, 'day').format('MM. DD (ddd)').toUpperCase()} 시작
                        </List.Item>
                        <List.Item as='li' value='-'>
                          2주마다 팀별로 모임
                          <List.Description>
                            (* 팀에 따라서 시작과 종료는 1주 정도 달라질 수 있습니다.)
                          </List.Description>
                        </List.Item>
                      </List.Item>
                    </List.Item>
                  </List>
                </Container>

                {this.mayBeRenderApplyComponent(season)}

              </Fragment>
            )
          })
        }

      </Fragment>
    );
  }

  mayBeRenderApplyComponent = (season) => {
    const {
      earlyBird1st,
      earlyBird2nd,
      registerStartDate,
      registerEndDate,
    } = season;



    if (moment().isSameOrBefore(moment(earlyBird1st.endDate.seconds * 1000), 'day')) {
      return (
        <Container text style={{marginBottom: 50}}>
          <Card fluid raised>
            <Card.Content textAlign={'center'}>
              <Header style={{color: BDCOLOR}}>
                <Header.Subheader>
                  1차 얼리버드
                </Header.Subheader>
                {moment(season.earlyBird1st.endDate.seconds * 1000).locale('ko').format('MM/DD dddd')}까지
              </Header>
            </Card.Content>
            <Card.Content>
              <List bulleted>
                <List.Item>
                  지원서 작성과 회비 입금이
                  마감일
                  ({moment(season.earlyBird1st.endDate.seconds * 1000).format('MM/DD')})
                  까지 완료되어
                  합니다.
                </List.Item>
                <List.Item>
                  지원 기간 내에는 100% 환불 가능 합니다.
                </List.Item>
                <List.Item>
                  궁금하신 사항이 있으신가요? <a href={'http://pf.kakao.com/_ptxdSxd/chat'} target={'_blank'}>
                  카카오톡
                </a>으로 문의하기
                </List.Item>
              </List>
            </Card.Content>
            <Card.Content textAlign={'center'}>
              {(earlyBird1st.discountRate * 100).toFixed(0)}% 할인 <br/>

              <span style={{textDecoration: 'line-through'}}>
                            {(season.membershipFee / 10000).toFixed(0)} 만원
                            </span> > <span style={{fontSize: '1.5rem', fontWeight: '900', color: BDCOLOR}}>
                            {(season.membershipFee * (1 - earlyBird1st.discountRate) / 10000).toFixed(0)} 만원
                            </span>
              <br/>
              <br/>

              <Button fluid color={'blue'} as={Link} target={'_blank'}
                      onClick={() => {
                        console.log('Clicked')
                        Analytics.trackGA('none');
                        Analytics.trackPixel('ViewContent', {})
                      }}
                      to={{pathname: earlyBird1st.applicationFormUrl}}>
                얼리버드로 지원하기
              </Button>
            </Card.Content>
          </Card>
        </Container>
      )
    } else if (moment().isSameOrBefore(moment(earlyBird2nd.endDate.seconds * 1000), 'day')) {
      return (
        <Container text style={{marginBottom: 50}}>
          <Card fluid raised>
            <Card.Content textAlign={'center'}>
              <Header style={{color: BDCOLOR}}>
                <Header.Subheader>
                  얼리버드
                </Header.Subheader>
                {moment(season.earlyBird2nd.endDate.seconds * 1000).format('MM/DD')}까지
              </Header>
            </Card.Content>
            <Card.Content>
              <List bulleted>
                <List.Item>
                  지원서 작성과 회비 입금이
                  마감일
                  ({moment(season.earlyBird2nd.endDate.seconds * 1000).format('MM/DD')})
                  까지 완료되어
                  합니다.
                </List.Item>
                <List.Item>
                  지원 기간 내에는 100% 환불 가능 합니다.
                </List.Item>
                <List.Item>
                  궁금하신 사항이 있으신가요? <a href={'http://pf.kakao.com/_ptxdSxd/chat'} target={'_blank'}>
                  카카오톡
                </a>으로 문의하기
                </List.Item>
              </List>
            </Card.Content>
            <Card.Content textAlign={'center'}>
              {(earlyBird2nd.discountRate * 100).toFixed(0)}% 할인 <br/>

              <span style={{textDecoration: 'line-through'}}>
                            {(season.membershipFee / 10000).toFixed(0)} 만원
                            </span> > <span style={{fontSize: '1.5rem', fontWeight: '900', color: BDCOLOR}}>
                            {(season.membershipFee * (1 - earlyBird2nd.discountRate) / 10000).toFixed(0)} 만원
                            </span>
              <br/>
              <br/>

              <Button fluid color={'blue'} as={Link} target={'_blank'}
                      onClick={() => {
                        console.log('Clicked')
                        Analytics.trackGA('none');
                        Analytics.trackPixel('ViewContent', {})
                      }}
                      to={{pathname: earlyBird2nd.applicationFormUrl}}>
                얼리버드로 지원하기
              </Button>
            </Card.Content>
          </Card>
        </Container>
      )
    } else {
      return (
        <Container text style={{marginBottom: 50}}>
          <Card fluid raised>
            <Card.Content textAlign={'center'}>
              <Header style={{color: BDCOLOR}}>
                <Header.Subheader>
                  {season.title} 시즌 지원하기
                </Header.Subheader>
                {moment(registerEndDate.seconds * 1000).format('MM/DD')}까지
              </Header>
            </Card.Content>
            <Card.Content>
              <List bulleted>
                <List.Item>
                  지원서 작성과 회비 입금이
                  마감일
                  ({moment(registerEndDate.seconds * 1000).format('MM/DD')})
                  까지 완료되어
                  합니다.
                </List.Item>
                <List.Item>
                  지원 기간 내에는 100% 환불 가능 합니다.
                </List.Item>
                <List.Item>
                  궁금하신 사항이 있으신가요? <a href={'http://pf.kakao.com/_ptxdSxd/chat'} target={'_blank'}>
                  카카오톡
                </a>으로 문의하기
                </List.Item>
              </List>
            </Card.Content>
            <Card.Content textAlign={'center'}>

                            <span style={{fontSize: '1.5rem', fontWeight: '900', color: BDCOLOR}}>
                            {(season.membershipFee / 10000).toFixed(0)} 만원
                            </span>
              <br/>
              <br/>

              <Button fluid color={'blue'} as={Link} target={'_blank'}
                      onClick={() => {
                        console.log('Clicked')
                        Analytics.trackGA('none');
                        Analytics.trackPixel('ViewContent', {})
                      }}
                      to={{pathname: season.applicationFormUrl}}>
                지원하기
              </Button>
            </Card.Content>
          </Card>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {};
}
const mapDispatchToProps = (dispatch) => {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyMainContainer);
