import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {db} from "../../config/firebase";
import {Container, Segment, Card, Button, Table, Header} from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

class AdminSeasonManagementContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seasons: [],
        }
    }

    componentDidMount() {
        this.loadSeasons();
    }


    loadSeasons = async () => {
        const seasonsRef = db.collection('seasons').orderBy('number');

        seasonsRef
            .get()
            .then(snapshot => {
                const seasons = [];
                const seasonsNowRecruiting = [];

                snapshot.forEach(doc => {
                    // console.log(doc.id, '=>', doc.data())

                    const season = doc.data();
                    season.id = doc.id

                    seasons.push(season);

                });

                this.setState({
                    seasons: seasons,
                })
            })
            .catch(e => {
                console.log(e)
            });
    }

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Segment vertical as={Container}>
                    <Button>
                        새 시즌 만들기
                    </Button>
                </Segment>
                <Segment vertical as={Container}>
                    <Table size={'small'} celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>번호</Table.HeaderCell>
                                <Table.HeaderCell>타이틀</Table.HeaderCell>
                                <Table.HeaderCell>isRecruiting</Table.HeaderCell>
                                <Table.HeaderCell>isOnGoing</Table.HeaderCell>
                                <Table.HeaderCell>버튼</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.seasons.map((season, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{season.number}</Table.Cell>
                                            <Table.Cell>{season.title}</Table.Cell>
                                            <Table.Cell>{season.isRecruiting && '모집중'}</Table.Cell>
                                            <Table.Cell>{season.isOngoing && '진행중'}</Table.Cell>
                                            <Table.Cell>
                                                <Button as={Link} to={`/admin/season/modify/${season.id}`} compact
                                                        size={'small'}>
                                                    수정하기
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>

                </Segment>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminSeasonManagementContainer);
