import {createLogic} from 'redux-logic';
import {authActionCreators, authActions} from "./authStore";
import {auth, db, firebase} from "../../config/firebase";
import Network from "../../middleware/Network";

const signInLogic = createLogic({
  type: authActions.SIGN_IN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
          return firebase
            .auth()
            .signInWithEmailAndPassword(action.email, action.password)
            .then(user => {
              console.log(user);
              if (action.cb) action.cb();

              dispatch(authActionCreators.getUserInfo());
              dispatch(authActionCreators.getMemberInfo());
            })
            .catch(e => {
              console.log(e);
              if (action.cb) action.cb(e)
            })
        }
      )
      .catch(error => {
        console.log(error);
        if (action.cb) action.cb(error)
      })

  }
});

const getUserInfoLogic = createLogic({
  type: authActions.GET_USER_INFO,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const user = await Network.getUserInfo(currentUser.uid);
      if (!user.err) {
        console.log({user});
        dispatch(authActionCreators.getUserInfoSuccess(user));
      }
    } else {

    }

    console.log(getState());

    done();
  }
});

const getMemberInfoLogic = createLogic({
  type: authActions.GET_MEMBER_INFO,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    console.log('여기');
    console.log(auth.currentUser);
    if (auth.currentUser) {
      const members = await Network.getMemberInfo(auth.currentUser.email);
      if (!members.err) {
        console.log({members});
        if (members.length > 0) {
          dispatch(authActionCreators.getMemberInfoSuccess(members[0]));
        }
      }
    }
    console.log(getState());
    done();
  }
});

const signOutLogic = createLogic({
  type: authActions.SIGN_OUT,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    localStorage.clear();
    await firebase.auth().signOut();
    if (action.cb) action.cb();
  }
});


export default [
  signInLogic,
  getUserInfoLogic,
  signOutLogic,
  getMemberInfoLogic,
]