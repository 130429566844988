import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Card, Image, Table, Button, Segment, Input, Container, Header, Form, TextArea} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

const essaySubjectsRef = db.collection('essaySubjects');

class AdminEssaySubjectMainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            essaySubjects: [],
            newSubject: {
                subject: '',
                description: '',
                number: '',
                category: '',
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._loadEssaySubjects();
    }

    _loadEssaySubjects = async () => {
        essaySubjectsRef
            .orderBy('number')
            .get()
            .then(snapshot => {
                const essaySubjects = [];
                snapshot.forEach(doc => {
                    const subject = doc.data();
                    subject.id = doc.id;
                    essaySubjects.push(subject);
                })
                this.setState({
                    essaySubjects: essaySubjects
                })
            })
            .catch(e => {
                console.log(e);
            })
    };
    _addEssaySubject = () => {
        const {newSubject} = this.state;
        newSubject.number = parseInt(newSubject.number, 10);
        newSubject.createdAt = new Date();
        newSubject.modifiedAt = new Date();
        essaySubjectsRef
            .add(newSubject)
            .then(() => {
                this._loadEssaySubjects();
                this.setState({
                    newSubject: {
                        subject: '',
                        description: '',
                        number: '',
                        category: '',
                    }
                })
            })
            .catch(e => {
                console.log(e);
                window.alert('에러 발생')
            })
    }

    _modifyEssaySubject = (subject) => {
        let modifiedSubject = subject;
        modifiedSubject.number = parseInt(subject.number, 10);
        modifiedSubject.modifiedAt = new Date();
        essaySubjectsRef
            .doc(subject.id)
            .set(modifiedSubject)
            .then(() => {
                // this._loadEssaySubjects();
                window.alert('수정 완료')
            })
            .catch(e => {
                console.log(e);

            })
    }

    _deleteEssaySubject = (subject) => {
        essaySubjectsRef
            .doc(subject.id)
            .delete()
            .then(() => {
                window.alert('삭제 완료')
                this._loadEssaySubjects();
            })
            .catch(e => {
                console.log(e);

            })
    }

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Container as={Segment} vertical>
                    <Form>
                        <Table celled size={'small'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>번호</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>카테고리</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>주제</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>설명</Table.HeaderCell>
                                    <Table.HeaderCell width={1}>번호</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>기타</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.essaySubjects.map((subject, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                <Table.Cell>
                                                    {i + 1}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Input fluid
                                                           value={subject.category}
                                                           onChange={(e, {value}) => {
                                                               let essaySubjects = this.state.essaySubjects;
                                                               essaySubjects[i].category = value
                                                               this.setState({essaySubjects});
                                                           }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <TextArea autoHeight
                                                              value={subject.subject}
                                                              onChange={(e, {value}) => {
                                                                  let essaySubjects = this.state.essaySubjects;
                                                                  essaySubjects[i].subject = value
                                                                  this.setState({essaySubjects});
                                                              }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <TextArea autoHeight
                                                              value={subject.description}
                                                              onChange={(e, {value}) => {
                                                                  let essaySubjects = this.state.essaySubjects;
                                                                  essaySubjects[i].description = value
                                                                  this.setState({essaySubjects});
                                                              }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Input fluid
                                                           value={subject.number}
                                                           onChange={(e, {value}) => {
                                                               let essaySubjects = this.state.essaySubjects;
                                                               essaySubjects[i].number = value
                                                               this.setState({essaySubjects});
                                                           }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button compact size={'small'} onClick={() => {
                                                        this._modifyEssaySubject(subject)
                                                    }}>
                                                        수정하기
                                                    </Button>
                                                    <Button compact size={'small'} onClick={() => {
                                                        this._deleteEssaySubject(subject)
                                                    }}>
                                                        삭제
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                                <Table.Row>
                                    <Table.Cell>
                                        추가
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input fluid
                                               value={this.state.newSubject.category}
                                               onChange={(e, {value}) => {
                                                   let newSubject = this.state.newSubject;
                                                   newSubject.category = value
                                                   this.setState({newSubject});
                                               }}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <TextArea autoHeight
                                                  value={this.state.newSubject.subject}
                                                  onChange={(e, {value}) => {
                                                      let newSubject = this.state.newSubject;
                                                      newSubject.subject = value
                                                      this.setState({newSubject});
                                                  }}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <TextArea autoHeight
                                                  value={this.state.newSubject.description}
                                                  onChange={(e, {value}) => {
                                                      let newSubject = this.state.newSubject;
                                                      newSubject.description = value
                                                      this.setState({newSubject});
                                                  }}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input fluid
                                               value={this.state.newSubject.number}
                                               onChange={(e, {value}) => {
                                                   let newSubject = this.state.newSubject;
                                                   newSubject.number = value
                                                   this.setState({newSubject});
                                               }}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button compact size={'small'} onClick={() => {
                                            this._addEssaySubject()
                                        }}>
                                            추가
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Form>
                </Container>
            </Fragment>
        );
    }
}


export default AdminEssaySubjectMainContainer;
