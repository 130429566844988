import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Segment, Image, Container} from 'semantic-ui-react';

const propTypes = {};
const defaultProps = {};

const style = {
    header: {
        marginTop: '1em',
        fontWeight: 100,
        fontSize: '1.4em',
    },
    header1: {
        marginTop: '1em',
        fontWeight: 400,
        fontSize: '1.5em',
    },
    Segment: {
        marginTop: '50px',
        marginBottom: '100px',
    }
}

class HomeP1Way extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Segment
                vertical
                basic
                style={style.Segment}>
                <Container>
                    <Grid
                        stackable
                        verticalAlign='middle'>
                        <Grid.Row
                            centered
                            style={{
                                padding: '100px'
                            }}>
                            <div style={style.header1}>
                                Project 1은 각자의 분야에서 최고가 되기 위해 노력하는 사람들의 모임입니다.
                            </div>
                        </Grid.Row>
                        <Grid.Row
                            columns={3}
                            textAlign='center'>

                            <Grid.Column>
                                <Image src='/images/image.png' circular/>
                                <div style={style.header}>
                                    최고를 만나고
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src='/images/image.png' circular/>
                                <div style={style.header}>
                                    최고처럼 생각하고
                                </div>
                            </Grid.Column>

                            <Grid.Column>
                                <Image src='/images/image.png' circular/>
                                <div style={style.header}>
                                    최고처럼 시간을 사용합니다
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        );
    }
};

HomeP1Way.propTypes = propTypes;
HomeP1Way.defaultProps = defaultProps;

export default HomeP1Way;
