import authLogics from "./auth/authLogics";
import goalLogics from "./goal/goalLogics";
import essayLogics from "./essay/essayLogics";
import eodLogics from "./eod/eodLogics";


export default [
  ...authLogics,
  ...goalLogics,
  ...essayLogics,
  ...eodLogics,
]