export const eodActions = {
  FETCH_ALL_MY_EODS: 'FETCH_ALL_MY_EODS',
  FETCH_ALL_MY_EODS_SUCCESS: 'FETCH_ALL_MY_EODS_SUCCESS',

};

export const eodActionCreators = {
  fetchAllMyEods: (cb) => ({type: eodActions.FETCH_ALL_MY_EODS, cb}),
  fetchAllMyEodsSuccess: (eods, cb) => ({type: eodActions.FETCH_ALL_MY_EODS_SUCCESS, eods, cb}),
};


const initialState = {
  eods: [],
};


export const eodReducer = (state = initialState, action) => {
  switch (action.type) {
    case eodActions.FETCH_ALL_MY_EODS_SUCCESS:
      return {
        ...state,
        eods: [...action.eods],
      };
    default:
      return state;
  }
};