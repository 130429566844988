const p1Program = [
    {
        programGroupTitle: '내가 바라는 나의 미래에 대해',
        programs: [
        ]
    },
    {
        programGroupTitle: '생각하고 (Being)',
        programs: [
            {
                title: 'ESSAY',
                programImage: '/images/program/program_essay2.jpg',
                description: '자신을 이해하기 위한 질문을 합니다',
                contents: [
                    '나를 객관적으로, 정확하기 보기 위한 가장 좋은 방법은 나에 대한 글쓰기 입니다',
                    '내가 바라는 미래를 구체화 하기 위해 스스로 묻고 답합니다',
                ],
                detailURL: '/#'
            },
        ]
    },
    {
        programGroupTitle: '실천합니다 (Doing)',
        programs: [
            {
                title: 'GOAL',
                programImage: '/images/program/program_goal2.jpg',
                description: '나다운 삶을 살기 위해 4개월 동안 달성할 목표를 설정합니다',
                contents: [
                    '목표 달성을 위한 노력과 아웃풋을 매주 체크하고 이주에 한번씩 팀원들과 만나 공유합니다'
                    // "내가 원하는 특정 분야의 최고가 되기 위해 이번 기수동안 달성하고자 하는 목표를 설정합니다.",
                    // "설정한 목표 달성을 위해 노력한 내용을 매주 작성하고 결과물을 팀원들과 공유합니다."
                ]
            },
            {
                title: 'EOD',
                programImage: '/images/program/program_eod1.jpg',
                description: '노력하는 과정을 매일의 습관으로 만듭니다',
                contents: [
                    "목표 달성을 위해 투입한 시간과 내용을 매일 기록합니다.",
                    "기록을 통해 지속적으로 변화하는 모습과 성장을 관리합니다."
                ]
            }
        ]
    }
];

export {p1Program}