import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../../components/index';
import {
    Segment,
    Container,
    Card,
    Image,
    Divider,
    List,
    Button,
    Grid,
    Header,
    Item,
    Label,
    Icon,
    Responsive
} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {goalExample} from "../../data/bd_goals";
import {essayExample} from "../../data/bd_essay";
import * as BDBrand from "../../constants/BDBrand";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');


const propTypes = {};
const defaultProps = {};
const styles = {
    Header: {
        backgroundColor: BDBrand.BDCOLOR,
        height: '40vh'

    },
    mainContent: {
        padding: 20,
    }
}

class EssayDetail extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.pageview('/program/essay/'+this.props.match.params.id);

    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        window.scrollTo(0, 0);
        ReactGA.pageview('/program/essay/'+this.props.match.params.id);


    }


    render() {
        console.log(this.props);
        const essayId = this.props.match.params.id;
        const prevEssayId = Number(essayId) - 1;
        const nextEssayId = Number(essayId) + 1;
        const lengthOfEssay = essayExample.length;
        const selectedEssay = essayExample[essayId];

        console.log(selectedEssay);
        return (
            <div>
                <Responsive minWidth={768}>
                    <Grid style={styles.Header} textAlign={'center'} verticalAlign={'middle'}>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                {
                                    Number(essayId) == 0 ?
                                        (null) :
                                        (<Link to={'/program/essay/' + prevEssayId}>
                                            <Icon name={'left chevron'} size={'massive'} color={'white'}/>
                                        </Link>)
                                }

                            </Grid.Column>
                            <Grid.Column>
                                <h3 style={{color: 'yellow'}}>{selectedEssay.author}</h3>
                                <h2 style={{color: 'white'}}>{selectedEssay.title}</h2>
                            </Grid.Column>
                            <Grid.Column>
                                {
                                    Number(essayId) + 1 == lengthOfEssay ?
                                        (null) :
                                        (<Link to={'/program/essay/' + nextEssayId}>
                                            <Icon name={'right chevron'} size={'massive'} color={'white'}/>
                                            <br/>
                                        </Link>)
                                }

                            </Grid.Column>
                        </Grid.Row>

                    </Grid>

                </Responsive>

                <Responsive maxWidth={767}>
                    <Grid style={styles.Header} textAlign={'center'} verticalAlign={'middle'}>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>
                                {
                                    Number(essayId) == 0 ?
                                        (null) :
                                        (<Link to={'/program/essay/' + prevEssayId}>
                                            <Icon name={'left chevron'} size={'big'} color={'white'}/>
                                        </Link>)
                                }

                            </Grid.Column>
                            <Grid.Column width={12}>
                                <h4 style={{color: 'yellow'}}>{selectedEssay.author}</h4>
                                <h3 style={{color: 'white'}}>{selectedEssay.title}</h3>
                            </Grid.Column>
                            <Grid.Column width={2}>

                                {
                                    Number(essayId) + 1 == lengthOfEssay ?
                                        (null) :
                                        (<Link to={'/program/essay/' + nextEssayId}>
                                            <Icon name={'right chevron'} size={'big'} color={'white'}/>
                                            <br/>
                                        </Link>)
                                }

                            </Grid.Column>
                        </Grid.Row>

                    </Grid>


                </Responsive>
                <Container text>
                    <Segment style={{marginTop: 20}}>
                        <Image src={selectedEssay.thumbnail} size={'small'} centered/>
                        <div dangerouslySetInnerHTML={{__html: selectedEssay.content}} style={styles.mainContent}>
                        </div>
                    </Segment>
                </Container>
                <Container text>

                    <Segment style={{marginTop: 20}}>
                        <Item.Group>

                            {
                                Number(essayId) == 0 ?
                                    (null) :
                                    (
                                        <Item as={Link} to={'/program/essay/' + prevEssayId} style={{marginRight: 20}}>
                                            <Item.Image size='small' src={essayExample[prevEssayId].thumbnail}/>
                                            <Item.Content>
                                                <Item.Header className='under'>

                                                    {essayExample[prevEssayId].title}
                                                </Item.Header>
                                                <Item.Meta>
                                                    {
                                                        essayExample[prevEssayId].tag.map((tag, i) => {
                                                            return (
                                                                <Label key={i}>
                                                                    #{tag}
                                                                </Label>
                                                            )
                                                        })
                                                    }
                                                </Item.Meta>
                                                <Item.Description>{essayExample[prevEssayId].description}</Item.Description>
                                                <Item.Extra>
                                                    <span>{essayExample[prevEssayId].author}</span>
                                                    <span>{essayExample[prevEssayId].date}</span>
                                                </Item.Extra>
                                            </Item.Content>
                                        </Item>

                                    )
                            }
                            {
                                Number(essayId) + 1 == lengthOfEssay ?
                                    (null) :
                                    <Item as={Link} to={'/program/essay/' + nextEssayId} style={{marginRight: 20}}>
                                        <Item.Image size='small' src={essayExample[nextEssayId].thumbnail}/>
                                        <Item.Content>
                                            <Item.Header className='under'>

                                                {essayExample[nextEssayId].title}
                                            </Item.Header>
                                            <Item.Meta>
                                                {
                                                    essayExample[nextEssayId].tag.map((tag, i) => {
                                                        return (
                                                            <Label key={i}>
                                                                #{tag}
                                                            </Label>
                                                        )
                                                    })
                                                }
                                            </Item.Meta>
                                            <Item.Description>{essayExample[nextEssayId].description}</Item.Description>
                                            <Item.Extra>
                                                {/*<Image src={essayExample[nextEssayId].authorImage} size='mini'
                                                       circular/>*/}
                                                <span>{essayExample[nextEssayId].author}</span>
                                                <span>{essayExample[nextEssayId].date}</span>
                                            </Item.Extra>
                                        </Item.Content>
                                    </Item>
                            }
                        </Item.Group>

                    </Segment>
                </Container>


                <Container textAlign={'center'} style={{margin: 20}}>
                    {/*<Button size={'small'} icon={'left arrow'} content={'뒤로가기'} onClick={() => {
                        window.history.back();
                    }}/>*/}
                </Container>

            </div>
        );
    }
};

EssayDetail.propTypes = propTypes;
EssayDetail.defaultProps = defaultProps;

export default EssayDetail;
