import {db, firebase} from "../config/firebase";


export const getBDMemberInfo = async () => {

    const userEmail = firebase.auth().currentUser.email;

    if (!userEmail) return window.alert('유저 이메일 정보를 찾을 수 없습니다');

    return await db.collection('members').where('email', '==', userEmail)
        .get()
        .then(snapshot => {
            let users = [];
            snapshot.forEach(doc => {
                let user = doc.data();
                user.id = doc.id;
                users.push(user);
            });

            let isMember = false;
            if (users.length > 0) isMember = true;

            if (isMember) {
                const memberId = users[0].id;
                const savedMemberId = localStorage.getItem('memberId');
                if (!savedMemberId) {
                    localStorage.setItem('memberId', memberId);
                }
                return users[0]
            } else {
                return false
            }
        })
        .catch(e => {
            console.log(e);
            return false;
        })
};