import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../../components/index';
import {Link} from 'react-router-dom';
import {
    Card,
    Segment,
    Container,
    Image,
    Header,
    Item,
    Button,
    Icon,
    Label,
    List,
    Divider,
    Grid,
} from 'semantic-ui-react';
import {talkList} from "../../data/Sphere";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');

const propTypes = {};
const defaultProps = {};

class TalkDetail extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/sphere/talk/' + this.props.match.params.id.toUpperCase());
    }

    render() {
        const a = this.props.match.params.id.toUpperCase();
        let selectedTalk = '';
        let selectedSeason = '';
        talkList.map((season, i) => {
            season.movies.map((talk, i) => {
                if (talk.id == a) {
                    selectedTalk = talk;
                    selectedSeason = season;
                } else {
                }
            })
        });

        return (
            <div>
                <Segment vertical basic style={styles.Segment}>
                    <Container>

                    </Container>
                </Segment>
                <Segment vertical basic style={styles.Segment}>
                    <Container text>
                        <Header content={selectedTalk.title}/>
                        {selectedTalk.speakerName}
                    </Container>
                    <Container textAlign='center' text>
                        <iframe width="560" height="315" src={selectedTalk.link} frameborder="0" allow="autoplay; encrypted-media"></iframe>
                    </Container>
                </Segment>
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                    </Container>
                </Segment>


            </div>
        );
    }
};

const styles = {
    Segment: {
        margin: "5em 0em"
    }
}

TalkDetail.propTypes = propTypes;
TalkDetail.defaultProps = defaultProps;

export default TalkDetail;
