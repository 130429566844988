import React, {Component, Fragment} from 'react';
import {Route, Redirect, BrowserRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import './Animate.css';

import Interview from "./containers-z99-backup/people/Interview";
import HomeContainer from "./containers/home/HomeContainer";
import Layout from "./containers/layout/LayoutContainer";
import AboutUs from "./containers/AboutUs";
import EssayDetail from "./containers-z99-backup/program/EssayDetail";
import Essay from "./containers-z99-backup/program/Essay";
import Goal from "./containers-z99-backup/program/Goal";
import Eod from "./containers-z99-backup/program/Eod";
import Program from "./containers/Program";
import Article from "./containers/Article";
import Recruit from "./containers/Recruit";
import People from "./containers-z99-backup/people/People";
import FAQ from "./containers/FAQ";
import TalkDetail from "./containers-z99-backup/talk/TalkDetail";
import TalkMain from "./containers-z99-backup/talk/TalkMain";
import Home from "./containers-z99-backup/home-backup/Home";
import TalkMainContainer from "./containers/talk/TalkMainContainer";
import BlogMainContainer from "./containers/blog/BlogMainContainer";
import AboutMainContainer from "./containers/about/AboutMainContainer";
import ApplyMainContainer from "./containers/apply/ApplyMainContainer";
import TalkDetailContainer from "./containers/talk/TalkDetailContainer";
import SignInContainer from "./containers/auth/SignInContainer";
import SignUpContainer from "./containers/auth/SignUpContainer";
import {firebase} from "./config/firebase";
import AdminSeasonManagementContainer from "./containers-admin/season/AdminSeasonManagementContainer";
import MyPageMainContainer from "./containers-member/mypage/MyPageMainContainer";
import AdminMainContainer from "./containers-admin/main/AdminMainContainer";
import AdminBlogMainContainer from "./containers-admin/blog/AdminBlogMainContainer";
import AdminBlogWriteContainer from "./containers-admin/blog/AdminBlogWriteContainer";
import AdminBlogReadContainer from "./containers-admin/blog/AdminBlogReadContainer";
import BlogReadContainer from "./containers/blog/BlogReadContainer";
import AdminTalkManagementContainer from "./containers-admin/talk/AdminTalkManagementContainer";
import AdminMemberManagementContainer from "./containers-admin/member/AdminMemberManagementContainer";
import AdminReviewContainer from "./containers-admin/review/AdminReviewContainer";
import AdminSeasonModifyContainer from "./containers-admin/season/AdminSeasonModifyContainer";
import AdminSeasonMakeContainer from "./containers-admin/season/AdminSeasonMakeContainer";
import AdminTeamDetailContainer from "./containers-admin/team/AdminTeamDetailContainer";
import AdminFaqContainer from "./containers-admin/faq/AdminFaqContainer";
import FaqMainContainer from "./containers/faqs/FaqMainContainer";
import AdminTalkModifyContainer from "./containers-admin/talk/AdminTalkModifyContainer";
import AdminTalkMakeContainer from "./containers-admin/talk/AdminTalkMakeContainer";
import AdminEventMainContainer from "./containers-admin/event/AdminEventMainContainer";
import AdminEventModifyContainer from "./containers-admin/event/AdminEventModifyContainer";
import MemberTeamMainContainer from "./containers-member/team/MemberTeamMainContainer";
import AdminTeamMainContainer from "./containers-admin/team/AdminTeamMainContainer";
import AdminBDPaperMainContainer from "./containers-admin/bdpaper/AdminBDPaperMainContainer";
import AdminGoalMainContainer from "./containers-admin/goal/AdminGoalMainContainer";
import MemberBdPaperMainContainer from "./containers-member/bdPapers/MemberBdPaperMainContainer";
import MemberEssaysMainContainer from "./containers-member/essays/MemberEssaysMainContainer";
import MemberGoalsMainContainer from "./containers-member/goals/MemberGoalsMainContainer";
import MemberSearchMemberMainContainer from "./containers-member/search/MemberSearchMemberMainContainer";
import BoardsForAllMembersMainContainer from "./containers-member/boards/BoardsForAllMembersMainContainer";
import MemberBdPaperDetailContainer from "./containers-member/bdPapers/MemberBdPaperDetailContainer";
import MemberBdPaperWriteContainer from "./containers-member/bdPapers/MemberBdPaperWriteContainer";
import MemberSeasonsMainContainers from "./containers-member/season/MemberSeasonsMainContainers";
import MemberBdPaperSampleContainer from "./containers-member/bdPapers/MemberBdPaperSampleContainer";
import AdminEssaySubjectMainContainer from "./containers-admin/essay/AdminEssaySubjectMainContainer";
import AdminEssayListMainContainer from "./containers-admin/essay/AdminEssayListMainContainer";
import {authActionCreators} from "./redux/auth/authStore";
import FindPasswordContainer from "./containers/auth/FindPasswordContainer";



class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      user: {}
    }
  }

  componentDidMount() {


    const _this = this;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log(user.email);
        _this.props.getMemberInfo();
        _this.props.getUserInfo();
      } else {
      }
    });
  }


  render() {

    const {user, isLoggedIn} = this.props.auth;

    console.log({
      user,
      isLoggedIn,
    })



    return (
      <BrowserRouter>
        <Layout isLoggedIn={isLoggedIn}>
          <Route exact path="/aboutus" component={AboutUs}/>
          <Route exact path="/program/essay/:id" component={EssayDetail}/>
          <Route exact path="/program/essay" component={Essay}/>
          <Route exact path="/program/goal" component={Goal}/>
          <Route exact path="/program/eod" component={Eod}/>
          <Route exact path="/program" component={Program}/>
          <Route exact path="/people/interview/:id" component={Interview}/>
          <Route exact path="/people" component={People}/>
          <Route exact path="/article" component={Article}/>
          <Route exact path="/recruit" component={Recruit}/>
          <Route exact path="/faq" component={FAQ}/>
          <Route exact path="/sphere/talk/:id" component={TalkDetail}/>
          <Route exact path="/sphere/talk/" component={TalkMain}/>
          <Route exact path="/home" component={Home}/>


          <Route exact path="/signin" component={SignInContainer}/>
          <Route exact path="/signup" component={SignUpContainer}/>
          <Route exact path="/reset-password" component={FindPasswordContainer}/>


          <Route exact path="/apply" component={ApplyMainContainer}/>
          <Route exact path="/about" component={AboutMainContainer}/>
          <Route exact path="/blog/:blogId" component={BlogReadContainer}/>
          <Route exact path="/blog" component={BlogMainContainer}/>
          <Route exact path="/talk/:talkId" component={TalkDetailContainer}/>
          <Route exact path="/talk" component={TalkMainContainer}/>
          <Route exact path="/faqs" component={FaqMainContainer}/>
          <Route exact path="/" component={HomeContainer}/>


          <AdminRoute exact
                      user={user}
                      path="/admin"
                      component={AdminMainContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* 시즌 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/season/modify/:seasonId"
                      component={AdminSeasonModifyContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/season/make"
                      component={AdminSeasonMakeContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/season"
                      component={AdminSeasonManagementContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* 팀 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/team/modify/:teamId"
                      component={AdminTeamDetailContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/team"
                      component={AdminTeamMainContainer}
                      isLoggedIn={isLoggedIn}/>


          {/* 토크 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/talk"
                      component={AdminTalkManagementContainer}
                      isLoggedIn={isLoggedIn}/>
          <AdminRoute exact
                      user={user}
                      path="/admin/talk/modify/:talkId"
                      component={AdminTalkModifyContainer}
                      isLoggedIn={isLoggedIn}/>
          <AdminRoute exact
                      user={user}
                      path="/admin/talk/make"
                      component={AdminTalkMakeContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* 멤버 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/member"
                      component={AdminMemberManagementContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* FAQ */}
          <AdminRoute exact
                      user={user}
                      path="/admin/faq"
                      component={AdminFaqContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* 리뷰 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/review"
                      component={AdminReviewContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* 이벤트 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/event"
                      component={AdminEventMainContainer}
                      isLoggedIn={isLoggedIn}/>
          <AdminRoute exact
                      user={user}
                      path="/admin/event/modify/:eventId"
                      component={AdminEventModifyContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/event/make"
                      component={AdminEventModifyContainer}
                      isLoggedIn={isLoggedIn}/>


          {/* 블로그 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/blog/write"
                      component={AdminBlogWriteContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/blog/read/:blogId"
                      component={AdminBlogReadContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/blog"
                      component={AdminBlogMainContainer}
                      isLoggedIn={isLoggedIn}/>


          {/* BD Paper */}
          <AdminRoute exact
                      user={user}
                      path="/admin/bdpaper"
                      component={AdminBDPaperMainContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* Goal */}
          <AdminRoute exact
                      user={user}
                      path="/admin/goal"
                      component={AdminGoalMainContainer}
                      isLoggedIn={isLoggedIn}/>

          {/* 에세이 */}
          <AdminRoute exact
                      user={user}
                      path="/admin/essay/subject"
                      component={AdminEssaySubjectMainContainer}
                      isLoggedIn={isLoggedIn}/>

          <AdminRoute exact
                      user={user}
                      path="/admin/essay/list"
                      component={AdminEssayListMainContainer}
                      isLoggedIn={isLoggedIn}/>


          {/* 마이페이지 */}
          <PrivateRoute exact
                        path="/mypage"
                        component={MyPageMainContainer}
                        isLoggedIn={isLoggedIn}/>

          {/* BD Paper - */}
          <PrivateRoute exact
                        path="/app/bdpaper"
                        component={MemberBdPaperMainContainer}
                        isLoggedIn={isLoggedIn}/>

          <PrivateRoute exact
                        path="/app/bdpaper/read/:bdPaperId"
                        component={MemberBdPaperDetailContainer}
                        isLoggedIn={isLoggedIn}/>

          <PrivateRoute exact
                        path="/app/bdpaper/write/:bdPaperId"
                        component={MemberBdPaperWriteContainer}
                        isLoggedIn={isLoggedIn}/>

          <Route exact
                 path="/app/bdpaper/sample"
                 component={MemberBdPaperSampleContainer}
                 isLoggedIn={isLoggedIn}/>

          {/* GOAL - */}
          <PrivateRoute exact
                        path="/app/goal"
                        component={MemberGoalsMainContainer}
                        isLoggedIn={isLoggedIn}/>

          {/* Essay - */}
          <PrivateRoute exact
                        path="/app/essay"
                        component={MemberEssaysMainContainer}
                        isLoggedIn={isLoggedIn}/>

          {/* 팀 - */}
          <PrivateRoute exact
                        path="/app/team/:teamId"
                        component={MemberTeamMainContainer}
                        isLoggedIn={isLoggedIn}/>
          {/* 시즌 - */}
          <PrivateRoute exact
                        path="/app/seasons"
                        component={MemberSeasonsMainContainers}
                        isLoggedIn={isLoggedIn}/>

          {/* 멤버 검색 - */}
          <PrivateRoute exact
                        path="/app/search/member"
                        component={MemberSearchMemberMainContainer}
                        isLoggedIn={isLoggedIn}/>


          {/* 게시판 - 전체 크루 대상 컨텐츠 개별 */}
          <PrivateRoute exact
                        path="/app/board/all/:contentId"
                        component={BoardsForAllMembersMainContainer}
                        isLoggedIn={isLoggedIn}/>

          {/* 게시판 - 전체 크루 대상 */}
          <PrivateRoute exact
                        path="/app/board/all"
                        component={BoardsForAllMembersMainContainer}
                        isLoggedIn={isLoggedIn}/>

          {/* 게시판 - 시즌 */}
          <PrivateRoute exact
                        path="/app/board/season/:seasonId"
                        component={BoardsForAllMembersMainContainer}
                        isLoggedIn={isLoggedIn}/>

          {/* 게시판 - 시즌 - 컨텐츠 개별 */}
          <PrivateRoute exact
                        path="/app/board/season/content/:contentId"
                        component={BoardsForAllMembersMainContainer}
                        isLoggedIn={isLoggedIn}/>

        </Layout>
      </BrowserRouter>
    );



  }
}

const PrivateRoute = ({component: Component, isLoggedIn, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />
      )
    }
  />
);

const AdminRoute = ({component: Component, isLoggedIn, user, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      ((user.email === 'yhyun0513@gmail.com') || (user.email === 'jwokok@naver.com') || (user.email === 'jwokok706@gmail.com') || (user.email === 'dustinkim@stunitas.com')) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />
      )
    }
  />
);


const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(App);
