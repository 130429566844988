import React, {Component, Fragment} from 'react';
import {Menu, Container, Sidebar, Segment, Icon, Header, Button, Image, Responsive, Dropdown} from 'semantic-ui-react';
import {Link, NavLink} from 'react-router-dom';
import {BDCOLOR} from "../../constants/BDBrand";
import {db, firebase} from "../../config/firebase";
import {connect} from 'react-redux'
import {authActionCreators} from "../../redux/auth/authStore";


class PartialMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      visible: false,
      user: {},
      nowRecruiting: false,

      emailVerified: false,
      email: '',
      memberInfo: {},
      isMember: false,
      memberChecked: false,
    }
  }


  componentDidMount() {
    // this.checkIsLoggedIn();
    this.checkNowRecruiting();
  }

  // checkIsLoggedIn = () => {
  //   firebase.auth().onAuthStateChanged(user => {
  //     if (user) {
  //       this.setState({
  //         isLoggedIn: true,
  //         user
  //       })
  //       this._checkEmailVerified();
  //     } else {
  //       this.setState({
  //         isLoggedIn: false,
  //         user: {},
  //
  //         emailVerified: false,
  //         email: '',
  //         memberInfo: {},
  //         isMember: false,
  //         memberChecked: false,
  //       })
  //     }
  //   });
  // }

  // _checkEmailVerified = () => {
  //   let user = firebase.auth().currentUser;
  //
  //   this.setState({
  //     email: user.email,
  //     emailVerified: user.emailVerified
  //   });
  //   if (user.emailVerified) {
  //     // this._checkBDMember(user.email);
  //   }
  // };

  // _checkBDMember = (userEmail) => {
  //   if (!userEmail) return window.alert('유저 이메일 정보를 찾을 수 없습니다');
  //   db.collection('members').where('email', '==', userEmail)
  //     .get()
  //     .then(snapshot => {
  //       let users = [];
  //       snapshot.forEach(doc => {
  //         let user = doc.data();
  //         user.id = doc.id;
  //         users.push(user);
  //       });
  //
  //       let isMember = false;
  //       if (users.length > 0) isMember = true;
  //       this.setState({
  //         memberInfo: users[0],
  //         isMember: isMember,
  //         memberChecked: true
  //       });
  //
  //       if (isMember) {
  //         const memberId = users[0].id;
  //         const savedMemberId = localStorage.getItem('memberId');
  //         if (!savedMemberId) {
  //           localStorage.setItem('memberId', memberId);
  //         }
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     })
  // };

  checkNowRecruiting = () => {
    db.collection('config').doc('recruitingInfo').get().then(doc => {
      const {
        nowRecruiting
      } = doc.data();

      this.setState({
        nowRecruiting
      })
    })
  }

  toggleVisibility = () => {
    this.setState({visible: !this.state.visible})
  };

  render() {
    const {visible} = this.state;
    const {user, member, isLoggedIn} = this.props.auth;

    return (
      <Fragment>
        <Responsive minWidth={765}>
          <Menu fixed={'top'} secondary style={{backgroundColor: 'white'}}>

            <Container>
              <Menu.Item as={Link} to='/' style={{color: BDCOLOR, fontSize: 20, fontWeight: 'bold'}}>
                Being&Doing
              </Menu.Item>
              <Menu.Item as={Link} to='/about' style={{color: BDCOLOR,}}>
                알아보기
              </Menu.Item>
              {/*<Menu.Item as={Link} to='/event' style={{color: BDCOLOR,}}>
                                이벤트
                            </Menu.Item>*/}
              <Menu.Item as={Link} to='/blog' style={{color: BDCOLOR}}>
                소식
              </Menu.Item>
              <Menu.Item as={Link} to='/talk' style={{color: BDCOLOR}}>
                Talk
              </Menu.Item>
              <Menu.Menu position={'right'}>
                {
                  this.state.nowRecruiting && (
                    <Menu.Item as={Link} to='/apply' style={{color: BDCOLOR}}>
                      지원하기
                    </Menu.Item>
                  )
                }

                {
                  isLoggedIn ? (
                    member.email ? (
                      <Dropdown text='멤버 메뉴' className={'link item'} style={{color: BDCOLOR}}>
                        <Dropdown.Menu>
                          <Dropdown.Item icon={'user outline'} text='마이페이지' as={NavLink} to='/mypage'/>
                          <Dropdown.Divider/>
                          <Dropdown.Item icon={'sticky note outline'} text='BD Paper' as={NavLink} to='/app/bdpaper'/>

                          <Dropdown.Item icon={'flag checkered'} text='Goal' as={NavLink} to='/app/goal'/>
                          <Dropdown.Item icon={'sticky note outline'} text='Essay' as={NavLink} to='/app/essay'/>
                          <Dropdown.Item icon={'calendar'} text='Season' as={NavLink} to='/app/seasons'/>
                          <Dropdown.Divider/>
                          <Dropdown.Item icon={'alarm'} text='전체 게시판' as={NavLink} to='/app/board/all'/>
                          <Dropdown.Item icon={'search'} text='멤버 검색' as={NavLink} to='/app/search/member'/>

                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Menu.Item as={Link} to='/mypage' style={{color: BDCOLOR}}>
                        마이페이지
                      </Menu.Item>
                    )
                  ) : (
                    <Menu.Item as={Link} to='/signup' style={{color: BDCOLOR}}>
                      시작하기
                    </Menu.Item>
                  )
                }
                {
                  ((user.email === 'yhyun0513@gmail.com') || (user.email === 'jwokok@naver.com') || (user.email === 'jwokok706@gmail.com') || (user.email === 'dustinkim@stunitas.com')) && (
                    <Menu.Item as={Link} to='/admin' style={{color: BDCOLOR}}>
                      관리
                    </Menu.Item>
                  )
                }

              </Menu.Menu>
            </Container>
          </Menu>
        </Responsive>


        <Responsive maxWidth={764}>
          <Menu fixed={'top'}>
            <Menu.Item onClick={this.toggleVisibility}>
              <Icon name='sidebar'/>
            </Menu.Item>
          </Menu>

          <Sidebar as={Menu}
                   animation='overlay'
                   width={'wide'}
                   visible={visible}
                   icon='labeled'
                   vertical
                   inverted>
            <Menu.Item icon={'remove'} onClick={this.toggleVisibility}/>
            <Menu.Item as={NavLink} exact to='/' name='홈' onClick={this.toggleVisibility}/>

            <Menu.Item as={NavLink} to='/about' name='알아보기' onClick={this.toggleVisibility}/>
            <Menu.Item as={NavLink} to='/blog' name='소식' onClick={this.toggleVisibility}/>
            <Menu.Item as={NavLink} to='/talk' name='Talk' onClick={this.toggleVisibility}/>

            {
              this.state.nowRecruiting && (
                <Menu.Item as={NavLink} to='/apply' style={{color: BDCOLOR}}>
                  지원하기
                </Menu.Item>
              )
            }

          </Sidebar>
        </Responsive>
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(PartialMenu);
