export const goalActions = {
  FETCH_ALL_GOALS: 'FETCH_ALL_GOALS',
  FETCH_ALL_GOALS_SUCCESS: 'FETCH_ALL_GOALS_SUCCESS',
};

export const goalActionCreators = {
  fetchAllGoals: (cb) => ({type: goalActions.FETCH_ALL_GOALS, cb}),
  fetchAllGoalsSuccess: (goals, cb) => ({type: goalActions.FETCH_ALL_GOALS_SUCCESS, goals, cb}),
};


const initialState = {
  goals: [],
};


export const goalReducer = (state = initialState, action) => {
  switch (action.type) {
    case goalActions.FETCH_ALL_GOALS_SUCCESS:
      return {
        ...state,
        goals: action.goals,
      };
    default:
      return state;
  }
};