import React from 'react'
import {List} from 'semantic-ui-react'



const faq = [
    {
        title: {
            "content": 'Project 1 을 하면 무엇이 좋은가요?',
            key : 'title-3'
        },
        content: {
            content: (
                <div>
                    Project 1 을 통해  한 분야에 대해 장기적인 목표를 세우고 꾸준하게 노력할 수 있습니다. 시즌을 거듭할수록 특정 분야에서 더 깊이있는 지식을 가지게 됩니다. Project 1 에는 각자의 분야에서 최고의 전문성을 갖추기 위해 노력할 수 있는 환경이 갖춰져 있습니다
                </div>
            ),
            key : 'content-3'
        }
    },
    {
        title: {
            content: '그냥 혼자해도 되는 일들 아닌가요?',
        },
        content: {
            content: (
                <div>
                    Project 1 에서는 뛰어난 사람들과 함께 팀을 이룰 수 있습니다. 자기분야에서 최고의 전문성을 갖추고자 노력하는 사람들과 매주 만나며 서로 자극을 주고 받습니다.<br/><br/>
                    나와 분야는 다르지만 최고를 추구하는 사람들을 만나며 시야를 넓히고, 최고의 전문성을 갖추기 위한 노하우도 서로 교환합니다.
                </div>
            ),
        }
    },
    {
        title: {
            content: '모임은 몇시부터 몇시까지 하나요?',
        },
        content: {
            content: (
                <div>
                    Project 1 은 토요일 오전, 일요일 오전, 월요일 오전 모임이 있습니다. <br/><br/>
                    지난기수에는 토요일 오전 9시~11시, 일요일 오전 10시~12시 모임을 진행하였습니다. 지원서에 제출하신 가능시간대 (토요일 오전, 일요일 오전, 월요일 오전) 를 바탕으로 팀원이 선발되며, 팀별 구체적인 시간은 OT 를 통해 확정 짓습니다.
                </div>
            ),
        }
    },
    {
        title: {
            content: '모임은 어디에서 하나요?',
        },
        content: {
            content: (
                <div>
                    모임 장소는 팀별로 다르며, 정해져있는 고정된 장소는 없습니다. <br/><br/>
                    지난 기수의 경우를 말씀드리면, 강남역 카페(스타벅스, 엔제리너스, 할리스 등) 에서 모임을 진행하였습니다.
                </div>
            ),
        }
    },
    {
        title: {
            content: '모임은 얼마나 자주하나요?',
        },
        content: {
            content: (
                <div>
                    모임은 매주 이루어집니다.
                </div>
            ),
        }
    },
    {
        title: {
            content: '모임 전체 인원과 팀원은 몇명이예요?',
        },
        content: {
            content: (
                <div>
                    한 기수의 전체 인원은 대략 10-20명이며, 한 팀은 4-5명으로 이루어집니다.
                </div>
            ),
        }
    },
    {
        title: {
            content: '팀원은 어떻게 선발이 되나요?',
        },
        content: {
            content: (
                <div>
                    팀원들은 지원서를 바탕으로 선발됩니다. 지원서에 작성하신 내용을 토대로 Project 1 과 얼마나 Fit 이 맞는 분인지를 판단합니다. <br/><br/>
                    모임 시간 역시 팀원 선정에 중요한 요소입니다. 함께하고 싶은 분일지라도 가능한 시간대가 맞지 않아 선발을 할 수 없는 경우가 있습니다.
                </div>
            ),
        }
    },
    {
        title: {
            content: '회비는 얼마인가요?',
        },
        content: {
            content: (
                <div>
                    한 기수의 참가 회비는 20만원입니다.<br/><br/>
                    회비에는 Project 1 에서 제공하는 Tool (EOD App, P1 Paper, Project 1 스티커) 사용권한과 P1 Talk, P1 Closing 등 전체모임 참석 비용 (음식 및 대관료 등) 이 포함됩니다.<br/>
                    단, P1 Talk 등 전체행사가 수도권을 벗어난 지역에서 진행될 경우 추가비용이 있을 수 있습니다 (e.g. 비행기, KTX 비용 등)
                </div>
            ),
        }
    },

]


export {faq};