const stepInfo = [
    {
        title: 'INTRO',
        description: 'BD Paper를 설명합니다.'
    },
    {
        title: 'To-Be 도출',
        description: '나에 대한 고민을 통해 궁극적인 To-Be를 도출 해 봅시다.'
    },
    {
        title: '핵심 역량 도출',
        description: 'To-Be에 도달하기 위해 나를 구성하는 영역 별 핵심 역량을 도출해 봅시다.'
    },
    {
        title: '핵심 역량별 As-Is 분석',
        description: '핵심 역량 별로 나의 현재 수준을 분석해 봅시다.'
    },
    {
        title: 'Task 도출',
        description: 'As-Is에서 To-Be로 가기 위해 필요한 Task를 도출해 봅시다.'
    },
    {
        title: 'Task 우선 순위화',
        description: '전체 Task 중 이번 기수에 집중 할 Task를 선정 해 봅시다.'
    },
    {
        title: 'Task 실행 기준 설정',
        description: 'Task 수행을 평가할 실행 기준을 설정 합니다.'
    }
];

const categoryInfo = categoryName => {
    switch (categoryName) {
        case 'category_1':
            return {
                title: '(1) 어떤 사람',
                question1: '신상정보, 사회적 역할, 소유를 제외하고 본인을 소개해 보세요.',
                question2: '내가 닮고 싶은 Role Model을 소개해 주세요.',
                question3: '어떤 사람이 되고 싶나요?'
            };
        case 'category_2':
            return {
                title: '(2) 어떤 일',
                question1: '일생을 통해 이루고 싶은 또는 개선하고 싶은 일은 무엇인가요?',
                question2: '남은 10년 동안 단 한 가지 일만 할 수 있다면 무엇을 하실 건가요?',
                question3: '어떤 일을 하고 싶나요?'
            };
        case 'category_3':
            return {
                title: '(3) 어떤 방식의 삶',
                question1: '내 인생의 원칙/Ground Rule/의사결정 기준은 무엇인가요?',
                question2: '생을 마지막 순간에 나의 묘비에 어떤 글귀가 적혔으면 하나요?',
                question3: '어떤 삶을 살고 싶나요?'
            };
        case 'category_4':
            return {
                title: '(4) 왜?',
                question1: '무엇을 위해 이와 같은 인생을 살고 싶은 건가요?',
                question2: '지금 이와 같은 삶을 상상하면, 어떤 기분이 드나요?',
                question3: '무엇을 위해?'
            };
        default:
            return {};
    }
};



const rawBDPaper = (name, email, season) => {
    return {
        name: name,
        email: email,
        season: season,
        tobe: [
            {
                answer1: '',
                answer2: '',
                answer3: '',
                categoryName: 'category_1'
            },
            {
                answer1: '',
                answer2: '',
                answer3: '',
                categoryName: 'category_2'
            },
            {
                answer1: '',
                answer2: '',
                answer3: '',
                categoryName: 'category_3'
            },
            {
                answer1: '',
                answer2: '',
                answer3: '',
                categoryName: 'category_4'
            }
        ],
        oneSentence: '',
        goalPackages: [
            {
                areaName: '지식/기술',
                capability: [
                    {
                        name: '',
                        asIs: '',
                        toBe: '',
                        task: [
                            {
                                name: '',
                                selected: false,
                                isSpecific: false,
                                plan_detail: [
                                    {
                                        week: '1주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '2주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '3주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '4주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '5주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '6주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '7주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '8주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '9주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '10주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '11주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '12주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '13주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '14주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '15주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '16주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ],
                                plan_short: [
                                    {
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                areaName: '가치관/성격',
                capability: [
                    {
                        name: '',
                        asIs: '',
                        toBe: '',
                        task: [
                            {
                                name: '',
                                selected: false,
                                isSpecific: false,
                                plan_detail: [
                                    {
                                        week: '1주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '2주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '3주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '4주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '5주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '6주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '7주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '8주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '9주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '10주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '11주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '12주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '13주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '14주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '15주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '16주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ],
                                plan_short: [
                                    {
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                areaName: '신체',
                capability: [
                    {
                        name: '',
                        asIs: '',
                        toBe: '',
                        task: [
                            {
                                name: '',
                                selected: false,
                                isSpecific: false,
                                plan_detail: [
                                    {
                                        week: '1주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '2주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '3주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '4주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '5주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '6주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '7주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '8주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '9주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '10주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '11주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '12주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '13주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '14주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '15주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '16주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ],
                                plan_short: [
                                    {
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                areaName: '기타',
                capability: [
                    {
                        name: '',
                        asIs: '',
                        toBe: '',
                        task: [
                            {
                                name: '',
                                selected: false,
                                isSpecific: false,
                                plan_detail: [
                                    {
                                        week: '1주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '2주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '3주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '4주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '5주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '6주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '7주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '8주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '9주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '10주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '11주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '12주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '13주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '14주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '15주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    },
                                    {
                                        week: '16주',
                                        description: '',
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ],
                                plan_short: [
                                    {
                                        three: '',
                                        six: '',
                                        nine: '',
                                        twelve: ''
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        createdAt: new Date(),
        modifiedAt: new Date(),
    }
}

const bdPaperCapability = () => {
    return {
        name: '',
        asIs: '',
        toBe: '',
        task: [
            {
                name: '',
                selected: false,
                isSpecific: false,
                plan_detail: [
                    {
                        week: '1주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '2주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '3주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '4주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '5주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '6주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '7주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '8주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '9주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '10주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '11주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '12주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '13주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '14주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '15주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    },
                    {
                        week: '16주',
                        description: '',
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    }
                ],
                plan_short: [
                    {
                        three: '',
                        six: '',
                        nine: '',
                        twelve: ''
                    }
                ]
            }
        ]
    }
}

export {
    stepInfo,
    categoryInfo,
    rawBDPaper,
    bdPaperCapability
}