import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Card, Container, Segment, Header, Image, Button} from 'semantic-ui-react'
import {db} from "../../config/firebase";
import BlogCardComponent from "../blog/BlogCardComponent";

class BlogPreviewComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs: []
        }
    }


    componentDidMount() {
        this.loadBlogList();
    }

    loadBlogList = async () => {
        const blogRef = db.collection('blogs').orderBy('number', 'desc');
        blogRef
            .get()
            .then(snapshot => {
                const blogs = [];
                snapshot.forEach(doc => {
                    const blog = doc.data();
                    blog.id = doc.id;
                    blogs.push(blog);
                })
                this.setState({
                    blogs
                })
            })
            .catch(e => {
                console.log(e);
            })

    };

    render() {
        return (
            <Container as={Segment} vertical style={{marginTop: 50}} basic>
                <Header size={'large'}>
                    소식
                </Header>
                <Card.Group itemsPerRow={3} stackable>
                    {
                        this.state.blogs.slice(0, 3).map((blog, i) => {
                            return (
                                <BlogCardComponent blog={blog} key={i}/>
                            )
                        })
                    }
                </Card.Group>
                <div style={{textAlign: 'center', marginTop: 20}}>
                    <Button basic as={Link} to={'/blog'}>
                        BD 소식 더보기
                    </Button>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BlogPreviewComponent);
