const goalExample = [
    {
        image: '/images/program/goal/01.jpg',
        title: '목표명',
        comment: 'BD를 통해 꾸준히 글쓰기 연습을 한 것이 졸업에 큰 도움이 되었습니다.',
        goalOwner: '이준엽'
    },
    {
        image: '/images/program/goal/02.jpg',
        title: '목표명',
        comment: 'Wired구독으로 영어와 친숙해지고 일기로 스트레스를 성공적으로 낮췄습니다.',
        goalOwner: '김덕현'
    },
    {
        image: '/images/program/goal/03.jpg',
        title: '목표명',
        comment: '건설적인 피드백과 서로의 생각을 공유해준 팀원들 덕분에 중국어 공부와 독서 목표 달성',
        goalOwner: '박정수'
    },
    {
        image: '/images/program/goal/04.jpg',
        title: '목표명',
        comment: '골프가 그러하듯 오래도록 좋은 BD 사람들과 함께 하고 싶습니다',
        goalOwner: '길수민'
    },
    {
        image: '/images/program/goal/05.jpg',
        title: '목표명',
        comment: '꾸준한 걷기+건강한 식습관 유지를 통해 체력각화ing, 10~12월 전화 중국어 출석률 100%',
        goalOwner: '안소현'
    },


    {
        image: '/images/program/goal/06.jpg',
        title: '목표명',
        comment: '꾸준히 실천한 건강한 식습관 기르기. BD와 함께해서 가능했어요',
        goalOwner: '류기령'
    },
    {
        image: '/images/program/goal/07.jpg',
        title: '목표명',
        comment: '내 삶을 풍성하게 매일 1 paragraph 4달동안 실천했네요!',
        goalOwner: '김다인'
    },
    {
        image: '/images/program/goal/08.jpg',
        title: '목표명',
        comment: '논문 2편의 마침표를 찍었어요! BD 감사해요',
        goalOwner: '이신형'
    },
    {
        image: '/images/program/goal/09.jpg',
        title: '목표명',
        comment: '늘 마음 속에 묻어두었던 버킷리스트 글쓰기를 시작할 수 있게 자극을 주고 응원해준 BD',
        goalOwner: '김윤혜'
    },
    {
        image: '/images/program/goal/10.jpg',
        title: '목표명',
        comment: '다른 세계관을 가진 팀원들을 통해 저의 세계까지 넓어질 수 있어서 즐거웠습니다.',
        goalOwner: '박혜진'
    },



    {
        image: '/images/program/goal/11.jpg',
        title: '목표명',
        comment: '다양한 문화적 포인트들을 배우며 나를 찾아가는 여행을 시작했습니다.',
        goalOwner: '최이주'
    },
    {
        image: '/images/program/goal/12.jpg',
        title: '목표명',
        comment: '미뤄오던 영어공부 드디어 시작, 그리고 밀가루 안먹기와 유무산소 운동 꾸준히 실천',
        goalOwner: '김덕호'
    },
    {
        image: '/images/program/goal/13.jpg',
        title: '목표명',
        comment: '삼십년간 못했던 정리정돈을 드디어 시작. 앞으로도 쭉 해내갈 수 있으리란 자신감 장착',
        goalOwner: '김주은'
    },
    {
        image: '/images/program/goal/14.jpg',
        title: '목표명',
        comment: '소중한 여가시간을 보내기 위한 취미계발, 깊이가 더해질 수록 활력소가 되어줍니다.',
        goalOwner: '이태석'
    },
    {
        image: '/images/program/goal/15.jpg',
        title: '목표명',
        comment: '스페인어 실력을 향상시키기 위한 방법 중 하나로 DELE를 위해 4달간 꾸준히 공부했어요',
        goalOwner: '이교영'
    },



    {
        image: '/images/program/goal/16.jpg',
        title: '목표명',
        comment: '영어공부 놓지 않기. BD여서 4개월 동안 가능했네요',
        goalOwner: '박윤정'
    },
    {
        image: '/images/program/goal/17.jpg',
        title: '목표명',
        comment: '원하는 투자자의 길을 향해 또 한단계 전진. BD 팀원들과 많은 생각을 공유 했습니다.',
        goalOwner: '최혁준'
    },
    {
        image: '/images/program/goal/18.jpg',
        title: '목표명',
        comment: '주2회 산업 및 사업 스터디를 통해 요약노트를 정리하는 습관이 생겼어요',
        goalOwner: '전현경'
    },
    {
        image: '/images/program/goal/19.jpg',
        title: '목표명',
        comment: '중국을 조금은 더 알게된 지난 4개월! BD에서 어제보다 나은 오늘의 저를 발견했습니다.',
        goalOwner: '이성민'
    },
    {
        image: '/images/program/goal/20.jpg',
        title: '목표명',
        comment: '차곡차곡 취미생활로 그림 쌓아가는 중, 유화도 시작했고 펜화는 이제 데일리로 정착',
        goalOwner: '이미혜'
    },



    {
        image: '/images/program/goal/21.jpg',
        title: '목표명',
        comment: '취미로 좋아하던 와인, BD덕에 이제 업으로 시작합니다',
        goalOwner: '윤누리'
    },
    {
        image: '/images/program/goal/22.jpg',
        title: '목표명',
        comment: '틈이 나면 책을 읽고, 영어에 친숙해 졌다는 것이 BD가 준 선물입니다.',
        goalOwner: '조영화'
    },
    {
        image: '/images/program/goal/23.jpg',
        title: '목표명',
        comment: '현재의 삶에 집중하기 위해 업무 프로세스 정리. 문제가 개선 되었을 때의 성취감에 자신감 UP UP',
        goalOwner: '김정규'
    },

]

export {goalExample}