import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../components';
import {Segment, Container, Item, Image, Button, Icon, Label, Divider} from 'semantic-ui-react';
import {articleData} from "../data/p1_article";

const propTypes = {};
const defaultProps = {};

class Article extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <AboutUsBackground imageUrl={'url(/images/article.jpg)'} fontColor='white'>
                    내 관심분야에 대해 공부한 내용과 생각을 정제된 글로 표현합니다
                    {/*매월, 하나의 생각을 완결시킵니다*/}
                </AboutUsBackground>
                {/*<Segment vertical basic>
                    <Container text textAlign='center'>
                        <Label>#마케팅</Label>
                        <Label>#세일즈</Label>
                        <Label>#기획</Label>
                        <Label>#조직문화</Label>
                        <Label>#시간관리</Label>
                        <Label>#기업문화</Label>
                        <Label>#창업</Label>
                        <Label>#독서</Label>
                        <Label>#관찰</Label>
                    </Container>

                </Segment>*/}
                <Segment vertical basic>
                    <Container text>
                        {
                            articleData.map((article, i) => {
                                return (
                                    <div>
                                        <Segment basic key={i}>
                                            <Item.Group>
                                                <Item as='a' href={article.url} target='_blank'>
                                                    <Item.Image size='small' src={article.image}/>
                                                    <Item.Content>
                                                        <Item.Header className='under'>

                                                            {article.title}
                                                        </Item.Header>
                                                        <Item.Meta>
                                                            {
                                                                article.tag.map((tag, i) => {
                                                                    return (
                                                                        <Label key={i}>
                                                                            #{tag}
                                                                        </Label>
                                                                    )
                                                                })
                                                            }
                                                        </Item.Meta>
                                                        <Item.Description>{article.summary}</Item.Description>
                                                        <Item.Extra>
                                                            <Image src={article.authorImage} size='mini' circular/>
                                                            <span>{article.author}</span>
                                                            <span>{article.date}</span>
                                                        </Item.Extra>
                                                    </Item.Content>
                                                </Item>
                                            </Item.Group>
                                        </Segment>
                                        <Divider/>
                                    </div>

                                )
                            })
                        }
                    </Container>
                </Segment>


            </div>
        );
    }
};

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default Article;
