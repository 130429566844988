import React, {Component} from 'react';
import {Container, Divider, Segment, Form, Button, Image} from 'semantic-ui-react';
import {db} from "../../config/firebase";
import {imageUploadToFirebase} from "../../middleware/firebaseImageHandler";
import moment from 'moment';

class AdminTalkModifyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            talk: {},
            isLoaded: false,
        }
    }


    componentDidMount() {
        const talkId = this.props.match.params.talkId;

        this.loadTalkData(talkId)
    }


    loadTalkData = async (talkId) => {
        const talkRef = db.collection('talks').doc(talkId);
        talkRef
            .get()
            .then(doc => {
                const talk = doc.data();
                talk.id = doc.id;
                talk.date = talk.date ? moment(talk.date.seconds * 1000).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm');
                this.setState({
                    talk,
                    isLoaded: true
                });
            })
            .catch(e => {
                console.log(e);
            })
    };

    saveTalk = () => {
        const talkId = this.props.match.params.talkId;
        const talkRef = db.collection('talks').doc(talkId);
        const newTalkData = this.state.talk;

        newTalkData.date = new Date(newTalkData.date);

        talkRef
            .set(newTalkData, {merge: true})
            .then(() => {
                window.alert('저장되었습니다');

            })
            .catch(e => {
                console.log(e);
            })
    };

    deleteTalk = () => {
        const talkId = this.props.match.params.talkId;
        const talkRef = db.collection('talks').doc(talkId);
        talkRef
            .delete()
            .then(() => {
                window.alert('삭제되었습니다.')
                this.props.history.push('/admin/talk')
            })
            .catch(e => {
                console.log(e);
            })
    };

    onMainImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'blogImage'

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);


            const talk = this.state.talk;
            talk.image = image;
            talk.imageFirebase = imageFirebase;

            this.setState({
                talk
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

            const talk = this.state.talk;
            talk.image = '';
            talk.imageFirebase = {};

            this.setState({
                talk
            })
        }
    }

    render() {
        return (
            <Container>
                <Form>
                    <Form.Input
                        label={'title'}
                        name={'title'}
                        value={this.state.talk.title}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'subTitle'}
                        name={'subTitle'}
                        value={this.state.talk.subTitle}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'date'}
                        name={'date'}
                        type={'datetime-local'}
                        value={this.state.talk.date}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'speakerName'}
                        name={'speakerName'}
                        value={this.state.talk.speakerName}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'number'}
                        name={'number'}
                        value={this.state.talk.number}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'seasonId'}
                        name={'seasonId'}
                        value={this.state.talk.seasonId}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'seasonTitle'}
                        name={'seasonTitle'}
                        value={this.state.talk.seasonTitle}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'link'}
                        name={'link'}
                        value={this.state.talk.link}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'youTubeId'}
                        name={'youTubeId'}
                        value={this.state.talk.youTubeId}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />


                    <Form.TextArea
                        autoHeight
                        label={'description'}
                        name={'description'}
                        value={this.state.talk.description}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    {
                        this.state.talk.image && (
                            <Image src={this.state.talk.image} onClick={() => {
                                const a = window.confirm('삭제하시겠습니까?')
                                if (!a) {
                                    return;
                                }
                                const talk = this.state.talk;
                                talk.image = '';
                                talk.imageFirebase = {};
                                this.setState({
                                    talk
                                })

                            }}/>
                        )
                    }

                    <Form.Field>
                        <label>메인 사진 추가</label>
                        <input type="file" onChange={e => {
                            this.onMainImageUpload(e)
                        }}
                               accept=".jpg, .jpeg, .png, .heic"/>
                    </Form.Field>

                </Form>
                <Divider/>
                <Button onClick={() => {
                    const a = window.confirm('수정하시겠습니까?');
                    if (!a) {
                        return;
                    }
                    this.saveTalk();
                }}>
                    저장
                </Button>
                <Button
                    negative
                    onClick={() => {
                        const a = window.confirm('삭제하시겠습니까? \n 되돌릴 수 없습니다.');
                        if (!a) {
                            return;
                        }
                        this.deleteTalk();
                    }}>
                    삭제
                </Button>
            </Container>
        );
    }
}


export default AdminTalkModifyContainer;
