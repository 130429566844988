import React, { Component } from 'react';
import { AboutUsBackground } from '../../components/index';
import { Card, Container, Image, Label, Segment, Dimmer, Grid, Header, Button } from 'semantic-ui-react';
import { peopleData, staffData } from '../../data/people';
import PropTypes from 'prop-types';
import { interviews } from '../../data/bd_interview';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');

const propTypes = {};
const defaultProps = {};

const styles = {
  mainStatement: {
    fontSize: '2em',
    fontWeight: 300,
    color: 'grey'
  },
  subscript: {
    marginTop: '-2em',
    marginBottom: '2em'
  }
};

class People extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    ReactGA.pageview('/people');
  }

  render() {
    return <div>
        <AboutUsBackground imageUrl={'url(/images/program/rawpixel-com-250087_resized.jpg)'} fontColor="white">
          내가 바라는 나를 만들어 가기 위해, 성장과 나아짐을 위해,
          <br />
          매일 노력하고 있는 BD Crew입니다
        </AboutUsBackground>

        {/*<Segment vertical basic>
                    <Container>
                        <h3 style={styles.mainStatement}>Interview</h3>
                        <br/>
                        <p style={styles.subscript}>Crew들이 말하는 BD에 대한 이야기를 들어보세요.</p>

                    </Container>
                    <Container textAlign={'center'}>
                        <Card.Group itemsPerRow={5} doubling>
                            {
                                interviews.map((interview, i) => {
                                    return (
                                        <Card as={Link} to={'/people/interview/' + i} key={i}
                                              style={{borderRadius: '0px', border: 'none'}} basic>
                                            <Image src={interview.image} style={{borderRadius: '0px', border: 'none'}}/>
                                            <Card.Content>
                                                <Card.Header>{interview.interviewee}</Card.Header>
                                                <Card.Description></Card.Description>
                                            </Card.Content>
                                        </Card>
                                    )
                                })
                            }
                        </Card.Group>
                    </Container>
                </Segment>*/}
        <Segment vertical basic>
          <Container>
            <h3 style={styles.mainStatement}>Interview</h3>
            <br />
            <p style={styles.subscript}>Crew들이 말하는 BD에 대한 이야기를 들어보세요.</p>
          </Container>
          <Container textAlign={'center'}>
            <a target="_blank" href={'http://naver.me/FwVHR7PC'}>
              <Grid style={{ height: 150 }} verticalAlign={'middle'} textAlign={'center'}>
                <Grid.Column>
                  <div style={{ maxWidth: 400, height: 150, borderWidth: 1, margin: 'auto', textAlign: 'left' }}>
                    <Image src={'/images/crewProfile/30.jpg'} size={'tiny'} floated={'right'} />
                    <span>BD는 의지를 지속할 수 있게 해주는 원동력이에요. 팀 멤버들과의 약속을 지키려고 노력하는 것이 제 의지를 지속할 수 있는 원동력이 됐다고 생각합니다. </span> <br />
                    <span>[더보기]</span>
                  </div>
                  <Image />
                </Grid.Column>
              </Grid>
            </a>
            <a target="_blank" href={'http://naver.me/xbBhZltp'}>
              <Grid style={{ height: 150 }} verticalAlign={'middle'} textAlign={'center'}>
                <Grid.Column>
                  <div style={{ maxWidth: 400, height: 150, borderWidth: 1, margin: 'auto', textAlign: 'left' }}>
                    <Image src={'/images/crewProfile/staff2.jpg'} size={'tiny'} floated={'right'} />
                    <span>
                      스스로의 가능성을 믿는 사람들이 모인 곳은 BD뿐일 거에요. BD에서 스스로의 가능성을 믿는 사람들을 꾸준히 만난 지난 5년이 없었다면 지금의 저와는 완전히 다른 모습이었을 거예요.{' '}
                    </span> <br />
                    <span>[더보기]</span>
                  </div>
                  <Image />
                </Grid.Column>
              </Grid>
            </a>
            <a target="_blank" href={'http://naver.me/GOj4dKM2'}>
              <Grid style={{ height: 150 }} verticalAlign={'middle'} textAlign={'center'}>
                <Grid.Column>
                  <div style={{ maxWidth: 400, height: 150, borderWidth: 1, margin: 'auto', textAlign: 'left' }}>
                    <Image src={'/images/crewProfile/staff3.jpg'} size={'tiny'} floated={'right'} />
                    <span>
                      어제의 나보다 오늘의 내가 더 성장했다고 확신하는 삶. BD 활동을 통해 얻은 3가지는 어제보다 오늘 더 성장했다는 확신, 마음먹으면 반드시 행동하는 실천력, 내가 살고 싶은 삶의 방향
                      입니다.
                    </span> <br />
                    <span>[더보기]</span>
                  </div>
                  <Image />
                </Grid.Column>
              </Grid>
            </a>
            {/* <Link to={'/people/interview/' + i} key={i}>
              <Grid style={{ height: 150 }} verticalAlign={'middle'} textAlign={'center'}>
                <Grid.Column>
                  <div style={{ maxWidth: 400, height: 150, borderWidth: 1, margin: 'auto', textAlign: 'left' }}>
                    <Image src={interview.image} size={'tiny'} floated={'right'} />
                    <span>{interview.summary}</span> <br />
                    <span>[더보기]</span>
                  </div>
                  <Image />
                </Grid.Column>
              </Grid>
            </Link> */}
            {interviews.map((interview, i) => {
              return <Link to={'/people/interview/' + i} key={i}>
                  <Grid style={{ height: 150 }} verticalAlign={'middle'} textAlign={'center'}>
                    <Grid.Column>
                      <div style={{ maxWidth: 400, height: 150, borderWidth: 1, margin: 'auto', textAlign: 'left' }}>
                        <Image src={interview.image} size={'tiny'} floated={'right'} />
                        <span>{interview.summary}</span> <br />
                        <span>[더보기]</span>
                      </div>
                      <Image />
                    </Grid.Column>
                  </Grid>
                </Link>;
            })}
          </Container>
        </Segment>

        <Segment vertical basic>
          <Container>
            <h3 style={styles.mainStatement}>멤버들에게 BD란?</h3>
            <br />
            <p style={styles.subscript}>2017 Fall</p>
          </Container>
          <Container textAlign="center">
            <Card.Group itemsPerRow={5} doubling>
              {peopleData.map((person, i) => {
                return <Card key={i} style={{ borderRadius: '0px', border: 'none' }} basic>
                    <Image src={person.image} style={{ borderRadius: '0px', border: 'none' }} />
                    <Card.Content>
                      <Card.Header>{person.name}</Card.Header>
                      {/*<Card.Meta>{person.comment}</Card.Meta>*/}
                      <Card.Description>{person.comment}</Card.Description>
                    </Card.Content>
                  </Card>;
              })}
            </Card.Group>
          </Container>
        </Segment>

        <Segment vertical basic>
          <Container>
            <h3 style={styles.mainStatement}>Staff</h3>
            <br />
            <p style={styles.subscript}>2018 Fall BD의 운영진입니다</p>
          </Container>
          <Container textAlign="center">
            <Card.Group itemsPerRow={5} doubling>
              {staffData.map((person, i) => {
                return <Card key={i} style={{ borderRadius: '0px', border: 'none' }} basic>
                    <Image src={person.image} style={{ borderRadius: '0px', border: 'none' }} />
                    <Card.Content>
                      <Card.Header>{person.name}</Card.Header>
                      {/*<Card.Meta>{person.comment}</Card.Meta>*/}
                      <Card.Description>{person.comment}</Card.Description>
                    </Card.Content>
                  </Card>;
              })}
            </Card.Group>
          </Container>
        </Segment>
        <Segment vertical basic />
        <Segment vertical basic textAlign={'center'}>
          <Button as={Link} to={'/recruit'} size={'big'} color={'blue'}>
            지원하러 가기
          </Button>
        </Segment>
      </div>;
  }
}

People.propTypes = propTypes;
People.defaultProps = defaultProps;

export default People;
