import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Form, Input, Card, Image, Header, Button} from 'semantic-ui-react';
import moment from 'moment'
import {imageUploadToFirebase} from "../../middleware/firebaseImageHandler";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import {db} from "../../config/firebase";

class AdminBlogReadContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            subTitle: '',
            number: parseInt(moment().format('YYYYMMDDHHmmss'), 10),
            image: '',
            imageFirebase: {},
            body: [],
            date: moment().format('YYYY-MM-DDTHH:mm'),

            isLoaded: false,
            isDeleted: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._loadBlogFromFirebase();
    }

    _loadBlogFromFirebase = async () => {
        const {blogId} = this.props.match.params;
        db.collection('blogs')
            .doc(blogId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    window.alert('문서를 찾을 수 없습니다.');
                    this.props.history.goBack();
                    return;
                }
                const {
                    body,
                    image,
                    imageFirebase,
                    number,
                    subTitle,
                    title,
                    date,
                    isDeleted,
                } = doc.data();
                this.setState({
                    body,
                    image,
                    imageFirebase,
                    number,
                    subTitle,
                    title,
                    isDeleted : isDeleted ? isDeleted : false,
                    date: moment(date.seconds * 1000).format('YYYY-MM-DDTHH:mm'),
                })
                console.log(moment(date.seconds * 1000).toISOString())
            })
            .catch(e => {
                console.log(e);
            })
    }


    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value
        })
    };

    addBody = () => {
        this.setState(state => {
            let body = state.body;
            body.push({
                header: {
                    content: '',
                    color: '',
                    align: '',
                },
                subHeader: {
                    content: '',
                    color: '',
                    align: '',
                },
                body: {
                    content: '',
                    color: '',
                    align: '',
                },
                movie: {},
                image: '',
                imageFirebase: {},
            });
            return {
                body
            }
        })
    };

    onIntroFileUpload = (e, index) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'blogImage'

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);

            let body = this.state.body;
            body[index].image = image;
            body[index].imageFirebase = imageFirebase;

            this.setState({
                body
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            let body = this.state.body;
            body[index].image = '';
            body[index].imageFirebase = {};

            this.setState({
                body
            })
        }
    };

    onMainImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'blogImage'

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);


            this.setState({
                image,
                imageFirebase
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

            this.setState({
                image: '',
                imageFirebase: {}
            })
        }
    }

    _handleDelete = () => {
        const a = window.confirm('삭제하시겠습니까?');
        if (!a) {
            return;
        }
        const {blogId} = this.props.match.params;
        db.collection('blogs').doc(blogId).set({
            isDeleted: true,
            number: 0
        }, {merge: true}).then(() => {
            this.props.history.push('/admin/blog')
        });

    }

    _handleRestore = () => {
        const a = window.confirm('복원하시겠습니까?');
        if (!a) {
            return;
        }
        const {blogId} = this.props.match.params;
        db.collection('blogs').doc(blogId).set({
            isDeleted: false,
            number: parseInt(moment(this.state.date).format('YYYYMMDDHHmmss'), 10),
        }, {merge: true}).then(() => {
            this.props.history.push('/admin/blog')
        });

    }

    handleSave = () => {
        const a = window.confirm('업로드하시겠습니까?');
        if (!a) {
            return;
        }
        const {
            title,
            subTitle,
            number,
            body,
            image,
            imageFirebase,
            date,
            isDeleted
        } = this.state;
        const data = {
            title,
            subTitle,
            number: parseInt(moment(this.state.date).format('YYYYMMDDHHmmss'), 10),
            body,
            image,
            imageFirebase,
            date: new Date(date),
            isDeleted,
            modifiedAt: new Date(),
        };

        const {blogId} = this.props.match.params;

        db.collection('blogs').doc(blogId).set(data, {merge: true}).then(() => {
            this.props.history.push('/admin/blog')
        });
    }

    render() {
        return (
            <div>
                <Container as={Segment} vertical>
                    <Card.Group itemsPerRow={2}>
                        <Card>
                            {
                                this.state.image && (
                                    <Image src={this.state.image} onClick={() => {
                                        const a = window.confirm('삭제하시겠습니까?')
                                        if (!a) {
                                            return;
                                        }
                                        this.setState({
                                            image: '',
                                            imageFirebase: {},
                                        })

                                    }}/>
                                )
                            }
                            <Card.Content>
                                <Header>
                                    {this.state.title}
                                    <Header.Subheader>
                                        {this.state.subTitle}
                                    </Header.Subheader>
                                </Header>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </Container>
                <Container as={Segment} text vertical>
                    <Form>
                        <Form.Input label={'Title'}
                                    name={'title'}
                                    value={this.state.title}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'subTitle'}
                                    name={'subTitle'}
                                    value={this.state.subTitle}
                                    onChange={this.handleChange}/>

                        <Form.Input label={'date'}
                                    name={'date'}
                                    type={'datetime-local'}
                                    value={this.state.date}
                                    onChange={this.handleChange}/>

                        <Form.Field>
                            <label>메인 사진 추가</label>
                            <input type="file" onChange={e => {
                                this.onMainImageUpload(e)
                            }}
                                   accept=".jpg, .jpeg, .png, .heic"/>
                        </Form.Field>
                    </Form>
                </Container>

                <Container as={Segment} text vertical>
                    {
                        this.state.body.map((module, i) => {
                            return (
                                <Card key={i} raised fluid>
                                    {
                                        module.image && (
                                            <Image src={module.image} onClick={() => {
                                                const a = window.confirm('삭제하시겠습니까?')
                                                if (!a) {
                                                    return;
                                                }

                                                let body = this.state.body;
                                                body[i].image = null;
                                                body[i].imageFirebase = {};
                                                this.setState({
                                                    body
                                                })

                                            }}/>
                                        )
                                    }
                                    {
                                        module.movie && module.movie.youTubeId && (
                                            <iframe width="560" height="315" src={module.movie.link} frameBorder="0"
                                                    allow="autoplay; encrypted-media"/>
                                        )
                                    }
                                    <Card.Content>
                                        <Form>
                                            <Form.Input
                                                placeholder={'헤더'}
                                                value={this.state.body[i].header.content}
                                                onChange={(e, {value}) => {
                                                    let body = this.state.body;
                                                    body[i].header.content = value
                                                    this.setState({
                                                        body
                                                    })
                                                }}/>
                                            <Form.Input
                                                placeholder={'서브헤더'}
                                                value={this.state.body[i].subHeader.content}
                                                onChange={(e, {value}) => {
                                                    let body = this.state.body;
                                                    body[i].subHeader.content = value
                                                    this.setState({
                                                        body
                                                    })
                                                }}/>
                                            <Form.Input
                                                placeholder={'YouTube link'}
                                                value={this.state.body[i].movie.link}
                                                onChange={(e, {value}) => {
                                                    let body = this.state.body;
                                                    body[i].movie.link = value
                                                    this.setState({
                                                        body
                                                    })
                                                }}/>
                                            <Form.TextArea
                                                value={this.state.body[i].body.content}
                                                rows={8}
                                                placeholder={'서브헤더'}
                                                onChange={(e, {value}) => {
                                                    let body = this.state.body;
                                                    body[i].body.content = value
                                                    this.setState({
                                                        body
                                                    })
                                                }}/>
                                        </Form>
                                        <input type="file" onChange={e => {
                                            this.onIntroFileUpload(e, i)
                                        }}
                                               accept=".jpg, .jpeg, .png, .heic"/>
                                    </Card.Content>
                                    <Card.Content extra>

                                        <Button onClick={() => {
                                            const a = window.confirm('삭제하시겠습니까?');
                                            if (!a) {
                                                return;
                                            }
                                            this.setState(state => {
                                                let body = state.body;
                                                body.splice(i, 1)
                                                return {
                                                    body
                                                }
                                            })
                                        }}>
                                            삭제
                                        </Button>
                                        {
                                            i !== 0 && (
                                                <Button onClick={() => {
                                                    const body = moveItemInArrayFromIndexToIndex(this.state.body, i, i - 1)
                                                    console.log({body})
                                                    this.setState({
                                                        body
                                                    })
                                                }}>
                                                    위로
                                                </Button>
                                            )
                                        }
                                        {
                                            i !== this.state.body.length - 1 && (
                                                <Button onClick={() => {
                                                    const body = moveItemInArrayFromIndexToIndex(this.state.body, i, i + 1)
                                                    console.log({body})
                                                    this.setState({
                                                        body
                                                    })
                                                }}>
                                                    아래로
                                                </Button>
                                            )
                                        }
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Container>
                <Container as={Segment} text vertical>
                    <Button onClick={() => {
                        this.addBody()
                    }}>
                        본문 추가하기
                    </Button>

                    <Button onClick={() => {
                        this.handleSave()
                    }}>
                        수정/저장하기
                    </Button>
                    {
                        this.state.isDeleted ? (
                            <Button
                                negative
                                onClick={() => {
                                    this._handleRestore()
                                }}>
                                복원하기
                            </Button>
                        ) : (
                            <Button
                                negative
                                onClick={() => {
                                    this._handleDelete()
                                }}>
                                삭제하기
                            </Button>
                        )
                    }

                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminBlogReadContainer);
