import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {auth, db, firebase} from "../../config/firebase";
import {Link} from 'react-router-dom'
import {Button, List, Grid, Container, Image, Header, Loader, Item, Card, Message, Segment} from 'semantic-ui-react';
import {authActionCreators} from "../../redux/auth/authStore";

class MyPageMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailVerified: false,
      email: '',
      userInfo: {},
      isMember: false,
      memberChecked: false,
      teams: [],
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    // this._checkEmailVerified();
  }

  // _checkEmailVerified = () => {
  //   let user = firebase.auth().currentUser;
  //   console.log({emailVerified: user.emailVerified});
  //   this.setState({
  //     email: user.email,
  //     emailVerified: user.emailVerified
  //   });
  //
  //   this._checkBDMember(user.email);
  // };

  // _checkBDMember = (userEmail) => {
  //   if (!userEmail) return window.alert('유저 이메일 정보를 찾을 수 없습니다');
  //   db.collection('members').where('email', '==', userEmail)
  //     .get()
  //     .then(snapshot => {
  //       let users = [];
  //       snapshot.forEach(doc => {
  //         let user = doc.data();
  //         user.id = doc.id;
  //         users.push(user);
  //       });
  //
  //       let isMember = false;
  //       if (users.length > 0) isMember = true;
  //       this.setState({
  //         userInfo: users[0],
  //         isMember: isMember,
  //         memberChecked: true
  //       })
  //
  //       if (isMember) {
  //         const memberId = users[0].id;
  //         const savedMemberId = localStorage.getItem('memberId');
  //         if (!savedMemberId) {
  //           localStorage.setItem('memberId', memberId);
  //         }
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     })
  // };


  _sendEmailVerification = () => {
    firebase
      .auth().currentUser
      .sendEmailVerification()
      .then(() => {
        window.alert('확인 이메일이 발송되었습니다.')
      })
      .catch(e => {
        window.alert('이메일 발송에 실패하였습니다. 잠시 후 재시도 해주세요.')
      })
  };

  handleSignOut = async () => {
    const a = window.confirm('로그아웃 하시겠습니까?');
    if (!a) return;

    this.props.signOut();

    // firebase.auth().signOut();
    // localStorage.clear();
  };

  render() {

    const {
      isLoggedIn,
      user,
      isMember,
      member,
    } = this.props.auth;

    if (!auth.currentUser || !auth.currentUser.emailVerified) {
      return (
        <Segment vertical basic as={Container}>
          <Message>
            <Message.Header>이메일 인증 전</Message.Header>
            <List bulleted>
              <List.Item>
                {auth.currentUser.email}로 확인 이메일이 발송되었습니다. <br/>
                이메일을 확인해 주세요.
              </List.Item>
              <List.Item>
                이메일을 받지 못한 경우, 스팸함을 확인해 주세요.
              </List.Item>
              <List.Item>
                스팸함에도 이메일이 없는 경우에는 아래 버튼을 눌러 이메일 인증을 다시 시도해 주시길 바랍니다.
              </List.Item>
            </List>
          </Message>
          <Button onClick={() => {
            window.location.reload();
          }}>
            메일함에서 이메일을 인증하셨나요? 새로고침
          </Button>
          <Button onClick={() => {
            this._sendEmailVerification()
          }}>
            이메일 인증 다시 받기
          </Button>
          <Button onClick={this.handleSignOut}>
            LOGOUT
          </Button>
        </Segment>
      )

    }

    if (!isMember) {
      return (
        <Segment vertical as={Container}>
          <Message warning>
            <Message.Header>
              BD 멤버가 아니신가요?
            </Message.Header>
            <Message.List>
              <Message.Item>
                BD App의 세부기능은 BD에 참여중이신 분들에게만 제공되고 있습니다.
              </Message.Item>
              <Message.Item>
                혹시 BD 멤버이신가요? <br/>
                BD 지원서에 작성하셨던 e-mail 주소로 회원가입해 주셔야, 서비스를 정상적으로 이용하실 수 있습니다.
                이용에 문제가 있으시면 <br/>
                <a href='https://pf.kakao.com/_ptxdSxd/chat' target={'_blank'}>여기를 눌러서 카카오 플러스
                  친구
                </a>
                로 확인 요청해 주시면 빠르게 처리해드리겠습니다.
              </Message.Item>
            </Message.List>
          </Message>
          <Button onClick={this.handleSignOut}>
            LOGOUT
          </Button>
        </Segment>
      )
    } else {
      return (
        <Fragment>
          <Segment vertical as={Container}>
            <Header>
              <Button onClick={this.handleSignOut}
                      floated={'right'}
                      negative
                      compact
                      size={'small'}
                      basic>
                LOGOUT
              </Button>
              {member.name}님 안녕하세요 <br/>
              BD에 오신 것을 환영합니다.
            </Header>
          </Segment>
          <Segment vertical as={Container}>

            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Card fluid as={Link} to={{pathname: '/app/bdpaper'}}>
                    <Image src={'/images/bdpapercover.jpg'}/>
                    <Card.Content>
                      <Button fluid primary basic>
                        BD Paper 작성하기
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Fragment>
      );
    }

  }
}

const mapStateToProps = (state) => {
  return {...state};
};
const mapDispatchToProps = {
  ...authActionCreators,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyPageMainContainer);
