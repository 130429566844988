import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {goalActionCreators} from "../../redux/goal/goalStore";
import {Segment, Loader, Image, Grid, Item, Container, Table, Progress, Card} from 'semantic-ui-react'
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import GoalComponent from "../../components/goal/GoalComponent";
import {eodActionCreators} from "../../redux/eod/eodStore";

class MemberGoalsMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      fetching: false,
      selectedGoalId: '',
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this._loadMyGoals();
    this._loadEods();

  }

  _loadMyGoals = async () => {
    this.setState({
      fetching: true,
    });
    this.props.fetchAllGoals(() => {
      this.setState({
        isLoaded: true,
        fetching: false,
      })
    });
  };

  _loadEods = async () => {
    this.props.fetchAllMyEods();
  };

  render() {
    const {fetching, isLoaded} = this.state;
    const {goals} = this.props.goalReducer;
    const {eods} = this.props.eodReducer;

    const goalIng = _.orderBy(_.filter(goals, g => moment().isBetween(moment(g.startDate.seconds * 1000), moment(g.endDate.seconds * 1000), 'days', '[]')), ['endDate.seconds'], ['desc']);
    const goalEnded = _.orderBy(_.filter(goals, g => moment().isAfter(moment(g.endDate.seconds * 1000), 'day')));
    const selectedEods = _.filter(eods, eod => (eod.goalId === this.state.selectedGoalId));

    return (
      <div style={{display: 'flex', height: '90vh', margin: 'auto', maxWidth: '80vw'}}>
        <div style={{paddingTop: '3rem', maxWidth: '600px', overflow: 'auto'}}>
          <div>
            <p className={'headerText'}>
              진행중인 목표
            </p>
            <Item.Group unstackable>
              {
                goalIng.map((goal, i) => {
                  return (
                      <GoalComponent
                        onClick={() => {
                          console.log('버튼');
                          this.setState({
                            selectedGoalId: goal.id
                          })
                        }}
                        goal={goal} key={i}/>
                  )
                })
              }
            </Item.Group>
          </div>
          <div style={{
            marginTop: '3rem',
          }}>
            <p className={'headerText'}>
              종료된 목표
            </p>
            <Item.Group unstackable>
              {
                goalEnded.map((goal, i) => {
                  return (
                    <GoalComponent
                      onClick={() => {
                        console.log('버튼');
                        this.setState({
                          selectedGoalId: goal.id
                        })
                      }}
                      goal={goal} key={i}/>
                  )
                })
              }
            </Item.Group>
          </div>
        </div>

        <div style={{
          flex: 1,
          display: 'flex',
          overflow: 'auto',
          paddingTop: '3rem',
          flexDirection: 'column',
        }}>
          <Card.Group itemsPerRow={7} style={{
            paddingLeft: '1rem',
            paddingRight: '1rem'
          }}>
            {
              selectedEods.map((eod, i) => {
                return (
                  <Card key={i}>
                    <Card.Content>
                      {moment(eod.createdAt.seconds * 1000).format('YYYY.MM.DD')}
                    </Card.Content>
                  </Card>
                )
              })
            }
          </Card.Group>
        </div>
      </div>
    );
  }
}



const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
    ...goalActionCreators,
    ...eodActionCreators,
  }
);


export default enhance(MemberGoalsMainContainer);