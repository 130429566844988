import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Button, Container, Segment, Card} from 'semantic-ui-react'
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

class AdminMainContainer extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)
    }


    render() {
        return (
            <Segment vertical basic>
                <AdminSubMenuComponent/>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMainContainer);
