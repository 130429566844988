import React, { Component } from 'react';

class AdminEventModifyContainer extends Component {

    componentDidMount() {

    }


    render() {
        return (
            <div>

            </div>
        );
    }
}


export default AdminEventModifyContainer;
