import React, {Component, Fragment} from 'react';
import {db, firebase} from "../../config/firebase";
import {Link, NavLink, Route} from 'react-router-dom';
import {Segment, Container, Button, Card, Header, Icon, Radio, Menu, Form, Grid, Modal, Table,} from 'semantic-ui-react'
import {
    DateInput,
} from 'semantic-ui-calendar-react';
import moment from 'moment'

class MemberTeamMainContainer extends Component {


    constructor(props) {
        super(props);
        this.state = {

            activeTab: 'goal',
            memberId: '',
            teamData: {},
            teamMeetings: [],

            boardsForAllMembers: [],

            goals: [],

            essays: [],

            selectedDay: moment().format('YYYY-MM-DD'),
            addGoalModalVisible: false,


            fetchingMakingGoal: false,
            newGoal: {
                goalType: 'OX',
                title: '',
                output: '',
                description: '',

                customCountUnit: false,
                countUnit: '',

                totalAmount: '',

                targetGoalAsIs: '',
                targetGoalToBe: '',

                criteriaO: '',
                criteriaX: '',

                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
            },


        }
    }

    componentDidMount() {
        this._loadTeamData();
        this._loadMemberId();
        this._loadTeamMeetings();
        this._loadBoardsForAll();
    };

    _loadMemberId = () => {
        const memberId = localStorage.getItem('memberId');
        this.setState({
            memberId
        })
    };

    _loadTeamData = () => {
        const {teamId} = this.props.match.params;
        db.collection('teams')
            .doc(teamId)
            .get()
            .then(doc => {
                const teamData = doc.data();
                teamData.id = doc.id;
                this.setState({
                    teamData
                });

                const {
                    seasonId
                } = teamData;

                if (seasonId) {
                    this._loadBoardsForEachSeason(seasonId);
                    this._loadMyGoalsOfThisSeason(seasonId);
                    this._loadMyEssaysOfThisSeason(seasonId);
                }
            })
            .catch(e => {
                console.log(e);
                window.alert('팀 정보를 불러오는 데 오류가 발생하였습니다.')
            })
    };

    _loadTeamMeetings = () => {
        const {teamId} = this.props.match.params;
        console.log({teamId})
        db.collection('teamMeetings')
            .where('teamId', '==', teamId)
            .get()
            .then(snapShot => {
                const teamMeetings = [];
                snapShot.forEach(doc => {
                    const meeting = doc.data();
                    meeting.id = doc.id;
                    teamMeetings.push(meeting);
                });

                this.setState({
                    teamMeetings
                })
            })
    }

    _loadBoardsForAll = () => {
        db.collection('boardsForAllMembers')
            .limit(5)
            .get()
            .then(snapshot => {
                const boardsForAllMembers = [];
                snapshot.forEach(doc => {
                    const content = doc.data();
                    content.id = doc.id;
                    boardsForAllMembers.push(content);
                })

                this.setState({
                    boardsForAllMembers
                })
            })
            .catch(e => {
                console.log(e);
            })

    };

    _loadBoardsForEachSeason = (seasonId) => {
        const {teamId} = this.props.match.params;
        db.collection('boardsForEachSeason')
            .where('seasonId', '==', seasonId)
            // .orderBy()
            .get()
            .then(snapshot => {

                const boardsForEachSeason = [];
                snapshot.forEach(doc => {
                    const board = doc.data();
                    board.id = doc.id;
                    boardsForEachSeason.push(board);
                });

                this.setState({
                    boardsForEachSeason
                })

            })
            .catch(e => {
                console.log(e)
            })
    };

    _loadMyGoalsOfThisSeason = (seasonId) => {
        const memberId = localStorage.getItem('memberId');
        db.collection('goals')
            .where('memberId', '==', memberId)
            .where('seasonId', '==', seasonId)
            .get()
            .then(snapshot => {

                const goals = [];
                snapshot.forEach(doc => {
                    const goal = doc.data();
                    goal.id = doc.id;
                    goals.push(goal)
                })

                this.setState({
                    goals
                })
            })
    }

    _loadMyEssaysOfThisSeason = (seasonId) => {
        const memberId = localStorage.getItem('memberId');
        db.collection('essays')
            .where('memberId', '==', memberId)
            .where('seasonId', '==', seasonId)
            .get()
            .then(snapshot => {

                const essays = [];
                snapshot.forEach(doc => {
                    const essay = doc.data();
                    essay.id = doc.id;
                    essays.push(essay)
                });

                this.setState({
                    essays
                })
            })
    }

    _handleTabChange = (tabName) => {
        this.setState({
            activeTab: tabName
        })
    };

    _handlePrevWeek = () => {
        let selectedDay = this.state.selectedDay
        selectedDay = moment(selectedDay).subtract(1, 'week').format('YYYY-MM-DD');
        this.setState({
            selectedDay
        })
    }

    _handleNextWeek = () => {
        let selectedDay = this.state.selectedDay
        selectedDay = moment(selectedDay).add(1, 'week').format('YYYY-MM-DD');
        this.setState({
            selectedDay
        })
    }

    _completeAdd = () => {
        let newGoal = this.state.newGoal;
        newGoal.seasonId = this.state.teamData.seasonId;
        newGoal.teamId = this.state.teamData.id;
        newGoal.memberId = this.state.memberId;
        newGoal.userEmail = firebase.auth().currentUser.email;

        newGoal.endDate = new Date(moment(newGoal.endDate).startOf('day').toISOString());
        newGoal.startDate = new Date(moment(newGoal.startDate).startOf('day').toISOString());
        newGoal.createdAt = new Date();
        newGoal.modifiedAt = new Date();

        this.setState({
            fetchingMakingGoal: true,
        })

        db.collection('goals')
            .add(newGoal)
            .then(() => {
                window.alert('목표 생성이 완료되었습니다.');
                this._loadMyGoalsOfThisSeason(this.state.teamData.seasonId)
                this.setState({
                    addGoalModalVisible: false,
                    newGoal: {
                        goalType: 'OX',
                        title: '',
                        output: '',
                        description: '',

                        customCountUnit: false,
                        countUnit: '',

                        totalAmount: '',

                        targetGoalAsIs: '',
                        targetGoalToBe: '',

                        criteriaO: '',
                        criteriaX: '',

                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
                    },
                })
            })
            .catch(e => {
                window.alert('목표 생성에 에러가 발생하였습니다.');
                console.log(e);
                this.setState({
                    fetchingMakingGoal: false,
                })
            })
    }
    _cancelAdd = () => {
        const a = window.confirm('취소하시겠습니까?');
        if (!a) return;

        this.setState({
            addGoalModalVisible: false,
        })
    }

    handleChangeNewGoal = (e, {value, name}) => {
        let newGoal = this.state.newGoal;

        const preDefinedCountUnits = ['시간', '분', '회', '페이지']

        if (name === 'countUnit') {
            if (preDefinedCountUnits.findIndex(unit => unit === value) > -1) {
                newGoal['customCountUnit'] = false
            }
        } else if (name === 'customCountUnit') {
            newGoal['countUnit'] = '';
        }

        newGoal[name] = value;
        this.setState({
            newGoal
        })
        console.log({newGoal})
    }

    render() {
        return (
            <Fragment>
                <Segment as={Container} vertical>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <Card raised fluid>
                                    <Card.Content>
                                        <Header size={'small'}>
                                            <Button size={'mini'} compact basic floated={'right'}>
                                                전체보기
                                            </Button>
                                            BD 전체 게시판
                                        </Header>
                                    </Card.Content>
                                    <Card.Content>

                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column>
                                <Card raised fluid>
                                    <Card.Content>
                                        <Header size={'small'}>
                                            <Button size={'mini'} compact basic floated={'right'}>
                                                전체보기
                                            </Button>
                                            이번 시즌 게시판
                                        </Header>
                                    </Card.Content>
                                    <Card.Content>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column>
                                <Card raised fluid>
                                    <Card.Content>
                                        <Header size={'small'}>
                                            BD Paper
                                        </Header>
                                    </Card.Content>
                                    <Card.Content>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment as={Container} vertical basic>
                    <Menu widths={3} style={{borderRadius: 0}}>
                        <Menu.Item
                            onClick={() => {
                                this._handleTabChange('goal')
                            }}
                            active={this.state.activeTab === 'goal'}>
                            목표
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => {
                                this._handleTabChange('essay')
                            }}
                            active={this.state.activeTab === 'essay'}>
                            에세이
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => {
                                this._handleTabChange('meeting')
                            }}
                            active={this.state.activeTab === 'meeting'}>
                            팀모임
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Segment as={Container} vertical
                         style={{display: this.state.activeTab !== 'meeting' ? 'none' : ''}}>
                    <Header>팀모임</Header>
                    <Card.Group itemsPerRow={4} doubling>
                        {
                            this.state.teamMeetings.map((meeting, i) => {
                                return (
                                    <Card key={i}>
                                        <Card.Content>

                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                        {
                            this.state.teamData.chairId === this.state.memberId && (
                                <Card>
                                    <Card.Content>
                                        <Button fluid>
                                            모임 일정 만들기
                                        </Button>
                                    </Card.Content>
                                </Card>
                            )
                        }
                    </Card.Group>
                </Segment>
                <Segment as={Container} vertical
                         basic
                         style={{display: this.state.activeTab !== 'goal' ? 'none' : ''}}>

                    <Segment vertical basic>
                        <Button.Group>
                            <Button circular icon={'left chevron'} onClick={this._handlePrevWeek}/>
                            <Button>
                                {moment(this.state.selectedDay).startOf('week').format('YYYY. MM. DD (ddd)')} ~ {moment(this.state.selectedDay).endOf('week').format('YYYY. MM. DD (ddd)')}
                            </Button>
                            <Button circular icon={'right chevron'} onClick={this._handleNextWeek}
                                    disabled={moment(this.state.selectedDay).endOf('week').isAfter(moment())}/>
                        </Button.Group>
                    </Segment>
                    <Segment vertical basic>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>목표명</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.goals.map((goal, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                <Table.Cell>
                                                    {goal.title}
                                                </Table.Cell>
                                                <Table.Cell>

                                                </Table.Cell>
                                                <Table.Cell>

                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <Button onClick={() => {
                                            this.setState({
                                                addGoalModalVisible: true
                                            })
                                        }}>목표 추가</Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Segment>
                </Segment>
                <Segment as={Container} vertical
                         style={{display: this.state.activeTab !== 'essay' ? 'none' : ''}}>
                    <Header>에세이</Header>
                    <Card.Group itemsPerRow={4} doubling>
                        {
                            this.state.essays.map((essay, i) => {
                                return (
                                    <Card key={i}>
                                        <Card.Content>

                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                        <Card>
                            <Card.Content>
                                <Button fluid>
                                    새 에세이 쓰기
                                </Button>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </Segment>
                <Modal open={this.state.addGoalModalVisible}>
                    <Modal.Header>
                        새로운 목표 만들기
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Input label={'목표 이름'}
                                        name={'title'}
                                        value={this.state.newGoal.title}
                                        onChange={this.handleChangeNewGoal}/>
                            <Form.Group widths={'equal'}>
                                <Form.TextArea label={'목표 설명'}
                                               autoHeight
                                               name={'description'}
                                               value={this.state.newGoal.description}
                                               onChange={this.handleChangeNewGoal}/>
                                <Form.TextArea label={'목표의 결과'}
                                               autoHeight
                                               name={'output'}
                                               value={this.state.newGoal.output}
                                               onChange={this.handleChangeNewGoal}/>
                            </Form.Group>
                            <Form.Group grouped>
                                <label>목표 타입</label>
                                <Form.Field
                                    control={Radio}
                                    label='OX : 목표의 수행 여부를 기록'
                                    name='goalType'
                                    value='OX'
                                    checked={this.state.newGoal.goalType === 'OX'}
                                    onChange={this.handleChangeNewGoal}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='진행량 : 매일 수행한 내용을 정량적으로 기록'
                                    name='goalType'
                                    value='amount'
                                    checked={this.state.newGoal.goalType === 'amount'}
                                    onChange={this.handleChangeNewGoal}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='진척도 : 목표의 정해진 수준이 정해져 있고, 중간중간 진척도를 체크'
                                    name='goalType'
                                    value='target'
                                    checked={this.state.newGoal.goalType === 'target'}
                                    onChange={this.handleChangeNewGoal}
                                />
                            </Form.Group>

                            {
                                this.state.newGoal.goalType === 'OX' && (
                                    <Form.Group widths={'equal'}>
                                        <Form.Input label={'O 기준'}
                                                    name={'criteriaO'}
                                                    placeholder={''}
                                                    value={this.state.newGoal.criteriaO}
                                                    onChange={this.handleChangeNewGoal}/>

                                        <Form.Input label={'X 기준'}
                                                    name={'criteriaX'}
                                                    placeholder={''}
                                                    value={this.state.newGoal.criteriaX}
                                                    onChange={this.handleChangeNewGoal}/>
                                    </Form.Group>
                                )
                            }

                            {
                                this.state.newGoal.goalType === 'amount' && (
                                    <Fragment>
                                        <Form.Group grouped>
                                            <label>측정 타입</label>
                                            <Form.Field
                                                control={Radio}
                                                label='시간'
                                                name='countUnit'
                                                value='시간'
                                                checked={this.state.newGoal.countUnit === '시간'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='분'
                                                name='countUnit'
                                                value='분'
                                                checked={this.state.newGoal.countUnit === '분'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='회'
                                                name='countUnit'
                                                value='회'
                                                checked={this.state.newGoal.countUnit === '회'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='페이지'
                                                name='countUnit'
                                                value='페이지'
                                                checked={this.state.newGoal.countUnit === '페이지'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='기타 : 직접입력'
                                                name='customCountUnit'
                                                value={true}
                                                checked={this.state.newGoal.customCountUnit === true}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            {
                                                this.state.newGoal.customCountUnit && (
                                                    <Form.Input name='countUnit'
                                                                placeholder={'바퀴, 번, 권 등'}
                                                                value={this.state.newGoal.countUnit}
                                                                onChange={this.handleChangeNewGoal}/>

                                                )
                                            }
                                        </Form.Group>
                                        <Form.Input name={'totalAmount'}
                                                    label={'목표치'}
                                                    type={'number'}
                                                    placeholder={`총 몇${this.state.newGoal.countUnit}을 달성하실 것인가요? 예) 100${this.state.newGoal.countUnit}`}
                                                    value={this.state.newGoal.totalAmount}
                                                    onChange={this.handleChangeNewGoal}/>
                                    </Fragment>
                                )
                            }
                            {
                                this.state.newGoal.goalType === 'target' && (
                                    <Fragment>
                                        <Form.Group grouped>
                                            <label>측정 단위</label>
                                            <Form.Field
                                                control={Radio}
                                                label='kg'
                                                name='countUnit'
                                                value='kg'
                                                checked={this.state.newGoal.countUnit === 'kg'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='%'
                                                name='countUnit'
                                                value='%'
                                                checked={this.state.newGoal.countUnit === '%'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='회'
                                                name='countUnit'
                                                value='회'
                                                checked={this.state.newGoal.countUnit === '회'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='페이지'
                                                name='countUnit'
                                                value='페이지'
                                                checked={this.state.newGoal.countUnit === '페이지'}
                                                onChange={this.handleChangeNewGoal}
                                            />
                                            <Form.Field
                                                control={Radio}
                                                label='기타 : 직접입력'
                                                name='customCountUnit'
                                                value={true}
                                                checked={this.state.newGoal.customCountUnit === true}
                                                onChange={this.handleChangeNewGoal}
                                            />

                                            {
                                                this.state.newGoal.customCountUnit && (
                                                    <Form.Input name='countUnit'
                                                                placeholder={'바퀴, 번, 권 등'}
                                                                value={this.state.newGoal.countUnit}
                                                                onChange={this.handleChangeNewGoal}/>
                                                )
                                            }

                                        </Form.Group>
                                        <Form.Group widths={'equal'}>
                                            <Form.Input name={'targetGoalAsIs'}
                                                        label={`현재 수준(${this.state.newGoal.countUnit})`}
                                                        type={'number'}
                                                        value={this.state.newGoal.targetGoalAsIs}
                                                        onChange={this.handleChangeNewGoal}/>
                                            <Form.Input name={'targetGoalToBe'}
                                                        label={`목표 수준(${this.state.newGoal.countUnit})`}
                                                        type={'number'}
                                                        value={this.state.newGoal.targetGoalToBe}
                                                        onChange={this.handleChangeNewGoal}/>

                                        </Form.Group>
                                    </Fragment>
                                )
                            }


                            <Form.Group widths={'equal'}>
                                <DateInput
                                    label={'시작일'}
                                    name="startDate"
                                    dateFormat={'YYYY-MM-DD'}
                                    placeholder="Date"
                                    value={this.state.newGoal.startDate}
                                    iconPosition="left"
                                    onChange={this.handleChangeNewGoal}
                                />
                                <DateInput
                                    label={'종료일'}
                                    name="endDate"
                                    dateFormat={'YYYY-MM-DD'}
                                    placeholder="Date"
                                    value={this.state.newGoal.endDate}
                                    iconPosition="left"
                                    onChange={this.handleChangeNewGoal}
                                />
                            </Form.Group>
                        </Form>


                    </Modal.Content>


                    <Modal.Actions>
                        <Button disabled={this.state.fetchingMakingGoal}
                                active={this.state.fetchingMakingGoal}
                                primary
                                basic onClick={() => {
                            this._completeAdd()
                        }}>
                            만들기
                        </Button>
                        <Button negative basic onClick={() => {
                            this._cancelAdd()
                        }}>
                            취소
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        );
    }
}



export default MemberTeamMainContainer;
