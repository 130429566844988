import React, {Component, Fragment} from 'react';
import {Segment, Container, Button, Table, Input, Dropdown} from 'semantic-ui-react';
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";
import {Link} from 'react-router-dom'
import {db} from "../../config/firebase";


class AdminEventMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._loadEventList();
    }

    _loadEventList = async () => {
        const eventRef = db.collection('events');
        eventRef
            .get()
            .then(snapshot => {
                const events = [];
                snapshot.forEach(doc => {
                    const event = doc.data();
                    event.id = doc.id;
                    events.push(event);
                })
                this.setState({
                    events
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    _addEvent = () => {
        const newEvent = {}

        db.collection('events')
            .add(newEvent)
            .then(() => {
                this._loadEventList()
            })
            .catch(e => {
                console.log(e);
                window.alert('에러 발생')
            })
    }

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Segment vertical as={Container}>
                    <Button as={Link} to={'/admin/event/make'}>
                        만들기
                    </Button>
                </Segment>
                <Segment vertical as={Container}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell> 번호 </Table.HeaderCell>
                                <Table.HeaderCell> 구분 </Table.HeaderCell>
                                <Table.HeaderCell> 시즌 </Table.HeaderCell>
                                <Table.HeaderCell> 일시 </Table.HeaderCell>
                                <Table.HeaderCell> 장소 </Table.HeaderCell>
                                <Table.HeaderCell> 기타 </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.events.map((event, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell> {event.number}</Table.Cell>
                                            <Table.Cell> </Table.Cell>
                                            <Table.Cell> </Table.Cell>
                                            <Table.Cell> </Table.Cell>
                                            <Table.Cell> </Table.Cell>
                                            <Table.Cell>
                                                <Button as={Link} to={`/admin/event/modify/${event.id}`}>
                                                    수정
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                            <Table.Row>
                                <Table.Cell>
                                    <Input fluid/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Input fluid/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Input fluid/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => {
                                        this._addEvent()
                                    }}>
                                        만들기
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>
        );
    }
}


export default AdminEventMainContainer;
