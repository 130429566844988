const peopleData = [
  {
    name: '김다인',
    image: '/images/crewProfile/01.jpg',
    comment: '목표가 많은 꿈많은 내게 보내는 Push 알람'
  },
  {
    name: '김덕현',
    image: '/images/crewProfile/02.jpg',
    comment: '연결'
  },
  {
    name: '김소미',
    image: '/images/crewProfile/03.jpg',
    comment: '급하진 않지만 필요한 일을 하게 해주는 Tool'
  },
  {
    name: '김주은',
    image: '/images/crewProfile/04.jpg',
    comment: '페이스 늦추지 않고 꾸준하게 목표를 향해 정주행하게 해주는 페이스 메이커'
  },
  {
    name: '김준형',
    image: '/images/crewProfile/05.jpg',
    comment: '서프라이즈'
  },

  {
    name: '김중혁',
    image: '/images/crewProfile/06.jpg',
    comment: '짜증나는 일을 즐겁게 해주는 모임'
  },
  {
    name: '김태영',
    image: '/images/crewProfile/07.jpg',
    comment: '나를 자극시켜주고 화이팅해주는 곳'
  },
  {
    name: '류기령',
    image: '/images/crewProfile/08.jpg',
    comment: '어떠한 형태!'
  },
  {
    name: '박윤정',
    image: '/images/crewProfile/09.jpg',
    comment: '내가 꿈꾸고 꿈을 나눌 수 있는 공동체'
  },
  {
    name: '박전혜',
    image: '/images/crewProfile/10.jpg',
    comment: '어렵지만 삶의 방향성을 제시해주는 작은 틀'
  },

  {
    name: '박현응',
    image: '/images/crewProfile/11.jpg',
    comment: '한발한발 앞으로 나아갈 수 있도록 함께하는 모임'
  },
  {
    name: '성민선',
    image: '/images/crewProfile/12.jpg',
    comment: '여행!(떠나기전엔 설레고 막상은 힘들기도 하지만 새로운 것들을 만나고 배우고 다녀오면 추억이 쌓이는 곳'
  },
  {
    name: '손하연',
    image: '/images/crewProfile/13.jpg',
    comment: '나를 돌아보아 좀 더 성숙하고 발전하게 해주는 작은 틀'
  },
  {
    name: '송민준',
    image: '/images/crewProfile/14.jpg',
    comment: '나 자신을 알도록 도와주는 곳'
  },
  {
    name: '송채연',
    image: '/images/crewProfile/15.jpg',
    comment: '날마다 작은 성취감을 느낄 수 있는 계기를 만들어주는 곳'
  },

  {
    name: '안소현',
    image: '/images/crewProfile/16.jpg',
    comment: '나태해질 수 있는 내 삶에 끊임없이 원동력을 주는 곳'
  },
  {
    name: '원유진',
    image: '/images/crewProfile/17.jpg',
    comment: '나에게 동기부여를 주는 곳'
  },
  {
    name: '윤성준',
    image: '/images/crewProfile/18.jpg',
    comment: "'나이면서' '우리'일 수 있는 곳"
  },
  {
    name: '이교영',
    image: '/images/crewProfile/19.jpg',
    comment: '내 삶을 충만하게 해주는 곳'
  },
  {
    name: '이미혜',
    image: '/images/crewProfile/20.jpg',
    comment: '추진력'
  },

  {
    name: '이준엽',
    image: '/images/crewProfile/21.jpg',
    comment: '일상을 벗어나 신선함을 공유하는 시간'
  },
  {
    name: '이지현',
    image: '/images/crewProfile/22.jpg',
    comment: '끝까지 가고 싶은 친구'
  },
  {
    name: '이태석',
    image: '/images/crewProfile/23.jpg',
    comment: '활력원'
  },
  {
    name: '전성우',
    image: '/images/crewProfile/24.jpg',
    comment: '나에게 자극과 경각심을 주면서 긍정적 Feedback을 형성해 주는 곳'
  },
  {
    name: '전현경',
    image: '/images/crewProfile/25.jpg',
    comment: '삶의 방향을 잡아가고 새로운 습관을 만들어 준 곳'
  },

  {
    name: '조민주',
    image: '/images/crewProfile/26.jpg',
    comment: '동기부여를 위한 연료'
  },
  {
    name: '최이주',
    image: '/images/crewProfile/27.jpg',
    comment: '내 자신을 이해할 수 있게 해준 고마운 곳'
  },
  {
    name: '한세진',
    image: '/images/crewProfile/28.jpg',
    comment: '어제보다 나은 오늘을 위한 매일 먹는 영양제'
  },
  {
    name: '허경석',
    image: '/images/crewProfile/29.jpg',
    comment: '좋은 사람을 만날 수 있는 곳'
  }
];

const staffData = [
  // {
  //     name:'최혁준',
  //     image:'/images/crewProfile/staff1.jpg',
  //     comment: '나를 성장시켜주는 Life Partner',
  // },
  {
    name: '윤영훈',
    image: '/images/crewProfile/staff2.jpg',
    comment: '지속해서 성장할 수 있게 하는 곳'
  },
  {
    name: '김덕호',
    image: '/images/crewProfile/staff3.jpg',
    comment: '어디선가 지켜보는 보이지 않는 눈'
  },
  //   {
  //     name: '조영화',
  //     image: '/images/crewProfile/staff4.jpg',
  //     comment: '나의 일상을 채우고 기록하는 일기장'
  //   },
  {
    name: '이유아',
    image: '/images/crewProfile/staff5.jpg',
    comment: '인생의 마디를 구분하고 집중 할 수 있게 해주는 평생 친구'
  },
  {
    name: '이미혜',
    image: '/images/crewProfile/20.jpg',
    comment: '추진력'
  },
  {
    name: '신정원',
    image: '/images/crewProfile/30.jpg',
    comment: '의지를 지속시켜 주는 원동력'
  }
];

export { peopleData, staffData };
