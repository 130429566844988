import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground, Schedule} from '../components';
import {Segment, Container, Grid, Header, List, Divider, Table, Button} from 'semantic-ui-react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-114613423-1');

const propTypes = {};
const defaultProps = {};

const style = {
    Segment: {
        margin: '5em 0em'
    }
};

class Recruit extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/recruit');
    }

    render() {
        return <div>
            <AboutUsBackground imageUrl={'url(/images/background/recruitingBD2.jpg)'} fontColor="grey">
                Being&Doing은 내가 꿈꾸는 나의 미래를 실현하기 위해 노력하는 사람들을 찾고 있습니다
            </AboutUsBackground>
            <Segment vertical basic style={style.Segment}>
                <Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Header content="인재상"/>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <List bulleted>
                                    <List.Item>
                                        내가 바라는 나의 미래를 실현하기 위해, <br/>
                                        어제보다 더 나은 내가 되기 위해 꾸준히 노력하는 사람
                                    </List.Item>
                                    <List.Item>노력하는 과정과 그 의미를 소중하게 생각하는 사람</List.Item>
                                    <List.Item>긍정적인 피드백으로 Crew와의 시너지를 극대화하는 사람</List.Item>
                                    <List.Item>겸손한 태도로 상대방을 존중할 줄 아는 사람</List.Item>
                                    <List.Item>장기적인 관점에서 나의 미래를 긍정적으로 바라보는 사람</List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
            <Container>
                <Divider/>
            </Container>

            {/*리쿠르팅 프로세스*/}
            <Segment vertical basic style={style.Segment}>
                <Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Header content="리쿠르팅 프로세스"/>

                                {/* 지원기간이 끝나서 링크를 닫아 놓음 */}

                                <div className="header" style={{color: '#2185d0'}}>
                                    2018 Fall <br/>
                                    Now Recruiting
                                </div>

                                <Button as="a" href="https://goo.gl/forms/y0Lup0zug38qCTee2" target="_blank"
                                        color="blue" content="지원하기"/>
                                {/* 지원기간이 끝나면 이 사이를 닫으면 됨 */}
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <Header content="STEP 1"/>
                                            지원서 작성
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Header content="STEP 2"/>
                                            체어 발표
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Header content="STEP 3"/>
                                            팀원 발표
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Header content="STEP 4"/>
                                            최종 멤버 확정
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={4}/>
                                        <Grid.Column width={8}>
                                            인재상, 모임가능 시간, 지역 등을 고려하여 합격자를 선정합니다
                                            <br/>
                                            모집 종료 후 7일 이내로 연락을 드립니다
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            최종 멤버와 팀을 확정 합니다 <br/>
                                            OT 일정을 알려드립니다
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>

            <Container>
                <Divider/>
            </Container>

            {/*일정*/}
            <Segment vertical basic style={style.Segment}>
                <Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Header content="2018 Fall 일정"/>
                            </Grid.Column>

                            <Grid.Column width={11}>
                                {/*<Schedule />*/}
                                <List>
                                    <List.Item>지원 기간: 08/19 일 ~ 08/30 목</List.Item>
                                    <List.Item>Chair OT: 09/01 토</List.Item>
                                    <List.Item>멤버발표: 09/02 일</List.Item>
                                    <List.Item>OT: 09/08 토</List.Item>
                                    <List.Item>BD Talk: 11/03 토</List.Item>
                                    <List.Item>Closing: 2019/01/05 토</List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}/>

                            <Grid.Column width={11}>
                                <Header>2018. 08. 19일부터 BD의 모집이 시작됩니다.</Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
            <Container>
                <Divider/>
            </Container>
            <Segment vertical basic style={style.Segment}>
                <Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Header content="Membership Fee"/>
                            </Grid.Column>

                            <Grid.Column width={11}>
                                <Table textAlign={'center'} celled size={'small'} unstackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell style={style.bgBDColor}>Basic</Table.HeaderCell>
                                            <Table.HeaderCell style={style.bgClubColor}>Club</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>팀모임 8회</Table.Cell>
                                            <Table.Cell style={style.bgBDColor2}>O</Table.Cell>
                                            <Table.Cell style={style.bgClubColor2}>O</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>BD Talk(선택참여)</Table.Cell>
                                            <Table.Cell style={style.bgBDColor2}>
                                                불포함
                                                <br/>
                                                (별도 2.5만원)
                                            </Table.Cell>
                                            <Table.Cell style={style.bgClubColor2}>포함</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>BD Closing(선택참여)</Table.Cell>
                                            <Table.Cell style={style.bgBDColor2}>
                                                불포함
                                                <br/>
                                                (별도 2.5만원)
                                            </Table.Cell>
                                            <Table.Cell style={style.bgClubColor2}>포함</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>MemberShip Fee</Table.Cell>
                                            <Table.Cell style={style.bgBDColor}>190,000 원</Table.Cell>
                                            <Table.Cell style={style.bgClubColor}>220,000 원</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>* 체어로 선발되면 납부한 Membership Fee의 30%를 돌려드립니다 <br/>
                                (1회 이상 활동한 기존 멤버에 한하여 체어 지원이 가능합니다)
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
            <Segment vertical basic style={style.Segment} textAlign={'center'}>
                <Button as="a" href="https://goo.gl/forms/y0Lup0zug38qCTee2" target="_blank" color="blue"
                        content="2018 Fall BD 지원하기"/>
            </Segment>
            {/*<Segment style={{position: 'fixed', bottom: '5vh', right: '5vw', zIndex: 99}} size='big' inverted
                         circular>


                </Segment>*/}
        </div>;
    }
}

Recruit.propTypes = propTypes;
Recruit.defaultProps = defaultProps;

export default Recruit;
