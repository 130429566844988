import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Analytics from "../../middleware/Analytics";
import {firebase} from "../../config/firebase";
import {Container, Segment, Card, Item, Header, Image, Grid, Divider} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {BDCOLOR, BDCOLOR_SUB} from "../../constants/BDBrand";
import BlogCardComponent from "../../components/blog/BlogCardComponent";
import RecruitingNowHorizontalBannerComponent from "../../components/home/RecruitingNowHorizontalBannerComponent";

const db = firebase.firestore()

class BlogMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs: []
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        Analytics.pageViewAll('/blog');

        this.loadBlogList();
    }


    loadBlogList = async () => {
        const blogRef = db.collection('blogs').where('number', '>', 0).orderBy('number', 'desc');
        blogRef
            .get()
            .then(snapshot => {
                const blogs = [];
                snapshot.forEach(doc => {
                    const blog = doc.data();
                    blog.id = doc.id;
                    blogs.push(blog);
                })
                this.setState({
                    blogs
                })
            })
            .catch(e => {
                console.log(e);
            })

    };


    render() {
        return (
            <Fragment>
                <div style={{backgroundColor: BDCOLOR_SUB, paddingTop: 30, paddingBottom: 30}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            BLOG
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            BD 소식
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                BD에서는 어떤 일들이 있었을까요?
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/background/closing2017spring.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>

                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                2017 봄 클로징 - 뒷풀이가 끝나갈 무렵 동틀녘
                            </span>
                        </div>
                    </Container>
                </div>

                <Container as={Segment} vertical style={{marginTop: 50}} basic>
                    <Card.Group itemsPerRow={3} doubling>
                        {
                            this.state.blogs.map((blog, i) => {
                                return (
                                    <BlogCardComponent blog={blog} key={i}/>
                                )
                            })
                        }
                    </Card.Group>
                </Container>

                {/* 모집 중인 시즌 */}
                <RecruitingNowHorizontalBannerComponent/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BlogMainContainer);
