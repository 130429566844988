import {createLogic} from 'redux-logic';
import {eodActionCreators, eodActions} from "./eodStore";
import Network from "../../middleware/Network";

const fetchAllMyEodsLogic = createLogic({
  type: eodActions.FETCH_ALL_MY_EODS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {member} = getState().auth;
    if (!member.id) return done();
    const memberId = member.id;
    const eods = await Network.getAllMyEods(memberId);
    if (!eods.err)
      dispatch(eodActionCreators.fetchAllMyEodsSuccess(eods));
    if (action.cb)
      action.cb();
    done();
  }
});


export default [
  fetchAllMyEodsLogic,
]