import React from 'react'
import BigCalendar from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'

let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k])



let Schedule = props => (
    <BigCalendar
        style={{height: '420px'}}
        views={['month']}
        step={60}
        events={[
            {
                'start': moment('2018-08-19'),
                'end': moment('2018-08-31'),
                'title': '지원기간',
            },
            {
                'start': moment('2018-09-01'),
                'end': moment('2018-09-01'),
                'title': 'ChairOT',
            },
            {
                'start': moment('2018-09-02'),
                'end': moment('2018-09-02'),
                'title': 'Member 선발',
            },
            {
                'start': moment('2018-09-08'),
                'end': moment('2018-09-08'),
                'title': 'OT',
            },
            {
                'start': moment('2018-09-15'),
                'end': moment('2018-09-16'),
                'title': '1st 팀모임',
            },
            {
                'start': moment('2018-09-29'),
                'end': moment('2018-09-30'),
                'title': '2nd 팀모임',
            },
            {
                'start': moment('2018-10-13'),
                'end': moment('2018-10-14'),
                'title': '3rd 팀모임',
            },
            {
                'start': moment('2018-10-27'),
                'end': moment('2018-10-28'),
                'title': '4th 팀모임',
            },
            {
                'start': moment('2018-11-10'),
                'end': moment('2018-11-11'),
                'title': '5th 팀모임',
            },
            {
                'start': moment('2018-11-24'),
                'end': moment('2018-11-25'),
                'title': '6th 팀모임',
            },
            {
                'start': moment('2018-12-08'),
                'end': moment('2018-12-09'),
                'title': '7th 팀모임',
            },
            {
                'start': moment('2018-12-22'),
                'end': moment('2018-12-23'),
                'title': '8th 팀모임',
            },
            {
                'start': moment('2018-11-03'),
                'end': moment('2018-11-03'),
                'title': 'BD Talk',
            },
            {
                'start': moment('2019-01-05'),
                'end': moment('2019-01-05'),
                'title': 'Closing',
            }
        ]}
    />
)

export default Schedule