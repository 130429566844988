import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Segment, Container, Card, Table, Button, Form, Icon} from 'semantic-ui-react';
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

const faqRef = db.collection('config').doc('faqs');

class AdminFaqContainer extends Component {



    constructor(props) {
        super(props);
        this.state = {
            faqs: [],
            isLoaded: false,
        }
    }


    componentDidMount() {
        this._loadFAQ()
    }


    _loadFAQ = () => {
        faqRef
            .get()
            .then(doc => {
                if (!doc.exists) {
                    this.setState({
                        faqs: [],
                        isLoaded: true,
                    })
                    return;
                }
                const {faqs} = doc.data();
                this.setState({
                    faqs: faqs ? faqs : [],
                    isLoaded: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    saveFAQ = () => {
        faqRef
            .set({
                faqs: this.state.faqs
            }, {merge: true})
            .then(() => {
                window.alert('저장되었습니다.')
            })
            .catch(e => {
                console.log(e);
                window.alert('저장에 실패하였습니다.')
            })
    }

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>

                <Segment as={Container} vertical>
                    <Button onClick={this.saveFAQ}>
                        저장하기
                    </Button>
                </Segment>
                <Segment as={Container} vertical>
                    <Form>
                        <Table size={'small'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={2}>카테고리 </Table.HeaderCell>
                                    <Table.HeaderCell width={5}>질문 </Table.HeaderCell>
                                    <Table.HeaderCell width={7}>대답 </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>이동 </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.faqs.map((faq, i) => {
                                        return (
                                            <Table.Row key={i} verticalAlign={'top'}>
                                                <Table.Cell>
                                                    <Form.Input
                                                        label={'카테고리'}
                                                        name={'category'}

                                                        value={faq.category}
                                                        onChange={(e, d) => {
                                                            const {faqs} = this.state;
                                                            faqs[i][d.name] = d.value;
                                                            this.setState({
                                                                faqs
                                                            })
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Form.TextArea
                                                        label={'질문'}
                                                        name={'question'}
                                                        value={faq.question}
                                                        rows={10}
                                                        onChange={(e, d) => {
                                                            const {faqs} = this.state;
                                                            faqs[i][d.name] = d.value;
                                                            this.setState({
                                                                faqs
                                                            })
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Form.TextArea
                                                        label={'대답'}
                                                        name={'answer'}
                                                        value={faq.answer}
                                                        rows={10}
                                                        onChange={(e, d) => {
                                                            const {faqs} = this.state;
                                                            faqs[i][d.name] = d.value;
                                                            this.setState({
                                                                faqs
                                                            })
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button.Group size={'mini'}>
                                                        <Button negative compact onClick={() => {
                                                            const a = window.confirm('삭제하시겠습니까?');
                                                            if (!a) {
                                                                return;
                                                            }
                                                            const faqs = this.state.faqs;
                                                            faqs.splice(i, 1);
                                                            this.setState({
                                                                faqs
                                                            })
                                                        }}>
                                                            삭제
                                                        </Button>
                                                        <Button icon compact onClick={() => {
                                                            if (i === 0) {
                                                                return;
                                                            }
                                                            const faqs = moveItemInArrayFromIndexToIndex(this.state.faqs, i, i - 1);
                                                            this.setState({
                                                                faqs
                                                            })
                                                        }}>
                                                            <Icon name={'angle up'}/>
                                                        </Button>
                                                        <Button icon compact onClick={() => {
                                                            if (i === (this.state.faqs.length - 1)) {
                                                                return;
                                                            }
                                                            const faqs = moveItemInArrayFromIndexToIndex(this.state.faqs, i, i + 1);
                                                            this.setState({
                                                                faqs
                                                            })
                                                        }}>
                                                            <Icon name={'angle down'}/>
                                                        </Button>
                                                    </Button.Group>
                                                </Table.Cell>

                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={4}>
                                        <Button onClick={() => {
                                            this.setState(state => {
                                                let faqs = state.faqs;
                                                faqs.push({
                                                    question: '',
                                                    answer: '',
                                                    category: '',
                                                })
                                                return {
                                                    faqs
                                                }
                                            })
                                        }}>
                                            추가하기
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Form>

                </Segment>
            </Fragment>


        );
    }
}


export default AdminFaqContainer;
