import React, {Component} from 'react';
import {Segment, Container, Grid, Form, Message, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {db, firebase} from "../../config/firebase";
import Analytics from "../../middleware/Analytics";
import {Link} from 'react-router-dom'
import {authActionCreators} from "../../redux/auth/authStore";

class SignUpContainer extends Component {
  state = {
    email: '',
    password: '',
    passwordConfirm: '',
    name: '',
    phoneNumber: '',

    uploading: false,

    message: []
  };


  componentDidMount() {
    Analytics.pageViewAll(`/signup`);
    window.scrollTo(0, 0)
  }


  handleChange = (e, {value, name}) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    this.setState({message: ''});

    let message = [];

    e.preventDefault();
    console.log(this.state);
    if (!this.ValidateEmail(this.state.email)) {
      message.push('email 형식이 잘못되었습니다');
    }
    if (this.state.password.length < 6) {
      message.push('패스워드는 6자 이상으로 작성해주세요');
    }
    if (this.state.password !== this.state.passwordConfirm) {
      message.push('패스워드가 일치하지 않습니다');
    }
    if (this.state.name.length < 1) {
      message.push('이름을 올바르게 입력해주세요');
    }
    if (this.state.phoneNumber.length === null) {
      message.push('전화번호를 입력하세요');
    }
    if (message.length > 0) {
      console.log(message);
      this.setState({
        message: message
      });
      return;
    }

    this.setState({
      uploading: true,
    });

    const {email, password, passwordConfirm, name, phoneNumber} = this.state;

    const data = {
      name,
      email,
      password,
      phoneNumber
    };
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        const user = firebase.auth().currentUser;
        console.log({result});
        user.updateProfile({
          displayName: name,
          testParams: 'text'
        });

        user.sendEmailVerification().then(function () {
          // Email sent.
          window.alert('이메일 인증을 보냈습니다.')
        }).catch(function (error) {
          window.alert('이메일 인증 발송에 실패하였습니다. 재시도해 주세요')
        });

        let newUserData = user.toJSON();
        newUserData.phoneNumber = phoneNumber;

        db.collection('users')
          .doc(user.uid)
          .set(newUserData)
          .then(() => {
            console.log('success');
            this.props.getUserInfo();
            this.props.getMemberInfo();
            this.props.history.push('/mypage')
          })
          .catch(e => {
            console.log(e);
          });


      })
      .catch(error => {
        console.log(error);
        let message = [];
        message.push(error.message)
        this.setState({
          message,
          uploading: false,
        })
      })
  };

  /*logInWithFaceBook = () => {
      let provider = new firebase.auth.FacebookAuthProvider();

      firebase
          .auth()
          .signInWithPopup(provider)
          .then(function (result) {
              // This gives you a Facebook Access Token. You can use it to access the Facebook API.
              let token = result.credential.accessToken;
              // The signed-in user info.
              console.log(token);
              let user = result.user;
              console.log(user);
              // ...
          })
          .catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // The email of the user's account used.
              var email = error.email;
              // The firebase.auth.AuthCredential type that was used.
              var credential = error.credential;
              // ...

              console.log(error);
          });
  }*/

  ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };


  render() {
    const {email, password, passwordConfirm, name, phoneNumber} = this.state;

    return (
      <Grid textAlign="center" style={{height: '90vh'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}} textAlign="left">
          <Form size="large">
            <Segment stacked>
              <Form.Input icon="user" iconPosition="left" placeholder="이름"
                          name="name" value={name}
                          onChange={this.handleChange}/>

              <Form.Input icon="mail" iconPosition="left" placeholder="E-mail address"
                          type="email" name="email" value={email}
                          onChange={this.handleChange}/>
              <Form.Input icon="lock" iconPosition="left" placeholder="password"
                          type="password" name="password" value={password}
                          onChange={this.handleChange}/>
              <Form.Input icon="lock" iconPosition="left" placeholder="password 확인"
                          type="password" name="passwordConfirm"
                          value={passwordConfirm}
                          onChange={this.handleChange}/>

              <Form.Input icon="mobile" iconPosition="left" placeholder="전화번호"
                          name="phoneNumber" value={phoneNumber} type="number"
                          onChange={this.handleChange}/>

              <Button fluid
                      active={this.state.uploading}
                      disabled={this.state.uploading}
                      onClick={this.handleSubmit} color="blue" basic size="large">
                가입하기
              </Button>
            </Segment>
          </Form>
          <Message>
            이미 가입이 되어 있으신가요? <Link to={{pathname: '/signin'}}>여기</Link>를 눌러 로그인 하세요.
          </Message>
          {this.state.message.length > 0 && (
            <Message warning size="small">
              <Message.List>
                {this.state.message.map((message, i) => {
                  return <Message.Item key={i}>{message}</Message.Item>;
                })}
              </Message.List>
            </Message>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {...state};
};
const mapDispatchToProps = {
  ...authActionCreators,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpContainer);
