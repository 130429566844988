import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../../components/index';
import {Segment, Container, Card, Image, Divider, List, Grid, Header, Icon} from 'semantic-ui-react'
import {goalExample} from "../../data/bd_goals";
import {Link} from 'react-router-dom'
import * as BDBrand from "../../constants/BDBrand";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');

const propTypes = {};
const defaultProps = {};

const styles = {
    Segment: {
        margin: "5em 0em"
    },
    saying: {
        color: BDBrand.BDCOLOR,
        fontSize: BDBrand.SAYING_SIZE,
        fontStyle: 'italic',
        lineHeight: '1.5em',
    },
    description: {
        color: BDBrand.BDCOLOR_SUB
    },
    mainContents:{
        lineHeight: '1.7em',
    }
}

class Goal extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        ReactGA.pageview('/program/goal');
    }

    render() {
        return (
            <div>
                Gaol
                <AboutUsBackground imageUrl={'url(/images/background/bd_goal.jpg)'} fontColor='white'>
                    [GOAL] 4개월 동안 집중하고 싶은 목표를 설정하고 달성합니다
                </AboutUsBackground>
                <Segment vertical basic style={styles.Segment} textAlign={'center'}>

                    <Container>
                        <span style={styles.saying}>
                            위대한 인물에게는 목표가 있고 <br/>
                            평범한 인물에게는 소망이 있다.
                        </span>
                        <br/>
                        <span>
                            - 워싱턴 어빙 -
                        </span>
                    </Container>

                </Segment>

                {/* Why */}
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='목표를 왜 관리하나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <Grid.Column width={11} style={styles.mainContents}>
                                        내가 바라는 삶은 소망 만으로 이루어 지지 않습니다. <br/>
                                        현재 나의 모습과 차이를 좁혀야 합니다.<br/>
                                        누구도 한번에 차이를 좁힐 수는 없습니다.<br/>
                                        실행 가능한 목표를 세우고 하면서 단계적으로 성장 할 필요가 있습니다.<br/>
                                    </Grid.Column>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                {/* How */}

                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='목표를 어떻게 관리하나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11} style={styles.mainContents}>
                                    단기에 집중할 목표를 세우고, 달성하는 것이 중요합니다. <br/>
                                    여러 개의 목표를 동시에 추진 하는 것보다, <br/>
                                    꼭 이뤄야 하는 목표를 설정하고 집중해야 합니다.<br/>
                                    <br/>
                                    실천은 혼자보다 함께 하는 것이 효과적입니다.<br/>
                                    BD에서는 팀원들과 함께 목표를 위한 노력을 공유하며 추진력을 얻습니다.<br/>
                                    4개월 동안 집중한다면 짜릿한 성취감을 맞볼 수 있습니다.<br/>


                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                {/* How to */}
                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='BD에서는 어떻게 하나요?'/>
                                </Grid.Column>
                                <Grid.Column width={11} style={styles.mainContents}>
                                    4개월 후의 예상 성과를 구체화해서 목표를 설정하고 함께 실천합니다. <br/>
                                    Being&Doing은 목표설정 가이드를 통해 목표를 구체화 하도록 돕습니다. <br/>
                                    Being&Doing 팀 모임에서 목표달성도를 공유하고, 지속할 힘을 얻습니다. <br/>
                                    <br/><br/>
                                    <Grid stackable columns={2}>
                                        <Grid.Column width={10}>
                                            <Header content={'목표 설정 Process'}/>
                                            1. 내가 꿈꾸는 삶의 모습은 무엇인가? <br/>
                                            2. 현재 나의 모습은 어떤가?<br/>
                                            3. 이상과 현실의 차이를 좁히기 위해 무엇이 필요한가?<br/>
                                            4. 4개월 뒤에 어떤 성과를 얻고 싶은가?<br/>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Image src={'/images/program/goal/goalGuide.jpg'}/>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid stackable columns={2}>
                                        <Grid.Column width={10}>
                                        <Header content={'실천 사항 Tracking'}/>
                                            1. 매주 나의 목표 달성도를 체크하며, 계획에 얼마나 가까워 졌는지 확인합니다. <br/>
                                            3. 성과를 높이기 위한 전략을 팀원과 공유합니다. <br/>
                                            4. 4개월 뒤 계획했던 목표에 근접한 자신을 발견합니다. <br/>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Image src={'/images/program/goal/goalGraph.jpg'}/>
                                        </Grid.Column>

                                    </Grid>




                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

                <Container>
                    <Divider/>
                </Container>

                <Segment vertical basic style={styles.Segment}>
                    <Container>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Header content='목표 달성 이야기'/>
                                    <span style={styles.description}>
                                        Closing에서 목표를 얼만큼 이루었는지 이야기 합니다
                                    </span>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <Card.Group itemsPerRow={4} doubling>
                                        {
                                            goalExample.map((example, i) => {
                                                return (
                                                    <Card key={i} style={{borderRadius: '0px', border: 'none'}} basic>
                                                        <Image src={example.image}
                                                               style={{borderRadius: '0px', border: 'none'}}/>
                                                        <Card.Content>
                                                            <Card.Header>{example.name}</Card.Header>
                                                            <Card.Meta>{example.comment}</Card.Meta>
                                                            <Card.Description></Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>
                <Segment vertical basic style={styles.Segment}>
                    <Container textAlign={'center'} text>
                        <Link to={'/program/essay'} style={{marginLeft: 20}}>
                            <Icon name={'left chevron'} size={'big'}/>
                            Essay에 대해서 알아볼까요
                        </Link>
                        <Link to={'/program/eod'} style={{marginLeft: 20}}>
                            EOD에 대해서 알아볼까요
                            <Icon name={'right chevron'} size={'big'}/>
                        </Link>

                    </Container>
                </Segment>
                <Segment basic vertical>

                </Segment>
            </div>
        );
    }
};

Goal.propTypes = propTypes;
Goal.defaultProps = defaultProps;

export default Goal;
