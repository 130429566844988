import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import moment from "moment/moment";
import {Container, Segment, Card, Header, Loader, Label, Grid, Divider, Image} from 'semantic-ui-react'
import {withLineEnter} from "../../middleware/stringHandler";
import {Link, withRouter} from 'react-router-dom'
import {BDCOLOR_SUB} from "../../constants/BDBrand";
import BlogCardComponent from "../../components/blog/BlogCardComponent";
import Analytics from "../../middleware/Analytics";
import RecruitingNowHorizontalBannerComponent from "../../components/home/RecruitingNowHorizontalBannerComponent";

const blogRef = db.collection('blogs');

class BlogReadContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

            body: [],
            image: '',
            imageFirebase: {},
            number: parseInt(moment().format('YYYYMMDDHHmmss'), 10),

            subTitle: '',
            title: '',
            date: moment().format('YYYY-MM-DDTHH:mm'),

            isLoaded: false,

            prevBlogs: [],
            nextBlogs: [],
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        const {blogId} = this.props.match.params;
        Analytics.pageViewAll(`/blog/${blogId}`);
        this._loadBlogFromFirebase(blogId)
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.location !== this.props.location) {
            this.setState({
                isLoaded: false
            })
            window.scrollTo(0, 0);
            this._loadBlogFromFirebase(nextProps.match.params.blogId);
        }
    }

    _loadBlogFromFirebase = async (blogId) => {

        blogRef
            .doc(blogId)
            .get()
            .then(doc => {
                if (!doc.exists) {
                    window.alert('문서를 찾을 수 없습니다.');
                    this.props.history.goBack();
                    return;
                }
                const {
                    body,
                    image,
                    imageFirebase,
                    number,

                    subTitle,
                    title,
                    date,
                } = doc.data();

                if (number < 1) {
                    window.alert('문서를 찾을 수 없습니다.');
                    this.props.history.goBack();
                    return;
                }

                this.setState({
                    body,
                    image,
                    imageFirebase,
                    number,

                    subTitle,
                    title,
                    date: moment(date.seconds * 1000).format('YYYY-MM-DDTHH:mm'),

                    isLoaded: true,
                })
                this._loadPrevBlog(doc)
                this._loadNextBlog(doc)
            })
            .catch(e => {
                console.log(e);
                window.alert('문서를 불러오는데 오류가 발생했습니다. 재시도해 주세요');
                this.props.history.goBack();
                return;
            })
    }

    _loadPrevBlog = async (selectedDoc) => {
        blogRef
            .orderBy('number')
            .startAfter(selectedDoc)
            .limit(2)
            .get()
            .then(snapshot => {
                const prevBlogs = [];
                snapshot.forEach(doc => {
                    const blog = doc.data();
                    blog.id = doc.id;
                    prevBlogs.push(blog);
                })
                this.setState({
                    prevBlogs
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    _loadNextBlog = async (selectedDoc) => {
        blogRef
            .orderBy('number', "desc")
            .startAfter(selectedDoc)
            .limit(2)
            .get()
            .then(snapshot => {
                const nextBlogs = [];
                snapshot.forEach(doc => {
                    const blog = doc.data();
                    blog.id = doc.id;
                    nextBlogs.push(blog);
                })
                this.setState({
                    nextBlogs
                })
            })
            .catch(e => {
                console.log(e);
            })
    }


    render() {
        if (!this.state.isLoaded) {
            return (
                <Loader active/>
            )
        }
        return (
            <Fragment>
                <Segment vertical basic>
                    <div
                        className={'animated fadeIn slow'}
                        style={{
                            backgroundImage: `url(${this.state.image})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundColor: BDCOLOR_SUB,
                            marginLeft: 0,
                            marginRight: 0,
                            minHeight: '40vh'
                        }}>
                    </div>
                    <Container text style={{marginTop: 50}} className={'animated fadeIn slow delay-1s'}>

                        <Header content={this.state.title}
                                subheader={this.state.subTitle}
                                size={'large'}
                                color={'blue'}/>
                        <Label>
                            {moment(this.state.date).format('YYYY. MM. DD')}
                        </Label>

                        {
                            this.state.body.map((body, i) => {
                                return (
                                    <Segment vertical basic key={i} style={{marginTop: 30}}>
                                        <Header content={body.header.content !== '' ? body.header.content : null}
                                                subheader={body.subHeader.content !== '' ? body.subHeader.content : null}/>
                                        <Image src={body.image}/>
                                        <p style={{lineHeight: '180%', fontSize: 'medium'}}>
                                            {withLineEnter(body.body.content)}
                                        </p>

                                    </Segment>
                                )
                            })
                        }
                    </Container>
                </Segment>
                {/* 모집 중인 시즌 */}
                <RecruitingNowHorizontalBannerComponent/>

                {
                    (this.state.prevBlogs.length > 0) && (
                        <Segment vertical basic style={{marginTop: 50}} className={'animated fadeIn slow delay-1s'}>
                            <Container text>
                                <Header>
                                    이전글
                                </Header>
                                <Card.Group itemsPerRow={2} doubling>
                                    {
                                        this.state.prevBlogs.map((blog, i) => {
                                            return (
                                                <BlogCardComponent blog={blog} key={i}/>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Container>
                        </Segment>
                    )
                }
                {
                    (this.state.nextBlogs.length > 0) && (
                        <Segment vertical basic style={{marginTop: 50}} className={'animated fadeIn slow delay-1s'}>
                            <Container text>
                                <Header>
                                    다음 글
                                </Header>
                                <Card.Group itemsPerRow={2} doubling>
                                    {
                                        this.state.nextBlogs.map((blog, i) => {
                                            return (
                                                <BlogCardComponent blog={blog} key={i}/>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Container>
                        </Segment>
                    )
                }

            </Fragment>
        );
    }
}


export default withRouter(BlogReadContainer);
