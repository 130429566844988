const teams = [
    "/images/team_2.jpg",
    "/images/team_1.jpg",
    "/images/team_4.jpg",
    "/images/team_3.jpg",
    "/images/team_5.jpg",
];

const wholeMeeting =[
    "images/team_closing.jpg",
    "/images/team_6.jpg",
    "images/team_ot.jpg"
]

export {teams, wholeMeeting}