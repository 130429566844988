import React, {Component} from 'react';
import moment from "moment/moment";
import numeral from "numeral";
import {Segment, Loader, Image, Item, Container, Table, Progress, Card} from 'semantic-ui-react'


class GoalComponent extends Component {

  render() {
    const {goal} = this.props;
    return (
      <Item style={{backgroundColor: 'white', padding: '1rem', borderRadius: '1rem', ...this.props.style}}
            onClick={() => {
              this.props.onClick && this.props.onClick();
            }}>
        <Item.Image
          size={'tiny'}
          src={(goal.images && goal.images.length > 0) ? goal.images[0].imageUrl : '/images/image.png'}
          style={{}}
        />
        <Item.Content>
          <Item.Header
            content={goal.title}
            style={{
              fontSize: '1rem'
            }}/>
          <Item.Description
            style={{
              color: 'grey',
            }}>
            기간: {moment(goal.startDate.seconds * 1000).format('YYYY. MM. DD (ddd)')} - {moment(goal.endDate.seconds * 1000).format('YYYY. MM. DD (ddd)')} ({numeral(moment(goal.endDate.seconds * 1000).diff(goal.startDate.seconds * 1000, 'day')).format('0,0')}일)
          </Item.Description>
          {
            moment().isSameOrBefore(moment(goal.endDate.seconds * 1000), 'day') && (
              <Item.Meta>
                <Progress
                  percent={(moment().diff(goal.startDate.seconds * 1000, 'day') / moment(goal.endDate.seconds * 1000).diff(goal.startDate.seconds * 1000, 'day') * 100).toFixed(0)}
                  progress
                  color={'blue'}
                  size={'small'}
                  style={{
                    marginBottom: 0
                  }}/>
              </Item.Meta>
            )
          }

        </Item.Content>
      </Item>
    );
  }
}


export default GoalComponent;
