import React, {Component} from 'react';
import {Container, Divider, Segment, Form, Image, Button,} from 'semantic-ui-react';
import {db} from "../../config/firebase";
import {imageUploadToFirebase} from "../../middleware/firebaseImageHandler";

class AdminTalkMakeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            talk: {},
            isLoaded: false,
        }
    }


    componentDidMount() {

    }


    saveTalk = () => {
        const talkId = this.state.talk.id;

        const talkRef = db.collection('talks').doc(talkId);
        const newTalkData = this.state.talk;

        newTalkData.date = new Date(newTalkData.date);

        talkRef
            .set(newTalkData, {merge: true})
            .then(() => {
                window.alert('저장되었습니다');
                this.props.history.push('/admin/talk')
            })
            .catch(e => {
                console.log(e);
            })
    };

    onMainImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'blogImage'

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);


            const talk = this.state.talk;
            talk.image = image;
            talk.imageFirebase = imageFirebase;

            this.setState({
                talk
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

            const talk = this.state.talk;
            talk.image = '';
            talk.imageFirebase = {};

            this.setState({
                talk
            })
        }
    }


    render() {
        return (
            <Container>
                <Form>
                    <Form.Input
                        label={'id'}
                        name={'id'}
                        value={this.state.talk.id}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />
                    <Form.Input
                        label={'title'}
                        name={'title'}
                        value={this.state.talk.title}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'subTitle'}
                        name={'subTitle'}
                        value={this.state.talk.subTitle}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'date'}
                        name={'date'}
                        type={'datetime-local'}
                        value={this.state.talk.date}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'speakerName'}
                        name={'speakerName'}
                        value={this.state.talk.speakerName}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'number'}
                        name={'number'}
                        value={this.state.talk.number}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'seasonId'}
                        name={'seasonId'}
                        value={this.state.talk.seasonId}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'seasonTitle'}
                        name={'seasonTitle'}
                        value={this.state.talk.seasonTitle}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'link'}
                        name={'link'}
                        value={this.state.talk.link}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />
                    <Form.Input
                        label={'image'}
                        name={'image'}
                        value={this.state.talk.image}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />

                    <Form.Input
                        label={'youTubeId'}
                        name={'youTubeId'}
                        value={this.state.talk.youTubeId}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />


                    <Form.TextArea
                        autoHeight
                        label={'description'}
                        name={'description'}
                        value={this.state.talk.description}
                        onChange={(e, d) => {
                            const talk = this.state.talk;
                            talk[d.name] = d.value;
                            this.setState({
                                talk
                            })
                        }}
                    />
                    {
                        this.state.talk.image && (
                            <Image src={this.state.talk.image} onClick={() => {
                                const a = window.confirm('삭제하시겠습니까?')
                                if (!a) {
                                    return;
                                }
                                const talk = this.state.talk;
                                talk.image = '';
                                talk.imageFirebase = {};
                                this.setState({
                                    talk
                                })

                            }}/>
                        )
                    }

                    <Form.Field>
                        <label>메인 사진 추가</label>
                        <input type="file" onChange={e => {
                            this.onMainImageUpload(e)
                        }}
                               accept=".jpg, .jpeg, .png, .heic"/>
                    </Form.Field>

                </Form>
                <Divider/>
                <Button onClick={() => {
                    const a = window.confirm('수정하시겠습니까?');
                    if (!a) {
                        return;
                    }
                    this.saveTalk();
                }}>
                    저장
                </Button>
                <Button
                    negative
                    onClick={() => {
                        const a = window.confirm('삭제하시겠습니까? \n 되돌릴 수 없습니다.');
                        if (!a) {
                            return;
                        }
                        this.deleteTalk();
                    }}>
                    삭제
                </Button>
            </Container>
        );
    }
}


export default AdminTalkMakeContainer;
