import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground, AboutUsVisionStatement} from '../components';
import {Segment, Container, Header, Grid, Card, List, Responsive} from 'semantic-ui-react';
import {mission, belief, principle} from '../data/vision';
import {BDCOLOR} from "../constants/BDBrand";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');



const propTypes = {};
const defaultProps = {};

const style = {
    Segment: {
        margin: "5em 0em"
    }
}

class AboutUs extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/aboutus');
    }


    render() {
        return (
            <div>
                <AboutUsBackground imageUrl={'url(/images/background/aboutus_domore_resized.jpg)'}/>

                {/*<AboutUsVisionStatement/>*/}
                <Segment vertical basic style={style.Segment}>
                    <Container text>
                        <Header content="Mission"/>
                        <span>{mission}</span>
                    </Container>
                </Segment>
                <Segment vertical basic style={style.Segment}>
                    <Container text>
                        <Header content="Belief"/>
                        <span>사람이 바뀌는 방법은 3가지뿐이다.</span>
                        <List bulleted>
                            {belief.map((belief, i) => {
                                return (
                                    <List.Item key={i}>{belief}</List.Item>
                                )
                            })}
                        </List>
                        <span> 사람은 이 방법이 아니면 바뀌지 않는다. <br/>'새로운 결심을 하는 것'은 가장 무의미한 행위다. </span> <br/>
                        <span> <i>- 오마에 겐이치</i></span> <br/> <br/>
                        <span> BD는 시간을 달리 쓰게 하고, 새로운 곳에 가게 하며, 새로운 사람을 만나게 합니다. </span> <br/>


                    </Container>
                </Segment>

                <Segment vertical basic style={style.Segment}>
                    <Container text>
                        <Header content="Being & Doing"/>
                        <Grid columns={2} stackable>
                            <Grid.Column>
                                <span style={{color: BDCOLOR}}>Being</span> <br/>
                                <span>
                                    스스로 질문을 던지고 나만의 답을 찾습니다. <br/>
                                    정돈된 생각을 Essay에 담습니다. <br/>
                                </span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{color: BDCOLOR}}>Doing</span> <br/>
                                <span>
                                    4개월의 구체적인 목표를 설정합니다. <br/>
                                    매일, 매주 목표를 실천하며 목표를 달성합니다. <br/>
                                    목표 달성을 반복하여, 꿈에 다가갑니다.<br/>
                                </span>
                            </Grid.Column>
                        </Grid>

                    </Container>
                </Segment>

                <Segment vertical basic style={style.Segment}>
                    <Container text>
                        <Header content="Schedule"/>
                        <span>
                            1년에 두 번의 시즌이 진행됩니다. <br/>
                            시즌은 4개월로 진행됩니다. (3월-6월), (9월-12월) <br/>
                        </span>
                    </Container>
                </Segment>
                <Segment vertical basic style={style.Segment}>
                    <Container text>

                        <Header content="Team Meeting"/>
                        <span>
                            4-5명의 멤버가 하나의 팀을 이룹니다. <br/>
                            16주 동안 2주에 한번씩! 총 8번!, <br/>
                            주말 아침 팀모임에서 에세이와 목표를 공유합니다. <br/>
                            모든 모임에는 체어가 있으며 체어는 멤버들의 활동을 격려하고 응원합니다.
                        </span>
                    </Container>

                </Segment>


            </div>
        );
    }
};

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;
