import React, {Component, Fragment} from 'react';
import AdminSubMenuComponent from '../../components/admin-home/AdminSubMenuComponent'
import {db} from "../../config/firebase";
import {Container, Segment, Image} from 'semantic-ui-react'

class AdminGoalMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            goals: []
        }

    }


    componentDidMount() {
        this._loadAllGoals();
    }

    _loadAllGoals = () => {

        const startDate = new Date('1999-01-01');
        const endDate = new Date('2000-01-01');
        db.collection('goals')
            .where('startDate', "<" ,endDate)
            .get()
            .then(snapshot => {
                const goals = [];

                snapshot.forEach(doc => {
                    let goal = doc.data();
                    goal.id = doc.id;
                    goals.push(goal);

                })

                this.setState({
                    goals
                })
            })
    }



    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Segment>
                    {JSON.stringify(this.state.goals)}
                </Segment>
            </Fragment>
        );
    }
}


export default AdminGoalMainContainer;
