import React, {Component} from 'react';
import {connect} from 'react-redux';
import Analytics from "../../middleware/Analytics";
import moment from 'moment';
import {Segment, Button, Container, Grid, Header, Card, Image} from 'semantic-ui-react'
import {BDCOLOR, BDCOLOR_SUB} from "../../constants/BDBrand";
import {Link} from 'react-router-dom';
import {firebase} from "../../config/firebase";
import SeasonCardComponent from '../../components/home/SeasonCardComponent'
import ReviewComponent from '../../components/home/ReviewComponent'
import BlogPreviewComponent from '../../components/home/BlogPreviewComponent'
import RecruitingNowHorizontalBannerComponent from "../../components/home/RecruitingNowHorizontalBannerComponent";

const db = firebase.firestore();

class HomeContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seasons: [],
            seasonsNowRecruiting: [],
            seasonsIng: [],
            allReviews: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        Analytics.pageViewAll('/')
        this.loadSeasons();
    };

    loadSeasons = async () => {
        const seasonsRef = db.collection('seasons').orderBy('number', 'desc');
        seasonsRef
            .get()
            .then(snapshot => {
                const seasons = [];
                const seasonsNowRecruiting = [];
                const seasonsIng = [];

                snapshot.forEach(doc => {
                    // console.log(doc.id, '=>', doc.data())

                    const season = doc.data();
                    season.id = doc.id


                    if (season.isRecruiting) {
                        seasonsNowRecruiting.push(season)
                    } else if (season.isOngoing) {
                        seasonsIng.push(season);
                    } else {
                        seasons.push(season);
                    }

                });

                this.setState({
                    seasons: seasons,
                    seasonsNowRecruiting: seasonsNowRecruiting,
                    seasonsIng: seasonsIng
                })
            })
            .catch(e => {
                console.log(e)
            });
    }


    render() {
        return (
            <div style={{}}>
                <div style={{
                    backgroundImage: `url(/images/background/closingNavyFilter.jpg)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundColor: BDCOLOR_SUB,
                    marginLeft: 0,
                    marginRight: 0,
                }}>
                    <Grid as={Container} stackable>
                        <Grid.Row columns={2} verticalAlign={'middle'} style={{minHeight: '50vh'}}>
                            <Grid.Column
                                style={{}}
                                verticalAlign={'middle'}
                                className={'headerGrid'}>
                                <Header inverted size={'huge'}>
                                    <Header.Subheader className={'animated fadeIn slower'}
                                                      style={{fontWeight: '700', fontSize: '1.3rem'}}>
                                        내가 바라는 나의 미래를 실현하기 위해 <br/>
                                        노력하는 사람들의 4개월 모임 <br/>
                                        <br/>
                                    </Header.Subheader>
                                    <span className={'animated fadeIn slower delay-1s'}
                                          style={{fontSize: '3rem', fontWeight: '900'}}>
                                        Being&Doing
                                    </span>
                                </Header>
                                <div className={'animated fadeIn slower delay-1s'}>
                                    <Button inverted size={'large'} as={Link} to={{pathname: '/about'}}>
                                        BD가 무엇인가요?
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

                {/* 소식 미리보기 */}
                <BlogPreviewComponent/>


                {/* 모집 중인 시즌 */}
                <RecruitingNowHorizontalBannerComponent/>

                {/* 진행중인 시즌*/}
                {
                    this.state.seasonsIng.length > 0 && (
                        <Container as={Segment} vertical style={{marginTop: 100}} basic>
                            <Header>
                                진행중인 시즌
                            </Header>
                            <Card.Group itemsPerRow={3} doubling>
                                {
                                    this.state.seasonsIng.slice(0, 9).map((season, i) => {
                                        return (
                                            <Card key={i}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        {season.title}
                                                    </Card.Header>
                                                    <Card.Description>
                                                        {season.number}기
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        )
                                    })
                                }
                            </Card.Group>
                        </Container>
                    )

                }

                <ReviewComponent/>

                {/* 종료된 시즌 */}
                <Container as={Segment} vertical style={{marginTop: 100}} basic>
                    <Header>
                        종료된 시즌
                    </Header>
                    <Card.Group itemsPerRow={3} doubling>
                        {
                            this.state.seasons.slice(0, 9).map((season, i) => {
                                return (
                                    <Card key={i}>
                                        <Card.Content>
                                            <Card.Header>
                                                {season.title}
                                            </Card.Header>
                                            <Card.Description>
                                                {season.number}기
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeContainer);
