import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Segment, Loader, Container, Image, Card, Form, Header, Table, Button} from 'semantic-ui-react'
import DevTool from "../../middleware/DevTool";
import _ from 'lodash';

class AdminTeamDetailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            team: {
                title: '',
                seasonId: '',
                chair: {
                    name: ''
                },
                chairId: '',
                membersIds: [],
                membersIdsObject: {},
                createdAt: new Date(),
                modifiedAt: new Date(),
            },
            members: [],

            searchedMember: [],
            searchName: '',
            searchMode: false,

            searchedChair: [],
            searchNameChair: '',
            searchModeChair: false,
        }
    }

    componentDidMount() {
        this._loadTeamData();
        window.scrollTo(0, 0)
    }

    _searchMembers = () => {
        const {searchName} = this.state;
        db.collection('members')
            .where('name', '==', searchName)
            .get()
            .then(snapshot => {
                let searchedMember = [];
                snapshot.forEach(doc => {
                    let member = doc.data();
                    member.id = doc.id;
                    searchedMember.push(member);
                })
                this.setState({
                    searchedMember
                })
            })
    }

    _addMember = (member) => {
        const {team} = this.state;

        DevTool.log(team);

        let {membersIds, membersIdsObject} = team;

        if ((membersIds === null) || (membersIds === undefined)) membersIds = [];
        if ((membersIdsObject === null) || (membersIdsObject === undefined)) membersIdsObject = {};

        membersIds.push(member.id);
        membersIdsObject[member.id] = 1;

        DevTool.log({
            membersIds,
            membersIdsObject
        });

        let {teamsIds, teamsIdsObject} = member;
        if (!teamsIds) teamsIds = [];
        if (!teamsIdsObject) teamsIdsObject = {};

        teamsIds.push(team.id);
        teamsIdsObject[team.id] = 1;

        db.collection('members')
            .doc(member.id)
            .update({
                teamsIds,
                teamsIdsObject,
            })

        db.collection('teams')
            .doc(team.id)
            .update({
                membersIds: membersIds,
                membersIdsObject: membersIdsObject,
            })
            .then(() => {
                this._loadTeamData()
            })
            .catch(e => {
                console.log(e);
            })
    };

    _removeMember = (member) => {
        const {teamId} = this.props.match.params;

        let {teamsIds, teamsIdsObject} = member;

        teamsIds = _.without(teamsIds, teamId);
        teamsIdsObject[teamId] = null;

        db.collection('members')
            .doc(member.id)
            .update({
                teamsIds,
                teamsIdsObject
            })
            .then(() => {
                DevTool.log('멤버 데이터에서 팀 정보 삭제')
            })
            .catch(e => {
                console.log(e);
            })


        let {membersIds, membersIdsObject} = this.state.team;

        membersIds = _.without(membersIds, member.id);
        membersIdsObject[member.id] = null;

        db.collection('teams')
            .doc(teamId)
            .update({
                membersIds,
                membersIdsObject,
            })
            .then(() => {
                this._loadTeamData();
            })
            .catch(e => {
                console.log(e);
            })
    };

    _loadMembers = () => {
        const {teamId} = this.props.match.params;
        db.collection('members')
            .where(`teamsIdsObject.${teamId}`, '>', 0)
            .get()
            .then(snapshot => {
                let members = [];
                snapshot.forEach(doc => {
                    let member = doc.data();
                    member.id = doc.id;
                    members.push(member);
                })
                this.setState({
                    members,
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    _searchChair = () => {
        const {searchNameChair} = this.state;
        db.collection('members')
            .where('name', '==', searchNameChair)
            .get()
            .then(snapshot => {
                let searchedChair = [];
                snapshot.forEach(doc => {
                    let member = doc.data();
                    member.id = doc.id;
                    searchedChair.push(member);
                })
                this.setState({
                    searchedChair
                })
            })
    }

    _addChair = (member) => {
        const {teamId} = this.props.match.params;

        const {team} = this.state;

        team.chair = {
            id: member.id,
            name: member.name,
        }

        this.setState({
            team,
            searchModeChair: false
        })


        let {teamsIdsAsChair, teamsIdsObjectAsChair} = member;
        if (!teamsIdsAsChair) teamsIdsAsChair = [];
        if (!teamsIdsObjectAsChair) teamsIdsObjectAsChair = {};

        teamsIdsAsChair.push(team.id);
        teamsIdsObjectAsChair[team.id] = 1;

        db.collection('members')
            .doc(member.id)
            .update({
                teamsIdsAsChair,
                teamsIdsObjectAsChair,
            });

        db.collection('teams')
            .doc(teamId)
            .update({
                chair: team.chair
            })
            .then(() => {
                this._loadTeamData()
            })
            .catch(e => {
                console.log(e)
            })
    };

    _removeChair = (memberInfoOfChair) => {
        const {team} = this.state;
        team.chair = {}

        this.setState({
            team,
        });

        let {teamsIdsAsChair, teamsIdsObjectAsChair} = memberInfoOfChair;

        teamsIdsAsChair = _.without(teamsIdsAsChair, team.id);
        teamsIdsObjectAsChair[team.id] = null;


        db.collection('members')
            .doc(memberInfoOfChair.id)
            .update({
                teamsIdsAsChair,
                teamsIdsObjectAsChair
            })

        const {teamId} = this.props.match.params;
        db.collection('teams')
            .doc(teamId)
            .update({
                chair: team.chair
            })
            .then(() => {
                this._loadTeamData()
            })
            .catch(e => {
                console.log(e)
            })
    }

    _loadTeamData = async () => {
        const {teamId} = this.props.match.params;
        db.collection('teams')
            .doc(teamId)
            .get()
            .then(doc => {
                const team = doc.data();
                team.id = doc.id;

                DevTool.log({team});

                let memberInfoOfChair = {};


                // 체어 정보 가져오기
                if (team.chair && team.chair.id) {
                    db.collection('members')
                        .doc(team.chair.id)
                        .get()
                        .then(doc => {
                            memberInfoOfChair = doc.data();
                            memberInfoOfChair.id = doc.id;
                            console.log({memberInfoOfChair});
                            this.setState({
                                memberInfoOfChair
                            })
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }

                // 팀원 정보 가져오기
                this._loadMembers()

                this.setState({
                    team,
                    memberInfoOfChair,
                    isLoaded: true,
                })
            })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div>
                    <Loader active/>
                </div>
            )
        }

        const {
            team
        } = this.state;
        return (
            <Fragment>
                <Segment vertical as={Container}>
                    <Header>
                        체어
                    </Header>
                    {
                        (this.state.team.chair && this.state.team.chair.id && this.state.memberInfoOfChair.id) ? (
                            <Fragment>
                                <Card>
                                    <Image src={this.state.memberInfoOfChair.image}/>
                                    <Card.Content>
                                        <Card.Header>
                                            {team.chair.name}
                                        </Card.Header>
                                    </Card.Content>
                                </Card>
                                <Button onClick={() => {
                                    this._removeChair(this.state.memberInfoOfChair)
                                }}>
                                    체어 삭제
                                </Button>
                            </Fragment>
                        ) : (
                            <Button onClick={() => {
                                this.setState({
                                    searchModeChair: true
                                })
                            }}>
                                체어 추가
                            </Button>
                        )
                    }
                </Segment>
                {
                    this.state.searchModeChair && (
                        <Fragment>
                            <Segment vertical as={Container}>
                                <Form>
                                    <Form.Input
                                        value={this.state.searchNameChair}
                                        onChange={(e, d) => {
                                            this.setState({
                                                searchNameChair: d.value
                                            })
                                        }}
                                    />
                                    <Button onClick={() => {
                                        this.setState({
                                            searchModeChair: true,
                                        })
                                        this._searchChair()
                                    }}>
                                        검색
                                    </Button>
                                    <Button onClick={() => {
                                        this.setState({
                                            searchModeChair: false,
                                        })
                                    }}>
                                        닫기
                                    </Button>
                                </Form>
                            </Segment>
                            <Segment vertical as={Container}>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>번호</Table.HeaderCell>
                                            <Table.HeaderCell>이름</Table.HeaderCell>
                                            <Table.HeaderCell>이메일</Table.HeaderCell>
                                            <Table.HeaderCell>-</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            this.state.searchedChair.map((member, i) => {
                                                return (
                                                    <Table.Row key={i}>
                                                        <Table.Cell>{i + 1}</Table.Cell>
                                                        <Table.Cell>{member.name}</Table.Cell>
                                                        <Table.Cell>{member.email}</Table.Cell>
                                                        <Table.Cell>
                                                            <Button onClick={() => {
                                                                this._addChair(member)
                                                            }}>
                                                                체어로 설정
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Fragment>
                    )
                }
                <Segment vertical as={Container}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>번호</Table.HeaderCell>
                                <Table.HeaderCell>이름</Table.HeaderCell>
                                <Table.HeaderCell>이메일</Table.HeaderCell>
                                <Table.HeaderCell>-</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.members.map((member, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{i + 1}</Table.Cell>
                                            <Table.Cell>{member.name}</Table.Cell>
                                            <Table.Cell>{member.email}</Table.Cell>
                                            <Table.Cell>
                                                <Button basic onClick={() => {
                                                    this._removeMember(member)
                                                }}>
                                                    [삭제하기]
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.Cell columns={4}>
                                    <Button onClick={() => {
                                        this.setState({
                                            searchMode: true
                                        })

                                    }}>
                                        멤버 추가하기
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Segment>

                {
                    this.state.searchMode && (
                        <Fragment>
                            <Segment vertical as={Container}>
                                <Form>
                                    <Form.Input
                                        value={this.state.searchName}
                                        onChange={(e, d) => {
                                            this.setState({
                                                searchName: d.value
                                            })
                                        }}
                                    />
                                    <Button onClick={() => {
                                        this.setState({
                                            searchMode: true,
                                        })
                                        this._searchMembers()
                                    }}>
                                        검색
                                    </Button>
                                    <Button onClick={() => {
                                        this.setState({
                                            searchMode: false,
                                        })
                                    }}>
                                        닫기
                                    </Button>
                                </Form>
                            </Segment>
                            <Segment vertical as={Container}>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>번호</Table.HeaderCell>
                                            <Table.HeaderCell>이름</Table.HeaderCell>
                                            <Table.HeaderCell>이메일</Table.HeaderCell>
                                            <Table.HeaderCell>-</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            this.state.searchedMember.map((member, i) => {
                                                return (
                                                    <Table.Row key={i}>
                                                        <Table.Cell>{i + 1}</Table.Cell>
                                                        <Table.Cell>{member.name}</Table.Cell>
                                                        <Table.Cell>{member.email}</Table.Cell>
                                                        <Table.Cell>
                                                            <Button onClick={() => {
                                                                this._addMember(member)
                                                            }}>
                                                                추가하기
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Fragment>
                    )
                }
            </Fragment>
        );
    }
}


export default AdminTeamDetailContainer;
