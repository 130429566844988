import React, {Component} from 'react';
import {AboutUsBackground} from '../components';
import {Grid, Segment, Container, Header, Item, Button, Icon, Label, List, Divider} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {p1Program} from "../data/programs";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');


class Program extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/program');
    }

    render() {
        return (
            <div>
                <AboutUsBackground imageUrl={'url(/images/background/bd_program.jpg)'} fontColor='black'>
                    내 삶의 주인이 되기 위해 <br/>
                    내가 바라는 나의 미래를 <br/>
                    생각하고(Being) 실천합니다(Doing)
                </AboutUsBackground>


                {/* 프로그램들을 하나씩 나열 */}
                {p1Program.map((programGroup, i) => {
                    return (
                        <Segment vertical basic key={i} style={{marginTop: '1em', marginBottom: '1em'}}>
                            <Container text>
                                <Header content={programGroup.programGroupTitle}/>
                                {
                                    programGroup.programs.map((program, j) => {
                                        return (
                                            <Segment basic key={j} style={{backgroundColor: '#F7F7F7'}}>
                                                <Item.Group>
                                                    <Item>
                                                        <Item.Image src={program.programImage}/>
                                                        <Item.Content>
                                                            <Item.Header as='a'>{program.title}</Item.Header>
                                                            <Item.Meta>
                                                                <span className="meta">{program.description}</span>
                                                            </Item.Meta>
                                                            <Item.Description>
                                                                <List bulleted>
                                                                    {
                                                                        program.contents.map((contents, i) => {
                                                                            return (
                                                                                <List.Item
                                                                                    key={i}>{contents}</List.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </List>
                                                            </Item.Description>
                                                            <Item.Extra>
                                                                <Button primary
                                                                        floated='right'
                                                                        as={Link}
                                                                        to={'/program/'+program.title}
                                                                >
                                                                    더 알아보기
                                                                    <Icon name='right chevron'/>
                                                                </Button>
                                                            </Item.Extra>
                                                        </Item.Content>
                                                    </Item>
                                                </Item.Group>
                                            </Segment>

                                        )
                                    })
                                }
                                <Divider/>
                            </Container>

                        </Segment>
                    )
                })

                }
            </div>
        );
    }
};


export default Program;
