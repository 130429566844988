import React, {Component} from 'react';
import {connect} from 'react-redux';
import {firebase} from "../../config/firebase";
import {Segment, Container, Grid, Header, Button, Image, Card} from 'semantic-ui-react'
import {BDCOLOR, BDCOLOR_SUB} from "../../constants/BDBrand";
import moment from "moment/moment";
import {Link} from "react-router-dom";

const db = firebase.firestore();

class SeasonCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: []
        }
    }


    componentDidMount() {
        this.loadSeasonReview();
    }

    loadSeasonReview = () => {
        const {id, isRecruiting} = this.props.season;
        if (isRecruiting) {
            return;
        }
        const reviewsRef = db.collection('reviews').doc(id).collection('seasonReviews');
        reviewsRef
            .get()
            .then(snapshot => {
                const reviews = [];
                snapshot.forEach(doc => {
                    const {
                        userName,
                        content
                    } = doc.data();

                    const review = {
                        userName,
                        content
                    }

                    reviews.push(review);
                })
                this.setState({
                    reviews
                })
            })
            .catch(e => {
                console.log(e);
            })

    }

    render() {
        const {season} = this.props;
        const {
            isRecruiting,
            earlyBird1st,
            earlyBird2nd,
            registerStartDate,
            registerEndDate,
        } = season;

        if (isRecruiting) {

            return (
                <Card raised>
                    <Card.Content>
                        <Header style={{color: BDCOLOR_SUB}}>
                            {season.title}
                            <Header.Subheader>
                                {season.number}번째 BD
                            </Header.Subheader>
                        </Header>
                        <Card.Description>
                            지원기간: {moment(season.registerStartDate.seconds * 1000).format('MM/DD(ddd)')} - {moment(season.registerEndDate.seconds * 1000).format('MM/DD(ddd)')}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content>
                        <Button fluid as={Link} to={'/apply'} color={'blue'}>
                            자세히 보기
                        </Button>
                    </Card.Content>
                </Card>
            )

            if (moment().isSameOrBefore(moment(earlyBird1st.endDate.seconds * 1000), 'day')) {
                return (
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                {season.title}
                            </Card.Header>
                            <Card.Description>
                                {season.number}기
                            </Card.Description>
                        </Card.Content>
                        <Card.Content>
                            <Button color={'blue'}>
                                1차 얼리버드로 지원하기
                            </Button>
                        </Card.Content>
                    </Card>
                )
            } else if (moment().isSameOrBefore(moment(earlyBird2nd.endDate.seconds * 1000), 'day')) {
                return (
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                {season.title}
                            </Card.Header>
                            <Card.Description>
                                {season.number}기
                            </Card.Description>
                        </Card.Content>
                        <Card.Content>
                            <Button>
                                지원하기
                            </Button>
                        </Card.Content>
                    </Card>
                )
            } else {
                return (
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                {season.title}
                            </Card.Header>
                            <Card.Description>
                                {season.number}기
                            </Card.Description>
                        </Card.Content>
                        <Card.Content>
                            <Button>
                                지원하기
                            </Button>
                        </Card.Content>
                    </Card>
                )
            }
        } else {
            return (
                null
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SeasonCardComponent);
