import React, {Component} from 'react';
import AdminSubMenuComponent from '../../components/admin-home/AdminSubMenuComponent'
import axios from 'axios';
import {Segment, Container, Table, Grid, Form, Button, Card} from 'semantic-ui-react'
import {db} from "../../config/firebase";
import {deleteTabAndSpace, deleteTag} from "../../middleware/stringHandler";

class AdminBDPaperMainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bdPapers: [],
        }
    }

    componentDidMount() {
        this._loadBdPaper()
    }

    _loadBdPaper = async () => {
        db.collection('bdPapers')
            .orderBy('season')
            .orderBy('modifiedAt')
            .get()
            .then(snapshot => {
                let bdPapers = []
                snapshot.forEach(doc => {
                    let bdPaper = doc.data();
                    bdPaper.id = doc.id;
                    bdPapers.push(bdPaper);
                })
                this.setState({
                    bdPapers
                })
                console.log('BDPAPERS ARE LOADED')
            })
            .catch(e => {
                console.log(e)
            })
    };



    render() {
        return (
            <div>
                <AdminSubMenuComponent/>
                BD Paper
                <Segment as={Container}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.bdPapers.map((bdPaper, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{bdPaper.name}</Table.Cell>
                                            <Table.Cell>{bdPaper.season}</Table.Cell>
                                            <Table.Cell>
                                                <Button size={'small'} compact onClick={() => {
                                                }}>
                                                    자세히 보기
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Segment>
            </div>
        );
    }
}


export default AdminBDPaperMainContainer;
