import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Analytics from "../../middleware/Analytics";
import {db, firebase} from "../../config/firebase";
import {Container, Segment, Card, Item, Header, Accordion, Icon, Image, Grid, Divider} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {BDCOLOR, BDCOLOR_SUB} from "../../constants/BDBrand";
import {withLineEnter} from "../../middleware/stringHandler";

const faqRef = db.collection('config').doc('faqs');


class FaqMainContainer extends Component {



    constructor(props) {
        super(props);
        this.state = {
            faqs: [],
            isLoaded: false,
            activeIndex: 0,
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        Analytics.pageViewAll(`/faqs/`);
        this._loadFAQ()
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    _loadFAQ = () => {
        faqRef
            .get()
            .then(doc => {
                if (!doc.exists) {
                    this.setState({
                        faqs: [],
                        isLoaded: true,
                    })
                    return;
                }
                const {faqs} = doc.data();
                this.setState({
                    faqs: faqs ? faqs : [],
                    isLoaded: true,
                })
            })
            .catch(e => {
                console.log(e);
            })
    };


    render() {
        const {activeIndex} = this.state
        return (
            <Fragment>
                <div style={{backgroundColor: BDCOLOR_SUB, paddingTop: 30, paddingBottom: 30, marginBottom: 50}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            FAQ
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            자주 묻는 질문
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                궁금한 점에 대해서 답변해 드리겠습니다
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/background/closingColor.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                2018 가을 클로징
                            </span>
                        </div>
                    </Container>
                </div>

                <Container as={Segment} vertical text basic>
                    <Accordion fluid styled>
                        {
                            this.state.faqs.map((faq, i) => {
                                return (
                                    <Fragment key={i}>
                                        <Accordion.Title key={i}
                                                         active={activeIndex === i}
                                                         index={i}
                                                         onClick={this.handleClick}>
                                            <Icon name='dropdown'/>
                                            {faq.question}
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === i}>
                                            <p style={{lineHeight: '150%'}}>
                                                {withLineEnter(faq.answer)}
                                            </p>
                                        </Accordion.Content>
                                    </Fragment>
                                )
                            })
                        }
                    </Accordion>
                </Container>
                <Container as={Segment} vertical text basic style={{marginTop: 50}}>
                    <p>
                        추가로 궁금하신 점이 있으시면, <br/>
                        <a href="mailto:beingndoing.apply@gmail.com">
                            beingndoing.apply@gmail.com
                        </a>
                        로 메일을 주시거나 <br/>
                        <a href='https://pf.kakao.com/_ptxdSxd/chat'
                           target={'_blank'}
                           style={{color: '#E3C748'}}>
                            카카오 플러스 친구
                        </a> <br/>
                        로 문의 주세요. 최대한 빨리 답변드리겠습니다.
                    </p>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FaqMainContainer);
