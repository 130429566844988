const interviews = [
    {
        interviewee:'윤성준',
        image: '/images/crewProfile/interview/05.jpg',
        title: '',
        summary:'좋은 사람들을 지속적으로 만날 수 있다는 것이 가장큰 장점입니다. 주말을 알차게 보낼 수 있다는 장점도 있죠',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'\'꾸준한 글쓰기\'이다. 이전까지 했던 BD 목표는 \'회사를 위한 목표\'들이 많았는데, 지난 시즌에는 개인적인 목표를 설정했더니, 관리하기도 쉽고 달성여부를 측정하기도 쉬웠다. 앞으로도 BD 목표는 스스로 온전히 컨트롤 할 수 있는 것들로 목표를 세울 예정이다.',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'지난 가을 시즌에 \'번아웃\'에 대해 에세이를 쓴 적이 있다. 스타트업을 하면서 쉼없이 달리다가 \'번아웃 증후군 초기 단계\'가 왔는데, 때마침 관련해서 에세이를 쓰면서, 어떻게 해야 이를 극복할 수 있는지 생각해보고 정리할 수 있는 계기가 되었다. (결국 스타트업은 망했지만...)',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'너무 뻔한 답일 수 있겠지만, BD의 Core Value 및 Culture와 잘 맞는 사람이어야 한다고 생각한다.',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'\'좋은 사람\'들을 지속적으로 만날 수 있다는 점이다. 그리고 보통 주말에는 늦게 일어나기 마련인데, BD를 하게 되면, 토요일이든 일요일이든 하루는 일찍 일어나게 되어 주말을 알차게 보낼 수 있다는 장점도 있다.',
            },
        ]
    },
    {
        interviewee:'류기령',
        image: '/images/crewProfile/interview/06.jpg',
        title: '',
        summary: '하루를 한 줄로 줄이는 것도 쉽지 않았는데 나의 인생전체를 요약해보려 하니 앞으로 남은 인생에 대한 모토가 좀 더 구체적이고 삶 전체에 대해 생각해볼 수 있는 기회였던 것 같아요. ',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'저는 제 개인적인 목표보다 저희 팀원들의 목표달성이 더 기억에 남아요. 당시 저희 팀은 4명이었어요. 저는 매주 체크할 수 있지만 어떠한 큰 성과를 기대하기는 힘든 목표였고, 다른 3명중 2명은 이직, 한 명은 시험준비를 하는 것이었는데 모임에서 스코어를 보여주기에는 상대적으로 제 목표가 보여주기 쉬웠죠. 이직과 시험은 장기적 문제이기 때문에 2주마다 결과물을 보여주기엔 어려운 부분도 있었고 중간에 지치는 상황도 있었지만 진심으로 응원해주고 싶었어요. 사람은 감정적인 동물이기 때문에 어느 한 부분만 분리될 수는 없다고 생각해요. 개인적인 얘기도 많이 나누고 응원하면서 서로 의지 했던 것 같아요. 결과적으로 2명 모두 이직에 성공하고 한 명은 시험결과가 좋아서 가벼웠던 저의 목표보다는 함께했던 팀원들의 성과가 기억에 남아요.',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'\'나의 묘비명 생각해보기\'라는 주제가 기억에 남아요. 너무 많은 에세이를 써서 하나만 고르려니 이것저것 스쳐가는데 이게 제일기억에 남네요. 주제는 보통 책을 읽으면서 찾는 편인데 \'앤드류 카네기(Andrew Carnegie,1835-1919)\'의 묘비명인 "여기 자신보다 더 우수한 사람을 어떻게 다루어야 하는지를 아는 사람이 누워있다" 를 보고 저도 써보게 되었어요. 제가 Being&Doing을 처음 했을 때 목표 중에 \'하루 일기 한 줄로 쓰기\' 가 있었는데 평소처럼 일기를 쓰고 그 내용을 한 줄로 요약하는 날들을 보낸 적이 있어요. 하루를 한 줄로 줄이는 것도 쉽지 않았는데 나의 인생전체를 요약해보려 하니 앞으로 남은 인생에 대한 모토가 좀 더 구체적이고 삶 전체에 대해 생각해볼 수 있는 기회였던 것 같아요. 저의 묘비명으로 택한 것은 "살아있는 동안은 모든 게 아름답다는 것을 알고 있던 여자, 편안히 잠들다" 입니다. 여기서 포인트는 \'편안히 잠들다!\' 부분이에요. 항상 인생을 마무리할 때에는 후회 없이 곱게 죽고 싶다는 생각을 하거든요.',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'삶에 애착이 있는 사람이면 좋겠어요. 야망이나 욕심과는 좀 다른 접근인데 하루를 또는 본인의 인생 전체를 아끼는 사람이면 좋을 것 같아요. 단순하게 행동하는 먹고, 자고, 일하고, 놀고 등등의 반복되는 패턴으로는 만족하지 않는 분이면 좋겠네요. 그러면서 동시에 너무나 당연시 되는 것들 조차에도 감사와 행복을 느끼셨으면 좋겠고, 쉬운 것 같으면서 어려운 것 같지만요. 많은 분들이 함께 하며 시너지를 내는 모임이니 좋은 에너지를 주고받으면 좋겠죠?',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'BD활동은 여러모로 좋은 점이 많아요. 전체적으로 봤을 때 제일 좋은 부분은 다양한 분야에 서로 다른 환경에서 자란 여러 Crew들과 교류할 수 있다는 점이죠. 저는 일찍부터 미술을 해서 이전까지는 대부분 미대, 예체능계 친구들이 대부분이었는데 생소하거나 낯설기도 했던 직업이나 뭐 그런 다양성에 있어 새로움을 느낄 수 있어 좋죠. 그리고 개인적으로는 최근 모임 때 털어놓기 싫지만 위로 받고 싶은 내용들을 솔직하게 얘기할 수 있어서 좋았어요. 팀원들의 응원이 많이 위로 되었어요. 그때 \'정말 BD에 참여하길 잘했구나\' 생각했거든요. 아마 그래서 새로운 팀이 정해질 때 \'제발 좋은 팀원들과 팀이 되길!\' 바라는데 다른 팀들도 보면 본인들의 팀에 애정과 만족을 가지는 것 같아요. ',
            },
        ]
    },
    {
        interviewee:'허경석',
        image: '/images/crewProfile/interview/03.jpg',
        title: '',
        summary: '평소 무심코 넘어갈 사안과 개인적 행동에 대해 원인을 재차 물어볼 수 있는 기회였다. 이로 인해 나를 더 잘 파악하게 된다.',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'- 목표 : 체중 감량 + 운동 실시<br/>' +
                '- 내용 : 4개월 간 제로 카브(탄수화물) 식단 유지 + 주 4회 운동을 실시해서 첫 2개월 동안 9kg 몸무게 감량 성공! 그리고 줄어든 순수 지방이 근육으로 증가함.<br/>' +
                '- 소감 : 이 목표 덕분에 5년 전 체중으로 복귀할 수 있었고 모든 옷을 새로 사게 됨. 가장 가시적인 목표 및 변화였으므로 특히 성취감이 높았다<br/>',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'\'부모님이 나에게 중요한 영향을 미친 3가지는 무엇인가?\'란 주제를 통해 내 가치관의 근간을 구성하는 도덕관, 경제관, 가족관의 대표 속성과 그 생성 원인을 파악할 수 있었다.',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'- 지적 호기심이 높고 합리적 사고 체계를 보유한 사람<br/>' +
                '- 직접적이고 솔직한 커뮤니케이션을 하며 단순한 유화보다는 문제제기와 새로운 시각을 제시하는 것에 집중하는 사람<br/>' +
                '- 새로운 도전을 즐기며, 한계를 넘어서는 노력과 그 성취를 추구하는 사람<br/>' +
                '- 타인의 주말 시간을 사용한다는 전제에서, 상대가 뜻 깊고 유익한 대화였다고 생각할 수 있도록 최대의 성의를 표하거나, 경청하거나, 새로운 시각을 제시하는 사람. 즉, 남의 시간을 아깝게 생각할 줄 아는 사람.<br/>',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'- 평소 무심코 넘어갈 사안과 개인적 행동에 대해 원인을 재차 물어볼 수 있는 기회였다. 이로 인해 나를 더 잘 파악하게 된다.<br/>' +
                '- 타인의 솔직하고 내밀한 생각, 경험에 접속할 수 있다. 다양한 인간에 대한 이해를 돕는다.<br/>' +
                '- 수개월 정도를 투자하여 얻을 성취를 단계적으로 누적해 나갈 수 있다.<br/>',
            },
        ]
    },
    {
        interviewee:'이지현',
        image: '/images/crewProfile/interview/02.jpg',
        title: '',
        summary: 'BD활동은 무엇에 집중해야 할지 명확하게 알려주고, 어제보다 더 나은 내가 되기 위한 생각들을 자주, 재밌게, 지속적으로 할 수 있는 원동력이 됩니다.',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'저는 달성목표보다 미완의 목표(자격증 취득)가 기억에 오래 남아요. 만약 같은 상황이라면 나는 어떻게 행동할까? 시간 분배를 잘했다면 결과는 달라지지 않았을까? 라는 생각도 하고 최선으로는 충분하지 않기에 좀 더 전략적으로 계획을 세우고 실천하는 자세가 필요하다고 다짐합니다.',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'팀원을 형용사로 설명하기 : 마지막 모임의 에세이 주제로 많이 사용한다고 하는데 한 기수를 정리하면서 팀원과 함께한 시간, 대화들이 하나의 이미지로 만들어지는 과정이 특별하다 생각해요. 그리고 에세이는 과거, 현재, 미래로 구분되는데 자신의 인생과 생각을 정리할 수 있는 계기를 마련해줘 좋았어요.',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'- 자신이 어떤 사람인지 많이 생각하고 본인이 되고자 하는 목표를 달성하기 위해 행동하는 사람. <br/>' +
                '- 열려있는 생각&마음으로 타인과 긍정적인 영향력을 나누려 는 사람<br/>',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'BD Crew들은 서로를 진심으로 응원하고 이해심이 깊어요. 이직 후 일에 매몰되어 힘들었을 때, 도중에 포기하지 않도록 체어와 멤버들이 독려해준 덕분에 끝까지 활동할 수 있었고, 저 또한 삶의 균형을 맞출 수 있었고요. 멋진 사람들과 함께 한다는 건 개인적으로도 큰 행복이고 시너지가 발휘되는 느낌이에요. BD활동은 무엇에 집중해야 할지 명확하게 알려주고, 어제보다 더 나은 내가 되기 위한 생각들을 자주, 재밌게, 지속적으로 할 수 있는 원동력이 됩니다.',
            },
        ]
    },
    {
        interviewee:'이교영',
        image: '/images/crewProfile/interview/04.jpg',
        title: '',
        summary: 'BD 활동은 회사와 사회에 매몰되어 방향성을 잃는 순간마다 나답게 있을 수 있게 긍정 에너지를 심어주고, 매 순간이 충만하도록 집중하게 해줘요. ',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'이 글을 보시는 모든 분께서 매도하시겠지만 \'많이&자주 먹기\'가 가장 기억에 남아요. 어릴 적부터 저질체력에 시달려와서 뭘 하든 무의식적으로 무리하지 않으려는 편인데, 직장인 신분으로 하고 싶은 걸 욕심껏 하려면 체력이 필수더라구요. 이전에는 \'나는 살찌지 않는 체질이야\'라며 애써 노력하지 않았는데 1년간 작은 것 하나라도 더 먹으려고 노력하다 보니 지금은 +2kg 찌운 상태에서 3개월 이상 유지 중이에요. 처음에는 먹기 싫은데 먹으려 애쓰다 보니 토할 것 같고 너무 고통스러웠지만, BD 팀카톡방에 매일 얼마나 더 먹었는지 공유하고 매 회 모임마다 리뷰하다 보니 자연스레 먹는 양이 늘었어요. 덕분에 힘도 생기고, 적정량의 잠으로도 하루를 견딜 수 있어 뿌듯하고 씬나요',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'쿠바 여행 직후 \'정보의 홍수 속에 사는 현대인들\'이라는 주제로 여행 중 떠올랐던 질문을 에세이로 정리했었어요. 21세기는 정보화 시대이고, 정보는 곧 자산이라고 하죠. 그런데 쿠바에 있는 2주동안 이 명제에 의구심이 들더라구요. 우리가 정보에 뒤쳐져서는 안 된다고 생각하는 건 그저 강박관념이자 학습된 생각일 뿐인 것 같아, 과연 나에게 인사이트를 줄 수 있는 정보란 무엇이며 지금까지 불필요한 정보를 중요하다고 인식한 적은 없는지 에세이를 쓰며 다시 한 번 돌아보게 됐어요. 또, 시간, 스마트폰, 그리고 나의 일상에 대해 함께 생각해 볼 수 있었어요.',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'꿈과 열망이 가득한 사람! 각자의 장점이 모여 시너지 효과를 내고 서로의 단점을 보완하고 피드백해 더 나은 내가 되도록 도와주는 곳, 그게 BD라고 생각해요. 꿈이 넘치는 사람이 BD Crew가 된다면, 각자 소망하는 꿈에 한 발 더 다가갈 수 있도록 서로에게 좋은 영향을 주지 않을까요?',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'다양한 관점, 분야, Insight, 그리고 성향이 한자리에 모인다는 점이에요. 너무나도 다르고 다양한 사람들이 함께 하지만, 각자 꿈꾸는 미래와 스스로에 대한 굳은 믿음이 구심점을 이루죠. 저는 외압이 있다고 열심히 하는 타입은 아니라서 회차 모임에서 누군가 저에게 목표가 부진하다고 닥달을 해도 목표달성률이 올라가진 않아요. 다만, Crew들의 꿈과 목표 달성, 자기 믿음이 거울이 되어 내적 동기를 강하게 만들어줍니다. BD 활동은 회사와 사회에 매몰되어 방향성을 잃는 순간마다 나답게 있을 수 있게 긍정 에너지를 심어주고, 매 순간이 충만하도록 집중하게 해줘요. Crew들과 이야기를 주고 받으며 세상을 보는 창을 넓혀 가고, 내가 소망하는 미래를 향해 지치지 않고 지속할 수 있는 힘을 선물 받기에 매 기수마다 \'이번 시즌은 쉬어볼까?\'라는 생각을 하면서도 지원서를 쓰고 있는 나 자신을 발견하곤 하네요.',
            },
        ]
    },
    {
        interviewee:'이미혜',
        image: '/images/crewProfile/interview/07.jpg',
        title: '',
        summary: '\'자기 자신에 대해 진지하게 고민하고 그 결과를 실천하기 위해 함께 노력할 준비가 되어있는\' 사람들과 같이 하고 싶습니다.',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'제가 지난 BD를 시작했던 마음은 \'내가 좋아하는 것을 잘해보자\' 였습니다. 잘하려면 우선 많이 해야겠다는 생각이 들었고, 그래서 좋아하는 \'그림 그리기\'를 주제로 잡고 매일 한 장씩 그리는 걸 목표로 했습니다. 어제까지 그린 그림이 총 160장이 넘었으니 노트를 넘겨볼 때마다 뿌듯하기도 합니다만, 사실 그것보다도 시간이 날 때마다 노트를 펴고 그림 그리는 습관이 몸에 베어있는 자신의 모습이 더 뿌듯합니다 :)',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'8번의 Essay를 어떻게 적으면서 나에 대한 고민을 해볼까, 생각했던 BD 첫 주의 에세이가 가장 기억에 남습니다. 내가 언제 행복한지, 나는 무엇을 좋아하는지, 내가 그걸 왜 좋아하고 왜 잘하고 싶은지... 지난 인생을 쭉 한번 돌아보게 되더라고요. 이미 알고 있다고 생각했던 것들인데도, 그걸 한 편의 글로 적고 BD Crew들과 공유하는 과정이 제겐 먼지 쌓인 자기 내면을 툭툭 털어내고 새로운 것들도 틈틈이 채워 넣는 내면의 청소 시간이었습니다. 오래 전에 했던 생각도 꺼내보고 새로운 생각도 덧붙이면서, 내가 어떤 사람이 되고 싶은지 더 또렷해졌어요.',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'지금 고민하고 있는 모든 사람들, 구체적으로는 \'자기 자신에 대해 진지하게 고민하고 그 결과를 실천하기 위해 함께 노력할 준비가 되어있는\' 사람들과 같이 하고 싶습니다.',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'함께 하는 것이 주는 힘이 있다고 생각합니다. 혼자 하기엔 약해지는 순간들이 있는데, 그 순간마다 함께해주는 사람들이 옆에 있다는 것만으로도 힘을 얻습니다. 게다가 그 사람들이 엄청나게 매력적이에요. 다들 이미 멋진데, 더 멋진 사람들이 되기 위해 노력하더라고요? 그런 모습을 보면서, 미처 생각하지 못했던 꿈의 조각들을 발견하곤 합니다. \'나도 이런 사람이 되고 싶다, 이런 부분은 닮고 싶다\', 같은 조각들. 함께하는 모든 BD 사람들에게서 나를 위한 힘과 긍정적인 변화의 시작을 얻습니다. 이 사람들을 알게 된 것이 가장 좋은 점이에요.',
            },
        ]
    },
    {
        interviewee:'김준형',
        image: '/images/crewProfile/interview/01.jpg',
        title: '',
        summary: '나에겐 이게 힘든 과정이기 보단 성장한 나를 보았을 때, 그리고 꿈에 가까워짐을 어렴풋이 느끼는 것에 대한 기쁨이 훨씬 컸던 것뿐이다.',
        contents:[
            {
                q:'BD에서 설정했던 목표 중 가장 기억에 남는 목표는 무엇인가요?',
                a:'The great books program 정복. 혼자 시작 했으면 못했을 독서 목록을 BD와 함께 연차별로 깨 나갈 수 있었음.',
            },
            {
                q: 'BD Essay 중 가장 기억에 남는 주제는 무엇인가요?',
                a:'단일 에세이 주제가 아닌, "나에게 행복이란",  "내가 원하는 삶의 특성", "나에게 일이란" 등을 연속적/복합적으로 고민하여 행복 - 가치 - 일이 연계된 내가 원하는 삶에 대한 전체적인 그림을 그릴 수 있었음.',
            },
            {
                q: '어떤 사람이 BD Crew가 되었으면 좋겠나요?',
                a:'나에게 도전을 주고 새로운 분야에 대한 지식을 공유 해 줄 수 있는 crew',
            },
            {
                q: 'BD 활동을 하며 특히 좋았던 점을 꼽으라면 어떤 게 있을까요?',
                a:'BD는 매일매일이 개선으로 다가오진 않는다 하지만 어느 순간 되돌아보면 성장한 것이 보이고 이를 보고 때때로 놀라기도 한다. 사람들이 나에게 왜 그렇게 열심히 사냐고 물어본다. 근데 나에겐 이게 힘든 과정이기 보단 성장한 나를 보았을 때, 그리고 꿈에 가까워짐을 어렴풋이 느끼는 것에 대한 기쁨이 훨씬 컸던 것뿐이다. 마치 강백호가 리바운드만 하다가 점프슛을 연습하며 득점을 하기 시작 했을 때의 기쁨이 이런 것이 아니었을까.',
            },
        ]
    },

]


export {interviews}