import React, {Component} from 'react';
import {Sidebar,} from 'semantic-ui-react';
import PartialMenu from "./PartialMenu";
import PartialFooter from "./PartialFooter";


class Layout extends Component {

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <div>
        <Sidebar.Pusher style={{paddingTop: 51}}>
          <PartialMenu/>
          <div style={{minHeight: '80vh'}}>
            {this.props.children}
          </div>
          {/*<PartialFooter/>*/}
        </Sidebar.Pusher>
      </div>
    );
  }
}

export default Layout;
