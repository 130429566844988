const mission = "BD는 내가 원하고 바라는 모습이 되기 위해 노력하는 사람들이 꿈을 실현하는 것을 돕습니다.";
// const mission = "Project 1은 최고를 꿈꾸는 사람들이 지속해서 성장하는 것을 돕고 최고가 되게 합니다";


/*const belief = [
    "Project 1은 의도된 학습을 지속하여 나의 comfort zone을 넓혀 나갑니다",
    "서로 다른 분야에서 최고가 되기 위해 노력하는 동료들을 만납니다",
    "다른 분야의 생각을 통해 나 또한 완전히 다른 방식의 접근을 할 수 있습니다",
];*/
const belief = [
    "시간을 달리 쓰는 것",
    "사는 곳을 바꾸는 것",
    "새로운 사람을 만나는 것",
];
const principle = [
    "자신에 대해 생각해 보고, 장기적인 관점으로 접근합니다.",
    "자신이 설정한 목표 달성을 위해 누구보다 많은 시간과 에너지를 쏟습니다",
    "매일의 노력을 쌓아서 장기적으로 큰 성과를 만듭니다",
    "모임에서는 각자의 의견을 존중하며 서로간에 긍정적인 영향을 줍니다",
]

const p1Way = [
    '최고처럼 생각하고',
    '최고를 만나고',
    '최고처럼 시간을 사용합니다'
];


export {mission, belief, p1Way, principle};
