import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import {unregister} from './registerServiceWorker';
import {Provider} from 'react-redux'
import store from "./redux/store";


unregister();
ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
  ,
  document.getElementById('root')
);
// registerServiceWorker();
