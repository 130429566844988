import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Card, Image, Header} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import Analytics from "../../middleware/Analytics";
import {firebase} from "../../config/firebase";
import moment from 'moment';
import {BDCOLOR_SUB} from "../../constants/BDBrand";
import TalkCardComponent from "../../components/talk/TalkCardComponent";
import RecruitingNowHorizontalBannerComponent from "../../components/home/RecruitingNowHorizontalBannerComponent";

const db = firebase.firestore()

class TalkMainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            talks: []
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        Analytics.pageViewAll('/talk');

        this.loadTalkList();

    }

    loadTalkList = async () => {
        const talkRef = db.collection('talks').orderBy('number', 'desc');
        talkRef
            .get()
            .then(snapshot => {
                const talks = []
                snapshot.forEach(doc => {
                    const talk = doc.data();
                    talk.id = doc.id;
                    talks.push(talk);
                })

                this.setState({
                    talks
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    render() {
        return (
            <Fragment>
                <div style={{backgroundColor: BDCOLOR_SUB, paddingTop: 30, paddingBottom: 30, marginBottom: 50}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            생각을 나누고, 자극을 받습니다.
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            BD Talk
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                BD의 중간모임 입니다. TED와 유사한 형태로 진행됩니다.
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/background/bdtalk2017spring.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                2017 봄 - BD Talk에서 발표를 듣고 있던 중
                            </span>
                        </div>
                    </Container>
                </div>

                <Container as={Segment} vertical>
                    <Card.Group itemsPerRow={3} doubling>
                        {
                            this.state.talks.map((talk, i) => {
                                return (
                                    <TalkCardComponent talk={talk} key={i}/>
                                )
                            })
                        }
                    </Card.Group>
                </Container>
                {/* 모집 중인 시즌 */}
                <RecruitingNowHorizontalBannerComponent/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TalkMainContainer);
