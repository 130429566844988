import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Button, Segment, Message, Grid} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {firebase} from "../../config/firebase";
import {Redirect} from 'react-router-dom';
import Analytics from "../../middleware/Analytics";
import {authActionCreators} from "../../redux/auth/authStore";

class SignInContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isFetching: false,
      message: '',
      visible: false
    }
  }

  componentDidMount() {
    Analytics.pageViewAll(`/signin`);
    window.scrollTo(0, 0)
  }


  handleChange = (e, {value, name}) => {
    this.setState({[name]: value});
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({isFetching: true});
    this.handleLogin();
  };

  handleLogin = () => {
    const {email, password} = this.state;
    this.props.signIn(email, password, (e) => {
      this.setState({
        isFetching: false,
        message: e && e.message
      });
    });
  };

  render() {
    const {isLoggedIn} = this.props.auth;
    if (isLoggedIn) {
      const {from} = this.props.location.state || {from: {pathname: '/'}};
      if (from === '/signin') {
        return <Redirect to={'/'}/>;
      } else {
        return <Redirect to={from}/>;
      }

    }

    const {isFetching} = this.state;
    return (
      <Grid textAlign="center" style={{height: '90vh'}} verticalAlign="middle">
        <Grid.Column style={{maxWidth: 450}}>
          <Form size="large">
            <Segment stacked>
              <Form.Input fluid icon="user" iconPosition="left" placeholder="E-mail address"
                          value={this.state.email} name={'email'} onChange={this.handleChange}/>
              <Form.Input fluid icon="lock" iconPosition="left" placeholder="Password" type="password"
                          value={this.state.password} name="password" onChange={this.handleChange}/>

              <Button color="blue" basic fluid size="large" onClick={this.handleSubmit}
                      loading={isFetching}>
                로그인
              </Button>
            </Segment>
          </Form>
          <Message>
            아직 회원 가입을 하지 않으셨나요? <Link to={{pathname: '/signup'}}>여기</Link>를 눌러 가입하세요 <br/>
            비밀번호가 기억나시지 않나요? <Link to={{pathname: '/reset-password'}}>여기</Link>를 비밀번호를 재설정하세요
          </Message>
          {
            this.state.message ? (
              <Message>{this.state.message}</Message>
            ) : null
          }

        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {...state};
};
const mapDispatchToProps = {
  ...authActionCreators,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInContainer);
