import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AboutUsBackground} from '../components';
import {Segment, Icon, Accordion, Container, List, Tab} from 'semantic-ui-react';
import {faq} from '../data/p1_faq';
import {general, sphere, teamMeeting, goal, essay} from '../data/bd_faq';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-114613423-1');
const propTypes = {};
const defaultProps = {};


const panes = [
    { menuItem: '일반', render: () => <Tab.Pane attached={false}><Accordion defaultActiveIndex={11} fluid panels={general}/></Tab.Pane> },
    { menuItem: '목표', render: () => <Tab.Pane attached={false}><Accordion defaultActiveIndex={11} fluid panels={goal}/></Tab.Pane> },
    { menuItem: '에세이', render: () => <Tab.Pane attached={false}><Accordion defaultActiveIndex={11} fluid panels={essay}/></Tab.Pane> },
    { menuItem: '팀모임', render: () => <Tab.Pane attached={false}><Accordion defaultActiveIndex={11} fluid panels={teamMeeting}/></Tab.Pane> },
    // { menuItem: 'Sphere', render: () => <Tab.Pane attached={false}><Accordion defaultActiveIndex={11} fluid panels={sphere}/></Tab.Pane> },
]

class FAQ extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview('/faq');
    }

    render() {

        return (
            <div>
                <AboutUsBackground imageUrl={'url(/images/background/faq2_resized.jpg)'} fontColor='white'>
                    BD에 대해서 자주 묻는 질문입니다
                </AboutUsBackground>
                <Segment vertical basic></Segment>
                <Segment vertical basic style={{lineHeight: '1.7em'}}>
                    <Container text>

                        <Tab menu={{ secondary: true }} panes={panes} />
                    </Container>
                </Segment>
                <Segment>

                </Segment>

            </div>
        );
    }
};

FAQ.propTypes = propTypes;
FAQ.defaultProps = defaultProps;

export default FAQ;
