import React, {Component, Fragment} from 'react';
import {Segment, Container, Table, Button,} from 'semantic-ui-react'
import {db} from "../../config/firebase";
import {Link} from 'react-router-dom';
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

class AdminTeamMainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            isLoaded: false,
        }
    }

    componentDidMount() {
        this._loadTeamList();
    };

    _loadTeamList = () => {
        db.collection('teams')
            .orderBy('seasonId')
            .get()
            .then(snapshot => {
                let teams = [];
                snapshot.forEach(doc => {
                    let team = doc.data();
                    team.id = doc.id;
                    teams.push(team);
                })
                this.setState({
                    teams: teams,
                    isLoaded: true,
                })
            })
    };

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Segment as={Container} vertical basic>
                    <Table size={'small'} celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>시즌 아이디</Table.HeaderCell>
                                <Table.HeaderCell>팀 이름</Table.HeaderCell>
                                <Table.HeaderCell>체어</Table.HeaderCell>
                                <Table.HeaderCell>멤버 수</Table.HeaderCell>
                                <Table.HeaderCell>기타</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.teams.map((team, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{team.seasonId}</Table.Cell>
                                            <Table.Cell>{team.title}</Table.Cell>
                                            <Table.Cell>{team.chair && team.chair.name ? team.chair.name : ''}</Table.Cell>
                                            <Table.Cell>{team.members && team.members.length ? team.members.length : ''}</Table.Cell>
                                            <Table.Cell>
                                                <Button size={'small'}
                                                        compact
                                                        as={Link}
                                                        to={{pathname: `/admin/team/modify/${team.id}`}}>
                                                    자세히 보기
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>
        );
    }
}


export default AdminTeamMainContainer;
