import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Segment, Container, Card, Table, Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

class AdminTalkManagementContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            talks: []
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadTalkList();
    }

    loadTalkList = async () => {
        const talkRef = db.collection('talks').orderBy('number', 'desc');
        talkRef
            .get()
            .then(snapshot => {
                const talks = []
                snapshot.forEach(doc => {
                    const talk = doc.data();
                    talk.id = doc.id;
                    talks.push(talk);
                })

                this.setState({
                    talks
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>

                <Segment vertical basic>
                    <Container>
                        <Button as={Link} to={'/admin/talk/make'}>
                            추가하기
                        </Button>

                        <Table size={'small'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>번호</Table.HeaderCell>
                                    <Table.HeaderCell>시즌Id</Table.HeaderCell>
                                    <Table.HeaderCell>제목</Table.HeaderCell>
                                    <Table.HeaderCell>발표자</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.talks.map((talk, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                <Table.Cell>{talk.number}</Table.Cell>
                                                <Table.Cell>{talk.seasonId}</Table.Cell>
                                                <Table.Cell>{talk.title}</Table.Cell>
                                                <Table.Cell>{talk.speakerName}</Table.Cell>
                                                <Table.Cell>
                                                    <Button as={Link} to={`/admin/talk/modify/${talk.id}`}>
                                                        modify
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>

                    </Container>
                </Segment>
            </Fragment>
        );
    }
}


export default AdminTalkManagementContainer;
