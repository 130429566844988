import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Segment, Card, Form, Container, Button, Header, Modal, Image, Table} from 'semantic-ui-react';
import {imageUploadToFirebase} from "../../middleware/firebaseImageHandler";
import {withLineEnter} from "../../middleware/stringHandler";
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";

const mainReviewRef = db.collection('reviews').doc('main').collection('review');

class AdminReviewContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainReviews: [],
            openAddModal: false,
            openModifyModal: false,

            newReview: {},
            modifyReview: {},
        }
    }

    componentDidMount() {
        this._loadMainReviews();
    }

    _loadMainReviews = () => {
        mainReviewRef
        // .where('number', '>', 0)
            .orderBy('number')
            .get()
            .then(snapshot => {
                const mainReviews = []
                snapshot.forEach(doc => {
                    const review = doc.data()
                    review.id = doc.id;
                    mainReviews.push(review);
                })

                this.setState({
                    mainReviews
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    _deleteReview = () => {

    }

    _addReview = () => {
        this.setState({
            openAddModal: true,
        })
    }

    _handleChangeNewReviewData = (e, d) => {
        const newReview = this.state.newReview
        newReview[d.name] = d.value;

        this.setState({
            newReview
        })
    }

    _handleChangeModifyReviewData = (e, d) => {
        const modifyReview = this.state.modifyReview
        modifyReview[d.name] = d.value;

        this.setState({
            modifyReview
        })
    }

    _handleChangeReviewData = (d, index) => {
        const mainReviews = this.state.mainReviews
        mainReviews[index][d.name] = d.value;

        this.setState({
            mainReviews
        })
    }

    onNewReviewImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'profileImage';

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);

            const newReview = this.state.newReview;

            newReview.image = image;
            newReview.imageFirebase = imageFirebase;

            this.setState({
                newReview
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

        }
    }

    onModifyReviewImageUpload = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'profileImage';

        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);

            const modifyReview = this.state.modifyReview;

            modifyReview.image = image;
            modifyReview.imageFirebase = imageFirebase;

            this.setState({
                modifyReview
            })
        };
        try {
            reader.readAsDataURL(file);
        } catch (e) {

        }
    }

    _onImageUpload = (e, index) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let bucketName = 'profileImage';
        reader.onloadend = async () => {
            const {
                mainImage: image,
                mainImageFirebase: imageFirebase
            } = await imageUploadToFirebase(file, bucketName);

            const mainReviews = this.state.mainReviews
            mainReviews[index].image = image;
            mainReviews[index].imageFirebase = imageFirebase;


            this.setState({
                mainReviews
            })
        };

        try {
            reader.readAsDataURL(file);
        } catch (e) {

        }
    }

    /*_modifyReview = (index) => {
        const newData = this.state.mainReviews[index]
        newData.modifiedAt = new Date();
        newData.number = parseInt(newData.number, 10);

        mainReviewRef
            .doc(this.state.mainReviews[index].id)
            .set(newData, {merge: true})
            .then(() => {
                window.alert('완료하였습니다.');
            })
            .catch(e => {
                window.alert('오류가 발생했습니다.');
                console.log(e);
            })
    }*/

    _modifyReview = (review) => {
        this.setState({
            modifyReview: review,
            openModifyModal: true,
        })
    }

    _completeAddReview = () => {
        const {newReview} = this.state;

        newReview.createdAt = new Date();
        newReview.modifiedAt = new Date();
        newReview.number = parseInt(newReview.number, 10);

        mainReviewRef
            .add(newReview)
            .then(() => {
                this._loadMainReviews();
                this.setState({
                    newReview: {},
                    openAddModal: false,
                })
            })
            .catch(e => {
                console.log(e);
            })

    }

    _cancelAdd = () => {

    }

    _completeModifyReview = () => {
        const newData = this.state.modifyReview;

        newData.modifiedAt = new Date();
        newData.number = parseInt(newData.number, 10);

        mainReviewRef
            .doc(newData.id)
            .set(newData, {merge: true})
            .then(() => {
                window.alert('완료하였습니다.');
                this.setState({
                    openModifyModal: false,
                    modifyReview: {}
                })
            })
            .catch(e => {
                window.alert('오류가 발생했습니다.');
                console.log(e);
            })
    };

    _cancelModify = () => {
        this.setState({
            openModifyModal: false
        })
    };

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>

                <Container vertical as={Segment}>
                    <Button onClick={this._addReview}>
                        추가하기
                    </Button>
                </Container>
                <Segment vertical as={Container}>
                    <Table size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>사진</Table.HeaderCell>
                                <Table.HeaderCell width={1}>번호</Table.HeaderCell>
                                <Table.HeaderCell width={2}>제목</Table.HeaderCell>
                                <Table.HeaderCell width={2}>부제</Table.HeaderCell>
                                <Table.HeaderCell width={6}>내용</Table.HeaderCell>
                                <Table.HeaderCell wdith={1}>이름</Table.HeaderCell>
                                <Table.HeaderCell width={2}>멤버설명</Table.HeaderCell>
                                <Table.HeaderCell width={1}>기타</Table.HeaderCell>
                                {/*<Table.HeaderCell width={1}></Table.HeaderCell>*/}
                                {/*<Table.HeaderCell width={1}></Table.HeaderCell>*/}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.mainReviews.map((review, i) => {
                                    return (
                                        <Table.Row key={i} verticalAlign={'top'}>
                                            <Table.Cell>
                                                <Image avatar src={review.image}/>
                                            </Table.Cell>
                                            <Table.Cell>{review.number}</Table.Cell>
                                            <Table.Cell>{review.title}</Table.Cell>
                                            <Table.Cell>{review.subTitle}</Table.Cell>
                                            <Table.Cell>{withLineEnter(review.content)}</Table.Cell>
                                            <Table.Cell>{review.userName}</Table.Cell>
                                            <Table.Cell>{withLineEnter(review.userDescription)}</Table.Cell>
                                            <Table.Cell>
                                                <Button size={'mini'} compact
                                                        onClick={() => {
                                                            this._modifyReview(review)
                                                        }}>
                                                    수정
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Segment>

                <Modal open={this.state.openAddModal}>
                    <Modal.Content>
                        <h3>신규 리뷰 생성</h3>
                    </Modal.Content>
                    <Modal.Content>
                        <Form>
                            <Image src={this.state.newReview.image}/>
                            <Form.Input
                                name={'number'}
                                label={'number'}
                                value={this.state.newReview.number}
                                onChange={this._handleChangeNewReviewData}
                            />
                            <Form.Input
                                name={'title'}
                                label={'title'}
                                value={this.state.newReview.title}
                                onChange={this._handleChangeNewReviewData}
                            />

                            <Form.Input
                                name={'subTitle'}
                                label={'subTitle'}
                                value={this.state.newReview.subTitle}
                                onChange={this._handleChangeNewReviewData}
                            />
                            <Form.TextArea
                                name={'content'}
                                label={'content'}
                                value={this.state.newReview.content}
                                onChange={this._handleChangeNewReviewData}
                            />
                            <Form.Input
                                name={'userName'}
                                label={'userName'}
                                value={this.state.newReview.userName}
                                onChange={this._handleChangeNewReviewData}
                            />

                            <Form.TextArea
                                name={'userDescription'}
                                label={'userDescription'}
                                value={this.state.newReview.userDescription}
                                onChange={this._handleChangeNewReviewData}
                            />

                            <Form.Field>
                                <label>프로필 사진 추가</label>
                                <input type="file" onChange={e => {
                                    this.onNewReviewImageUpload(e)
                                }}
                                       accept=".jpg, .jpeg, .png, .heic"/>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={() => {
                            this._completeAddReview()
                        }}>
                            확인
                        </Button>
                        <Button negative onClick={() => {
                            this._cancelAdd()
                        }}>
                            취소
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.state.openModifyModal}>
                    <Modal.Content>
                        <h3>수정</h3>
                    </Modal.Content>
                    <Modal.Content>
                        <Form>
                            <Image src={this.state.modifyReview.image}/>
                            <Form.Input
                                name={'number'}
                                label={'number'}
                                value={this.state.modifyReview.number}
                                onChange={this._handleChangeModifyReviewData}
                            />
                            <Form.Input
                                name={'title'}
                                label={'title'}
                                value={this.state.modifyReview.title}
                                onChange={this._handleChangeModifyReviewData}
                            />

                            <Form.Input
                                name={'subTitle'}
                                label={'subTitle'}
                                value={this.state.modifyReview.subTitle}
                                onChange={this._handleChangeModifyReviewData}
                            />
                            <Form.TextArea
                                name={'content'}
                                label={'content'}
                                value={this.state.modifyReview.content}
                                onChange={this._handleChangeModifyReviewData}
                            />
                            <Form.Input
                                name={'userName'}
                                label={'userName'}
                                value={this.state.modifyReview.userName}
                                onChange={this._handleChangeModifyReviewData}
                            />

                            <Form.TextArea
                                name={'userDescription'}
                                label={'userDescription'}
                                value={this.state.modifyReview.userDescription}
                                onChange={this._handleChangeModifyReviewData}
                            />

                            <Form.Field>
                                <label>프로필 사진 추가</label>
                                <input type="file" onChange={e => {
                                    this.onModifyReviewImageUpload(e)
                                }}
                                       accept=".jpg, .jpeg, .png, .heic"/>
                            </Form.Field>
                        </Form>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button positive onClick={() => {
                            this._completeModifyReview()
                        }}>
                            확인
                        </Button>
                        <Button negative onClick={() => {
                            this._cancelModify()
                        }}>
                            취소
                        </Button>
                    </Modal.Actions>
                </Modal>


                {/*<Segment vertical basic>
                    <Container>
                        <Card.Group itemsPerRow={5}>
                            {
                                this.state.mainReviews.map((review, i) => {
                                    return (
                                        <Card key={i}>
                                            <Image src={review.image}/>
                                            <Card.Content>
                                                <Form>
                                                    <Form.Input
                                                        name={'number'}
                                                        label={'number'}
                                                        value={review.number}
                                                        onChange={(e, d) => {
                                                            this._handleChangeReviewData(d, i)
                                                        }}
                                                    />

                                                    <Form.Input
                                                        name={'title'}
                                                        label={'title'}
                                                        value={review.title}
                                                        onChange={(e, d) => {
                                                            this._handleChangeReviewData(d, i)
                                                        }}
                                                    />

                                                    <Form.Input
                                                        name={'subTitle'}
                                                        label={'subTitle'}
                                                        value={review.subTitle}
                                                        onChange={(e, d) => {
                                                            this._handleChangeReviewData(d, i)
                                                        }}
                                                    />

                                                    <Form.TextArea
                                                        name={'content'}
                                                        label={'content'}
                                                        value={review.content}
                                                        onChange={(e, d) => {
                                                            this._handleChangeReviewData(d, i)
                                                        }}
                                                    />

                                                    <Form.Input
                                                        name={'userName'}
                                                        label={'userName'}
                                                        value={review.userName}
                                                        onChange={(e, d) => {
                                                            this._handleChangeReviewData(d, i)
                                                        }}
                                                    />

                                                    <Form.TextArea
                                                        name={'userDescription'}
                                                        label={'userDescription'}
                                                        value={review.userDescription}
                                                        onChange={(e, d) => {
                                                            this._handleChangeReviewData(d, i)
                                                        }}
                                                    />

                                                    <Form.Field>
                                                        <label>프로필 사진 추가</label>
                                                        <input type="file" onChange={e => {
                                                            this._onImageUpload(e, i);
                                                        }}
                                                               accept=".jpg, .jpeg, .png, .heic"/>
                                                    </Form.Field>
                                                </Form>
                                            </Card.Content>
                                            <Card.Content>
                                                <Button onClick={() => this._modifyReview(i)}>
                                                    수정하기
                                                </Button>
                                            </Card.Content>
                                        </Card>
                                    )
                                })
                            }

                            <Card>
                                <Image src={this.state.newReview.image}/>
                                <Card.Content>
                                    <Form>
                                        <Form.Input
                                            name={'number'}
                                            label={'number'}
                                            value={this.state.newReview.number}
                                            onChange={this._handleChangeNewReviewData}
                                        />
                                        <Form.Input
                                            name={'title'}
                                            label={'title'}
                                            value={this.state.newReview.title}
                                            onChange={this._handleChangeNewReviewData}
                                        />

                                        <Form.Input
                                            name={'subTitle'}
                                            label={'subTitle'}
                                            value={this.state.newReview.subTitle}
                                            onChange={this._handleChangeNewReviewData}
                                        />
                                        <Form.TextArea
                                            name={'content'}
                                            label={'content'}
                                            value={this.state.newReview.content}
                                            onChange={this._handleChangeNewReviewData}
                                        />
                                        <Form.Input
                                            name={'userName'}
                                            label={'userName'}
                                            value={this.state.newReview.userName}
                                            onChange={this._handleChangeNewReviewData}
                                        />

                                        <Form.TextArea
                                            name={'userDescription'}
                                            label={'userDescription'}
                                            value={this.state.newReview.userDescription}
                                            onChange={this._handleChangeNewReviewData}
                                        />

                                        <Form.Field>
                                            <label>프로필 사진 추가</label>
                                            <input type="file" onChange={e => {
                                                this.onNewReviewImageUpload(e)
                                            }}
                                                   accept=".jpg, .jpeg, .png, .heic"/>
                                        </Form.Field>
                                    </Form>
                                </Card.Content>
                                <Card.Content>
                                    <Button onClick={this._addReview}>
                                        추가 하기
                                    </Button>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Container>
                </Segment>*/}
            </Fragment>

        );
    }
}


export default AdminReviewContainer;
