import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Grid, Card, Header, Image} from "semantic-ui-react";
import {BDCOLOR, BDCOLOR_SUB} from "../../constants/BDBrand";
import {db} from "../../config/firebase";
import RecruitingNowHorizontalBannerComponent from "../../components/home/RecruitingNowHorizontalBannerComponent";
import Analytics from "../../middleware/Analytics";

class AboutMainContainer extends Component {


    constructor(props) {
        super(props);
        this.state = {
            allMembers: []
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        Analytics.pageViewAll(`/about`);
        this.loadAllMembers()
    }

    loadAllMembers = () => {

        db.collection('members')
            .get()
            .then(snapshot => {
                const allMembers = [];

                snapshot.forEach(doc => {
                    const member = doc.data();
                    member.id = doc.id;

                    allMembers.push(member);
                })

                this.setState({
                    allMembers
                })

            })
    }


    render() {
        return (
            <Fragment>
                <div style={{backgroundColor: BDCOLOR_SUB, paddingTop: 30, paddingBottom: 30, marginBottom: 50}}>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn slow'}>
                        <p style={{color: 'white'}}>
                            Being&Doing
                        </p>
                        <div style={{fontSize: '3rem', color: 'white', fontWeight: '700'}}
                             className={'hanSerifJapanese'}>
                            BD 알아보기
                        </div>
                        <Header inverted>
                            <Header.Subheader>
                                내가 바라는 나의 미래를 실현하기 위해 노력하는 사람들의 모임
                            </Header.Subheader>
                        </Header>
                    </Container>
                    <Container as={Segment}
                               textAlign={'center'}
                               vertical
                               basic style={{paddingTop: 30}}
                               className={'animated fadeIn delay-1s slow'}>
                        <div style={{
                            backgroundImage: `url(/images/background/chairsclub2018spring.jpg)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%',
                            minHeight: 300,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span style={{color: 'white'}} className={'hanSerifJapanese'}>
                                2018 봄 - BD 마지막 Chair's club
                            </span>
                        </div>
                    </Container>
                </div>

                <Segment vertical basic style={{marginTop: 50}}>
                    <Container text>
                        <Header size={'large'} style={{color: BDCOLOR}}>
                            BD는 어떤 모임인가요?
                        </Header>
                        <p>
                            Being&Doing은 내가 원하고 바라는 대로 인생을 살아가기 위해, <br/>
                            4개월 단위로 목표를 설정하고 성취해 나가는 자기계발 모임입니다. <br/>
                        </p>
                        <p>
                            나를 이루어 나가는 것이 무엇인지 생각해보고 <br/>
                            스스로 어떤 삶을 살고 싶은지 <br/>
                            어떤 하루를 보내고 싶은지, 스스로 결정하고, <br/>
                            그렇게 살아가기 위해서, 더 나아지기 위해서 <br/>
                            스스로 목표를 설정하고 그것을 달성하기 위해서 실천하는 모임입니다.
                        </p>
                    </Container>
                </Segment>

                <Segment vertical basic style={{marginTop: 50}}>
                    <Container text>
                        <Header size={'large'} style={{color: BDCOLOR}}>
                            BD Crew
                            <Header.Subheader>
                                Being&Doing에는 어떤 사람들이 있나요?
                            </Header.Subheader>
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            BD는 한 기수에 40~80명 정도의 크루들이 참여하는데요, <br/>
                            직장인부터, 대학생까지 다양한 연령대의 멤버들이 있습니다. <br/>
                            이중 대다수는 직장인들로 구성되어 있습니다. <br/>
                            직종은 스타트업 창업자, 대기업 직장인 뿐만 아니라, 의사, 변호사, 수의사 등등 직종에 관계없이 정말 많은 분들이 참여하고 있습니다.
                        </p>
                        <p style={{lineHeight: '180%'}}>
                            평소에는 대화를 많이 나눌 수 없는 다양한 직종의 사람들을 만날 수 있다는 것도 BD의 장점 중 하나입니다.
                        </p>
                    </Container>
                </Segment>


                <Segment vertical basic style={{marginTop: 50}}>
                    <Container text>
                        <Header size={'large'} style={{color: BDCOLOR}}>
                            시즌 구성
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            봄, 가을 시즌이 있고, 한 시즌은 4개월(16주)간 진행됩니다. <br/>
                            2주마다 한 번씩 총 8번의 모임이 진행됩니다.
                        </p>
                        <Image
                            src={'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/homepage%2FseasonDiagram.png?alt=media&token=ed446c1a-3568-4ff4-8f2c-7f23e8832675'}/>
                    </Container>
                </Segment>

                {/* 지원과 선발 */}
                <Segment vertical basic style={{marginTop: 20}}>
                    <Container text>
                        <Header size={'medium'} style={{color: BDCOLOR_SUB}}>
                            #1 지원과 선발
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            시즌이 시작되기 1달 전 (2월, 8월)에 홈페이지를 통해 신청하실 수 있습니다. <br/>
                            지원서에는 본인의 소개를 포함하여, 4개월동안 어떤 것을 하고 싶은지, 궁극적인 인생의 목표가 있다면 무엇인지, 작성합니다.
                        </p>
                    </Container>
                </Segment>

                {/* 목표 설정 */}
                <Segment vertical basic style={{marginTop: 20}}>
                    <Container text>
                        <Header size={'medium'} style={{color: BDCOLOR_SUB}}>
                            #2 목표 설정
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            BD Paper 작성을 통해 4개월의 목표를 설정합니다. <br/>
                            장기적: 인생을 통해 이루고자 하는 것들, 하고 싶은 것들에 대해서 생각해 봅니다. <br/>
                            단기적: 인생의 목표와 현재 필요한 것들을 고려하여 목표를 설정합니다.
                        </p>
                        <Image
                            src={'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/homepage%2Fbdpaper.png?alt=media&token=a0b755f8-b03a-4e5c-91ba-f6bcf20f47bd'}/>
                    </Container>
                </Segment>

                {/* OT */}
                <Segment vertical basic style={{marginTop: 20}}>
                    <Container text>
                        <Header size={'medium'} style={{color: BDCOLOR_SUB}}>
                            #3 OT
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            시즌 시작을 알리는 행사로, <br/>
                            한 시즌의 전체 멤버가 모두 모이는 자리입니다. 4개월을 함께 보낼 팀원들을 만날 수 있는 자리입니다. <br/>
                            16주 동안 달성할 목표를 공유하고, 팀의 그라운드 룰을 정합니다.
                        </p>
                        <Image
                            src={'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/homepage%2Fot.jpg?alt=media&token=96e796ee-c871-4343-b600-7d9157289548'}/>
                    </Container>
                </Segment>

                {/* 팀모임 */}
                <Segment vertical basic style={{marginTop: 20}}>
                    <Container text>
                        <Header size={'medium'} style={{color: BDCOLOR_SUB}}>
                            #4 팀모임
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            BD의 가장 중요한 활동입니다. <br/>
                            팀 모임은 Being(나에 대한 에세이 쓰기)과 Doing(목표 달성)으로 구성되어 있어요.<br/>
                        </p>

                        <p style={{lineHeight: '180%'}}>
                            4~5명으로 구성된 각 팀은 격주로 주말 오전에 2시간 정도 모임을 진행하게 되는데, 2주 동안 고민해서 작성한 에세이와 수행한 목표 달성 내용에 대해서 공유하는
                            것이 주요 활동입니다.<br/>
                        </p>
                        <p style={{lineHeight: '180%'}}>
                            (Being) 내가 작성한 에세이 내용에 대해서 팀원들의 피드백을 받을 수 있고, 팀원이 작성한 에세이를 통해 내가 평소에 생각해 보지 않았던 주제나 견해를 알 수
                            있어요.<br/>
                        </p>
                        <p style={{lineHeight: '180%'}}>
                            (Doing) 2주 동안 달성한 내역은 어떤 것들이 있는지, 목표를 수행하면서 어려웠던 점이나 잘한 점은 무엇인지 등을 나누면서, 서로 자극을 주고 지속할 수 있게
                            되는 거죠.​​<br/>
                        </p>
                    </Container>

                </Segment>

                {/* Talk */}
                <Segment vertical basic style={{marginTop: 20}}>
                    <Container text>
                        <Header size={'medium'} style={{color: BDCOLOR_SUB}}>
                            #5 BD Talk
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            시즌의 절반이 지날 때쯤 열리는 BD 표 TED입니다.
                        </p>
                        <p style={{lineHeight: '180%'}}>
                            전체 멤버뿐만 아니라, BD Alumni와 Guest들도 참여할 수 있는 열려있는 행사입니다. 멤버들에게 공유하면 좋을 생각과 경험을 가진 멤버들을 대상으로 발표자를
                            선정해서, 4개 정도의 발표를 듣고 이야기를 나누는 자리입니다.
                        </p>
                        <p style={{lineHeight: '180%'}}>
                            발표가 끝나면 뒤풀이를 진행해서, 다른 팀 멤버들과 친해질 수 있는 기회를 갖습니다.
                        </p>
                        <Image
                            src={'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/homepage%2Fbdtalk.jpg?alt=media&token=6b1cceab-a494-4ea8-9959-86fc2ff7915c'}/>
                    </Container>

                </Segment>

                {/* Closing */}
                <Segment vertical basic style={{marginTop: 20}}>
                    <Container text>
                        <Header size={'medium'} style={{color: BDCOLOR_SUB}}>
                            #6 Closing
                        </Header>
                        <p style={{lineHeight: '180%'}}>
                            8번의 팀 모임이 끝나면 시즌의 졸업파티인 Closing이 진행됩니다.
                        </p>
                        <p style={{lineHeight: '180%'}}>
                            Closing에서는 4개월 전, 시즌을 시작할 때 세워놓은 목표를 리뷰하고 소감을 공유하는 자리가 있고, 전체 멤버를 모두 만나서 친해질 수 있는 행사와 뒤풀이가
                            진행됩니다. 전체 멤버를 만날 수 있기 때문에, 동창회 같은 느낌도 있고요, 맥주와 간단한 음식을 마시다 보면 새벽까지 이야기를 하기도 해요.
                        </p>
                        <Image
                            src={'https://firebasestorage.googleapis.com/v0/b/beingndoing-web.appspot.com/o/homepage%2Fclosing.jpg?alt=media&token=c0260d6f-3669-422f-835c-b4bd3fe60c24'}/>
                    </Container>

                </Segment>

                {/* 모집 중인 시즌 */}
                <RecruitingNowHorizontalBannerComponent/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AboutMainContainer);
