import React, {Component, Fragment} from 'react';
import {db} from "../../config/firebase";
import {Card, Image, Button, Segment, Container, Header,} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import AdminSubMenuComponent from "../../components/admin-home/AdminSubMenuComponent";
import {connect} from 'react-redux';

class AdminBlogMainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadBlogList();
    }

    loadBlogList = async () => {
        const blogRef = db.collection('blogs').orderBy('number', 'desc');
        blogRef
            .get()
            .then(snapshot => {
                const blogs = [];
                snapshot.forEach(doc => {
                    const blog = doc.data();
                    blog.id = doc.id;
                    blogs.push(blog);
                })
                this.setState({
                    blogs
                })
            })
            .catch(e => {
                console.log(e);
            })
    };

    render() {
        return (
            <Fragment>
                <AdminSubMenuComponent/>
                <Container as={Segment} vertical>
                    <Button as={Link} to={{pathname: '/admin/blog/write'}}>
                        새로만들기
                    </Button>
                </Container>
                <Container as={Segment} vertical>
                    <Card.Group itemsPerRow={3}>
                        {
                            this.state.blogs.map((blog, i) => {
                                return (
                                    <Card key={i} as={Link} to={{pathname: `/admin/blog/read/${blog.id}`}}>
                                        <Image src={blog.image}/>
                                        <Card.Content>
                                            <Header>
                                                {blog.title}
                                                <Header.Subheader>
                                                    {blog.subTitle}
                                                </Header.Subheader>
                                            </Header>
                                        </Card.Content>
                                        <Card.Content>
                                            {blog.isDeleted ? (
                                                '삭제됨'
                                            ) : (
                                                ''
                                            )}
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminBlogMainContainer);
