import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Container, Button} from 'semantic-ui-react'

class AdminSubMenuComponent extends Component {
    render() {
        return (
            <Container>
                <Button as={Link} to={'/admin/blog'}>
                    블로그
                </Button>
                <Button as={Link} to={'/admin/season'}>
                    시즌
                </Button>
                <Button as={Link} to={'/admin/team'}>
                    팀
                </Button>
                <Button as={Link} to={'/admin/member'}>
                    멤버
                </Button>
                <Button as={Link} to={'/admin/talk'}>
                    토크
                </Button>
                <Button as={Link} to={'/admin/review'}>
                    리뷰
                </Button>
                <Button as={Link} to={'/admin/faq'}>
                    FAQ
                </Button>
                <Button as={Link} to={'/admin/event'} color={'yellow'}>
                    Event
                </Button>
                <Button as={Link} to={'/admin/goal'}>
                    목표
                </Button>
                <Button as={Link} to={'/admin/bdpaper'}>
                    BD Paper
                </Button>
                <Button as={Link} to={'/admin/essay/subject'}>
                    Essay 주제
                </Button>
                <Button as={Link} to={'/admin/essay/list'}>
                    Essay
                </Button>
            </Container>
        );
    }
}


export default AdminSubMenuComponent;
